import React from 'react';
import { connect } from 'react-redux';
const EMAILS = {
  US: {
    professional: 'ETARequests@am.sony.com ',
    consumer: 'SonyOrderManagement@am.sony.com '
  },
  CA: {
    professional: 'Order_Management@sony.ca',
    consumer: 'CanadaConsumerOM@am.sony.com'
  }
};
const EmailProvider = ({ contract_type, country }) => {
  return <a href={`mailto:${EMAILS[country][contract_type]}`} style={{ color: '#3072AB'}}>{EMAILS[country][contract_type]}</a>;
};

const mapStateToProps = ({ country, account: { contract_type } }) => ({ country, contract_type });
export default connect(mapStateToProps)(EmailProvider);
