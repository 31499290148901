import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateCart } from '../../modules/cart';
import { Table, Collapse } from 'react-bootstrap';
import Gallery from '../Gallery/Gallery';
import { getProduct, resetProduct } from '../../modules/product';
import { formValueSelector } from 'redux-form';
import Translate, { translate } from '../dictionary/Translator';
import ui from './dictionary/ui.json';
import './productDetail.css';
import BulletinSearchResults from '../bulletin/BulletinSearchResults';
import StickyContainer from '../StickyContainer/StickyContainer';
import Loading from '../Loading';
import { replaceHTMLTagsWithUnicodes, VerticalWrapper, groupSpecificationByAttribute } from '../utils/renderUtils';
import AccessoryThumb from '../AccessoryThumb/AccessoryThumb';
import cartUI from '../cart/dictionary/ui';
import { successToast } from '../utils/renderUtils';
import { getProductBulletins } from '../bulletin/BulletinHelper';
import validator from 'validator';
import * as orderActions from '../../modules/order';
import * as errorActions from '../../modules/error';
import * as utilsActions from '../../modules/utils';
import errorDictionary from '../dictionary/error.json';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
const selector = formValueSelector('cart');


class ProductDetail extends Component {
  state = {
    lang: '',
    fetching: true,
    qty: 0,
    bqty: 0,
    inQty: 0,
    qtyError: {val:false, type: ''},
    bqtyError: null,
    inQtyError: null,
    descriptionGlyph: 'glyphicon glyphicon-plus',
    descriptionCollapse: 'collapse',
    highlightsGlyph: 'glyphicon glyphicon-plus',
    highlightsCollapse: 'collapse',
    featuresGlyph: 'glyphicon glyphicon-plus',
    featuresCollapse: 'collapse',
    specificationsGlyph: 'glyphicon glyphicon-plus',
    specificationsCollapse: 'collapse',
    accessoriesCollapse: 'collapse',
    accessoriesGlyph: 'glyphicon glyphicon-plus',
    bulletinsCollapse: 'collapse',
    bulletinsGlyph: 'glyphicon glyphicon-plus',
  };

  static getDerivedStateFromProps(newProps, prevState) {
    if (prevState.lang !== newProps.lang) {
      newProps.getProduct(newProps.customerType, newProps.sku, newProps.lang);
    }
    return { lang: newProps.lang, isCartFull: newProps.isCartFull };
  }

  componentDidMount() {
    this.props.resetError();
    this.props.clearPreOrder();
    this.props.resetProduct();
    this.props
      .getProduct(this.props.customerType, this.props.prices.originalSku, this.props.lang)
      .then(() => {
        this.setState({ fetching: false });
      })
      .catch(() => {
        this.setState({ fetching: false });
      });
    this.setState({ lang: this.props.lang });
  }
  componentWillUnmount() {
    this.props.resetProduct();
    this.props.clearPreOrder();
    this.props.resetError();
  }

  handleQtyChange(event, multiplesOf ) {
    if (event.target.id === 'quantity') {
      if (this.isQuantityValid(event.target.value)) {
        if(multiplesOf && multiplesOf.val > 1){
          if(multiplesOf.type !== 'MOQ' && event.target.value % multiplesOf.val !== 0){
            return this.setState({ qty: event.target.value, qtyError: {val: true, type: 'MUL' }});
          } else if(multiplesOf.type === 'MOQ' && multiplesOf.val > 1 && event.target.value < multiplesOf.val){
            return this.setState({ qty: event.target.value, qtyError: {val: true, type: 'MOQ' } });
          }
        }
        return this.setState({ qty: event.target.value, qtyError: {val: false, type: '' } });
      } else {
        this.setState({ qty: event.target.value, qtyError:  {val: true, type: 'invalid' } });
      }
    } else {
      if (this.isQuantityValid(event.target.value)) {
        this.setState({ bqty: event.target.value, bqtyError: null });
      } else {
        this.setState({ bqty: event.target.value, bqtyError: true });
      }
    }
  }

  isQuantityValid = value => {
    if (!value || value.trim().length === 0 || !validator.isInt(value, { min: 0 })) {
      return false;
    }
    return true;
  };

  resetQuantity = e => {
    const key = e.target.id === 'bsquantity' ? 'bqty' : 'qty';
    const value = e.target.value;
    const err = key === 'bqty' ? null : {val:false, type: ''}
    if (!value || value.trim().length === 0) {
      this.setState({ [key]: 0, [`${key}Error`]: err });
    }
  };

  handleAddToCart(event) {
    event.preventDefault();
    const { lang } = this.props;
    const id = event.target.id;
    let AcartQty,BcartQty, cartSku;
    if (id === 'addtocart' || id === 'quantity') {
      AcartQty = this.state.qty;
      cartSku = this.props.prices.sku;
    } else {
      BcartQty = this.state.bqty;
      cartSku = this.props.prices.sku
    }
    if ((AcartQty === 0 || !this.isQuantityValid(AcartQty))&&
     (BcartQty === 0 || !this.isQuantityValid(BcartQty))) {
      return;
    } 

    let newItems = [];
    newItems.push({ sku: cartSku, 
      ...(AcartQty && {AStockQty:(AcartQty).toString(), grade: 'A'}), 
			...(BcartQty && this.props.prices.has_Bstock && {BStockQty:(BcartQty).toString(), grade: 'B'}) 
    });
    let cartItems = this.props.cartItems;
    newItems = cartItems ? cartItems.concat(newItems) : newItems;
    this.props.updateCart(newItems);
    successToast(
      translate({
        word: cartUI['CART_UPDATE_NOTIFICATION_SUCCESS'],
        language: lang
      })
    );
  !isNaN(BcartQty) ? this.setState({ bqty: 0 }) : this.setState({ qty: 0 });
  }

  renderAddToQtyButtons() {
    const { isCartFull, qtyError } = this.state;
    const multiplesOf = utilsActions.getMultiplesOf(this.props.prices, this.props.buyer)
    return (
      <div>
        <div>
          <span className="disclaimer text-success" align="center" style={{ fontWeight: 'bold' }}>
            <Translate dictionary={ui} word="PRICING_CONFIRMATION" />
          </span>
        </div>
        <form className="form-inline form-inline-multi">
          <div className="form-group" style={{ width: '75%' }}>
            <label htmlFor="quantity">
              <Translate dictionary={ui} word="AQTY" />
            </label>
            <input
              id="quantity"
              type="text"
              name="quantity"
              maxLength={9}
              onChange={event => this.handleQtyChange(event, multiplesOf)}
              value={this.state.qty}
              className="quantity-input"
              style={{
                color: `${qtyError && qtyError.val ? '#b71c1c' : 'inherit'}`
              }}
              onBlur={this.resetQuantity}
            />
            <button
              style={{
                marginLeft: '5px',
                marginBottom: '1%',
                fontSize: '1.3rem'
              }}
              data-toggle="tooltip"
              title={translate({ word: ui['QTY_TOOLTIP'], language: this.props.lang })}
              className="btn btn-success"
              id="addtocart"
              type="submit"
              onClick={event => this.handleAddToCart(event)}
              disabled={isCartFull || (qtyError && qtyError.val) || this.state.qty < 1}
            >
              <span className="glyphicon glyphicon-shopping-cart" /> <Translate dictionary={ui} word="ADD_TO_CART" />
            </button>
            {qtyError && qtyError.val && (
              qtyError.type === 'invalid' ?
                <span style={{ color: '#b71c1c', display: 'block' }}>
                  <Translate dictionary={ui} word="INVALID_QUANTITY" />
                </span>
                : (qtyError.type === 'MUL' ?
                    <span  style={{ color: '#b71c1c', display: 'block' }}>
                      <Translate dictionary={ui} word='MULTIPLE_OF_ERR_QTY' />
                      {multiplesOf.val}
                    </span> : 
                    <span style={{ color: '#b71c1c', display: 'block' }}>
                      <Translate dictionary={ui} word='MIN_ORDER_QTY_ERR' />
                      {multiplesOf.val}
                    </span>
                  )
            )}
          </div>

          {this.props.prices.has_Bstock && (
            <div className="form-group">
              <label htmlFor="bsquantity">
                <Translate dictionary={ui} word="BQTY" />
              </label>
              <input
                id="bsquantity"
                type="text"
                maxLength={9}
                onChange={event => this.handleQtyChange(event)}
                value={this.state.bqty}
                className="quantity-input"
                style={{
                  color: `${this.state.bqtyError ? '#b71c1c' : 'inherit'}`
                }}
                onBlur={this.resetQuantity}
                aria-label = "B-Stock Quantity"
              />
              <button
                style={{ marginLeft: '5px', fontSize: '1.3rem' }}
                className="btn btn-success"
                id="bsaddtocart"
                data-toggle="tooltip"
                title={translate({ word: ui['QTY_TOOLTIP'], language: this.props.lang })}
                type="submit"
                onClick={event => this.handleAddToCart(event)}
                disabled={isCartFull || this.state.bqtyError || this.state.bqty < 1}
              >
                <span className="glyphicon glyphicon-shopping-cart" /> <Translate dictionary={ui} word="BSCART" />
              </button>
              {this.state.bqtyError && (
                <span style={{ color: '#b71c1c' }}>
                  <Translate dictionary={ui} word="INVALID_QUANTITY" />
                </span>
              )}
            </div>
          )}
        </form>
        <div>
         {multiplesOf && multiplesOf.val > 1 ? 
            <div>
              {multiplesOf.type !== 'MOQ' ? 
                <i align="center" style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
                  <Translate dictionary={ui} word="CARTON_QTY_MESSAGE_1" />
                  {multiplesOf.val}
                  <Translate dictionary={ui} word="CARTON_QTY_MESSAGE_2" />
                </i>
              :
                <i align="center" style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
                  <Translate dictionary={ui} word="MINIMUM_QTY_MESSAGE" />{multiplesOf.val}
                </i>}
            </div>
          : ''}
          <div>
            <i align="center" style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
              <Translate dictionary={ui} word="CART_LIMIT" />
            </i>
          </div>
        </div>
        <div>
          {this.state.isCartFull && (
            <span align="center" className="text-danger">
              <Translate dictionary={ui} word="CART_FULL" />
            </span>
          )}
        </div>
        <br />
        <div>
          {' '}
          <b>
            <Translate dictionary={ui} word="INVENTORY" />:
          </b>
          <br />
          <div className="form-group" style={{ width: '86%' }}>
            <label htmlFor="inventoryQuantity">
              <Translate dictionary={ui} word="QTY" />
            </label>
            <input
              id="inventoryQuantity"
              type="text"
              maxLength={9}
              onChange={event => {
                if (this.isQuantityValid(event.target.value)) {
                  this.setState({ inQty: event.target.value, inQtyError: null });
                } else {
                  this.setState({ inQty: event.target.value, inQtyError: true });
                }
              }}
              value={this.state.inQty}
              className="quantity-input"
              style={{
                color: `${this.state.inQtyError ? '#b71c1c' : 'inherit'}`
              }}
              onBlur={event => {
                if (!event.target.value || event.target.value.trim().length === 0) {
                  this.setState({ inQty: 0, inQtyError: null });
                }
              }}
              aria-label="inventory Quantity"

            />
            <button
              style={{ marginLeft: '5px', fontSize: '1.3rem' }}
              className="btn btn-primary"
              id="checkinventory"
              type="submit"
              data-toggle="tooltip"
              title={translate({ word: ui['QTY_TOOLTIP'], language: this.props.lang })}
              onClick={() => {
                this.props.resetError();
                let items = [];
                if (this.state.inQty > 0) {
                  items.push({ sku: this.props.prices.sku, qty: this.state.inQty, grade: 'A' });
                 this.props.prices.has_Bstock && items.push({ sku: this.props.prices.sku, qty: this.state.inQty, grade: 'B' });
                }
                let billToId = utilsActions.getDefaultAddress(this.props.addresses, this.props.buyer, utilsActions.billToAddressType);
                let payerId = utilsActions.getDefaultAddress(this.props.addresses, this.props.buyer, utilsActions.payerAddressType);
                let langId = translate({ word: cartUI['LANG_ID'], language: this.props.lang });
                this.props.orderBlock === '01'
                  ? this.props.setError()
                  : this.props.simulateOrder(
                      {
                        items: items,
                        shippingAddress: this.props.buyer.id
                      },
                      this.props.buyer,
                      payerId,
                      billToId,
                      this.props.contract,
                      this.props.user,
                      langId
                    );
              }}
              disabled={this.state.inQtyError || this.state.inQty < 1}
            >
              <Translate dictionary={ui} word="CHECK_INVENTORY" />
            </button>
            <br></br>
            {this.state.inQtyError && (
              <span style={{ color: '#b71c1c' }}>
                <Translate dictionary={ui} word="INVALID_QUANTITY" />
              </span>
            )}
          </div>
          {this.props.error && (
            <div className="text-danger">
              <h1 style={{ fontSize: '1.2rem', fontWeight: '10' }}>
                <Translate dictionary={errorDictionary} word={this.props.error.errorPath === 'INVENTORY_ERROR' ? 'INVENTORY_ERROR' : 'GENERIC_ERROR'} />
              </h1>
            </div>
          )}
          {this.props.preorder && inventoryAvailabilityTable(this.props)}
        </div>
      </div>
    );
  }
  handleCollapseToggleOnClick(section) {
    if (this.state[section + 'Collapse'] === 'collapse') {
      this.setState({
        [section + 'Glyph']: 'glyphicon glyphicon-minus',
        [section + 'Collapse']: 'collapse in'
      });
    } else {
      this.setState({
        [section + 'Glyph']: 'glyphicon glyphicon-plus',
        [section + 'Collapse']: 'collapse'
      });
    }
  }

  renderSpecifications() {
    const technicalFeatureGroups = this.props.product.techSpecs;
    return (
      <div className="container">
        <div className="row">
          {technicalFeatureGroups && technicalFeatureGroups.length > 0 && (
            <div>
              <h3 style={{ padding: '1em' }} className="ProductDetail__collpase-header" onClick={() => this.handleCollapseToggleOnClick('specifications')}>
                <Translate dictionary={ui} word="SPECS" />
                <span style={{ float: 'right', cursor: 'pointer' }} className={this.state.specificationsGlyph} />
              </h3>
              <Collapse className={this.state.specificationsCollapse}>
                <div>{renderSpecificationsTable(technicalFeatureGroups)}</div>
              </Collapse>
            </div>
          )}
        </div>
      </div>
    );
  }

  renderFeatures() {
    const renderFeaturesContent = renderFeaturesTable(this.props.product);
    return (
      <div className="container">
        <div className="row">
          {renderFeaturesContent && (
            <div>
              <h3 style={{ padding: '1em' }} onClick={() => this.handleCollapseToggleOnClick('features')} className="ProductDetail__collpase-header">
                <Translate dictionary={ui} word="FEATURES" />
                <span className={this.state.featuresGlyph} style={{ float: 'right', cursor: 'pointer' }} />
              </h3>
              <Collapse className={this.state.featuresCollapse}>{renderFeaturesContent}</Collapse>
            </div>
          )}
        </div>
      </div>
    );
  }

  renderLongDescription() {
    var shortDesc = this.props.prices.short_desc ? this.props.prices.short_desc[this.props.language] : '';
    const description = this.props.product.description ? this.props.product.description : shortDesc;
    return (
      <div className="container">
        <div className="row">
          {description && (
            <div>
              <h3 style={{ padding: '1em' }} onClick={() => this.handleCollapseToggleOnClick('description')} className="ProductDetail__collpase-header">
                <Translate dictionary={ui} word="LONG_DESCRIPTION" />
                <span className={this.state.descriptionGlyph} style={{ float: 'right', cursor: 'pointer' }} />
              </h3>
              <Collapse className={this.state.descriptionCollapse}><div style={{padding: '10px'}}><VerticalWrapper>{ReactHtmlParser(replaceHTMLTagsWithUnicodes(description))}</VerticalWrapper></div></Collapse>
            </div>
          )}
        </div>
      </div>
    );
  }

  renderBulletins = () => {
    const { bulletins } = this.props;
    return (
      <div className="container">
        <div className="row">
          {bulletins && bulletins.length > 0 && (
            <div>
              <h3 style={{ padding: '1em' }} className="ProductDetail__collpase-header" onClick={() => this.handleCollapseToggleOnClick('bulletins')}>
                <Translate dictionary={ui} word="BULLETINS" />
                <span className={this.state.bulletinsGlyph} style={{ float: 'right', cursor: 'pointer' }} />
              </h3>
              <Collapse className={this.state.bulletinsCollapse}>
                <div>
                  <BulletinSearchResults results={bulletins} presentational={true} />
                </div>
              </Collapse>
            </div>
          )}
        </div>
      </div>
    );
  };

  renderHighlights() {
    let highlights = this.props.product.highlights
    return (
      <div className="container">
        <div className="row">
          {highlights && highlights.length > 0 && (
            <div>
              <h3 style={{ padding: '1em' }} className="ProductDetail__collpase-header" onClick={() => this.handleCollapseToggleOnClick('highlights')}>
                <Translate dictionary={ui} word="HIGHLIGHTS" />
                <span style={{ float: 'right', cursor: 'pointer' }} className={this.state.highlightsGlyph} />
              </h3>
              <Collapse className={this.state.highlightsCollapse}>
                <div>
                  <VerticalWrapper>
                    <ul>
                      {highlights.filter(item => item).map((aFact) => (
                        <li className="white-space-resolver" key={aFact + new Date().getTime}>
                          {ReactHtmlParser(replaceHTMLTagsWithUnicodes(aFact))}
                        </li>
                      ))}
                    </ul>
                  </VerticalWrapper>
                </div>
              </Collapse>
            </div>
          )}
        </div>
      </div>
    );
  }

  renderAccessories() {
    const accessories = this.props.product.accessories
    const relatedAccessories = accessories && accessories.length > 0 ? getRelatedAccessories(accessories, this.props.priceBook) : [];
    
    return (
      <div className="container">
        <div className="row">
          {relatedAccessories.length > 0 && (
            <div>
              <h3 style={{ padding: '1em' }} className="ProductDetail__collpase-header" onClick={() => this.handleCollapseToggleOnClick('accessories')}>
                <Translate dictionary={ui} word="ACCESSORIES" />
                <span style={{ float: 'right', cursor: 'pointer' }} className={this.state.accessoriesGlyph} />
              </h3>
              <Collapse className={this.state.accessoriesCollapse}>
                <div>
                  <div
                    style={{
                      borderBottom: '1px solid #ccc',
                      paddingBottom: '1%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-end'
                    }}
                  >
                    <div>
                      <span className="disclaimer" align="center">
                        <Translate dictionary={ui} word="CART_LIMIT_ERR" />
                      </span>
                    </div>
                    <div>
                      <span align="center" className="disclaimer">
                        <Translate dictionary={ui} word="DISCLAIMER" />
                      </span>
                    </div>
                    <div>
                      {this.state.isCartFull && (
                        <span align="center" className="text-danger">
                          <Translate dictionary={ui} word="CART_FULL" />
                        </span>
                      )}
                    </div>
                  </div>
                  <VerticalWrapper height={500}>
                    <ul style={{ padding: '0' }}>
                      {relatedAccessories.map((accessory, key) => (
                        <li key={key} className="ProductDetail__accessory">
                          <AccessoryThumb 
                            sku={accessory.sku} 
                            originalSku={accessory.originalSku} 
                            type ={accessory.type} 
                            description={accessory.desc} 
                            productAccessories =  {accessories.find(acc => acc.cfmModelName === accessory.sku )}
                          />
                        </li>
                      ))}
                    </ul>
                  </VerticalWrapper>
                </div>
              </Collapse>
            </div>
          )}
        </div>
      </div>
    );
  }
  renderOverviewAndPrices() {
    // const discntMsg =
    //   this.props.prices.discontinuedInd === 'Z3' || this.props.prices.discontinuedInd === 'Z4'
    //     ? `- ${translate({
    //         word: ui['PRODUCT_DISCONT_MSG'],
    //         language: this.props.lang
    //       })}`
    //     : null;
    return (
      <div>
        {renderPriceTable(this.props.prices)}
        <div>{this.renderAddToQtyButtons()}</div>
      </div>
    );
  }

  renderProductImagesAndDetails() {
    return (
      <div>
        {renderProductImages(this.props.product)}
        <div className="overview-prices col-md-6">{this.renderOverviewAndPrices()}</div>
      </div>
    );
  }
  render() {
    const { fetching } = this.state;
    var shortDesc = this.props.prices.short_desc ? this.props.prices.short_desc[this.props.language] : '';
    const factualescription = this.props.product.factualShortDescription ? this.props.product.factualShortDescription : shortDesc;
    
    return !fetching ? (
      <div className="container">
        {this.props.isFetching && !this.props.preorder && !this.props.error && <Loading />}
        <div className="row">
          <h1 style={{ fontSize: '2.5rem', fontWeight: '600' }}>{this.props.prices.sku}</h1>
          <h2 className="white-space-resolver" style={{ fontSize: '1.5rem' }}>
            {ReactHtmlParser(replaceHTMLTagsWithUnicodes(factualescription))}
          </h2>
          <br />
          <StickyContainer top={0} left={0}>
            <div className="ProductDetail__fixed">
              <span className="ProductDetail__fixed__sku">{this.props.prices.sku}</span>
              <span className="ProductDetail__fixed__desc white-space-resolver">{ReactHtmlParser(replaceHTMLTagsWithUnicodes(factualescription))}</span>
            </div>
          </StickyContainer>
          {this.renderProductImagesAndDetails()}
        </div>
        <hr />
        {this.renderLongDescription()}
        {this.renderFeatures()}
        {this.renderSpecifications()}
        {this.renderHighlights()}
        {this.renderAccessories()}
        {this.renderBulletins()}
      </div>
    ) : (
      <Loading />
    );
  }
}

function renderPriceTable(prices) {
  let tiertd = [],
    bstocktd = [],
    qtytd = [],
    bqtytd = [],
    bStockTiers = prices.has_Bstock ? prices.B_tiers.length : 0;
  if (prices.A_tiers.length > 0 || bStockTiers > 0) {
    if (prices.A_tiers.length > bStockTiers) {
      prices.A_tiers.forEach((aTier, key) => {
        tiertd.push([<td key={'tier' + key}>{aTier.price === '0.00' ? '' : '$' + aTier.price}</td>]);
        qtytd.push([
          <td key={'qty' + key}>
            {aTier.start_qty === null ? '1': aTier.start_qty}
            {aTier.end_qty === null ? '+' : '-' + aTier.end_qty}
          </td>
        ]);
      });

      if (prices.has_Bstock) {
        for (let i = 0; i < prices.B_tiers.length; i++) {
          bstocktd.push([
            prices.B_tiers[i] ? (
              <td key={'bstock' + i}>{prices.B_tiers[i].price === undefined || prices.B_tiers[i].price === '0.00' ? '' : '$' + prices.B_tiers[i].price}</td>
            ) : (
              <td />
            )
          ]);
          bqtytd.push([
            prices.B_tiers[i] ? (
              <td key={'qty' + i}>
                {prices.B_tiers[i].start_qty === null ? '1': prices.B_tiers[i].start_qty}
                {prices.B_tiers[i].end_qty === null ? '+' : '-' + prices.B_tiers[i].end_qty}
              </td>
            ) : (
              <td />
            )
          ]);
        }
      }
    } else {
      prices.B_tiers.forEach((aBstock, key) => {
        bstocktd.push([<td key={'bstock' + key}>{aBstock.price === '0.00' ? '' : '$' + aBstock.price}</td>]);
        bqtytd.push([
          <td key={'qty' + key}>
            {aBstock.start_qty === null ? '1' : aBstock.start_qty}
            {aBstock.end_qty === null ? '+' : '-' + aBstock.end_qty}
          </td>
        ]);
      });
      for (let i = 0; i < prices.B_tiers.length; i++) {
        tiertd.push([
          prices.A_tiers[i] ? (
            <td key={'tier' + i}>{prices.A_tiers[i].price === undefined || prices.A_tiers[i].price === '0.00' ? '' : '$' + prices.A_tiers[i].price}</td>
          ) : (
            <td />
          )
        ]);
        qtytd.push([
          prices.A_tiers[i] ? (
            <td key={'qty' + i}>
              {prices.A_tiers[i].start_qty === null ? '1': prices.A_tiers[i].start_qty}
              {prices.A_tiers[i].end_qty === null ? '+' : '-' + prices.A_tiers[i].end_qty}
            </td>
          ) : (
            <td />
          )
        ]);
      }
    }
  }
  let trArray = [];

  for (let i = 0; i < tiertd.length; i++) {
    trArray.push([
      <tr key={i}>
        {qtytd[i] ? qtytd[i] : <td />}
        {tiertd[i] ? tiertd[i] : <td />}
        {bqtytd[i] ? bqtytd[i] : <td />}
        {bstocktd[i] ? bstocktd[i] : <td />}
      </tr>
    ]);
  }
  return (
    <div>
      <Table responsive className="table-no-border price-table">
        <tbody>
          {prices.sppg_price && prices.sppg_price !== '0.00' && 
            <tr>
              <td>
                <b>
                  <Translate dictionary={ui} word="SPPG" />:
                </b>
              </td>
              <td>{!prices.sppg_price || prices.sppg_price === '0.00' ? '' : '$' + prices.sppg_price}</td>
            </tr>}
          {prices.map_price && prices.map_price !== '0.00' &&
            <tr>
              <td>
                <b>
                  <Translate dictionary={ui} word="MAP" />:
                </b>
              </td>
              <td>{!prices.map_price || prices.map_price === '0.00' ? '' : '$' + prices.map_price}</td>
            </tr>}
          {prices.list_price && prices.list_price !== '0.00' && 
            <tr>
              <td>
                <b>
                  <Translate dictionary={ui} word="LIST" />:
                </b>
              </td>
              <td>{!prices.list_price || prices.list_price === '0.00' ? '' : '$' + prices.list_price}</td>
            </tr>}
          {prices.sure_price && prices.sure_price !== '0.00' && 
            <tr>
              <td>
                <b>
                  <Translate dictionary={ui} word="SURE" />:
                </b>
              </td>
              <td>{!prices.sure_price || prices.sure_price === '0.00' ? '' : '$' + prices.sure_price}</td>
            </tr>}
        </tbody>
      </Table>
      <div style={{ height: '100%' }}>
        <b>
          <Translate dictionary={ui} word="YOURP" />:{' '}
        </b>
        <div ref="pricebreakdown">
          <Table className='unit-price-table'>
            <thead>
              <tr>
                <th>
                  <Translate dictionary={ui} word="AQTY" />
                </th>
                <th>
                  <Translate dictionary={ui} word="APRICE" />
                </th>
                {prices.has_Bstock && <th>
                  <Translate dictionary={ui} word="BQTY" />
                </th>}
                {prices.has_Bstock && <th>
                  <Translate dictionary={ui} word="BSTOCK" />
                </th>}
              </tr>
            </thead>
            <tbody>{trArray}</tbody>
          </Table>
        </div>
      </div>
    </div>
  );
}

function renderSpecificationsTable(techSpecs) {
    const technicalFeatureGroups = groupSpecificationByAttribute(techSpecs);
    return (
      <div className="ProductDetail__Specifications">
             {' '}
        <VerticalWrapper>
                 {' '}
          {Object.values(technicalFeatureGroups).map((group) => (
            <Specification key={group.groupName} {...group} />
          ))}
               {' '}
        </VerticalWrapper>
           {' '}
      </div>
    );
  }
  

const Specification = ({ groupName, technicalFeatures = [] }) => (
  <div className="Specification">
    <h4 className="Specification__name white-space-resolver">{ReactHtmlParser(replaceHTMLTagsWithUnicodes(groupName))}</h4>
    <div className="Specification__specs">
      {technicalFeatures.map(({ name, value }, index) => (
        <div className="Specification__spec" key={name + new Date().getTime() + index}>
          <span className="Specification__spec__name white-space-resolver"> {ReactHtmlParser(replaceHTMLTagsWithUnicodes(name))}</span>
          <span className="Specification__spec__value white-space-resolver">{ReactHtmlParser(replaceHTMLTagsWithUnicodes(value))}</span>
        </div>
      ))}
    </div>
  </div>
);

const featureBenefits = product => {
  const featuresArray = [];
  if (!product.keyFeature) {
    if (product.highlights) {
      product.highlights.forEach(topFeature => {
        featuresArray.push({
          title: topFeature,
          body: '',
          image: null
        });
      });
    }
  } else {
    product.keyFeature.forEach(featureBenefit => {
      featuresArray.push({
        title: featureBenefit.headline,
        body: featureBenefit.description,
        image: featureBenefit.featureImage
      });
    });
  }
  return featuresArray;
};

const renderFeaturesTable = product => {
  const _featureBenefits = featureBenefits(product);
  if (_featureBenefits.length === 0) {
    return null;
  }
  return (
    <div className="ProductDetail__Features">
      <VerticalWrapper>
        {_featureBenefits.map(feature => (
          <Feature {...feature} key={feature.title} />
        ))}
      </VerticalWrapper>
    </div>
  );
};

const Feature = ({ title, body, image }) => {
  return (
    <div className="Feature">
      <h4 className="Feature__title white-space-resolver">{ReactHtmlParser(replaceHTMLTagsWithUnicodes(title))}</h4>
      <div className="Feature__description">
        {image && <img className="Feature__image img-responsive" src={image} alt={title} />}
        <div className="Feature__body white-space-resolver">{ReactHtmlParser(replaceHTMLTagsWithUnicodes(body))}</div>
      </div>
    </div>
  );
};

function getRelatedAccessories(accessories, priceBook) {
  const relatedAccessories = [];
  if (accessories.length > 0) {
    accessories.forEach(aAccessory => {
      const product = priceBook.find(aProduct => aProduct.originalSku === cleanTheSku(aAccessory.cfmModelName));
      if (product) {
        relatedAccessories.push(product);
      }
    });
  }
  return relatedAccessories;
}

function renderProductImages(product) {
  let images = [];
  if (product.images && product.images.length > 0) {
      images = [];
      product.images.forEach(image => {
        images.push(image.largeAssetPath);
      });
  }
  return (
    <div className="col-md-6">
      <Gallery images={images} />
    </div>
  );
}

const inventoryAvailabilityTable = properties => {
  return (
    <div style={{ height: '80%' }}>
      <br />
      <div>
        <Table>
          <thead>
            <tr>
              <th>
                <Translate dictionary={ui} word="SKU" />
              </th>
              <th>
                <Translate dictionary={ui} word="STOCK" />
              </th>
              <th>
                <Translate dictionary={ui} word="AVAILABLE_QUANTITY" />
              </th>
              <th>
                <Translate dictionary={ui} word="ITEM_ESD" />
              </th>
             
            </tr>
          </thead>
          <tbody>
            {properties.preorder.items.map((item, i) => {
                // new item_categories and item_plants.
                let item_categories = ['ZSDD','ZCTO'],
                item_plants = ['US04', 'US10'],
              plant_category_check = item_categories.includes(item.item_category) || item_plants.includes(item.item_plant);
              let confirmedQuantity = 0;
              item.nonZeroConfQtySchLines &&
                item.nonZeroConfQtySchLines.length > 0 &&
                item.nonZeroConfQtySchLines.forEach(lineItem => {
                  confirmedQuantity = confirmedQuantity + lineItem.confirmedQuantity;
                });
              let mappingArray = item.nonZeroConfQtySchLines && item.nonZeroConfQtySchLines.length > 0 ? item.nonZeroConfQtySchLines : item.schedule || [];
              return mappingArray.map(lineItem => {
                return (
                  <tr key={i}>
                    <td>{item.requestedSKU}</td>
                    <td>{item.grade}</td>
                    <td>{lineItem.confirmedQuantity !== 0 ? lineItem.confirmedQuantity : 'TBD'}</td>
                    <td>
                      {lineItem.shippingDate && lineItem.confirmedQuantity !== 0 && !plant_category_check
                        ? moment(lineItem.shippingDate).format('MM/DD/YYYY')
                        : 'TBD'}
                    </td>
                  </tr>
                );
              });
            })}
          </tbody>
        </Table>
        <ul className="disclaimer" style={{paddingLeft:10}}>
          <li>
            <Translate dictionary={ui} word="PRODUCT_AVAILABLE_QUESTION" />
          </li>
          <li>
            <Translate dictionary={ui} word="ZERO_AVAILABLE_QTY_MESSAGE" />
          </li>
        </ul>
      </div>
    </div>
  );
};

const cleanTheSku = sku => {
  if (sku && sku.indexOf('-') !== -1) {
    return sku.replace(/-/g, '');
  }
  return sku;
};
const mapStateToProps = (state, ownProps) => {
  return {
    product: state.product,
    cartItems: selector(state, 'items'),
    customerType: state.account.contract && utilsActions.getContract(state.account.contract),
    priceBook: state.account.priceBook,
    lang: state.language,
    bulletins: state.bulletin && state.bulletin.bulletins ? getProductBulletins(state.bulletin.bulletins, ownProps.prices.originalSku) : [],
    buyer: state.account.buyer,
    contract: state.account.contract,
    addresses: state.account.addresses,
    preorder: state.order.preOrder,
    isFetching: state.common.isFetching,
    error: state.error.error_obj,
    user: state.account.user,
    orderBlock: state.account.contract.orderBlock
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateCart: items => dispatch(updateCart(items)),
    getProduct: (type, sku, lang) => dispatch(getProduct(type, sku, lang)),
    resetProduct: () => dispatch(resetProduct()),
    simulateOrder: (cart, buyer, payerId, billToId, contract, user, language) =>
      dispatch(orderActions.simulateOrder(cart, buyer, payerId, billToId, contract, user, language)),
    clearPreOrder: () => dispatch({ type: 'SIMULATE_ORDER_SUCCESS', data: { preOrder: undefined } }),
    resetError: () => dispatch(errorActions.resetError()),
    setError: () =>
      dispatch({
        type: 'SET_ERROR',
        data: {
          error_obj: {
            message: 'Order Block on customer',
            errorPath: 'INVENTORY_ERROR'
          }
        }
      })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail);
