import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import ui from './dictionary/ui.json';
import Translate, { translate } from '../dictionary/Translator';
import { formValueSelector } from 'redux-form';
import cartImage from '../../static/images/Cart.png';
import * as cartActions from '../../modules/cart';
import './productList.css';
import ReactTable from 'react-table';
import DocumentTitle from 'react-document-title';
import { isIE, successToast } from '../utils/renderUtils';
import cartUI from '../cart/dictionary/ui';
import { validateCart } from '../../modules/utils';
import utils from 'util';
const selector = formValueSelector('cart');

class ProductList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      products: [],
      quantities: {},
      errors: {},
      subCat: this.findSkusForSubCat(),
      isIE: false
    };
    this.handleAddToCart = this.handleAddToCart.bind(this);
    this.handleQuantityOnChange = this.handleQuantityOnChange.bind(this);
  }

  initializeQuantities = () => {
    const { subCat:{skus} } = this.state;
    const quantities = skus.reduce((accu, current) => {
      return {
        ...accu,
        [current]: 0
      };
    }, {});
    this.setState({ quantities });
  };

  componentDidMount() {
    if (isIE()) {
      this.setState({ isIE: true });
    }
    // const { skus } = this.state;
    const {subCat : {skus} } = this.state;
    const products = skus.reduce((accu, current) => {
      const product = this.searchPriceBookBySku(current);
      return accu.concat(product);
    }, []);
    this.initializeQuantities();
    this.setState({ products });
  }

  handleQuantityOnChange(event) {
    event.preventDefault();
    let value = event.target.value;
    const sku = event.target.name;
    value = String(value).startsWith('0') ? String(value).substr(1) : value;
    const re = /^[0-9\b]+$/;

    if (re.test(value) || value === '') {
      this.setState(pre => ({
        ...pre,
        quantities: {
          ...pre.quantities,
          [sku]: value
        },
        errors: {
          ...pre.errors,
          [sku]: false
        }
      }));
    } else {
      this.setState(pre => ({
        ...pre,
        quantities: {
          ...pre.quantities,
          [sku]: value
        },
        errors: {
          ...pre.errors,
          [sku]: true
        }
      }));
    }
  }
  handleAddToCart() {
    try {
      const { errors } = this.state;
      const { language } = this.props;
      Object.keys(errors).forEach(key => {
        if (errors[key] === true) {
          return;
        }
      });
      const { quantities, products } = this.state;
      const newCart = [];
      Object.keys(quantities).forEach(orSku => {
        if (quantities[orSku] && quantities[orSku] > 0) {
          newCart.push({
            AStockQty: quantities[orSku],
            sku: products.filter(({ originalSku }) => originalSku === orSku)[0].sku
          });
        }
      });
      if (newCart.length > 0) {
        let cartItems = this.props.cartItems;
        cartItems = cartItems ? cartItems.concat(newCart) : newCart;
        this.props.updateCart(cartItems);
        successToast(translate({ word: cartUI['CART_UPDATE_NOTIFICATION_SUCCESS'], language }));
        this.cartArray = [];
        this.initializeQuantities();
      }
    } catch (e) {
      console.log(e);
    }
  }

  getNewCartLength = (cartItems, quantities) => {
    var count = cartItems ? cartItems.length : 0;
    var qtyCount = quantities ? Object.values(quantities).filter(qty => qty !== 0).length : 0;
    return count + qtyCount > 50;
  };

  getExceededCartItems = (cartItems, quantities) => {
    var count = cartItems ? cartItems.length : 0;
    var qtyCount = quantities ? Object.values(quantities).filter(qty => qty !== 0).length : 0;
    return count + qtyCount - 50;
  };

  render() {
    const { isIE, products, quantities , subCat} = this.state;
    const { language, cartItems } = this.props;

    const isCartFull = validateCart(cartItems) || this.getNewCartLength(cartItems, quantities);
    const exceededSizeItems = this.getExceededCartItems(cartItems, quantities);
    const thereIsAnError = Object.keys(this.state.errors).filter(key => this.state.errors[key] === true).length > 0 ? true : false;

    // const oneOfTheProducts = products.filter(({ sCats }) => {
    //   console.log("sCats" , sCats);
    //   return sCats.length === 1
    // })[0];
    // const listTitle = oneOfTheProducts ? oneOfTheProducts['sCats'][0] : '';

    const oneOfTheProducts = products.map(({ sCats }) => {
      return sCats.find(sCatName => sCatName === subCat.name);
    })[0];
    const listTitle = oneOfTheProducts ? oneOfTheProducts : '';

    const tableStyle = isIE
      ? {}
      : {
          maxHeight: '500px',
          overflowY: 'auto'
        };
    return (
      <DocumentTitle title={`${listTitle} | Product List | Sony ExpressNet`}>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h1 style={{ padding: '0', margin: '0', fontSize: '2.2rem', fontWeight: '600' }}>{listTitle}</h1>
            </div>
            <div className="col-md-6">
              <div className="row">
                <span id="addToCartInstr" style={{ display: 'none' }}>
                  <Translate word={ui['CART']} />
                </span>
                <Button
                  bsStyle="primary"
                  id="topaddtocart"
                  onClick={this.handleAddToCart}
                  style={{ float: 'right' }}
                  disabled={isCartFull || thereIsAnError}
                  aria-describedby="addToCartInstr"
                >
                  <img bb={40} height={25} src={cartImage} alt="Cart" /> <Translate dictionary={ui} word="CART" />
                </Button>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <span className="disclaimer" style={{ wordBreak: 'break-word', float: 'right', maxWidth: '500px' }}>
                    <Translate dictionary={ui} word="CART_LIMIT_ERR" />
                  </span>
                </div>
                <div className="col-md-12">
                  <span style={{ wordBreak: 'break-word', float: 'right', maxWidth: '500px' }} className="disclaimer">
                    <Translate dictionary={ui} word="DISCLAIMER" />
                  </span>
                </div>

                {thereIsAnError ? (
                  <p className="error-message" style={{ float: 'right', textAlign: 'right' }}>
                    <Translate dictionary={ui} word="LIST_MODAL_DATA" />
                  </p>
                ) : (
                  !thereIsAnError &&
                  isCartFull &&
                  exceededSizeItems > 0 && (
                    <p className="error-message" style={{ float: 'right', textAlign: 'right' }}>
                      {utils.format(translate({ dictionary: ui, word: 'CART_LIMIT_PO_EXCEED_ERR', language }), exceededSizeItems)}
                    </p>
                  )
                )}
              </div>
              <div className="row" />
            </div>
          </div>
          <div className="row">
            <div
              style={{
                overflowX: 'auto',
                whiteSpace: 'nowrap',
                maxWidth: '99vw',
                padding: '1% 2%'
              }}
            >
              <ReactTable
                minRows={0}
                className={`-striped ${isIE ? 'react-table-ie' : ''}`}
                data={this.state.products}
                style={tableStyle}
                role="table"
                columns={[
                  {
                    Header: <Translate dictionary={ui} word="LIST_MODEL_NO" />,
                    accessor: 'sku',
                    headerClassName: 'react-table__th',
                    width: 300,
                    Filter: ({ filter, onChange }) => (
                      <div>
                        <span id="searchInstr" style={{ display: 'none' }}>
                          <Translate word={ui['MODEL_PLACEHOLDER']} />
                        </span>
                        <input
                          type="text"
                          placeholder={translate({ dictionary: ui, word: 'MODEL_PLACEHOLDER', language })}
                          value={filter ? filter.value : ''}
                          style={{ width: '100%' }}
                          onChange={e => onChange(e.target.value)}
                          aria-label={translate({ dictionary: ui, word: 'MODEL_PLACEHOLDER', language })}
                          aria-describedby="searchInstr"
                          role="search"
                        />
                      </div>
                    ),
                    Cell: props => {
                      var shortDesc = props.row._original.short_desc ? props.row._original.short_desc[language] : '';
                      return (
                        <div>
                          <span id="initInstr" style={{ display: 'none' }}>
                            <Translate word={ui['LINK_DESCRIPTION']} />
                          </span>
                          <NavLink
                            aria-describedby="initInstr"
                            to={'/productdetail/' + encodeURIComponent(props.row._original.originalSku)}
                            className="bold-link"
                          >
                            {props.value}
                          </NavLink>
                          <br />
                          <span style={{ overflowWrap: 'break-word' }}>{shortDesc}</span>
                        </div>
                      );
                    },
                    filterMethod: (filter, row) => {
                      const keyword = String(filter.value).toLowerCase().trim();
                      const sku = String(row.sku).toLowerCase().trim();
                      const desc = (row._original.short_desc ? String(row._original.short_desc[language]) : '').toLowerCase().trim();
                      if (sku.indexOf(keyword) !== -1 || desc.indexOf(keyword) !== -1) {
                        return true;
                      } else {
                        return false;
                      }
                    }
                  },
                  {
                    Header: <Translate dictionary={ui} word="QUANTITY" />,
                    filterable: false,
                    sortable: false,
                    className: 'react-table__td',
                    headerClassName: 'react-table__th',
                    width: 200,
                    Cell: props => (
                      <div>
                        <div className="__form-inline __form-inline--ProductList">
                          <h2 style={{ fontSize: '14px',marginTop: '0px', marginBottom: '0px'}}> 
                            <Translate dictionary={ui} word="QTY" />{' '}
                          </h2>
                          <input
                            onChange={this.handleQuantityOnChange}
                            name={props.row._original.originalSku}
                            id={props.row._original.originalSku}
                            value={this.state.quantities[props.row._original.originalSku]}
                            type="text"
                            maxLength={9}
                            className={`${this.state.errors[props.row._original.originalSku] ? 'input-has-error' : ''} quantity-input`}
                            aria-label="quantity"
                            onBlur={e => {
                              if (e.target.value === '') {
                                this.setState(pre => ({
                                  quantities: {
                                    ...pre.quantities,
                                    [props.row._original.originalSku]: 0
                                  }
                                }));
                              }
                            }}
                          />
                        </div>
                        {this.state.errors[props.row._original.originalSku] && (
                          <div className="input-error">
                            <Translate dictionary={ui} word="INVALID_QUANTITY" />
                          </div>
                        )}
                      </div>
                    )
                  },
                  {
                    Header: <Translate dictionary={ui} word="LIST" />,
                    accessor: 'list_price',
                    filterable: false,
                    className: 'react-table__td',
                    headerClassName: 'react-table__th',
                    sortMethod: priceSortMethod,
                    Cell: cell => {
                      return !cell.row._original.list_price || cell.row._original.list_price === '0.00' ? (
                        <span />
                      ) : (
                        <span>{'$' + cell.row._original.list_price}</span>
                      );
                    },
                    maxWidth: 150
                  },
                  {
                    Header: <Translate dictionary={ui} word="SURE" />,
                    accessor: 'sure_price',
                    filterable: false,
                    className: 'react-table__td',
                    headerClassName: 'react-table__th',
                    sortMethod: priceSortMethod,
                    Cell: cell => {
                      return !cell.row._original.sure_price || cell.row._original.sure_price === '0.00' ? (
                        <span />
                      ) : (
                        <span>{'$' + cell.row._original.sure_price}</span>
                      );
                    },
                    maxWidth: 150
                  },
                  {
                    Header: <Translate dictionary={ui} word="MAP" />,
                    accessor: 'map_price',
                    filterable: false,
                    className: 'react-table__td',
                    headerClassName: 'react-table__th',
                    sortMethod: priceSortMethod,
                    Cell: cell => {
                      return !cell.row._original.map_price || cell.row._original.map_price === '0.00' ? (
                        <span />
                      ) : (
                        <span>{'$' + cell.row._original.map_price}</span>
                      );
                    },
                    maxWidth: 150
                  },
                  {
                    Header: <Translate dictionary={ui} word="SPPG" />,
                    accessor: 'sppg_price',
                    filterable: false,
                    className: 'react-table__td',
                    headerClassName: 'react-table__th',
                    sortMethod: priceSortMethod,
                    Cell: cell => {
                      return !cell.row._original.sppg_price || cell.row._original.sppg_price === '0.00' ? (
                        <span />
                      ) : (
                        <span>{'$' + cell.row._original.sppg_price}</span>
                      );
                    },
                    maxWidth: 150
                  },
                  {
                    Header: <Translate dictionary={ui} word="YOURP" />,
                    accessor: 'A_base_price',
                    filterable: false,
                    className: 'react-table__td',
                    headerClassName: 'react-table__th',
                    sortMethod: priceSortMethod,
                    width: 200,
                    Cell: cell => {
                      return cell.row._original.A_base_price === '0.00' ? <span /> : <span>{'$' + cell.row._original.A_base_price}</span>;
                    },
                    maxWidth: 150
                  }
                ]
                }
                showPagination={false}
                defaultSorted={[{ id: 'sku' }]}
                filterable={true}
                pageSize={this.state.products.length}
              />
            </div>
          </div>
        </div>
      </DocumentTitle>
    );
  }

  searchPriceBookBySku(sku) {
    let retVal = {};
    retVal = this.props.priceBook.find(aData => {
      if (aData.sku ===  sku) return true;
      else return false;
    });
    return retVal;
  }

  findSkusForSubCat() {
    let retVal = [];
    this.props.catalog.forEach(aCatalog => {
      aCatalog.subCats.forEach(subCatObject => {
        if (subCatObject.id === this.props.match.params.subcatid) {
          retVal = {
            skus: subCatObject.skus.filter(aSku => !aSku.endsWith('.B')),
            name: subCatObject.name
          }
        }
      });
    });
    return retVal;
  }
}

const cleanTheCommasFromNumbers = str => {
  return str.replace(/,/g, '');
};
const priceSortMethod = (a, b) => {
  return parseFloat(cleanTheCommasFromNumbers(a)) - parseFloat(cleanTheCommasFromNumbers(b));
};

const mapStateToProps = state => {
  const { language } = state;
  var catalog = state.account.catalog && state.account.catalog[language] ? state.account.catalog[language] : [];
  return {
    priceBook: state.account.priceBook,
    catalog,
    cartItems: selector(state, 'items'),
    country: state.country,
    language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateCart: items => dispatch(cartActions.updateCart(items))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
