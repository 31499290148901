const CHANGE_COUNTRY = 'CHANGE_COUNTRY';

export const changeCountry = ({ country }) => {
  return {
    type: CHANGE_COUNTRY,
    country
  };
};

export const initializeCountry = () => {
  return dispatch => {
    const domainName = window.location.hostname;
    if (domainName.indexOf('.ca') !== -1) {
      dispatch(changeCountry({ country: 'CA' }));
    } else {
      dispatch(changeCountry({ country: 'US' }));
    }
  };
};

export default (state = '', action) => {
  switch (action.type) {
    case CHANGE_COUNTRY:
      return action.country;
    default:
      return state;
  }
};
