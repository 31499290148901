import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changeAppLanguage } from '../../modules/language';
export class LanguageSelect extends Component {
  state = {
    lang: this.props.lang
  };

  changeLanguage = e => {
    this.setState({ lang: e.target.value });
    this.props.changeAppLanguage({ lang: e.target.value });
  };
  renderOptions = () => {
    const { country } = this.props;
    switch (country) {
      case 'CA':
        return [
          <option key="en_CA" value="en_CA">
            English
          </option>,
          <option key="fr_CA" value="fr_CA">
            Français
          </option>
        ];
      case 'US':
        return [
          <option key="en_US" value="en_US">
            English
          </option>
        ];
      default:
        return [
          <option key="en_US" value="en_US">
            English
          </option>
        ];
    }
  };
  render() {
    const { lang } = this.state;
    return (
      <select aria-label="select language" value={lang} onChange={this.changeLanguage} className="Header__language-select">
        {this.renderOptions()}
      </select>
    );
  }
}

function mapStateToProps({ language, country }) {
  return {
    lang: language,
    country
  };
}
export default connect(
  mapStateToProps,
  { changeAppLanguage }
)(LanguageSelect);
