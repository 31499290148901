import React, { Component } from 'react';
import { connect } from 'react-redux';
import AccessoryThumbPresent from './AccessoryThumbPresent';
class AccessoryThumb extends Component {
  render() {
    const { sku, description, originalSku, priceBook, productAccessories, language } = this.props;
    return (
      <AccessoryThumbPresent
        sku={sku}
        description={description && description.length > 0 ? description : productAccessories.factualShortDescription[language] || ''}
        originalSku={originalSku}
        images={productAccessories.images.reduce((accu, current) => accu.concat(current.largeAssetPath),[])}
        name ={productAccessories.modelName || ''}
        prices={priceBook.find(aProduct => aProduct.originalSku === originalSku)}
      />
    )
  }
}

const mapStateToProps = ({ language, account: { priceBook } }) => ({ language, priceBook });
export default connect(
  mapStateToProps
)(AccessoryThumb);
