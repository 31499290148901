import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import MdShoppingCart from 'react-icons/lib/md/shopping-cart';
import { Link } from 'react-router-dom';
const selector = formValueSelector('cart');
const CartHeader = ({ cartItems }) => {
  const count = cartCount({ cartItems });
  return (
    <Link style={{ marginRight: '80px' }} to="/" aria-label="cart">
      <div className={`Header__cart ${count > 0 ? 'Header__cart--has-item' : 'Header__cart--empty'}`} role="alert">
        <p id="describe-cart-count" className="offscreen">
          The number of items in cart
        </p>
        {count > 0 && (
          <span className="badge cart-badge" role="alert" aria-describedby="describe-cart-count">
            {count}
          </span>
        )}
        <MdShoppingCart style={{ fontSize: '2.8rem' }} className="Header__cart__icon" role="alert" />
      </div>
    </Link>
  );
};
const cartCount = ({ cartItems }) => {
  var validItems = cartItems && cartItems.filter(item => item && item.sku && ((!isNaN(item.AStockQty) ||
   !isNaN(item.BStockQty)) && (item.BStockQty || item.AStockQty) && (item.BStockQty >0 || item.AStockQty >0)) );
   var count = 0;
  if (validItems) {
    validItems.forEach(function(item) {
      const AStockQuantity =  item.AStockQty && item.hasOwnProperty('AStockQty') && !isNaN(item.AStockQty) ? parseInt(item.AStockQty, 10) :0;
      const BStockQuantity =  item.BStockQty && item.hasOwnProperty('BStockQty') && !isNaN(item.BStockQty) ? parseInt(item.BStockQty, 10): 0;
      count = count + AStockQuantity + BStockQuantity;
    });
  }
  return count;
};
const mapStateToProps = state => ({ cartItems: selector(state, 'items') });
export default connect(mapStateToProps)(CartHeader);
