import React from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import ReactTable from 'react-table';
import moment from 'moment';
import * as orderActions from '../../modules/order';
import { renderInput, renderDatePicker, renderFilterSelect, isIE } from '../utils/renderUtils';
import Loading from '../Loading';
import * as errorActions from '../../modules/error';
import * as commonActions from '../../modules/common';
import 'react-table/react-table.css';
import './OrderHistory.css';
import MdSearch from 'react-icons/lib/md/search';
import IoAndroidRefresh from 'react-icons/lib/io/android-refresh';
import { translate } from '../dictionary/Translator';
import Translate from '../dictionary/Translator';
import errorDictionary from '../dictionary/error';
import ui from './dictionary/ui';
import orderHistoryStatusMappings from './dictionary/order_history_status';
import orderTypeMappings from './dictionary/order_type';
import gsapOrderTypeMappings from './dictionary/gsap_order_type.json'
import staticMappings from './dictionary/static';
import DocumentTitle from 'react-document-title';

const selector = formValueSelector('orderSearchFilter');
var users = [];
var language;
var headerStatusOptions;
const orderType = orderTypeMappings['ORDER_TYPES_ORDER_SEARCH'];
const gsapOrderType = gsapOrderTypeMappings['ORDER_TYPES_ORDER_SEARCH_GSAP']
const reasonType = orderTypeMappings['REASON_CODES_ORDER_SEARCH'];
const gsapReasonType = gsapOrderTypeMappings['REASON_CODES_ORDER_SEARCH_GSAP'];
const maxRecordsOptions = staticMappings['MAX_RECORD_OPTIONS'];

class OrderHistory extends React.Component {
  // constructor(props) {
  //   super(props);
  //   headerStatusOptions = translate({ word: ui['ORDER_HEADER_STATUS_OPTIONS'], language: props.language });
  // }

  userlistOptions = userList => {
    users = [];
    users.push({ value: '', label: <Translate word={ui['USER_TYPE_ANY']} /> });
    userList.map((item, index) => users.push({ value: item.id, label: item.firstName + ' ' + item.lastName }));
  };

  showResults = filters => {
    this.props.resetError();
    this.props.clearOrderHistory();
    this.props.listOrders(filters, this.props.buyer, this.props.contract, orderType, gsapOrderType, reasonType, gsapReasonType, this.props.contactIdMapping);
  };

  componentWillMount() {
    this.props.resetError();
    this.props.resetFetching();
    if (this.props.backButtonFlag) {
      this.props.setBackButtonFlag();
    } else {
      this.props.clearOrderHistory(true);
    }

    if (this.props.userList) {
      this.userlistOptions(this.props.userList);
    }
  }

  componentWillUnmount() {
    this.props.resetError();
  }

  render() {
    const { orderList, error, isFetching } = this.props;
    language = this.props.language;
    headerStatusOptions = translate({ word: ui['ORDER_HEADER_STATUS_OPTIONS'], language });
    return (
      <DocumentTitle title={`${translate({ word: ui['ORDER_HISTORY'], language: this.props.language })} | Sony ExpressNet`}>
        <div className="container-less-fluid">
          <h1 style={{ fontSize: '2.2rem', fontWeight: '600' }}>
            <Translate word={ui['ORDER_HISTORY']} />
          </h1>
          <div>
            <Translate word={ui['ORDER_HISTORY_HEADER_MESSAGE']} />
          </div>
          <div />
          <hr />
          <SearchFilterForm onSubmit={this.showResults} {...this.props} />
          <hr size="4" />
          {error && (
            <div className="text-danger">
              <h1 style={{ fontSize: '1.33rem', fontWeight: '450' }}>
                <Translate word={errorDictionary[error.errorPath]} />
              </h1>
            </div>
          )}
          {!isFetching && !error && orderList && orderList.length === 0 && (
            <div className="text-danger">
              <strong>
                <Translate word={errorDictionary['NO_RESULTS']} />
              </strong>
            </div>
          )}
          {!isFetching && !error && orderList && orderList.length > 0 && (
            <div>
              <h2 style={{ fontSize: '1.8rem', fontWeight: '450' }}>
                <Translate word={ui['ORDER_LIST']} />
              </h2>
              <OrderList {...this.props} />
            </div>
          )}
        </div>
      </DocumentTitle>
    );
  }
}

const columns = userList => {
  return [
    {
      Header: () => (
        <div>
          <strong>
            <Translate word={ui['ORDER_NUMBER']} />
          </strong>
        </div>
      ),
      accessor: 'orderNumber',
      minWidth: 165,
      style: { whiteSpace: 'pre-wrap' },
      className: 'react-table__td bold-link',
      headerClassName: 'react-table__th',
      headerStyle: { whiteSpace: 'pre-wrap', padding: '1%' },
      Cell: row => (
        <strong>
          <Link to={{ pathname: '/orderview/'+ row.original.source + '/' + row.value }}>{row.value}</Link>
        </strong>
      )
    },
    {
      Header: () => (
        <div>
          <strong>
            <Translate word={ui['ORDER_TYPE']} />
          </strong>
        </div>
      ),
      accessor: 'orderType',
      minWidth: 100,
      style: { whiteSpace: 'pre-wrap' },
      headerStyle: { whiteSpace: 'pre-wrap', padding: '1%' },
      className: 'react-table__td',
      headerClassName: 'react-table__th',
      Cell: row => {
        return row.value ? (row.original.source === 'S' ? translate({ word: orderTypeMappings[row.value], language }) : translate({ word: gsapOrderTypeMappings[row.value], language })) : '';
      }
    },
    {
      Header: () => (
        <div>
          <strong>
            <Translate word={ui['ORDER_DATE']} />
          </strong>
        </div>
      ),
      accessor: 'orderDate',
      minWidth: 105,
      style: { whiteSpace: 'pre-wrap' },
      className: 'react-table__td',
      headerClassName: 'react-table__th',
      headerStyle: { whiteSpace: 'pre-wrap', padding: '1%' },
      Cell: row => {
        return row.value ? moment(row.value).format('MM/DD/YYYY') : '';
      }
    },
    {
      Header: () => (
        <div>
          <strong>
            <Translate word={ui['ORDER_STATUS']} />
          </strong>
        </div>
      ),
      minWidth: 165,
      style: { whiteSpace: 'pre-wrap' },
      className: 'react-table__td',
      headerClassName: 'react-table__th',
      headerStyle: { whiteSpace: 'pre-wrap', padding: '1%' },
      accessor: 'status'
    },
    {
      Header: () => (
        <div>
          <strong>
            <Translate word={ui['PO_NUMBER']} />
          </strong>
        </div>
      ),
      minWidth: 165,
      style: { whiteSpace: 'pre-wrap' },
      className: 'react-table__td',
      headerClassName: 'react-table__th',
      headerStyle: { whiteSpace: 'pre-wrap', padding: '1%' },
      accessor: 'PO'
    },
    {
      Header: () => (
        <div>
          <strong>
            <Translate word={ui['ORDER_TOTAL']} />
          </strong>
        </div>
      ),
      accessor: 'orderTotal',
      minWidth: 115,
      style: { whiteSpace: 'pre-wrap' },
      className: 'react-table__td',
      headerClassName: 'react-table__th',
      headerStyle: { whiteSpace: 'pre-wrap', padding: '1%' },
      sortMethod: (a, b) => {
        return a - b;
      }
    },
    {
      Header: () => (
        <div>
          <strong>
            <Translate word={ui['ENTERED_BY']} />
          </strong>
        </div>
      ),
      accessor: 'contactId',
      minWidth: 165,
      style: { whiteSpace: 'pre-wrap' },
      className: 'react-table__td',
      headerClassName: 'react-table__th',
      headerStyle: { whiteSpace: 'pre-wrap', padding: '1%' },
      Cell: row => {
        let x = userList.find(user => row.original.source === 'GS' ? user.id === row.value : user.oldId === row.value);
        return x ? x.firstName + ' ' + x.lastName : <a href="/contact-us#order" style={{textDecoration:'none', color:'#2E6CA3'}}><Translate word={ui['OOA']} /></a>;
      },
      sortMethod: (a, b) => {
        let x = userList.find(user => user.id === a || user.oldId === a );
        let newA = x ? x.firstName + ' ' + x.lastName : <Translate word={ui['OOA']} />;
        let y = userList.find(user => user.id === b || user.oldId === b );
        let newB = y ? y.firstName + ' ' + y.lastName : <Translate word={ui['OOA']} />;

        if (newA > newB) {
          return 1;
        }
        if (newA < newB) {
          return -1;
        }
        return 0;
      }
    }
  ];
};

let OrderList = props => {
  const { orderList, userList } = props;
  let updatedOrderList = [].concat(orderList);
  updatedOrderList.map(item => {
    let status = translate({ word: orderHistoryStatusMappings[item.orderHeaderStatus], language });
    item.status = item.overallBlockedStatus ? `${status} - ${translate({ word: ui['STATUS_AWAIT_REVIEW'], language })}` : status;
    return item;
  });

  return (
    <div className="row">
      <div className="overflow-wrapper">
        <ReactTable
          minRows={1}
          showPagination={false}
          defaultPageSize={100}
          data={updatedOrderList}
          columns={columns(userList)}
          className={`-striped -highlight ${isIE() ? 'react-table-ie--customized' : ''}`}
          style={
            isIE()
              ? { marginBottom: '20px' }
              : {
                  maxHeight: '400px',
                  overflowY: 'auto',
                  marginBottom: '20px'
                }
          }
        />
      </div>
    </div>
  );
};

let SearchFilterForm = props => {
  const { handleSubmit, isFetching, clearOrderHistory, sku, AStockCheckbox, BStockCheckbox, resetStockCheckbox } = props;
  return (
    <form
      id="searchForm"
      onSubmit={handleSubmit}
      onKeyPress={e => {
        if (e.key === 'Enter') e.preventDefault();
      }}
    >
      <div className="form-group__row">
        <fieldset className="form-group--vertical">
          <div className="form-group--vertical__controls">
            <div className="form-group-fixed-width">
              <label htmlFor="orderNumber">
                <Translate word={ui['ORDER_NUMBER']} />
              </label>
              <Field id="orderNumber" name="orderNumber" isClearable={true} component={renderInput} maxLength={10} />
            </div>
            <div className="form-group-fixed-width">
              <label htmlFor="orderDateFrom">
                <Translate word={ui['ORDER_DATE_FROM']} />
              </label>
              <Field id="orderDateFrom" name="orderDateFrom" isClearable={true} component={renderDatePicker} />
            </div>
            <div className="form-group-fixed-width">
              <label htmlFor="orderDateTo">
                <Translate word={ui['ORDER_DATE_TO']} />
              </label>
              <Field id="orderDateTo" name="orderDateTo" isClearable={true} component={renderDatePicker} />
            </div>
          </div>
        </fieldset>
        <fieldset className="form-group--vertical">
          <div className="form-group-fixed-width">
            <label htmlFor="headerStatus">
              <Translate word={ui['ORDER_STATUS']} />
            </label>
            <Field id="headerStatus" aria-owns="react-select-2--value" name="headerStatus" component={renderFilterSelect} options={headerStatusOptions} />
          </div>
          <div style={{ marginLeft: '10px' }} className="input-checkbox-container">
            <div className="input-checkbox">
              <Field id="orderStatus" name="orderStatus" component="input" type="checkbox" />
              <label htmlFor="orderStatus">
                <Translate word={ui['OOA_REVIEW']} />
              </label>
            </div>
            <div className="input-checkbox">
              <Field id="creditHold" name="creditHold" component="input" type="checkbox" />
              <label htmlFor="creditHold">
                <Translate word={ui['CREDIT_REVIEW']} />
              </label>
            </div>
          </div>
        </fieldset>
        <fieldset className="form-group--vertical">
          <div className="form-group-fixed-width">
            <label htmlFor="invoiceNumber">
              <Translate word={ui['INVOICE_NUMBER']} />
            </label>
            <Field id="invoiceNumber" name="invoiceNumber" isClearable={true} component={renderInput} />
          </div>

          <div className="form-group-fixed-width">
            <label htmlFor="invoiceDateFrom">
              <Translate word={ui['INVOICE_DATE_FROM']} />
            </label>
            <Field id="invoiceDateFrom" name="invoiceDateFrom" isClearable={true} component={renderDatePicker} />
          </div>
          <div className="form-group-fixed-width">
            <label htmlFor="invoiceDateTo">
              <Translate word={ui['INVOICE_DATE_TO']} />
            </label>
            <Field id="invoiceDateTo" name="invoiceDateTo" isClearable={true} component={renderDatePicker} />
          </div>
        </fieldset>
        <fieldset className="form-group--vertical">
          <div className="form-group--vertical__controls">
            <div className="form-group-fixed-width">
              <label htmlFor="PO">
                <Translate word={ui['PO_NUMBER']} />
              </label>
              <Field id="PO" name="PO" isClearable={true} component={renderInput} maxLength={20} />
            </div>
            <div className="form-group-fixed-width">
              <label htmlFor="sku">
                <Translate word={ui['MODEL']} />
              </label>
              <Field id="sku" name="sku" isClearable={true} component={renderInput} />
            </div>
            <div className="form-group-fixed-width" style={{marginBottom: 0}}>
              <label htmlFor="model_grade" >
                  <Translate word={ui['MODEL_GRADE']} />
              </label>
              <div id="model_grade" style={{ display: 'contents'}} >
                <div style={{ display: 'flex'}}>
                  <Field id="AStock" name="AStock" component={renderInput} type="checkbox" disabled={sku && sku.length >0 ? false:true} />
                  <label htmlFor="AStock">
                    <Translate word={ui['A_STOCK']} />
                  </label>
                </div>
                <div style={{ display: 'flex'}}>
                  <Field id="BStock" name="BStock" component={renderInput} type="checkbox" disabled={sku && sku.length >0 ? false:true} />
                  <label htmlFor="BStock">
                    <Translate word={ui['B_STOCK']} />
                  </label>
                </div>
              </div>
            </div>
            <div className="stockError">
              <Field id="StockError" name="StockError" component={renderInput} type="checkbox" hidden
              style={{display: sku && !AStockCheckbox && !BStockCheckbox?'block':'none', position: 'absolute', left: '-9999px'}}/>
            </div>
            <div className="form-group-fixed-width">
              <label htmlFor="enteredBy">
                <Translate word={ui['ENTERED_BY']} />
              </label>
              <Field id="enteredBy" name="enteredBy" component={renderFilterSelect} options={users} />
            </div>
          </div>
        </fieldset>
      </div>

      <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '1%' }} className="search-tools">
        <fieldset className="form-group--vertical no-shadow" style={{ border: 'none' }}>
          <div className="form-group-fixed-width">
            <label htmlFor="maxRecords">
              <Translate word={ui['LIMIT_SEARCH_TO_LAST']} />
            </label>
            <Field id="maxRecords" name="maxRecords" component={renderFilterSelect} options={maxRecordsOptions} props={{ clearable: false }} />
          </div>
        </fieldset>
        <div className="form-group--vertical no-shadow" style={{ border: 'none' }}>
          <div className="__inline-group--buttons">
            <Button bsStyle="primary" type="submit" disabled={isFetching} className="btn-margin button" title="Search Orders">
              <MdSearch className="button__icon" role="img" aria-label="Search" />
              <span>
                <Translate word={ui['SEARCH_BUTTON']} />
              </span>
            </Button>
            <Button
              bsStyle="primary"
              id="resetForm"
              title="Reset Form"
              onClick={() => {
                clearOrderHistory(true);
                resetStockCheckbox()
              }}
              disabled={isFetching}
              style={{ backgroundColor: '#D1380A' }}
              className="button"
            >
              <IoAndroidRefresh className="button__icon" role="img" aria-label="Reset" />
              <span>
                <Translate word={ui['RESET_BUTTON']} />
              </span>
            </Button>
          </div>
        </div>
      </div>
      {isFetching && <Loading />}
    </form>
  );
};

const validate = filters => {
  const errors = {};
  if (filters.orderDateFrom && !moment(filters.orderDateFrom, 'MM/DD/YYYY', true).isValid()) {
    errors.orderDateFrom = <Translate word={ui['ERROR_DATE_FORMAT']} />;
  }
  if (filters.orderDateTo && !moment(filters.orderDateTo, 'MM/DD/YYYY', true).isValid()) {
    errors.orderDateTo = <Translate word={ui['ERROR_DATE_FORMAT']} />;
  }
  if (filters.invoiceDateFrom && !moment(filters.invoiceDateFrom, 'MM/DD/YYYY', true).isValid()) {
    errors.invoiceDateFrom = <Translate word={ui['ERROR_DATE_FORMAT']} />;
  }
  if (filters.invoiceDateTo && !moment(filters.invoiceDateTo, 'MM/DD/YYYY', true).isValid()) {
    errors.invoiceDateTo = <Translate word={ui['ERROR_DATE_FORMAT']} />;
  }
  if (filters.orderDateFrom && !filters.orderDateTo) {
    errors.orderDateTo = <Translate word={ui['ERROR_DATE_TO']} />;
  }
  if (filters.orderDateTo < filters.orderDateFrom) {
    errors.orderDateTo = <Translate word={ui['ERROR_DATE_FROM']} />;
  }
  if (filters.invoiceDateFrom && !filters.invoiceDateTo) {
    errors.invoiceDateTo = <Translate word={ui['ERROR_DATE_TO']} />;
  }
  if (filters.invoiceDateTo < filters.invoiceDateFrom) {
    errors.invoiceDateTo = <Translate word={ui['ERROR_DATE_FROM']} />;
  }
  if(filters.sku && !filters.sku.toUpperCase().endsWith('.B') && !filters.AStock && !filters.BStock) {
    errors.StockError= <Translate word={ui['MATERIAL_MANDATORY']} />;
  }
  return errors;
};

SearchFilterForm = reduxForm({
  form: 'orderSearchFilter',
  destroyOnUnmount: false,
  initialValues: { maxRecords: 10, AStock:true, BStock: true },
  validate
})(SearchFilterForm);

const mapStateToProps = (state, props) => {
  return {
    buyer: state.account.buyer,
    contract: state.account.contract,
    userList: state.account.userList,
    contactIdMapping: state.account.contactIdMapping,
    orderList: state.order.list,
    error: state.error.error_obj,
    isFetching: state.common.isFetching,
    backButtonFlag: state.order.backButtonFlag,
    language: state.language,
    sku: selector(state, 'sku'),
    AStockCheckbox: selector(state, 'AStock'),
    BStockCheckbox: selector(state, 'BStock')
  };
};

const mapDispatchToProps = dispatch => {
  return {
    listOrders: (filter, buyer, contract, orderType, gsapOrderType, reasonType, gsapReasonType, custIdMap) => {
      if(filter.sku && filter.sku.endsWith('.B') && filter.BStock === false){
          dispatch(change('orderSearchFilter', 'BStock', true))
        filter.BStock = true;
        filter ={...filter,...filter.BStock}
      }
      setTimeout(()=>{
        dispatch(orderActions.listOrders(filter, buyer, contract, orderType, gsapOrderType, reasonType, gsapReasonType, custIdMap))
      },.1);
    },
    clearOrderHistory: clearFormFlag => dispatch(orderActions.clearOrderHistory(clearFormFlag)),
    resetError: () => dispatch(errorActions.resetError()),
    setBackButtonFlag: resetFlag => dispatch(orderActions.setBackButtonFlag(resetFlag)),
    resetFetching: () => dispatch(commonActions.resetFetching()),
    // reset checkboxes to checked and disabled on click of reset button
    resetStockCheckbox: () => {dispatch(change('orderSearchFilter', 'AStock', true))
      dispatch(change('orderSearchFilter', 'BStock', true))
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderHistory);
