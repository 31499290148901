import React from 'react';
import { connect } from 'react-redux';
import { translatedMessage } from './errorTranslateUtil';
import { errorExpression } from './errorMapping';

export const Translate = ({ dictionary, word, language, displayMessage }) => {
  return <React.Fragment>{translate({ dictionary, word, language, displayMessage })}</React.Fragment>;
};

export const translate = ({ dictionary, word, language, displayMessage }) => {
  if (displayMessage && errorExpression(displayMessage)) {
    let expression = errorExpression(displayMessage);
    return translatedMessage(displayMessage, expression, language);
  } else {
    var value = dictionary ? dictionary[word] : word;
    if (!value) {
      return '?key?';
    } else if (typeof value === 'string' || value instanceof String) {
      return value;
    } else {
      return value[language] === undefined ? '?Word?' : value[language];
    }
  }
};

const mapStateToProps = ({ language }) => ({ language });

export default connect(mapStateToProps)(Translate);
