import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import * as orderActions from '../../modules/order';
import * as errorActions from '../../modules/error';
import * as commonActions from '../../modules/common';
import Loading from '../Loading';
import moment from 'moment';
import fileSaver from 'file-saver';
import Translate from '../dictionary/Translator';
import errorDictionary from '../dictionary/error';
import orderStatusMappings from './dictionary/order_status';
import itemStatusMappings from './dictionary/order_item_status';
import carrierCodeMappings from './dictionary/carrier_codes';
import shipModeMappings from './dictionary/ship_modes';
import gsapShipModeMappings from './dictionary/gsap_ship_modes.json'
import { translate } from '../dictionary/Translator';
import ui from './dictionary/ui';
import DocumentTitle from 'react-document-title';
import { isEmpty } from 'lodash';
//var langId;

class OrderView extends React.Component {
  // constructor(props) {
  //   super(props);
  //   langId = translate({ word: ui['LANG_ID'], language: props.language });
  // }

  deliveryFocus = React.createRef();
  invoiceFocus = React.createRef();

  componentWillMount() {
    this.props.resetFetching();
    this.props.resetError();
    this.props.clearOrderDetails();
    this.props.fetchOrder(translate({ word: ui['LANG_ID'], language: this.props.language }), this.props.match.params.id, this.props.match.params.source);

  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.invoice && nextProps.invoice !== this.props.invoice) {
      downloadPdf(nextProps);
    }
  }

  componentWillUnmount() {
    this.props.resetError();
  }

  scrollToDelivery = () => {
    if (this.deliveryFocus.current) {
      this.deliveryFocus.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest'
      });
    }
  };
  scrollToInvoice = () => {
    if (this.invoiceFocus.current) {
      this.invoiceFocus.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest'
      });
    }
  };

  render() {
    const { order, error, language } = this.props;
    //langId = translate({ word: ui['LANG_ID'], language });
    return (
      <DocumentTitle title={`${translate({ word: ui['ORDER_DETAILS'], language: language })} | Sony ExpressNet`}>
        <div style={{ paddingLeft: '3%', paddingRight: '3%' }}>
          {!error && !order && <Loading />}
          {error && (
            <div className="text-danger">
              <h1 style={{ fontSize: '1.33rem', fontWeight: '450' }}>
                <Translate word={errorDictionary[error.errorPath]} />
              </h1>
            </div>
          )}
          {!error && order && renderOrder({ ...this.props }, this.scrollToDelivery, this.deliveryFocus, this.invoiceFocus, this.scrollToInvoice)}
        </div>
      </DocumentTitle>
    );
  }
}

const downloadPdf = props => {
  const { invoice } = props;
  fileSaver.saveAs(b64toBlob(invoice.file, 'base64', 512), invoice.number + '.pdf');
};

function b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

const renderOrder = (props, scrollToDelivery, deliveryFocus, invoiceFocus, scrollToInvoice) => {
  const { order, setBackButtonFlag, isFetching, getInvoiceDetails, language } = props;
  const {match: {params: { source}}} = props;
  let deliveryCount = 0;
  let invoiceCount = 0;
  let mainStatus = '';
  mainStatus = order.orderStatus
    ? order.orderStatus[0].rejectionStatus === 'C'
      ? translate({
          word: ui['STATUS_CANCELLED'],
          language: language
        })
      : translate({
          word: orderStatusMappings[
            (order.orderStatus[0].overAllStatus ? order.orderStatus[0].overAllStatus : ' ') +
              (order.orderStatus[0].rejectionStatus ? order.orderStatus[0].rejectionStatus : ' ')
          ],
          language
        })
    : '';
  let subStatus = '';
  subStatus =
    order.billingBlockCode || order.deliveryBlockCode || (order.orderStatus && order.orderStatus[0].creditStatus === 'B')
      ? translate({
          word: ui['STATUS_AWAITING_OOA_REVIEW'],
          language: language
        })
      : '';

  return (
    <div>
      <div style={{ paddingBottom: '25px' }}>
        <Link
          to={'/orderhistory'}
          role="button"
          className="btn btn-primary"
          style={{ paddingTop: '8px', paddingBottom: '8px', float: 'left' }}
          onClick={() => setBackButtonFlag(true)}
        >
          <Translate word={ui['BACK_BUTTON']} />
        </Link>
      </div>
      <div style={{ paddingBottom: '10px' }}>
        <h1 style={{ fontSize: '2.2rem', fontWeight: '600' }}>
          <Translate word={ui['ORDER_DETAILS']} />
        </h1>
        <h2 style={{ fontSize: '1.8rem', fontWeight: '450' }}>
          <Translate word={ui['ORDER_SUMMARY']} />
        </h2>
      </div>
      <hr size="6" />
      <div className="row">
        <div className="col-sm-4">
          <div className="table-responsive">
            <table>
              <tbody>
                <tr>
                  <td>
                    <strong>
                      <Translate word={ui['ORDER_SUB_TOTAL']} />
                    </strong>
                  </td>
                  <td className="col-sm-2 text-right">{order.netValue ? '$' + order.netValue.toFixed(2) : '$0.00'}</td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      <Translate word={ui['ORDER_ESTIMATED_SHIPPING']} />
                    </strong>
                  </td>
                  <td className="col-sm-2 text-right">{order.netFreight ? '$' + order.netFreight.toFixed(2) : '$0.00'}</td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      <Translate word={ui['ORDER_ESTIMATED_TAX']} />
                    </strong>
                  </td>
                  <td className="col-sm-2 text-right">{order.netTax ? '$' + order.netTax.toFixed(2) : '$0.00'}</td>
                </tr>
                <tr>
                  <td>
                    <hr size="4" />
                  </td>
                  <td>
                    <hr size="4" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      <Translate word={ui['ORDER_ESTIMATED_TOTAL']} />
                    </strong>
                  </td>
                  <td className="col-sm-2 text-right">{order.netValue + order.netTax ? '$' + (order.netValue + order.netTax).toFixed(2) : '$0.00'}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="table-responsive">
            <table>
              <tbody>
                <tr>
                  <td>
                    <strong>
                      <Translate word={ui['ORDER_NUMBER']} />:
                    </strong>
                  </td>
                  <td className="col-sm-2">{order.id}</td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      <Translate word={ui['ORDER_DATE']} />:
                    </strong>
                  </td>
                  <td className="col-sm-2">{order.orderDate ? moment(order.orderDate).format('MM/DD/YYYY') : ''}</td>
                </tr>
                <tr>
                  <td style={{ verticalAlign: 'top' }}>
                    <strong>
                      <Translate word={ui['ORDER_STATUS']} />:
                    </strong>
                  </td>
                  <td className="col-sm-2">{mainStatus + subStatus}</td>
                </tr>
                <tr>
                  <td style={{ verticalAlign: 'top' }}>
                    <strong>
                      <Translate word={ui['ORDER_PAYMENT_TERM']} />
                    </strong>
                  </td>
                  <td className="col-sm-2">{order.descriptions ? order.descriptions.paymentTermsDesc : ''}</td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      <Translate word={ui['ORDER_SHIPCOMPLETE']} />
                    </strong>
                  </td>
                  <td className="col-sm-2">{order.shipComplete ? order.shipComplete : 'No'}</td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      <Translate word={ui['PO_NUMBER']} />:
                    </strong>
                  </td>
                  <td className="col-sm-2">{order.po}</td>
                </tr>
                {order.orderShippingAddress && (
                  <tr>
                    <td style={{ verticalAlign: 'top' }}>
                      <strong>
                        <Translate word={ui['ORDER_SHIPPING_ADDRESS']} />
                      </strong>
                    </td>
                    <td className="col-sm-2">
                      {order.orderShippingAddress ? order.orderShippingAddress.name : ''}
                      <br />
                      {order.orderShippingAddress ? order.orderShippingAddress.addressLine1 : ''}
                      <br />
                      {order.orderShippingAddress ? order.orderShippingAddress.city + ' ' : ''}
                      {order.orderShippingAddress ? order.orderShippingAddress.state + ' ' : ''}
                      {order.orderShippingAddress ? order.orderShippingAddress.postalCode : ''}
                    </td>
                  </tr>
                )}
                <tr>
                  <td style={{ verticalAlign: 'top' }}>
                    <strong>
                      <Translate word={ui['ORDER_SHIPPING_COMMENT']} />
                    </strong>
                  </td>
                  <td className="col-sm-8">{order.shipComments}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <hr size="2" />
      <h2 style={{ fontSize: '1.8rem', fontWeight: '450' }}>
        <Translate word={ui['ORDER_LINE_ITEM_SUMMARY']} />
      </h2>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>
                <Translate word={ui['MODEL']} />
              </th>
              <th id="col-header-grade">
                <Translate word={ui['GRADE_INDICATOR']} />
              </th>
              <th>
                <Translate word={ui['MODEL_DESCRIPTION']} />
              </th>
              <th>
                <Translate word={ui['ITEM_COST']} />
              </th>
              <th>
                <Translate word={ui['ITEM_SHIPPING']} />
              </th>
              {order.noOfScheduledLines === null ? (
                <th>
                  <Translate word={ui['ITEM_SCHEDULE']} />
                </th>
              ) : order.noOfScheduledLines && order.noOfScheduledLines > 0 ? (
                <th>
                  <Translate word={ui['ITEM_SCHEDULE']} />
                </th>
              ) : (
                null
              )}
            </tr>
          </thead>
          <tbody>
            {order.items &&
              order.items.map((item, index) => {
                let itemStatus = translate({
                  word: itemStatusMappings[
                    (item.status[0].processingStatus ? item.status[0].processingStatus : ' ') +
                      (item.status[0].rejectionStatus ? item.status[0].rejectionStatus : ' ')
                  ],
                  language
                });
                let itemSubStatus = item.delivery.find(del => del.creditHold === 'B')
                  ? translate({
                      word: ui['STATUS_ON_CREDIT_REVIEW'],
                      language: language
                    })
                  : item.delivery.find(del => del.PGIDate === null)
                  ? translate({
                      word: ui['STATUS_AWAITING_SHIPPING'],
                      language: language
                    })
                  : '';

                 // Item with categories ZTS,ZTS5,ZTS4 are third party products and these products don't have delivery info. Until these items are delivered we don't have proper ETA and we display ETA. So once these are delivered user should refer to invoice info.
                // Items shipped from plants 0777,0444, 0809, DS01 don't have proper ETA defined so we display TBD until they are shipped. Once these are shipped user should refer to delivery information.
                //ZTAN (ZTA in GPW) is not third party item category. 

                // New Values for GSAP
                // Item Category: 
                // ZTA        ZTAN
                // ZTS         ZSDD
                // ZTS4       ZCTO
                // ZTS5       ZSDD
                // Item Plants
                // 0444       No Applicable in GSAP
                // 0809       US10
                // 0777       No Applicable in GSAP
                // DS01      US04

                // new Values to the plant and categories
                let item_categories = ['ZSDD','ZCTO'],
                item_plants = ['US04', 'US10']
                if(source === 'S'){
                  item_categories = ['ZTS', 'ZTS5', 'ZTS4'];
                  item_plants = ['0444', '0809', '0777', 'DS01']; 
                }
                let plant_category_check,
                  display_ETA,
                  item_delivered = String(itemStatus).toUpperCase() === 'COMPLETED',
                  item_shipped = item.shippedQuantity > 0,
                  with_cat = item_categories.includes(item.item_category),
                  with_plant = item_plants.includes(item.item_plant),
                  plant_shipped = with_plant && item_shipped,
                  category_delivered = with_cat && item_delivered;
                if (String(mainStatus).toUpperCase() === 'COMPLETED') {
                  plant_category_check = false;
                  display_ETA = (!with_plant && !with_cat) || (category_delivered && isEmpty(item.invoice));
                } else {
                  plant_category_check =
                    (with_cat && !item_delivered) ||
                    (with_plant && !item_shipped) ||
                    (category_delivered && isEmpty(item.invoice)) ||
                    (plant_shipped && !with_cat && isEmpty(item.delivery));
                  display_ETA = (!with_plant && !with_cat) || plant_category_check;
                }
                let itemShipMode = item.businessOut && item.businessOut[0]  && (item.businessOut[0].shipMode || '');
                let shipMode = translate({
                  word: source === 'S' ? shipModeMappings[itemShipMode] : gsapShipModeMappings[itemShipMode],
                  language
                });
                let isGPWBStock;   
                if(source === 'S'){
                  //If Product ends with .B then we remove the .B from model
                  isGPWBStock = item.outProductCode.endsWith(".B") ? true : false ;
                  //if GPWstock is true for GPW vistex and non-vistex credits then add grade
                  isGPWBStock === true ? (item.grade = "B") : (item.grade = "A")
                }
                //Irrespective of source will check wheter it has grade "A" / "B"  after adding grade field in GPW to display the quanity respectively
                  let isBStock = item.grade === 'B' ? true : false;

                
                let shortQtyIssue = item.isShortQtyIssue;
                let confirmationRequired = false;
                let substutionRequired = false;
                let message1 = '';
                let message2 = '';
                let message3 = '';
                if (isBStock && shortQtyIssue) {
                  confirmationRequired = true;
                }
                if (item.outProductCode !== item.productCode) {
                  substutionRequired = true;
                }
                if (substutionRequired) {
                  message1 = translate({ word: ui['ITEM_SUBSTITUTION_MESSAGE'], language }) + ' ' + item.productCode + '.';
                }
                if (confirmationRequired) {
                  message2 = <Translate word={ui['ITEM_CONFIRMATION_MESSAGE']} />;
                }
                if (substutionRequired && confirmationRequired) {
                  message2 =
                    translate({ word: ui['ITEM_SUBSTITUTION_MESSAGE'], language }) +
                    item.productCode +
                    '. ' +
                    translate({ word: ui['ITEM_CONFIRMATION_MESSAGE'], language });
                }
                
                return (
                  <tr key={index}>
                    <td id={`productCode_${index}`}>
                    {source === 'S' ? (isGPWBStock ? item.outProductCode.slice(0,item.outProductCode.length - 2): item.outProductCode) : item.outProductCode} <br />
                      <span className="text-danger">
                        {confirmationRequired && substutionRequired ? message3 : confirmationRequired ? message2 : substutionRequired ? message1 : ''}
                      </span>
                    </td>
                    <td id={`gradeValue_${index}`}>{item.grade}</td>
                    <td>{item.shortText}</td>
                    <td>
                      <table className="table table-bordered table-condensed table-transparent">
                        <tbody>
                          <tr>
                            <td>
                              <Translate word={ui['ITEM_UNIT_PRICE']} />
                            </td>
                            <td>{'$' + (item.price.unitPrice - item.price.freight / item.requestedQuantity).toFixed(2)}</td>
                          </tr>
                          <tr>
                          <td>
                              {!isBStock ? <Translate word={ui['ITEM_A_STOCK_QUANTITY']} /> :  <Translate word={ui['ITEM_B_STOCK_QUANTITY']} />}
                            </td>
                            <td>{item.requestedQuantity}</td>
                          </tr>
                          <tr>
                            <td>
                              <Translate word={ui['ITEM_SUB_TOTAL']} />
                            </td>
                            <td>{'$' + item.price.netValue.toFixed(2)}</td>
                          </tr>
                          <tr>
                            <td>
                              <Translate word={ui['ITEM_TAX']} />
                            </td>
                            <td>{'$' + item.price.tax.toFixed(2)}</td>
                          </tr>
                          <tr>
                            <td>
                              <Translate word={ui['ITEM_FREIGHT']} />
                            </td>
                            <td>{'$' + item.price.freight.toFixed(2)}</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td>
                      <table className="table table-bordered table-condensed table-transparent">
                        <tbody>
                          <tr>
                            <td>
                              <Translate word={ui['ITEM_STATUS']} />
                            </td>
                            <td>{itemStatus + itemSubStatus}</td>
                          </tr>
                          <tr>
                            <td>
                              <Translate word={ui['ITEM_SHIPPIED_QUANTITY']} />
                            </td>
                            <td>{item.shippedQuantity}</td>
                          </tr>
                          <tr>
                            <td>
                              <Translate word={ui['ITEM_RDD']} />
                            </td>
                            <td>{item.scheduledLines[0] ? (item.scheduledLines[0].RDD ? moment(item.scheduledLines[0].RDD).format('MM/DD/YYYY') : '') : ''}</td>
                          </tr>
                          <tr>
                            <td>
                              <Translate word={ui['ITEM_SHIPPING_MODE']} />
                            </td>
                            <td>{shipMode}</td>
                          </tr>
                          {item.shippingAddress && (
                            <tr>
                              <td>
                                <Translate word={ui['ITEM_SHIPPING_ADDRESS']} />
                              </td>
                              <td>
                                {item.shippingAddress ? item.shippingAddress.name : ''}
                                <br />
                                {item.shippingAddress ? item.shippingAddress.addressLine1 : ''}
                                <br />
                                {item.shippingAddress ? item.shippingAddress.city + ' ' : ''}
                                {item.shippingAddress ? item.shippingAddress.state + ' ' : ''}
                                {item.shippingAddress ? item.shippingAddress.postalCode : ''}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </td>
                    <td>
                      {item.nonZeroConfQtySchLines.length > 0 ? (
                        <table className="table table-bordered table-condensed table-transparent">
                          <tbody>
                            {display_ETA ? (
                              <tr>
                                <td>
                                  <Translate word={ui['ITEM_SCHDULED_QUANTITY']} />
                                </td>
                                <td>
                                  <Translate word={ui['ITEM_MAD']} />
                                </td>
                                <td>
                                  <Translate word={ui['ITEM_ESD']} />
                                </td>
                                <td>
                                  <Translate word={ui['ITEM_EDD']} />
                                </td>
                              </tr>
                            ) : (
                              <tr>
                                <td>
                                  <Translate word={ui['ITEM_SCHDULED_QUANTITY']} />
                                </td>
                                <td> ETA </td>
                              </tr>
                            )}
                            {item.nonZeroConfQtySchLines.map((schItem, i) =>
                              display_ETA ? (
                                <tr key={i} id={`display_ETA_${index}`}>
                                  <td>{schItem.confirmedQuantity}</td>
                                  <td>
                                    {plant_category_check
                                      ? 'TBD'
                                      : schItem.materialAvailableDate
                                      ? moment(schItem.materialAvailableDate).format('MM/DD/YYYY')
                                      : ''}
                                  </td>
                                  <td>{plant_category_check ? 'TBD' : schItem.shippingDate ? moment(schItem.shippingDate).format('MM/DD/YYYY') : ''}</td>
                                  <td>
                                    {plant_category_check
                                      ? 'TBD'
                                      : schItem.estimatedDeliveryDate
                                      ? moment(schItem.estimatedDeliveryDate).format('MM/DD/YYYY')
                                      : ''}
                                  </td>
                                </tr>
                              ) : (
                                <tr key={i}>
                                  <td>{schItem.confirmedQuantity}</td>
                                  <td>
                                    {!isEmpty(item.invoice) || !isEmpty(item.delivery) ? (
                                      <a href ="/#" target="_blank" onClick={!isEmpty(item.delivery) ? scrollToDelivery : scrollToInvoice}>
                                        <Translate word={ui['SEE_BELOW_DELIVERY_INFO']} />
                                      </a>
                                    ) : (
                                      'TBD'
                                    )}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      ) : (
                        ''
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {order.items &&
        order.items.forEach(item => {
          deliveryCount = deliveryCount + item.delivery.length;
        })}
      {deliveryCount > 0 ? (
        <div>
          <hr size="2" />
          <h2 style={{ fontSize: '1.8rem', fontWeight: '450' }}>
            <Translate word={ui['ITEM_DELIVERY_INFORMATION']} />
          </h2>
          <div className="table-responsive" ref={deliveryFocus}>
            <table className="table">
              <thead>
                <tr>
                  <th>
                    <Translate word={ui['MODEL']} />
                  </th>
                  <th>
                  <Translate word={ui['GRADE_INDICATOR']} />
                  </th>
                  <th>
                    <Translate word={ui['ITEM_DELIVERED_QUANTITY']} />
                  </th>
                  <th>
                    <Translate word={ui['ITEM_SHIPPING_CARRIERS']} />
                  </th>
                  <th>
                    <Translate word={ui['ITEM_TRACKING_NUMBER']} />
                  </th>
                  <th>
                    <Translate word={ui['ITEM_BILL_OF_LADING_NUMBER']} />
                  </th>
                  <th>
                    <Translate word={ui['ITEM_WAREHOUSE_SHIPPING_DATE']} />
                  </th>
                </tr>
              </thead>
              <tbody>
                {order.items.map((item, index) => {
                  return item.delivery.map((del, i) => {
                    //checking delivery material has B stock
                    let isGPWBStock = del.material.endsWith(".B") ? true : false;
                    let carrier = carrierCodeMappings.find(carrierCode => del.shippingCarrier === carrierCode.CODE);
                    let PGIDate = del.PGIDate;
                    let PLDGIDate = del.PLDGIDate;
                    return (
                      <tr key={i}>
                        <td>{!isGPWBStock ? del.material : del.material.slice(0 , del.material.length - 2)}</td>
                        <td>{item.grade}</td>
                        <td>{del.deliveredQty}</td>
                        <td>{carrier ? carrier.NAME : ''}</td>
                        <td>
                          {carrier && carrier.TRACKING_URL !== '#' ? 
                            <a target="_blank"  rel="noopener noreferrer" href={carrier.TRACKING_URL.replace('~TRACKING_NUMBER~', del.trackingNumber)}>
                              {del.trackingNumber}
                            </a> 
                            : 
                            del.trackingNumber
                          }
                        </td>
                        <td>{del.billOfLadingNumber}</td>
                        <td>
                          {' '}
                          {(PGIDate || PLDGIDate) &&
                            (del.PGIDate !== '0000-00-00' ? moment(del.PGIDate).format('MM/DD/YYYY') : moment(del.PLDGIDate).format('MM/DD/YYYY'))}
                        </td>
                      </tr>
                    );
                  });
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        ''
      )}

      {order.items &&
        order.items.forEach(item => {
          item.invoice.forEach(inv => {
            if (inv.docType === 'M') {
              invoiceCount++;
            }
          });
        })}
      {invoiceCount > 0 ? (
        <div className="table-responsive" ref={invoiceFocus}>
          <hr size="2" />
          <h2 style={{ fontSize: '1.8rem', fontWeight: '450' }}>
            <Translate word={ui['ITEM_INVOICE_INFORMATION']} />
          </h2>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>
                    <Translate word={ui['MODEL']} />
                  </th>
                  <th>
                    <Translate word={ui['GRADE_INDICATOR']} />
                  </th>
                  <th>
                    <Translate word={ui['ITEM_INVOICE_NUMBER']} />
                  </th>
                  <th>
                    <Translate word={ui['ITEM_INVOICE_DATE']} />
                  </th>
                  <th>
                    <Translate word={ui['ITEM_INVOICE_QUANTITY']} />
                  </th>
                  <th>
                    <Translate word={ui['ITEM_SUB_TOTAL']} />
                  </th>
                  <th>
                    <Translate word={ui['ITEM_TAX']} />
                  </th>
                  <th>
                    <Translate word={ui['ITEM_FREIGHT']} />
                  </th>
                  <th>
                    <Translate word={ui['ITEM_TOTAL']} />
                  </th>
                  <td />
                </tr>
              </thead>
              <tbody>
                {order.items.map((item, index) => {
                  return item.invoice.map((inv, i) => {
                    //checking whether invoice has B stock
                    let isGPWBStock = inv.material.endsWith(".B") ? true : false;
                    return inv.docType === 'M' ? (
                      <tr key={i}>
                        <td width="10%">{!isGPWBStock ? inv.material : inv.material.slice(0 , inv.material.length - 2)}</td>
                        <td>{item.grade}</td>
                        <td>{inv.invoiceNumber}</td>
                        <td>{inv.creationDate ? moment(inv.creationDate).format('MM/DD/YYYY') : ''}</td>
                        <td>{inv.invoicedQuantity}</td>
                        <td>{'$' + inv.lineNetValue.toFixed(2)}</td>
                        <td>{'$' + inv.lineTax.toFixed(2)}</td>
                        <td>{'$' + inv.lineFreight.toFixed(2)}</td>
                        <td>{'$' + (inv.lineNetValue + inv.lineTax).toFixed(2)}</td>
                        <td>
                          <Button
                            bsStyle="primary"
                            className="btn-margin"
                            onClick={() => getInvoiceDetails(translate({ word: ui['LANG_ID'], language: language }), order.id, inv.invoiceNumber,source)}
                            disabled={isFetching}
                          >
                            <Translate word={ui['DOWNLOAD_INVOICE_PDF_BUTTON']} />
                          </Button>
                        </td>
                      </tr>
                    ) : (
                      <tr />
                    );
                  });
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        ''
      )}
      <div style={{ paddingBottom: '20px', fontSize: '1.3rem' }}>
        <b>
          <Translate word={ui['SHIPPING_COST']} />
        </b>
        :
        <br />
        <Translate word={ui['SHIPPING_COST_MSG']} />
      </div>
      {/* <div className="disclaimer" style={{ paddingBottom: '20px' }}>
        <Translate word={ui['ORDER_ESTIMATED_SHIPPING_MESSAGE']} />
      </div> */}
      <hr size="8" />
      <Link
        to={'/orderhistory'}
        role="button"
        className="btn btn-primary"
        id="backbutton"
        style={{ paddingTop: '8px', paddingBottom: '8px', float: 'left' }}
        onClick={() => setBackButtonFlag(true)}
      >
        <Translate word={ui['BACK_BUTTON']} />
      </Link>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    order: state.order.order,
    isFetching: state.common.isFetching,
    invoice: state.order.invoice,
    error: state.error.error_obj,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchOrder: (language, orderId, source) => dispatch(orderActions.fetchOrder(language, orderId, source)),
    setBackButtonFlag: resetFlag => dispatch(orderActions.setBackButtonFlag(resetFlag)),
    getInvoiceDetails: (language, orderId, invoiceNumber,source) => dispatch(orderActions.getInvoiceDetails(language, orderId, invoiceNumber,source)),
    clearOrderDetails: () => dispatch(orderActions.clearOrderDetails()),
    resetError: () => dispatch(errorActions.resetError()),
    resetFetching: () => dispatch(commonActions.resetFetching())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderView);
