import config from '../config';
import { getHttp } from './utils';
import { ADMIN_LOGOUT, USER_LOGOUT } from './common_actions';
const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
const GET_PRODUCT_ERROR = 'GET_PRODUCT_ERROR';
const RESET_PRODUCT = 'RESET_PRODUCT';
const enAPI = config.enAPI;

export default (state = {}, action) => {
  switch (action.type) {
    case GET_PRODUCT_SUCCESS:
      return action.data;
    case RESET_PRODUCT:
    case ADMIN_LOGOUT:
    case GET_PRODUCT_ERROR:
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const resetProduct = () => {
  return dispatch => {
    dispatch({ type: RESET_PRODUCT });
  };
};

export const getProduct = (type, sku, lang) => {
  return (dispatch, state) => {
    return new Promise((resolve, reject) => {
      if (sku) {
        const http = getHttp(dispatch, state);
        http
          .get(`${enAPI}product/${type}/${lang}/${sku}`)
          .then(response => {
            dispatch({ type: GET_PRODUCT_SUCCESS, data: response.data });
            resolve();
          })
          .catch(error_obj => {
            dispatch({ type: GET_PRODUCT_ERROR });
            reject({ error: error_obj });
          });
      } else {
        resolve();
      }
    });
  };
};

const CONTRACT_TYPES = {
  pro: 'professional',
  consumer: 'consumer'
};
export const statelessGetProduct = (type, sku, lang) => {
  return (dispatch, state) => {
    return new Promise((resolve, reject) => {
      if (sku) {
        const http = getHttp(dispatch, state);
        http
          .get(`${enAPI}product/${CONTRACT_TYPES[type]}/${lang}/${sku}`)
          .then(response => {
            if (response.status === 200) {
              resolve({ data: response.data });
            } else {
              reject();
            }
          })
          .catch(error_obj => {
            reject({ error: error_obj });
          });
      } else {
        reject();
      }
    });
  };
};
