import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import config from '../config';
import { getHttp } from './utils';
import { filterBulletinsBasedOnPriceBook } from '../components/bulletin/BulletinHelper';
const GET_ALL_BULLETINS = 'GET_ALL_BULLETINS';
const GET_BULLETIN_TYPES = 'GET_BULLETIN_TYPES';
const FETCHING_BULLETIN = 'FETCHING_BULLETIN';
const FETCHING_BULLETIN_ERROR = 'FETCHING_BULLETIN_ERROR';
const DOWNLOADING_BULLETIN_PDFS = 'DOWNLOADING_BULLETIN_PDFS';
export default (state = { bulletins: [], fetching: false, error: null, downloading: false }, action) => {
  switch (action.type) {
    case GET_ALL_BULLETINS:
      return {
        ...state,
        bulletins: action.bulletins
      };
    case GET_BULLETIN_TYPES:
      return {
        ...state,
        bulletin_types:action.bulletin_types
      }
    case FETCHING_BULLETIN:
      return {
        ...state,
        fetching: action.status
      };
    case FETCHING_BULLETIN_ERROR: {
      return {
        ...state,
        fetching: false,
        error: action.error
      };
    }
    case DOWNLOADING_BULLETIN_PDFS:
      return {
        ...state,
        downloading: action.status
      };
    default:
      return state;
  }
};
export const getAllBulletins = ({ cpg, soldTo }) => {
  return (dispatch, state) => {
    try {
      const URL = `${config.enAPI}bulletin/all/${cpg}/${soldTo}`;
      const http = getHttp(dispatch, state);
      dispatch({ type: FETCHING_BULLETIN_ERROR, error: null });
      if (!state().bulletin.fetching) {
        dispatch({ type: FETCHING_BULLETIN, status: true });
        http
          .get(URL)
          .then(response => {
            const filtered = filterBulletinsBasedOnPriceBook(response.data, state().account.priceBook);
            const bulletin_types = new Set()
            filtered.forEach(obj=>bulletin_types.add(obj.bulletin_type))
            dispatch({ type: FETCHING_BULLETIN, status: false });
            dispatch({ type: GET_ALL_BULLETINS, bulletins: filtered });
            dispatch({type:GET_BULLETIN_TYPES,bulletin_types:Array.from(bulletin_types)})
          })
          .catch(error_obj => {
            dispatch({ type: FETCHING_BULLETIN, status: false });
            dispatch({ type: FETCHING_BULLETIN_ERROR, error: { message: 'Something went wrong when tried to get bulletins', detail: error_obj } });
          })
          .finally(() => {
            dispatch({ type: FETCHING_BULLETIN, status: false });
          });
      }
    } catch (error) {
      dispatch({ type: FETCHING_BULLETIN, status: false });
      dispatch({ type: FETCHING_BULLETIN_ERROR, error: { message: 'Something went wrong when tried to get bulletins', detail: error } });
    }
  };
};

const downloadBulletinPDF = ({ bulletin_no, bulletin_url }) => {
    return (dispatch, state) => {
        return new Promise((resolve, reject) => {
            try {
                const URL = `${bulletin_url}`;
                fetch(URL, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/octet-stream',
                    },
                })
                    .then((res) => res.arrayBuffer())
                    .then((data) => {
                        var base64Str = Buffer.from(data).toString('base64');
                        resolve(base64Str);
                    });
            } catch (error) {
                console.log(error);
                reject(error);
            }
        });
    };
};

///HELPER
export const downloadFilesAndCompress = ({ zipFileName = 'bulletins.zip', bulletins }) => {
  return dispatch => {
    dispatch({ type: DOWNLOADING_BULLETIN_PDFS, status: true });
    return new Promise((resolve, reject) => {
      try {
        let fileCount = 0;
        const zip = new JSZip();
        bulletins.forEach(({ bulletin_url, bulletin_no }) => {
          const fileName = `${bulletin_no}.pdf`;
          dispatch(downloadBulletinPDF({ bulletin_no, bulletin_url }))
            .then(response => {
              fileCount++;
              zip.file(fileName, response, { base64: true });
              if (fileCount === bulletins.length) {
                zip
                  .generateAsync({ type: 'blob' })
                  .then(zipFile => {
                    saveAs(zipFile, zipFileName);
                    dispatch({ type: DOWNLOADING_BULLETIN_PDFS, status: false });
                    resolve();
                  })
                  .catch(error => {
                    dispatch({ type: DOWNLOADING_BULLETIN_PDFS, status: false });
                    reject();
                  });
              }
            })
            .catch(error => {
              fileCount++;
            });
        });
      } catch (error) {
        reject();
      }
    });
  };
};
