import { USER_LOGOUT, ADMIN_LOGOUT, SET_IMPERSONATION, CHANGE_FETCHING } from './common_actions';
import config from '../config';
import { respond, getHttp, getContract } from './utils';
import { getAllBulletins } from './bulletin';
import { shoudlShowBulletin } from '../components/bulletin/BulletinHelper';
import { getCart } from './cart';
import * as commonActions from './common';
const enAPI = config.enAPI;

const FETCH_ACCOUNT_SUCCESS = 'FETCH_ACCOUNT_SUCCESS';
const FETCH_ADMIN_ACCOUNT_SUCCESS = 'FETCH_ADMIN_ACCOUNT_SUCCESS';
const FETCH_BUYER_USERS_SUCCESS = 'FETCH_BUYER_USERS_SUCCESS';
const CHANGE_PRICEBOOK = 'CHANGE_PRICEBOOK';

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_ACCOUNT_SUCCESS:
    case FETCH_ADMIN_ACCOUNT_SUCCESS:
    case FETCH_BUYER_USERS_SUCCESS:
    case 'SAVE_USER_SUCCESS':
    case SET_IMPERSONATION:
      return { ...state, ...action.data };
    case CHANGE_PRICEBOOK:
      return {
        ...state,
        priceBook: action.data
      };
    case USER_LOGOUT:
      return {};
    case ADMIN_LOGOUT:
      return {
        ...state,
        user: undefined,
        buyer: undefined,
        catalog: undefined,
        priceBook: undefined,
        impersonated: undefined,
        managerList: undefined,
        bulletin: undefined,
        userTemp: undefined
      };
    default:
      return state;
  }
};

export const loadUsersForBuyer = buyerId => {
  return (dispatch, state) => {
    const http = getHttp(dispatch, state);
    dispatch({ type: CHANGE_FETCHING, data: { isFetching: true } });
    dispatch({ type: FETCH_BUYER_USERS_SUCCESS, data: { userList: [] } });

    http
      .get(enAPI + 'account/internal/buyer/users/' + buyerId)
      .then(response => {
        dispatch({ type: FETCH_BUYER_USERS_SUCCESS, data: { userList: response.data } });
        dispatch({ type: CHANGE_FETCHING, data: { isFetching: undefined } });
      })
      .catch(error_obj => {
        dispatch({ type: CHANGE_FETCHING, data: { isFetching: undefined } });
        respond('loadUsersForBuyer', error_obj, dispatch);
      });
  };
};

export const loadAdminAccount = country => {
  return (dispatch, state) => {
    const http = getHttp(dispatch, state);
    return http
      .get(enAPI + 'account/internal/' + country + '00')
      .then(response => {
        dispatch({
          type: FETCH_ADMIN_ACCOUNT_SUCCESS,
          data: {
            adminUser: response.data.user,
            buyerList: response.data.accounts,
            enUserToken: response.headers['x-en-user-token']
          }
        });
      })
      .catch(error_obj => {
        respond('loadAdminAccount', error_obj, dispatch);
      });
  };
};

export const impersonate = (params, country) => {
  return (dispatch, state) => {
    dispatch(commonActions.resetForm('cart'));
    dispatch(loadAccount(params, country));
    dispatch({ type: ADMIN_LOGOUT });
  };
};

export const loadAccount = (params, country) => {
  return (dispatch, state) => {
    var loadAccountAPI = '';

    var salesOrg = country + '00';

    if (params) {
      loadAccountAPI =
        state().account.adminUser.role === 'ADMIN'
          ? enAPI + 'account/external/' + salesOrg + '?userid=' + params.user
          : enAPI + 'account/internal/buyer/' + salesOrg + '/' + params.buyer;
    } else {
      loadAccountAPI = enAPI + 'account/external/' + salesOrg;
    }
    dispatch({ type: CHANGE_FETCHING, data: { isFetching: true } });

    const http = getHttp(dispatch, state);
    let response = {};
    return http
      .get(loadAccountAPI)
      .then(actualResponse => {
        response = { ...actualResponse };
        // console.log('Buyer account pricebook url::', actualResponse.data.pricebook, new Date());
        dispatch({ type: 'SAVE_USER_SUCCESS', data: { userTemp: response.data.user } });
        return fetch(actualResponse.data.pricebook, { method: 'GET' }); //Fetch pricebook from signed url
      })
      .then(res => {
        console.log('Pricebook::', res);
        return res && res.text();
      })
      .then(res => {
        let temppricebook = res && JSON.parse(JSON.parse(res).body);
        if (params) {
          dispatch({ type: SET_IMPERSONATION, data: { impersonated: true } });
        }
        dispatch({ type: CHANGE_FETCHING, data: { isFetching: undefined } });
        response.data.pricebook = temppricebook ? temppricebook : response.data.pricebook;
        const contract_type = getContract(response.data.contract);
        dispatch({
          type: FETCH_ACCOUNT_SUCCESS,
          data: {
            user: response.data.user,
            buyer: response.data.buyer,
            contract_type: contract_type,
            priceBook: response.data.pricebook,
            productDescriptions: response.data.product_descriptions,
            contract: response.data.contract,
            userList: response.data.userList,
            catalog: response.data.catalog,
            addresses: response.data.addresses,
            enUserToken: response.headers['x-en-user-token'],
            managerList: response.data.managerList,
            contactIdMapping: getcontactIdMappings(response.data.userList)
          }
        });
        dispatch(getCart(response.data.user, response.data.contract, response.data.pricebook, state().language));
        dispatch(updateProductDescription(state().language));
        //dispatch({ type: CHANGE_FETCHING, data: { isFetching: undefined } });
        if (shoudlShowBulletin(response.data)) {
          const cpg = response.data.contract.pricingGroup;
          const soldTo = response.data.buyer.id;
          dispatch(getAllBulletins({ cpg, soldTo }));
        }
      })
      .catch(error_obj => {
        dispatch({ type: SET_IMPERSONATION, data: { impersonated: undefined } });
        dispatch({ type: CHANGE_FETCHING, data: { isFetching: undefined } });
        respond('loadAccount', error_obj, dispatch);
      });
  };
};

export const updateProductDescription = language => {
  return (dispatch, state) => {
    if (!state().account.priceBook) {
      return;
    }

    var newPriceBook = [...(state().account.priceBook || {})];

    for (let item of newPriceBook) {

      let subCats = [];
      if (state().account.catalog && state().account.catalog[language]) {
        var catalog = state().account.catalog[language];

        catalog.forEach(cat => {
          let sCats = cat.subCats.filter(subCats =>
            subCats.skus.find(
              aSku =>
               aSku === item.sku
               )
              ? true
              : false
          );

          if (sCats.length > 0) {
            subCats = subCats.concat(sCats.map((item, index) => item.name));
          }
        });
      }

      item.sCats = subCats;
    }
    dispatch({ type: CHANGE_PRICEBOOK, data: newPriceBook });
  };
};

export const getcontactIdMappings = userList => {
  const idMap = {}
  userList.forEach(user =>{
    idMap[user.id] = user.oldId
  })
  return idMap
}