import React from 'react';
import * as authActions from '../modules/auth';
import { connect } from 'react-redux';
import Translate, { translate } from './dictionary/Translator';
import ui from './dictionary/ui';
import DocumentTitle from 'react-document-title';

class Login extends React.Component {
  componentWillMount() {
    this.props.resetLogin();
  }

  login = () => {
    this.props.login();
  };

  render() {
    const { lang } = this.props;
    return (
      <DocumentTitle
        title={`${translate({
          language: lang,
          word: 'LOGIN',
          dictionary: ui
        })} | Sony ExpressNet`}
      >
        <div className="container" style={{ display: 'flex', flexWrap: 'wrap' }}>
          <h1 style={{ fontSize: '2.6rem' }}>
            <Translate dictionary={ui.MAIN} word="LOGIN_INSTRUCTION" />
          </h1>
          {!this.props.token && (
            <button
              style={{
                marginLeft: '3%',
                minWidth: '150px',
                alignSelf: 'center'
              }}
              className="btn btn-primary"
              onClick={() => this.login()}
            >
              <Translate dictionary={ui.BTN} word="LOGIN" />
            </button>
          )}
          {/* {!this.props.token && (
            <h5
              style={{
                display: 'inline-block',
                marginTop: 'auto'
              }}
            >
              <span style={{ color: 'red', fontWeight: 'bolder' }}>
                <Translate dictionary={ui} word="ATTENTION" />
                <u>
                  <Translate dictionary={ui} word="FIRST_LOGIN" />
                </u>{' '}
                <Translate dictionary={ui} word="CLICK_ON" />
              </span>
            </h5>
          )} */}
        </div>
      </DocumentTitle>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    login: () => dispatch(authActions.login()),
    resetLogin: () => dispatch(authActions.resetLogin())
  };
};

const mapStateToProps = ({ language, auth }) => {
  return {
    lang: language,
    token: auth.token
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
