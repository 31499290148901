import jwt_decode from 'jwt-decode';
import { CognitoAuth } from 'amazon-cognito-auth-js';
import { USER_LOGOUT, ADMIN_LOGOUT } from './common_actions';
import * as commonActions from './common';
import { resetAppStatus } from './appStatus';
import { clienID, domainName } from '../config';
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const ADMIN_LOGIN = 'ADMIN_LOGIN';

export default (state = {}, action) => {
  switch (action.type) {
    case ADMIN_LOGIN:
      return { ...state, isAdmin: action.isAdmin };
    case LOGIN_SUCCESS:
      return { ...state, token: action.data };
    case USER_LOGOUT:
      return { token: undefined };
    default:
      return state;
  }
};

const authData = {
  ClientId: clienID,
  AppWebDomain: domainName,
  TokenScopesArray: [],
  RedirectUriSignIn: window.location.origin + '/callback',
  RedirectUriSignOut: window.location.origin
};

const auth = new CognitoAuth(authData);

const loginDispatch = dispatch => {
  auth.userhandler = {
    onSuccess: function (result) {
      var token = result.getIdToken().getJwtToken();
      var decoded_token = jwt_decode(token);
      dispatch({ type: LOGIN_SUCCESS, data: token });
      // If the user has custom resource id, treat the user as external(not Admin)
      var resourceId = decoded_token['custom:resourceid'] ? decoded_token['custom:resourceid'].trim() : null;

      dispatch({ type: ADMIN_LOGIN, isAdmin: !resourceId });
    },
    onFailure: function (err) {}
  };
};

export const authReceived = href => {
  return dispatch => {
    loginDispatch(dispatch);
    auth.parseCognitoWebResponse(href);
  };
};

export const logout = isAdmin => {
  if (!isAdmin) {
    auth.signOut();
  }
  return dispatch => {
    if (isAdmin) {
      dispatch({ type: ADMIN_LOGOUT });
    } else {
      dispatch({ type: USER_LOGOUT });
      dispatch(commonActions.resetForm('AccountSearch'));
    }
    dispatch(commonActions.resetForm('cart'));
    dispatch(commonActions.resetForm('orderSearchFilter'));
    dispatch(commonActions.resetForm('promotionForm'));
    dispatch(commonActions.resetForm('createCreditForm'));
    dispatch(resetAppStatus());
  };
};

export const login = () => {
  return dispatch => {
    loginDispatch(dispatch);
    auth.getSession();
  };
};

export const resetLogin = () => {
  return (dispatch, state) => {
    dispatch({ type: USER_LOGOUT });
  };
};
