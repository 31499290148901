import React from 'react';
import * as accountActions from '../../modules/account';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, clearFields } from 'redux-form';
import { renderFilterSelect, removeAttribute } from '../utils/renderUtils';
import Loading from '../Loading';
import { Button } from 'react-bootstrap';
import * as errorActions from '../../modules/error';
import * as commonActions from '../../modules/common';
import Translate from '../dictionary/Translator';
import { translate } from '../dictionary/Translator';
import ui from './dictionary/ui';
import errorDictionary from '../dictionary/error';
import role_mappings from '../dictionary/roles';
import DocumentTitle from 'react-document-title';
var buyers = [];
var users = [];

const selector = formValueSelector('AccountSearch');

class AccountSearch extends React.Component {
  submit = values => {
    this.props.resetError();
    this.props.impersonate(values, this.props.country);
  };

  formBuyerOptions = buyerList => {
    buyers = [];
    buyerList.map((item, index) => buyers.push({ value: item.accountId, label: item.accountId + ' - ' + item.accountName }));
  };

  formUserOptions = (userList, language) => {
    users = [];
    userList.map((item, index) =>
      users.push({
        value: item.id,
        label: item.firstName + ' ' + item.lastName + '   -   ' + translate({ word: role_mappings[item.role], language })
      })
    );
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.buyerList) {
      this.formBuyerOptions(nextProps.buyerList);
    }
    if (nextProps.userList) {
      this.formUserOptions(nextProps.userList, this.props.language);
    }

    if (nextProps.impersonated && nextProps.user) {
      this.props.history.push('/');
    }
  }

  componentWillMount() {
    this.props.resetError();

    if (!this.props.adminUser) {
      this.props.loadAdminAccount(this.props.country);
    }

    if (this.props.buyerList) {
      this.formBuyerOptions(this.props.buyerList);
    }
    if (this.props.userList) {
      this.formUserOptions(this.props.userList, this.props.language);
    }
  }

  componentDidMount(){
    removeAttribute()
  }

  componentWillUnmount() {
    this.props.resetError();
  }

  render() {
    const { adminUser, error_obj, language } = this.props;
    // setTimeout(() => {
        removeAttribute();
    // }, 200);
    return (
      <DocumentTitle title={`${translate({ word: 'TITLE', dictionary: ui, language })} | Sony ExpressNet`}>
        <div className="container">
          {error_obj && !adminUser && (
            <div className="text-danger">
              <h4>
                <Translate dictionary={errorDictionary} word={error_obj.errorPath} />
              </h4>
            </div>
          )}
          {adminUser ? <AccountSearchForm {...this.props} onSubmit={this.submit} /> : !error_obj && <Loading />}
        </div>
      </DocumentTitle>
    );
  }
}

let CSSUserFields = props => {
  const { buyerSelected, loadUsersForBuyer, change, isFetching } = props;
  return (
    <div>
      <div style={{ marginBottom: '30px' }}>
        <label htmlFor="select-account">
          <Translate dictionary={ui} word="SEARCH_SELECT_ACCOUNT" />
        </label>
        <Field
          name="buyer"
          component={renderFilterSelect}
          options={buyers}
          onChange={(event, buyerId) => {
            loadUsersForBuyer(buyerId);
            change('user', '');
          }}
          props={{ disabled: isFetching }}
          id="select-account"
        />
      </div>

      {buyerSelected && (
        <div id="selectUser" style={{ marginBottom: '30px' }}>
          <label htmlFor="select-user">
            <Translate dictionary={ui} word="SEARCH_SELECT_USER" />
          </label>
          <Field name="user" component={renderFilterSelect} options={users} props={{ disabled: isFetching }} id="select-user" />
        </div>
      )}
    </div>
  );
};

let NonCSSUserFields = props => {
  return (
    <div style={{ marginBottom: '30px' }}>
      <label htmlFor="select-account">
        <Translate dictionary={ui} word="SEARCH_SELECT_ACCOUNT" />
      </label>
      <Field name="buyer" component={renderFilterSelect} options={buyers} id="select-account" />
    </div>
  );
};

let AccountSearchForm = props => {
  const { handleSubmit, adminUser, error_obj, isFetching } = props;

  return (
    <div style={{ width: '80vw', maxWidth: '600px', margin: '0 auto' }}>
      <h1 style={{ fontSize: '2.5rem', marginBottom: '4%' }}>
        <Translate dictionary={ui} word="TITLE" />
      </h1>
      {error_obj && (
        <div className="text-danger">
          <Translate dictionary={errorDictionary} word={error_obj.errorPath} />
        </div>
      )}
      <form onSubmit={handleSubmit} id="accountSearchForm">
        {adminUser.role === 'ADMIN' ? <CSSUserFields {...props} /> : <NonCSSUserFields {...props} />}
        <Button id="impersonate" bsStyle="primary" type="submit" disabled={isFetching}>
          <Translate dictionary={ui} word="SEARCH_BTN_IMPERSONATE" />
        </Button>
        {isFetching && <Loading />}
      </form>
    </div>
  );
};

const validate = (values, { language }) => {
  const errors = {};
  if (!values.buyer) {
    errors.buyer = translate({ language, dictionary: ui, word: 'SELECT_ACCOUNT_ERR' });
  }
  if (!values.user) {
    errors.user = translate({ language, dictionary: ui, word: 'SELECT_USER_ERR' });
  }

  return errors;
};

AccountSearchForm = reduxForm({
  form: 'AccountSearch',
  destroyOnUnmount: false,
  validate
})(AccountSearchForm);

const mapStateToProps = (state, props) => {
  return {
    adminUser: state.account.adminUser,
    buyerList: state.account.buyerList,
    userList: state.account.userList && state.account.userList.filter(user => user.role !== 'CLAIMS' && user.role !== 'OASC'),
    buyerSelected: selector(state, 'buyer'),
    impersonated: state.account.impersonated,
    user: state.account.user,
    error_obj: state.error.error_obj,
    isFetching: state.common.isFetching,
    country: state.country,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadAdminAccount: country => dispatch(accountActions.loadAdminAccount(country)),
    loadUsersForBuyer: buyerId => dispatch(accountActions.loadUsersForBuyer(buyerId)),
    impersonate: (params, country) => dispatch(accountActions.impersonate(params, country)),
    resetError: () => dispatch(errorActions.resetError()),
    resetFetching: () => dispatch(commonActions.resetFetching()),
    resetCartTable: () => dispatch(clearFields('cart'))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountSearch);
