import React from 'react';
import { FAQData } from './data';
import { connect } from 'react-redux';
import Translate from '../dictionary/Translator';
import ui from './dictionary/ui.json';
import './Help.css';
const FAQ = ({ language }) => {
  return (
    <div id="faq" name="faq" className="container FAQ">
      <h1 style={{ fontSize: '3rem', fontWeight: '600', borderBottom: '3px solid #bdbdbd ', paddingBottom: '3%', marginBottom: '2%', marginTop: '3%' }}>
        <Translate dictionary={ui} word="HELP_HEADER_FAQ" />
      </h1>
      {FAQData.map((FAQSection, index) => (
        <div key={index} className="FAQSection">
          <h2 className="FAQSection__header">{FAQSection.title[language]}</h2>
          <ul className="FAQSection__questions">
            {FAQSection.questions.map(
              (q, indexQuestion) =>
                q.question[language] && (
                  <li className="qa" key={`question${index}-${indexQuestion}`}>
                    <div className="qa__question">{q.question[language]}</div>
                    <div className="qa__answer">{q.answer[language]}</div>
                  </li>
                )
            )}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default connect(({ language }) => ({ language }))(FAQ);
