import React from 'react';
import './BulletinSearchForm.css';
import GoSearch from 'react-icons/lib/go/search';
import MdHighlightRemove from 'react-icons/lib/md/highlight-remove';
import ui from './dictionary/ui.json';
import Translate from '../dictionary/Translator';
import Select from 'react-select';
import { removeAttribute } from '../utils/renderUtils';
//import { BULLETIN_TYPES } from './BulletinHelper';
const BulletinSearchForm = ({ onInputChange, model, description, effectivePeriod, publishPeriod, type, number, onSearch, onClear, models, numbers, typeOptions }) => {
  return (
    <form className="BulletinSearchForm">
      <div className="BulletinSearchForm__inputs">
        <div className="left-side">
          <div className="form-group">
            <label htmlFor="model">
              <Translate dictionary={ui} word="MODEL" />
            </label>
            <Select
              id="model"
              name="model"
              options={prepareDataForSelect(models)}
              style={{ width: '100%' }}
              value={model}
              onChange={(e) => {
                onInputChange({
                    key: 'model',
                    value: e ? e.value : '',
                });
                removeAttribute("model");
                }
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="no">
              <Translate dictionary={ui} word="BULLETIN" />
            </label>
            <Select
              id="no"
              name="number"
              options={prepareDataForSelect(numbers)}
              style={{ width: '100%' }}
              value={number}
              onChange={e => {
                onInputChange({ 
                  key: 'number', value: e ? e.value : '' }); 
                  removeAttribute("no");
                }
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="description">
              <Translate dictionary={ui} word="DESCRIPTION" />
            </label>
            <textarea
              className="form-control"
              id="description"
              rows={3}
              name="description"
              onChange={e => onInputChange({ key: 'description', value: e ? e.target.value : '' })}
              value={description}
            />
          </div>
        </div>
        <div className="right-side">
          <div className="form-group">
            <label htmlFor="type">
              <Translate dictionary={ui} word="TYPE" />
            </label>
            <Select
              id="type"
              name="type"
              options={prepareDataForSelect(typeOptions ? typeOptions : [])}
              style={{ width: '100%' }}
              value={type}
              onChange={e => {
                onInputChange({ 
                  key: 'type', value: e ? e.value : '' });
                  removeAttribute("type");
                }
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="effectivePeriod">
              <Translate dictionary={ui} word="EFFECTIVE_PERIOD" />
            </label>
            <select className="form-control" id="effectivePeriod" name="effectivePeriod" onChange={e => onInputChange({ key: 'effectivePeriod', value: e.target.value })} value={effectivePeriod}>
              {renderMonthes()}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="publishPeriod">
              <Translate dictionary={ui} word="PUBLISH_PERIOD" />
            </label>
            <select className="form-control" id="publishPeriod" name="publishPeriod" onChange={e => onInputChange({ key: 'publishPeriod', value: e.target.value })} value={publishPeriod}>
              {renderMonthes()}
            </select>
          </div>
        </div>
      </div>
      <div className="BulletinSearchForm__buttons">
        <button type="button" className="btn btn-warning" onClick={onClear} aria-label="It clears the result table" style={{ background: '#A5660D' }}>
          <MdHighlightRemove role="img" aria-label="Clear" />
          <Translate dictionary={ui} word="CLEAR" />
        </button>
        <button type="button" className="btn btn-primary" onClick={onSearch} aria-label="it search bulletins based on parameters entered">
          <GoSearch role="img" aria-label="Search" />
          <Translate dictionary={ui} word="SEARCH" />
        </button>
      </div>
    </form>
  );
};

const prepareDataForSelect = data => {
  return data.map(option => ({ value: option, label: option }));
};
const renderMonthes = () => {
  return (
    <React.Fragment>
      <option value="">None</option>
      <option value="0">January</option>
      <option value="1">February</option>
      <option value="2">March</option>
      <option value="3">April</option>
      <option value="4">May</option>
      <option value="5">June</option>
      <option value="6">July</option>
      <option value="7">August</option>
      <option value="8">September</option>
      <option value="9">October</option>
      <option value="10">November</option>
      <option value="11">December</option>
    </React.Fragment>
  );
};
export default BulletinSearchForm;
