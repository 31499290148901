import React from 'react';
import { withRouter } from 'react-router-dom';
import './SideBySideCatalogMenu.css';
import Main from './MainCategorySide';
const SideBySideCatalogMenu = ({ mains, onGetSubCategories, selectedMain, selectedId, selectedSub, onSelectMain, onSelectSub, closed }) => {
  return (
    <div className="Side-By-Side-Menu">
      <div className="Side-By-Side-Menu__Main">
        <Main titles={mains} onSelectMain={onSelectMain} selectedMain={selectedMain} closed={closed} />
      </div>
      {selectedId && (
        <div className="Side-By-Side-Menu__Sub">
          {selectedId && <Sub subs={onGetSubCategories(selectedId)} onSelectSub={onSelectSub} selectedSub={selectedSub} main={selectedMain} />}
        </div>
      )}
    </div>
  );
};

const Sub = ({ subs, onSelectSub, selectedSub, main }) => {
  return (
    <div>
      <span className="Side-By-Side-Menu__Sub__Main">{main}</span>
      <ul>
        {subs.map(({ name, id }) => (
          <li key={id} className={selectedSub === id ? 'selected' : ''} onClick={() => onSelectSub({ id })}>
            {name}
          </li>
        ))}
      </ul>
    </div>
  );
};
export default withRouter(SideBySideCatalogMenu);
