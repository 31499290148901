import { format } from 'util';
import errorDictionary from './error';

export const translatedMessage = (message, exp, lang) => {
  let args = exp.args ? exp.args : [];
  return format.apply(null, [errorDictionary[exp.code][lang], ...args]);
};

// export const getArguments = (message, args) => {
// 	let extracted = [];
// 	args.forEach((element) => {
// 		extracted.push(message.match(element)[0]);
// 	});
// 	return extracted;
// };
