export const errorExpression = str => {
  if (str.indexOf('PO Number') !== -1 && str.indexOf('is already used in Order Number') !== -1) {
    let PONumber = str.substring(10, str.indexOf('is already used in Order Number') - 1);
    let OrderNumber = str.substring(str.indexOf('is already used in Order Number') + 32, str.indexOf('for Sold-to') - 1);
    return {
      valid: true,
      args: [PONumber, OrderNumber],
      code: 'PO_NUMBER_USED'
    };
  } else {
    return false;
  }
};
