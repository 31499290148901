import { updateProductDescription } from './account.js';
const key = '__EXPRESS_NET_Language__';

const CHANGE_LANG = 'CHANGE_LANG';
export const setStoreLang = ({ lang }) => {
  return {
    type: CHANGE_LANG,
    lang
  };
};

export const initializeAppLanguage = () => {
  return (dispatch, state) => {
    const savedLang = getLanguageFromLocalStorage();
    const country = state().country;
    if (country === 'US') {
      const validLangs = ['en_US'];
      if (validLangs.indexOf(savedLang) !== -1) {
        return dispatch(setStoreLang({ lang: savedLang }));
      } else {
        return dispatch(setStoreLang({ lang: validLangs[0] }));
      }
    } else if (country === 'CA') {
      const validLangs = ['en_CA', 'fr_CA'];
      if (validLangs.indexOf(savedLang) !== -1) {
        return dispatch(setStoreLang({ lang: savedLang }));
      } else {
        return dispatch(setStoreLang({ lang: validLangs[0] }));
      }
    } else {
      return dispatch(setStoreLang({ lang: 'en_US' }));
    }
  };
};

export const changeAppLanguage = ({ lang }) => {
  return (dispatch, state) => {
    setLanguageOnLocalStorage({ lang });
    dispatch(updateProductDescription(lang));
    dispatch(setStoreLang({ lang }));
  };
};

export default (state = 'en_US', action) => {
  switch (action.type) {
    case CHANGE_LANG:
      return action.lang;
    default:
      return state;
  }
};

const setLanguageOnLocalStorage = ({ lang }) => {
  try {
    localStorage.setItem(key, lang);
  } catch (error) {
    console.log(error);
  }
};

const getLanguageFromLocalStorage = () => {
  try {
    const lang = localStorage.getItem(key);
    if (lang && lang.length > 2) {
      return lang;
    } else {
      return 'en_US';
    }
  } catch (error) {
    console.log(error);
    return 'en_US';
  }
};

/* const removeLanguageFromLocalStorage = ({ lang }) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.log(error);
  }
};
 */
