import { SET_ERROR, RESET_ERROR } from './common_actions';

//Reducer
export default (state = {}, action) => {
  switch (action.type) {
    case SET_ERROR:
      return { ...state, ...action.data };
    case RESET_ERROR:
      return {};
    default:
      return state;
  }
};

export const resetError = () => {
  return dispatch => {
    dispatch({ type: RESET_ERROR });
  };
};
