import React from 'react';
import { connect } from 'react-redux';
import Translate from '../dictionary/Translator';
import ui from './dictionary/ui.json';
import { links } from './data';
const SonyLinks = ({ language, contract_type }) => {
  return (
    <div id="links" name="links" className="container">
      <h1 style={{ fontSize: '3rem', fontWeight: '600', borderBottom: '3px solid #bdbdbd ', paddingBottom: '4%', marginBottom: '2%', marginTop: '3%' }}>
        <Translate dictionary={ui} word="SONY_LINKS" />
      </h1>
      <p>
        <Translate dictionary={ui} word="SONY_LINKS_DESC" />
      </p>
      {links[language][contract_type].map(section => (
        <section style={{ marginBottom: '3%' }} key={section.header}>
          {section.header && section.header.length > 0 && <h3 className="nested-header">{section.header}</h3>}
          <section className="link-container">
            {section.links.map(link => (
              <div className="link-group" key={link.href}>
                {section.header && section.header.length > 0 ? (
                  <h4 style={{ margin: 0, fontWeight: '600' }}>{link.title}</h4>
                ) : (
                  <h3 style={{ margin: 0, fontWeight: '600', fontSize: '1.4rem' }}>{link.title}</h3>
                )}
                <a target="_blank" rel="noopener noreferrer" href={link.href}>
                  {link.visibility || link.href}
                </a>
              </div>
            ))}
          </section>
        </section>
      ))}
      {/* <h2 style={{ fontSize: '2.5rem', fontWeight: '600', marginBottom: '1%' }}>
        <Translate dictionary={ui} word="MEDIA_LINKS" />
      </h2>
      {mediaLinks[language].map(section => (
        <section style={{ marginBottom: '3%' }} key={section.header}>
          <h3 className="nested-header">{section.header}</h3>
          <section className="link-container">
            {section.links.map(link => (
              <div className="link-group" key={link.href}>
                <h4 style={{ margin: 0, fontWeight: '600' }}>{link.title}</h4>
                <a target="_blank" rel="noopener noreferrer" href={link.href}>
                  {link.href}
                </a>
              </div>
            ))}
          </section>
        </section>
      ))} */}
    </div>
  );
};
export default connect(({ language, account: { contract_type } }) => ({ language, contract_type }))(SonyLinks);
