import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, FieldArray, reduxForm, formValueSelector, stopSubmit, change } from 'redux-form';
import { Button } from 'react-bootstrap';
import * as orderActions from '../../modules/order';
import * as cartActions from '../../modules/cart';
import {
	renderInput,
	renderFilterSelect,
	renderSelect,
	renderDatePicker,
	RenderFileUpload,
	removeAttribute
} from '../utils/renderUtils';
import Modal from 'react-modal';
import moment from 'moment';
import * as utilsActions from '../../modules/utils';
import MdAddCircleOutline from 'react-icons/lib/md/add-circle-outline';
import MdRemoveRedEye from 'react-icons/lib/md/remove-red-eye';
import MdDelete from 'react-icons/lib/io/ios-trash';
import MdCloudUpload from 'react-icons/lib/md/cloud-upload';
import './cart.css';
import { getAlphaNumSkuToCompare } from '../utils/jsUtils';
import shipModes from './dictionary/ship_modes';
import ui from './dictionary/ui';
import Translate, { translate } from '../dictionary/Translator';
import DocumentTitle from 'react-document-title';
import { successToast, checkQtyIsValid } from '../utils/renderUtils';
import CartError from './CartError';
import utils from 'util';
const selector = formValueSelector('cart');
class Cart extends React.Component {
	static getDerivedStateFromProps(props, state) {
		if (props.language !== state.language)
			return {
				language: props.language,
				langId: translate({ word: ui['LANG_ID'], language: props.language }),
				shipModes: translate({ dictionary: shipModes, word: 'shipModes', language: props.language })
			};

		return null;
	}

	constructor(props) {
		super(props);

		this.state = {
			shipModes: translate({ dictionary: shipModes, word: 'shipModes', language: this.props.language }),
			language: this.props.language,
			langId: translate({ word: ui['LANG_ID'], language: this.props.language }),
			isOpen: false,
			filetypeerr: false
		};
	}

	handleFiles = event => {
		var files = event.target.files;

		this.setState({
			poErrors: undefined,
			poUpdates: undefined,
			poFileError: false
		});

		if (files && files.length > 0) {
			var file = files[0];
			if (file.type !== 'text/csv' || !(file.name && file.name.toLowerCase().endsWith('.csv'))) {
				this.toggleModal();
				this.setState({ filetypeerr: true })
				return;
			} else {
				this.setState({ filetypeerr: false })
				utilsActions.parseCSV(file, parsedData => {
					if (!parsedData || !parsedData.data || parsedData.data.length === 0) {
						this.setState({
							poFileError: true,
							poFileErrorMessage: 'CART_PO_UPLOAD_ERROR'
						});
						return;
					}
					var poTemplateHeaders = Object.keys(parsedData.data[0]);

					if (
						!poTemplateHeaders ||
						poTemplateHeaders.length === 0 ||
						poTemplateHeaders[0].toUpperCase() !== 'MODEL' ||
						//A stock and B stock quantity fields added in poTemplate
						poTemplateHeaders[1].toUpperCase() !== 'A STOCK QUANTITY' ||
						poTemplateHeaders[2].toUpperCase() !== 'B STOCK QUANTITY'
					) {
						this.setState({
							poFileError: true,
							poFileErrorMessage: 'CART_PO_UPLOAD_ERROR'
						});
						return;
					}

					var { priceBook } = this.props;

					let errorArray = [];
					let poUpdates = [];

					parsedData.data.forEach(function (poItem) {
						var sku = poItem[poTemplateHeaders[0]];
						var AQty = poItem[poTemplateHeaders[1]];
						var BQty =  poItem[poTemplateHeaders[2]];
						var parsedItem = sku && sku.trim() !== '' ? getAlphaNumSkuToCompare(sku).toUpperCase() : undefined;

						var priceBookItem = priceBook.find(
							pbItem =>
								pbItem.sku.trim().toUpperCase() === parsedItem || pbItem.originalSku.trim().toUpperCase() === parsedItem
						);
						var hasBStockPrice = priceBookItem && priceBookItem.has_Bstock;
						if (priceBookItem && hasBStockPrice) {
							if (checkQtyIsValid(AQty) && checkQtyIsValid(BQty)) {
							  poUpdates.push({ sku: priceBookItem.sku, AStockQty: AQty, BStockQty: BQty });
							} else if (checkQtyIsValid(AQty) && !checkQtyIsValid(BQty)) {
							  poUpdates.push({ sku: priceBookItem.sku, AStockQty: AQty, BStockQty: '' });
							  if (BQty) {
								errorArray.push({ MODEL: sku, AStockQty: '', BStockQty: BQty });
							  }
							} else if (!checkQtyIsValid(AQty) && checkQtyIsValid(BQty)) {
							  	poUpdates.push({ sku: priceBookItem.sku, AStockQty: '',BStockQty: BQty });
							  	if(AQty) {
									errorArray.push({ MODEL: sku, AStockQty: AQty, BStockQty: '' });
							  	}
							} else {
							  errorArray.push({ MODEL: sku, AStockQty: AQty, BStockQty: BQty });
							}
						  } else if (priceBookItem && !hasBStockPrice) {
							if(checkQtyIsValid(AQty)) {
							  if (BQty) {
								errorArray.push({ MODEL: sku, AStockQty: '', BStockQty: BQty });
							  }
							  poUpdates.push({sku: priceBookItem.sku, AStockQty: AQty, BStockQty: ''});
							} else {
							  errorArray.push({ MODEL: sku, AStockQty: AQty, BStockQty: BQty });
							}
						  } else {
							errorArray.push({ MODEL: sku, AStockQty: AQty, BStockQty: BQty });
						  }
					});
					const { cartItems } = this.props;
					var newCartItems = cartItems ? cartItems.filter(item => item.sku || item.AStockQty || item.BStockQty) : [];
					this.props.updateCart(newCartItems);
					const total = this.props.cartItems.length + poUpdates.length;
					const exceededItems = total - 50;
					if (utilsActions.validateCartMaxLimit(total)) {
						this.setState({
							poFileError: true,
							poFileErrorMessage: 'CART_EXCEED',
							pofileExceededItems: exceededItems
						});
						return;
					}
					this.setState({
						poErrors: errorArray,
						poUpdates,
						poFileError: false
					});
				});
			}
		}

		event.target.value = null;
	};

	updateCart = () => {
		var itemArray = this.state.poUpdates;

		if (itemArray && itemArray.length > 0) {
			itemArray = this.props.cartItems ? this.props.cartItems.concat(itemArray) : itemArray;
			this.props.updateCart(itemArray, this.props.language);
		}
		this.toggleModal();
	};

	componentDidMount() {
		if (this.props.priceBook) {
			var sku_options = [];
			this.props.priceBook.map((item, index) => sku_options.push({ value: item.sku, label: item.sku, item }));

			this.setState({ sku_options: sku_options });
		}

		if (this.props.addresses) {
			var addresses = this.props.addresses
				.filter(addr => addr.addressType === 'WE')
				.map((shipAdr, index) => ({
					value: shipAdr.addressId,
					label: (
						<span>
							{shipAdr.name} <br />
							{shipAdr.street} <br />
							{shipAdr.city} <br />
							{shipAdr.state} - {shipAdr.postalCode} <br />
							{shipAdr.country}
						</span>
					),
					shipAddr: shipAdr
				}));

			this.setState({ addresses: addresses });
		}
	}

	componentWillUnmount = () => {
		const { cartItems } = this.props;
		var newCartItems = cartItems ? cartItems.filter(item => item.sku || item.AStockQty || item.BStockQty) : [];

		this.props.updateCart(newCartItems);
	};

	submit = cart => {
		//Update each line item with sku, qty and grade to send to GSAP
		const updatedCart = utilsActions.updateCartItemsWithGrade(cart);
		var billToId = utilsActions.getDefaultAddress(
			this.props.addresses,
			this.props.buyer,
			utilsActions.billToAddressType
		);
		var payerId = utilsActions.getDefaultAddress(this.props.addresses, this.props.buyer, utilsActions.payerAddressType);
		this.props.saveCart(cart.items, this.props.user, this.props.contract, this.props.language);
		this.props.simulateOrder(
			updatedCart,
			this.props.buyer,
			payerId,
			billToId,
			this.props.contract,
			this.props.user,
			this.state.langId
		);
		this.props.history.push('/ordersimulate');
	};

	toggleModal = () => {
		this.setState({
			isOpen: !this.state.isOpen,
			poFileError: false,
			poErrors: undefined,
			poUpdates: undefined
		});
	};

	render() {
		const { language } = this.props;
		return (
			<DocumentTitle title={`${translate({ dictionary: ui, word: 'TITLE', language })} | Sony ExpressNet`}>
				<div style={{ paddingBottom: '3%' }} className='container-less-fluid'>
					<div className='row'>
						<div>
							<CartForm
								onSubmit={this.submit}
								{...this.state}
								onFileUpload={this.handleFiles}
								toggleModal={this.toggleModal}
							/>
						</div>
						<Modal
							ariaHideApp={false}
							isOpen={this.state.isOpen}
							onRequestClose={this.toggleModal}
							className='POUpload__Modal'
							overlayClassName='POUpload__Overlay'
						>
							<div>
								<h1 style={{ fontSize: '2.4rem', fontWeight: '600' }}>
									<Translate dictionary={ui} word='CART_PO_UPLOAD' />
								</h1>
								<hr />
								<button onClick={this.toggleModal} className='close-icon' aria-label='close the upload po'>
									X
								</button>
								<p>
									<Translate dictionary={ui} word='CART_PO_UPLOAD_TEMPLATE_DOWNLOAD_MSG_1' />
									<a href={'/assets/docs/PO_TEMPLATE.csv'} style={{ fontWeight: '700' , color:'#2F6FA7' }} aria-describedby='sample-desc'>
										<Translate dictionary={ui} word='CART_PO_UPLOAD_TEMPLATE_DOWNLOAD_MSG_2' />
									</a>
									<p className='offscreen' id='sample-desc'>
										download sample file
									</p>
									<Translate dictionary={ui} word='CART_PO_UPLOAD_TEMPLATE_DOWNLOAD_MSG_3' />
								</p>
								<span style={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap' }} className='disclaimer'>
									<Translate dictionary={ui} word='CART_LIMIT_ERR' />
								</span>

								<RenderFileUpload
									handleFiles={this.handleFiles}
									fileTypes={['.csv']}
									label={translate({ word: ui['CART_PO_UPLOAD_BTN_LABEL'], language })}
								/>
							</div>
							{this.state.filetypeerr && (
								<h2
									style={{
										fontSize: '1.7rem',
										fontWeight: '600',
										color: 'red'
									}}
								>

									<Translate dictionary={ui} word='CART_PO_UPLOAD_ERROR' />
								</h2>
							)}

							{(this.state.poErrors || this.state.poUpdates) && (
								<div>
									{this.state.poUpdates && (
										<h2
											style={{
												fontSize: '1.7rem',
												fontWeight: '600',
												color: this.state.poUpdates.length === 0 ? 'red' : 'inherit'
											}}
										>
											{this.state.poUpdates.length}
											<Translate dictionary={ui} word='CART_PO_UPLOAD_SUCCESS' />
										</h2>
									)}
									{this.state.poUpdates && <hr />}
									{this.state.poErrors && this.state.poErrors.length > 0 && (
										<div >
											<h3 style={{ fontSize: '1.5rem', fontWeight: '600' }}>
												<Translate dictionary={ui} word='CART_PO_UPLOAD_FAIL' />
											</h3>
											<div className='overflow-wrapper-two-direction'>
												<table className='table'>
													<thead>
														<tr>
															<th>
																<Translate dictionary={ui} word='CART_PO_UPLOAD_NUMBER' />
															</th>
															<th>
																<Translate dictionary={ui} word='CART_PO_UPLOAD_SKU' />
															</th>
															{/* PO error column for A stock quantity for which cart upload failed */}
															<th>
																<Translate dictionary={ui} word='CART_PO_A_QUANTITY' />
															</th>
															{/* PO error columns for B stock quantity for which cart upload failed */}
															<th>
																<Translate dictionary={ui} word='CART_PO_B_QUANTITY' />
															</th>
														</tr>
													</thead>
													<tbody>
														{this.state.poErrors.map((error, index) => (
															<tr key={index}>
																<td>{index + 1}</td>
																<td> {error.MODEL}</td>
																<td> {error.AStockQty}</td>
																<td> {error.BStockQty}</td>
															</tr>
														))}
													</tbody>
												</table>
											</div>
										</div>
									)}
									{this.state.poUpdates && (
										<div className='inline-buttons'>
											<Button
												bsStyle='primary'
												className='btn-margin button'
												style={{ marginTop: '10px' }}
												id='Upload'
												onClick={this.updateCart}
												disabled={this.state.poUpdates.length === 0}
											>
												<Translate dictionary={ui} word='CART_PO_UPLOAD_BTN_UPLOAD' />
											</Button>
											<Button
												bsStyle='primary'
												className='btn-margin button'
												style={{ marginTop: '10px' }}
												id='Cancel'
												onClick={this.toggleModal}
											>
												<Translate dictionary={ui} word='CART_PO_UPLOAD_BTN_CANCEL' />
											</Button>
										</div>
									)}
								</div>
							)}
							{this.state.poFileError && (
								<div>
									<h4 className='text-danger' style={{ marginTop: '20px' }}>
										{this.state.poFileErrorMessage === 'CART_PO_UPLOAD_ERROR' ? (
											<Translate dictionary={ui} word={this.state.poFileErrorMessage} />
										) : (
											utils.format(
												translate({ dictionary: ui, word: this.state.poFileErrorMessage, language }),
												this.state.pofileExceededItems
											)
										)}
									</h4>
								</div>
							)}
						</Modal>
						{this.props.user &&
							(this.props.user.role === 'XBU' ||
								this.props.user.role === 'XVO' ||
								this.props.user.role === 'XAO' ||
								this.props.user.role === 'ACTREP' ||
								this.props.user.role === 'ACTREPMGR' ||
								this.props.user.role === 'SLSANL') && (
								<div style={{ paddingTop: '30px' }}>
									<small>
										<strong>
											<Translate dictionary={ui} word='CART_DISCLAIMER_HEADER' />
										</strong>
										<Translate word={ui['CART_DISCLAIMER_MSG']} />
										<a href='mailto:digwiprocustomer@am.sony.com'>digwiprocustomer@am.sony.com</a>
									</small>
								</div>
							)}
					</div>
				</div>
			</DocumentTitle>
		);
	}
}

class CustomAddressSelectValue extends React.Component {
	render() {
		return (
			<div className='Select-value' title={this.props.value.title}>
				<span className='Select-value-label'>
					{this.props.value.shipAddr.name} <br />
					{this.props.value.shipAddr.street} <br />
					{this.props.value.shipAddr.city} <br />
					{this.props.value.shipAddr.state} - {this.props.value.shipAddr.postalCode} <br />
					{this.props.value.shipAddr.country}
				</span>
			</div>
		);
	}
}

let CartForm = props => {
	const { handleSubmit, shipModes, sku_options, addresses, language, onFileUpload, toggleModal } = props;

	return (
		<form
			onSubmit={handleSubmit}
			onKeyPress={e => {
				if (e.key === 'Enter') e.preventDefault();
			}}
		>
			<div className='row'>
				<div className='col-sm-3 shippingAddress'>
					<h1 style={{ marginTop: '0' }}>
						<Translate dictionary={ui} word='CART_HEADER' />
					</h1>
					<span className='cartHeader'>
						<Translate dictionary={ui} word='CART_RDD_HEADER' />
					</span>

					<Field
						name='rdd'
						editable={false}
						component={renderDatePicker}
						minDate={moment().add(3, 'days')}
						isClearable={false}
						id='rdd'
						label={ui['CART_RDD_HEADER'][language]}
					/>
					<hr />
					<span className='cartHeader'>
						<Translate dictionary={ui} word='CART_SHIP_TO_ADDR' />
					</span>
					<Field
						name='shippingAddress'
						editable={false}
						component={renderSelect}
						options={addresses}
						style={{ height: '110px' }}
						clearable={false}
						searchable={false}
						placeholder={<Translate dictionary={ui} word='CART_SHIP_TO_ADDR_SELECT' />}
						valueComponent={CustomAddressSelectValue}
						id='ship-address'
						aria-label='ship address'
					/>
				</div>
				<div className='col-sm-9' style={{ borderLeft: '1px solid lightgray', minHeight: '300px' }}>
					<FieldArray
						name='items'
						component={RenderCartItems}
						toggleModal={toggleModal}
						shipModes={shipModes}
						sku_options={sku_options}
						language={language}
						onFileUpload={onFileUpload}
					/>
				</div>
			</div>
		</form>
	);
};

const scrollToTheEndOfTable = () => {
	setTimeout(() => {
		const newItemsTable = document.getElementById('cart-items');
		newItemsTable.scrollTop = newItemsTable.scrollHeight;
	}, 200);
};

class RenderButtonGroup extends Component {
	state = {
		itemsqty: '1',
		error: '',
		disableUploadpo: undefined
	};

	componentDidMount() {
		const { fields } = this.props;
		if (fields && fields.getAll() && fields.getAll().filter(items => items.sku || items.AStockQty || items.BStockQty).length === 50) {
			this.setState({
				disableUploadpo: true
			});
		}
		if (fields.length === 50) {
			this.setState({
				error: true
			});
		}
	}
	componentDidUpdate() {
		const { fields } = this.props;
		const total = fields.length + parseInt(this.state.itemsqty, 10);
		if (total <= 50 && this.state.error) {
			this.setState({
				error: undefined
			});
		}
		if (
			fields &&
			fields.getAll() &&
			fields.getAll().filter(items => items.sku || items.AStockQty || items.BStockQty).length < 50 &&
			this.state.disableUploadpo
		) {
			this.setState({
				disableUploadpo: undefined
			});
		}

		if (
			fields &&
			fields.getAll() &&
			fields.getAll().filter(items => items.sku || items.AStockQty || items.BStockQty).length === 50 &&
			!this.state.disableUploadpo
		) {
			this.setState({
				disableUploadpo: true
			});
		}
	}
	handleChange = event => {
		const itemsqty = event.target.value;
		if (isNaN(itemsqty) || itemsqty % 1 !== 0 || itemsqty > 50) {
			return;
		}
		this.setState({
			itemsqty: event.target.value
		});

		const { fields } = this.props;

		const total = parseInt(itemsqty, 10) + fields.length;
		if (utilsActions.validateCartMaxLimit(total)) {
			this.setState({
				error: true
			});
		} else {
			this.setState({
				error: undefined
			});
		}
	};
	render() {
		const { fields, toggleModal, touched, submitFailed, error, language } = this.props;
		return (
			<div style={{ padding: '1% 0%' }} className='space-between-flex'>
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<div className='start-flex' style={{ alignSelf: 'flex-start' }}>
						<input
							type='text'
							id='itemsqty'
							name='itemsqty'
							maxLength={2}
							style={{ height: '35px', width: '25px' }}
							onChange={this.handleChange}
							value={this.state.itemsqty}
							aria-label={ui['NUMBER_OF_LINES'][this.props.language]}
						/>
						<Button
							bsStyle='primary'
							className='button btn-small-screen'
							id='AddItem'
							aria-describedby='add-item-desc'
							onClick={() => {
								const val = this.state.itemsqty;
								const totalCount = parseInt(val, 10) + fields.length;
								//validate the limit of 50 rows in cart
								if (utilsActions.validateCartMaxLimit(totalCount)) {
									this.setState({
										error: true
									});
								} else {
									for (let i = 0; i < val; i++) {
										fields.push({});
									}
									this.setState({
										itemsqty: '1'
									});
								}
								scrollToTheEndOfTable();
							}}
							style={{ marginRight: '2%' }}
							disabled={(fields.length === 50 || this.state.error)? true:false}
						>
							<MdAddCircleOutline className='button__icon' role="img" aria-label="Click to add items" />
							<Translate dictionary={ui} word='CART_ADD_ITEM' />
						</Button>
						<span className='offscreen' id='add-item-desc'>
							Add item to cart
						</span>
						<Button
							bsStyle='primary'
							className='button btn-small-screen'
							id='AddItem'
							onClick={() => {
								toggleModal();
							}}
							style={{ marginRight: '2%' }}
							disabled={this.state.disableUploadpo}
						>
							<MdCloudUpload className='button__icon' role="img" aria-label="Click to upload PO file" />
							<Translate dictionary={ui} word='CART_PO_UPLOAD' />
						</Button>
					</div>
					<span style={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap', maxWidth: '500px' }} className='disclaimer'>
						<Translate dictionary={ui} word='CART_LIMIT_ERR' />
					</span>
				</div>
				<div className='Cart__preview-order-section'>
					<Button id='PreviewOrder' bsStyle='primary' className='button btn-small-screen' type='submit'>
						<MdRemoveRedEye className='button__icon'role="img" aria-label="Preview Order" />
						<Translate dictionary={ui} word='CART_PREVIEW_ORDER' />
					</Button>
					<span style={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap', maxWidth: '500px' }} className='disclaimer'>
						<Translate dictionary={ui} word='CART_PRICE_DISCLAIMER' />
					</span>
					<div className='Cart__common-error'>
						{touched || submitFailed || error ? (
							<CartError
								submitErrors={(touched || submitFailed) && error ? [].concat(error) : []}
								submitFailed={submitFailed}
							/>
						) : fields.length === 50 ? (
							<span align='center' className='text-danger'>
								<Translate dictionary={ui} word='CART_FULL' />
							</span>
						) : (
							fields.length + parseInt(this.state.itemsqty, 10) - 50 > 0 && (
								<span align='center' className='text-danger'>
									{utils.format(
										translate({ dictionary: ui, word: 'CART_EXCEED', language }),
										fields.length + parseInt(this.state.itemsqty, 10) - 50
									)}
								</span>
							)
						)}
					</div>
				</div>
			</div>
		);
	}
}
const RenderCartItems = ({
	toggleModal,
	sku_options,
	onFileUpload,
	shipModes,
	fields,
	language,
	meta: { touched, error, submitFailed }
}) => (
	<div style={{ padding: '0 1%' }}>
		<div className='row' style={{ marginBottom: '1%' }}>
			<RenderButtonGroup
				fields={fields}
				language={language}
				onFileUpload={onFileUpload}
				toggleModal={toggleModal}
				touched={touched}
				submitFailed={submitFailed}
				error={error}
			/>
		</div>
		<div className='overflow-wrapper'>
			<div className='fixed-header-table-container'>
				<table className='table' border='0' cellPadding='0' cellSpacing='0' width='100%' id='cart-table'>
						<thead className='fixed-header' style={{whiteSpace: 'normal'}}>
						<tr>
							<th style={{ fontSize: '0' }} width='3%'>
								NO
							</th>
							<th width='17%'>
								<Translate dictionary={ui} word='CART_SKU' />
							</th>
							<th width='7%'>
								<Translate dictionary={ui} word='A_STOCK_QUANTITY' />
							</th>
							<th width='7%'>
								<Translate dictionary={ui} word='B_STOCK_QUANTITY' />
							</th>
							<th width='20%'>
								<Translate dictionary={ui} word='CART_DESCRIPTION' />
							</th>
							<th width='11%'>
								<Translate dictionary={ui} word='A_UNIT_PRICE' />
							</th>
							<th width='11%'>
								<Translate dictionary={ui} word='B_UNIT_PRICE' />
							</th>
							<th width='20%'>
								<Translate dictionary={ui} word='CART_SHIP_MODE' />
							</th>
							<th style={{ fontSize: '0' }} width='5%'>
								Remove
							</th>
						</tr>
					</thead>
					<tbody id='cart-items' className='fixed-header-table-content'>
						{fields.map((item, index) => (
							<CartItem
								item={item}
								fields={fields}
								index={index}
								key={index}
								shipModes={shipModes}
								sku_options={sku_options}
								language={language}
							/>
						))}
					</tbody>
				</table>
				<div id='end-of-table' />
			</div>
		</div>
	</div>
);

const getPriceByQuantity = (qty, sku_item, tier) =>
	sku_item[tier].find(
		tier =>
			tier.start_qty === qty ||
			(tier.end_qty !== null && qty >= tier.start_qty && qty <= tier.end_qty) ||
			(tier.end_qty === null && tier.start_qty <= qty)
	);

const scrollOuterMenu = index => {
	const outerMenu = document.querySelector('.Select-menu-outer');
	if (outerMenu) {
		const bottomOfMenu = outerMenu.getBoundingClientRect().bottom;
		const newItemsTable = document.getElementById('cart-items');
		const bottomOfTable = newItemsTable.getBoundingClientRect().bottom;
		if (bottomOfMenu > bottomOfTable) {
			newItemsTable.scrollTop = newItemsTable.scrollTop + outerMenu.clientHeight * 0.8;
		}
	}
};

let CartItem = props => {
	const {
		item,
		index,
		fields,
		priceBook,
		skuValue,
		contract,
		AStockQty,
		BStockQty,
		shipModes,
		sku_options,
		buyer,
		language,
		user,
		saveCart,
		handleSkuChange
	} = props;
	const sku_item = skuValue && skuValue.length && priceBook.find(x => x.sku === skuValue || x.originalSku === skuValue);
	//Derive A stock price for sku item
	const AStockPrice =  sku_item && sku_item.sku && isNormalInteger(AStockQty) && AStockQty && AStockQty > 0 && getPriceByQuantity(AStockQty, sku_item,"A_tiers").price;
	//Derive B stock price for sku item
	const BStockPrice =  sku_item && sku_item.sku && isNormalInteger(BStockQty) && BStockQty && BStockQty > 0 && getPriceByQuantity(BStockQty, sku_item, "B_tiers").price;
	const desc = sku_item && sku_item.short_desc[language];
	const shipModeOptons =
		contract.salesOrg === 'US00' && sku_item && sku_item.type === 'pro'
			? shipModes
			: [{ value: '', label: translate({ word: ui['SEL_NORMAL_DELIVERY'], language: language }) }];
	var multiplesOf = utilsActions.getMultiplesOf(sku_item, buyer)
	setTimeout(() => {
        removeAttribute();
    }, 1000);
	return (
		<React.Fragment>
	<tr id={'item_' + index} key={index} style={{whiteSpace: 'normal'}}>
		<td style={{ fontWeight: 600, paddingLeft: '0', fontSize: '1.5rem', verticalAlign: 'middle' }} width='3%'>
			<span>{index + 1}</span>
		</td>
		<td onClick={() => scrollOuterMenu(index)} width='17%'>
			<Field
				id={`${item}.sku`}
				name={`${item}.sku`}
				component={renderFilterSelect}
				onChange={()=> handleSkuChange(item)}
				placeholder={<Translate dictionary={ui} word='CART_SKU_SELECT' />}
				filterOption={(option, filter) => {
					return (
						option.item.sku.indexOf(filter.toUpperCase()) >= 0 ||
						option.item.originalSku.indexOf(filter.toUpperCase()) >= 0
					);
				}}
				options={sku_options}
				label='SKU'
			/>
		</td>
		<td width='7%'>
			<Field
				id={`${item}.AStockQty`}
				wrap={false}
				name={`${item}.AStockQty`}
				label='AStockQuantity'
				type='text'
				maxLength={9}
				component={renderInput}
				onBlur={e => {
					if (
						e.target.className !== 'input-has-error' &&
						e.target.value !== 0 &&
						e.target.value.trim().length > 0 &&
						sku_item
					) {
						successToast(translate({ word: ui['CART_UPDATE_NOTIFICATION_SUCCESS'], language }));
					}
				}}
				style={{ height: '35px', width: '50px' }}
				
			/>
			</td>
		<td width='7%'>
			<Field
				id={`${item}.BStockQty`}
				wrap={false}
				name={`${item}.BStockQty`}
				label='BStockQuantity'
				type='text'
				maxLength={9}
				component={renderInput}
				disabled={!sku_item || (sku_item && sku_item.B_base_price)? false: true }
				onBlur={e => {
					if (
						e.target.className !== 'input-has-error' &&
						e.target.value !== 0 &&
						e.target.value.trim().length > 0 &&
						sku_item
					) {
						successToast(translate({ word: ui['CART_UPDATE_NOTIFICATION_SUCCESS'], language }));
					}
				}}
				style={{ height: '35px', width: '50px' }}
			/>
		</td>
		<td arial-label='description' width='25%'>
			{desc}
		</td>
		<td arial-label='A unit price' width='11%'>
			{AStockPrice}
		</td>
		
		<td arial-label='B unit price' width='11%'>
			{BStockPrice}
		</td>
		<td onClick={() => scrollOuterMenu(index)} width='20%'>
			<Field
				id={`${item}.shipMode`}
				name={`${item}.shipMode`}
				label='Ship Mode'
				component={renderFilterSelect}
				options={sku_item && shipModeOptons}
			/>
		</td>
		<td width='5%'>
			<button
				aria-label='remove'
				alt='remove'
				id='RemoveItem'
				type='button'
				className='btn btn-danger'
				title='Remove Item'
				onClick={() => {
					fields.remove(index);
					if (fields.length === 1) {
						saveCart({}, user, contract, language);
					}
				}}
				style={{ padding: '3px 6px' }}
			>
				<MdDelete style={{ fontSize: '2.4rem' }} role="img" aria-label="Remove Item from your Cart"/>
			</button>
		</td>
	</tr>
	{multiplesOf && multiplesOf.val > 1 ? (
		<tr>
			<td colSpan='6' style={{ border: 'none', padding: '0' }}>
				<small>
					{multiplesOf.type !== 'MOQ' ?
					<strong>
						<Translate dictionary={ui} word='CARTON_QTY_MESSAGE_1' />
						{multiplesOf.val}
						<Translate dictionary={ui} word='CARTON_QTY_MESSAGE_2' />
					</strong> : 
					<strong>
						<Translate dictionary={ui} word='MINIMUM_QTY_MESSAGE' />
						{multiplesOf.val}
					</strong>}
				</small>
			</td>
		</tr>
	) : (
		<tr />
	)}
		</React.Fragment>
	);
};

const validateCart = (values, props) => {
	const { priceBook, buyer } = props;
	let qtyPattern = /^\d+$/;
	const errors = {};
	const itemsErrors = [];
	if (!values.shippingAddress) {
		errors.shippingAddress = <Translate dictionary={ui} word='CART_ERR_SHIP_TO' />;
	}
	if (!values.rdd) {
		errors.rdd = <Translate dictionary={ui} word='CART_ERR_RDD' />;
	}
	if (!values.items || !values.items.length) {
		errors.items = { _error: <Translate dictionary={ui} word='CART_ERR' /> };
	} else {
		values.items.forEach((item, itemIndex) => {
			const sku_item = priceBook.find(x => x.sku === item.sku)
			const itemErrors = {};
			if (!item.sku) {
				itemErrors.sku = <Translate dictionary={ui} word='CART_ERR_SKU' />;
				itemsErrors[itemIndex] = itemErrors;
			}
			if(!sku_item || (sku_item && !sku_item.B_base_price)) {
				if (isNaN(item.AStockQty) || !item.AStockQty || item.AStockQty <= 0 || item.AStockQty % 1 !== 0 || !qtyPattern.test(item.AStockQty)) {
					itemErrors.AStockQty = <Translate dictionary={ui} word='CART_ERR_QTY' />;
					itemsErrors[itemIndex] = itemErrors;
				}
			} 
			if(!sku_item || (sku_item && sku_item.B_base_price)) {
				if((isNaN(item.AStockQty) || !item.AStockQty || item.AStockQty <= 0 || item.AStockQty % 1 !== 0 || !qtyPattern.test(item.AStockQty)) && 
				(isNaN(item.BStockQty) || !item.BStockQty || item.BStockQty <= 0 || item.BStockQty % 1 !== 0 || !qtyPattern.test(item.BStockQty))) {
					itemErrors.AStockQty = <Translate dictionary={ui} word='CART_ERR_QTY' />;
					itemErrors.BStockQty = <Translate dictionary={ui} word='CART_ERR_QTY' />;
					itemsErrors[itemIndex] = itemErrors;
				}else {
					if(item.BStockQty && (item.BStockQty <=0 || isNaN(item.BStockQty) || item.BStockQty % 1 !== 0 || !qtyPattern.test(item.BStockQty))) {
					itemErrors.BStockQty = <Translate dictionary={ui} word='CART_ERR_QTY' />;
						itemsErrors[itemIndex] = itemErrors;
					}if(item.AStockQty && (item.AStockQty <= 0 || isNaN(item.AStockQty) || item.AStockQty % 1 !== 0 || !qtyPattern.test(item.AStockQty))) {
					itemErrors.AStockQty = <Translate dictionary={ui} word='CART_ERR_QTY' />;
						itemsErrors[itemIndex] = itemErrors;
					}
				}
			}
			if(item && item.sku) {
				const multiplesOf = utilsActions.getMultiplesOf(sku_item, buyer);
				if (multiplesOf && multiplesOf.val > 1) {
					if(multiplesOf.type !== 'MOQ' && item.AStockQty % multiplesOf.val !== 0) {
						itemErrors.AStockQty = (
							<span>
								<Translate dictionary={ui} word='MULTIPLE_OF_ERR_QTY' />
								{multiplesOf.val}
							</span>
						);
					} 
					else if(multiplesOf.type === 'MOQ' && multiplesOf.val > 1 && item.AStockQty < multiplesOf.val){
						itemErrors.AStockQty = (
							<span>
								<Translate dictionary={ui} word='MIN_ORDER_QTY_ERR' />
								{multiplesOf.val}
							</span>
						);
					}
				}
				itemsErrors[itemIndex] = itemErrors;
			} else {
				if(item.hasOwnProperty('AStockQty') && !isNaN(item.AStockQty)){
					item.AStockQty= parseInt(item.AStockQty,10)
				}
				if(item.hasOwnProperty('BStockQty') && !isNaN(item.BStockQty)){
					item.BStockQty= parseInt(item.BStockQty,10)
				}
			}
		});
	}
	if (itemsErrors.length) {
		errors.items = itemsErrors;
	}

	return errors;
};

CartItem = connect(
	(state, props) => ({
		skuValue: selector(state, `${props.item}.sku`),
		priceBook: state.account.priceBook,
		buyer: state.account.buyer,
		contract: state.account.contract,
		user: state.account.user,
		AStockQty: selector(state, `${props.item}.AStockQty`),
		BStockQty: selector(state, `${props.item}.BStockQty`),
	}),
	dispatch => ({
		saveCart: (items, user, contract, language) => dispatch(cartActions.saveCart(items, user, contract, language)),
		// Reset AStockQty and BStockQty fields on change of SKU
		handleSkuChange: item => {dispatch(change('cart', `${item}.AStockQty`, ''))
		dispatch(change('cart', `${item}.BStockQty`, ''))
	  },
	})
)(CartItem);

CartForm = reduxForm({
	form: 'cart',
	validate: validateCart,
	destroyOnUnmount: false,
	onChange: (values, dispatch, props) => {
		if (props.submitFailed) {
			dispatch(stopSubmit('cart'));
		}
	}
})(CartForm);

CartForm = connect((state, props) => {
	let defAddr =
		state.account.addresses &&
		state.account.addresses.find(addr => addr.addressType === 'WE' && addr.isDefault === 'TRUE');

	return {
		initialValues: {
			rdd: moment().add(3, 'days').format('YYYY-MM-DD'),
			shippingAddress: defAddr ? defAddr.addressId : ''
		},
		priceBook: state.account.priceBook,
		buyer: state.account.buyer,
		language: state.language
	};
})(CartForm);

const isNormalInteger = str => {
	return /^\+?(0|[1-9]\d*)$/.test(str);
};

const mapStateToProps = (state, props) => {
	return {
		priceBook: state.account.priceBook,
		buyer: state.account.buyer,
		contract: state.account.contract,
		user: state.account.user,
		cart: state.cart,
		addresses: state.account.addresses,
		cartItems: selector(state, 'items'),
		language: state.language
	};
};

const mapDispatchToProps = dispatch => {
	return {
		simulateOrder: (cart, buyer, payerId, billToId, contract, user, language) =>
			dispatch(orderActions.simulateOrder(cart, buyer, payerId, billToId, contract, user, language)),
		updateCart: items => dispatch(cartActions.updateCart(items)),
		saveCart: (items, user, contract, language) => dispatch(cartActions.saveCart(items, user, contract, language))
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Cart));
