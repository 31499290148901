import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import * as orderActions from '../../modules/order';
import * as errorActions from '../../modules/error';
import * as commonActions from '../../modules/common';
import Loading from '../Loading';
import moment from 'moment';
import fileSaver from 'file-saver';
import Translate from '../dictionary/Translator';
import errorDictionary from '../dictionary/error';
import orderStatusMappings from './dictionary/order_status';
import itemStatusMappings from './dictionary/order_item_status';
import carrierCodeMappings from './dictionary/carrier_codes';
import { translate } from '../dictionary/Translator';
import ui from './dictionary/ui';
import DocumentTitle from 'react-document-title';

//var langId;

class ReturnView extends React.Component {
  // constructor(props) {
  //   super(props);
  //   langId = translate({ word: ui['LANG_ID'], language: props.language });
  // }

  componentWillMount() {
    this.props.resetFetching();
    this.props.resetError();
    this.props.clearOrderDetails();
    this.props.fetchOrder(translate({ word: ui['LANG_ID'], language: this.props.language }),this.props.match.params.id, this.props.match.params.source);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.invoice && nextProps.invoice !== this.props.invoice) {
      downloadPdf(nextProps);
    }
  }

  componentWillUnmount() {
    this.props.resetError();
  }
  render() {
    const { order, error, language } = this.props;
    //langId = translate({ word: ui['LANG_ID'], language: this.props.language });
    return (
      <DocumentTitle title={`${translate({ word: ui['RETURN_ORDER_DETAILS'], language: language })} | Sony ExpressNet`}>
        <div style={{ paddingLeft: '3%', paddingRight: '3%' }}>
          {!error && !order && <Loading />}
          {error && (
            <span className="text-danger">
              <Translate word={errorDictionary[error.errorPath]} />
            </span>
          )}
          {!error && order && renderOrder({ ...this.props })}
        </div>
      </DocumentTitle>
    );
  }
}

const downloadPdf = props => {
  const { invoice } = props;
  fileSaver.saveAs(b64toBlob(invoice.file, 'base64', 512), invoice.number + '.pdf');
};

function b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

const renderOrder = props => {
  const { order, setBackButtonFlag, isFetching, getInvoiceDetails, language } = props;
  const {match: {params: {source}}} = props;
  let deliveryCount = 0;
  let invoiceCount = 0;
  let mainStatus =
    order.orderStatus[0].rejectionStatus === 'C'
      ? translate({
          word: ui['STATUS_CANCELLED'],
          language: language
        })
      : translate({
          word: orderStatusMappings[
            (order.orderStatus[0].overAllStatus ? order.orderStatus[0].overAllStatus : ' ') +
              (order.orderStatus[0].rejectionStatus ? order.orderStatus[0].rejectionStatus : ' ')
          ],
          language
        });
  let subStatus =
    order.billingBlockCode || order.deliveryBlockCode || order.orderStatus[0].creditStatus === 'B'
      ? translate({
          word: ui['STATUS_AWAITING_OOA_REVIEW'],
          language: language
        })
      : '';

  return (
    <div>
      <div style={{ paddingBottom: '25px' }}>
        <Link
          to={'/returnhistory'}
          role="button"
          className="btn btn-primary"
          style={{ paddingTop: '8px', paddingBottom: '8px', float: 'left' }}
          onClick={() => setBackButtonFlag(true)}
        >
          <Translate word={ui['BACK_BUTTON']} />
        </Link>
      </div>
      <div style={{ paddingBottom: '20px' }}>
        <h1 style={{ fontSize: '2.2rem', fontWeight: '600' }}>
          <Translate word={ui['RETURN_ORDER_DETAILS']} />
        </h1>
        <h2 style={{ fontSize: '1.8rem', fontWeight: '450' }}>
          <Translate word={ui['ORDER_SUMMARY']} />
        </h2>
      </div>
      <hr size="6" />
      <div className="row">
        <div className="col-sm-4">
          <div className="table-responsive">
            <table>
              <tbody>
                <tr>
                  <td>
                    <strong>
                      <Translate word={ui['ORDER_SUB_TOTAL']} />
                    </strong>
                  </td>
                  <td className="col-sm-2 text-right">{order.netValue === 0 ? '$' + order.netValue.toFixed(2) : '$-' + order.netValue.toFixed(2)}</td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      <Translate word={ui['ORDER_ESTIMATED_SHIPPING']} />
                    </strong>
                  </td>
                  <td className="col-sm-2 text-right">{order.netFreight ? '$' + order.netFreight.toFixed(2) : '$0.00'}</td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      <Translate word={ui['ORDER_ESTIMATED_TAX']} />
                    </strong>
                  </td>
                  <td className="col-sm-2 text-right">{order.netTax ? '$' + order.netTax.toFixed(2) : '$0.00'}</td>
                </tr>
                <tr>
                  <td>
                    <hr size="4" />
                  </td>
                  <td>
                    <hr size="4" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      <Translate word={ui['ORDER_ESTIMATED_TOTAL']} />
                    </strong>
                  </td>
                  <td className="col-sm-2 text-right">
                    {(order.netValue + order.netTax).toFixed(2) === 0.0
                      ? '$' + (order.netValue + order.netTax).toFixed(2)
                      : '$-' + (order.netValue + order.netTax).toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="table-responsive">
            <table>
              <tbody>
                <tr>
                  <td>
                    <strong>
                      <Translate word={ui['ORDER_NUMBER']} />:
                    </strong>
                  </td>
                  <td className="col-sm-2">{order.id}</td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      <Translate word={ui['ORDER_DATE']} />:
                    </strong>
                  </td>
                  <td className="col-sm-2">{order.orderDate ? moment(order.orderDate).format('MM/DD/YYYY') : ''}</td>
                </tr>
                <tr>
                  <td style={{ verticalAlign: 'top' }}>
                    <strong>
                      <Translate word={ui['ORDER_STATUS']} />:
                    </strong>
                  </td>
                  <td className="col-sm-2">{mainStatus + subStatus}</td>
                </tr>
                <tr>
                  <td style={{ verticalAlign: 'top' }}>
                    <strong>
                      <Translate word={ui['ORDER_PAYMENT_TERM']} />
                    </strong>
                  </td>
                  <td className="col-sm-2">{order.descriptions ? order.descriptions.paymentTermsDesc : ''}</td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      <Translate word={ui['ORDER_SHIPCOMPLETE']} />
                    </strong>
                  </td>
                  <td className="col-sm-2">{order.shipComplete ? order.shipComplete : 'No'}</td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      <Translate word={ui['DEBIT_REFERENCE_NUMBER']} />:
                    </strong>
                  </td>
                  <td className="col-sm-2">{order.po}</td>
                </tr>
                {order.orderShippingAddress && (
                  <tr>
                    <td style={{ verticalAlign: 'top' }}>
                      <strong>
                        <Translate word={ui['ORDER_SHIPPING_ADDRESS']} />
                      </strong>
                    </td>
                    <td className="col-sm-2">
                      {order.orderShippingAddress ? order.orderShippingAddress.name : ''}
                      <br />
                      {order.orderShippingAddress ? order.orderShippingAddress.addressLine1 : ''}
                      <br />
                      {order.orderShippingAddress ? order.orderShippingAddress.city + ' ' : ''}
                      {order.orderShippingAddress ? order.orderShippingAddress.state + ' ' : ''}
                      {order.orderShippingAddress ? order.orderShippingAddress.postalCode : ''}
                    </td>
                  </tr>
                )}
                <tr>
                  <td style={{ verticalAlign: 'top' }}>
                    <strong>
                      <Translate word={ui['REASON_FOR_RETURN']} />:
                    </strong>
                  </td>
                  <td className="col-sm-8">{order.reasonForReturn}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <hr size="2" />
      <h2 style={{ fontSize: '1.8rem', fontWeight: '450' }}>
        <Translate word={ui['ORDER_LINE_ITEM_SUMMARY']} />
      </h2>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>
                <Translate word={ui['MODEL']} />
              </th>
              <th id="col-header-grade">
                <Translate word={ui['GRADE_INDICATOR']} />
              </th>
              <th>
                <Translate word={ui['MODEL_DESCRIPTION']} />
              </th>
              <th>
                <Translate word={ui['ITEM_COST']} />
              </th>
              <th>
                <Translate word={ui['ITEM_SHIPPING']} />
              </th>
              {order.noOfScheduledLines === null ? <th>Schedule</th> : order.noOfScheduledLines && order.noOfScheduledLines > 0 ? <th>Schedule</th> : <td /> }
            </tr>
          </thead>
          <tbody>
            {order.items.map((item, index) => {
              let itemStatus =
                item.status[0].rejectionStatus === 'C'
                  ? translate({
                      word: ui['STATUS_CANCELLED'],
                      language: language
                    })
                  : translate({
                      word: itemStatusMappings[
                        (item.status[0].processingStatus ? item.status[0].processingStatus : ' ') +
                          (item.status[0].rejectionStatus ? item.status[0].rejectionStatus : ' ')
                      ],
                      language
                    });
              let itemSubStatus = item.delivery.find(del => del.creditHold === 'B')
                ? translate({
                    word: ui['STATUS_ON_CREDIT_REVIEW'],
                    language: language
                  })
                : item.delivery.find(del => del.PGIDate === null)
                ? translate({
                    word: ui['STATUS_AWAITING_SHIPPING'],
                    language: language
                  })
                : '';
                //As Refurbished - B stock products will not be returned
                // will not have any logic around finding B stock products
                if(source === 'S') {
                  item && (item.grade = 'A')
                }
                // --Return Order
                //ZRN -- GPW 
                //ZRE -- GSAP
              return (
                <tr key={index}>
                  <td id='productCode'>{item.outProductCode}</td>
                  <td id='gradeValue'>{item.grade}</td>
                  <td>{item.shortText}</td>
                  <td>
                    <table className="table table-bordered table-condensed table-transparent">
                      <tbody>
                        <tr>
                          <td>
                            <Translate word={ui['ITEM_UNIT_PRICE']} />
                          </td>
                          <td>
                            {order.orderType === 'ZRN' || order.orderType === 'ZRE'
                              ? item.price.unitPrice - item.price.freight / item.requestedQuantity === 0
                                ? '$' + (item.price.unitPrice - item.price.freight / item.requestedQuantity).toFixed(2)
                                : '$-' + (item.price.unitPrice - item.price.freight / item.requestedQuantity).toFixed(2)
                              : item.price.unitPrice - item.price.freight / item.targetQuantity === 0
                              ? '$' + (item.price.unitPrice - item.price.freight / item.targetQuantity).toFixed(2)
                              : '$-' + (item.price.unitPrice - item.price.freight / item.targetQuantity).toFixed(2)}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Translate word={ui['ITEM_QUANTITY']} />
                          </td>
                          <td>{(order.orderType === 'ZRN' || order.orderType === 'ZRE') ? item.requestedQuantity : item.targetQuantity}</td>
                        </tr>
                        <tr>
                          <td>
                            <Translate word={ui['ITEM_SUB_TOTAL']} />
                          </td>
                          <td>{item.price.netValue === 0 ? '$' + item.price.netValue.toFixed(2) : '$-' + item.price.netValue.toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td>
                            <Translate word={ui['ITEM_TAX']} />
                          </td>
                          <td>{item.price.tax ? '$' + item.price.tax.toFixed(2) : '$0.00'}</td>
                        </tr>
                        <tr>
                          <td>
                            <Translate word={ui['ITEM_FREIGHT']} />
                          </td>
                          <td>{item.price.freight ? '$' + item.price.freight.toFixed(2) : '$0.00'}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td>
                    <table className="table table-bordered table-condensed table-transparent">
                      <tbody>
                        <tr>
                          <td>
                            <Translate word={ui['ITEM_STATUS']} />
                          </td>
                          <td>{itemStatus + itemSubStatus}</td>
                        </tr>
                        <tr>
                          <td>
                            <Translate word={ui['ITEM_SHIPPIED_QUANTITY']} />
                          </td>
                          <td>{item.shippedQuantity}</td>
                        </tr>
                        <tr>
                          <td>
                            <Translate word={ui['ITEM_RDD']} />
                          </td>
                          <td>{item.scheduledLines[0] ? (item.scheduledLines[0].RDD ? moment(item.scheduledLines[0].RDD).format('MM/DD/YYYY') : '') : ''}</td>
                        </tr>
                        <tr>
                          <td>
                            <Translate word={ui['ITEM_SHIPPING_MODE']} />
                          </td>
                          <td>{item.descriptions ? item.descriptions.shipModeDesc : ''}</td>
                        </tr>
                        {item.shippingAddress && (
                          <tr>
                            <td>
                              <Translate word={ui['ITEM_SHIPPING_ADDRESS']} />
                            </td>
                            <td>
                              {item.shippingAddress ? item.shippingAddress.name : ''}
                              <br />
                              {item.shippingAddress ? item.shippingAddress.addressLine1 : ''}
                              <br />
                              {item.shippingAddress ? item.shippingAddress.city + ' ' : ''}
                              {item.shippingAddress ? item.shippingAddress.state + ' ' : ''}
                              {item.shippingAddress ? item.shippingAddress.postalCode : ''}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </td>
                  <td>
                    {item.nonZeroConfQtySchLines.length > 0 ? (
                      <table className="table table-bordered table-condensed table-transparent">
                        <tbody>
                          <tr>
                            <td>
                              <Translate word={ui['ITEM_SCHDULED_QUANTITY']} />
                            </td>
                            <td>
                              <Translate word={ui['ITEM_MAD']} />
                            </td>
                            <td>
                              <Translate word={ui['ITEM_ESD']} />
                            </td>
                            <td>
                              <Translate word={ui['ITEM_EDD']} />
                            </td>
                          </tr>
                          {item.nonZeroConfQtySchLines.map((schItem, i) => (
                            <tr key={i}>
                              <td>{schItem.confirmedQuantity}</td>
                              <td>{schItem.materialAvailableDate ? moment(schItem.materialAvailableDate).format('MM/DD/YYYY') : ''}</td>
                              <td>{schItem.shippingDate ? moment(schItem.shippingDate).format('MM/DD/YYYY') : ''}</td>
                              <td>{schItem.estimatedDeliveryDate ? moment(schItem.estimatedDeliveryDate).format('MM/DD/YYYY') : ''}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      ''
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {order.items.forEach(item => {
        deliveryCount = deliveryCount + item.delivery.length;
      })}
      {deliveryCount > 0 ? (
        <div>
          <hr size="2" />
          <h2 style={{ fontSize: '1.8rem', fontWeight: '450' }}>
            <Translate word={ui['ITEM_DELIVERY_INFORMATION']} />
          </h2>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>
                    <Translate word={ui['MODEL']} />
                  </th>
                  <th>
                    <Translate word={ui['ITEM_DELIVERED_QUANTITY']} />
                  </th>
                  <th>
                    <Translate word={ui['ITEM_SHIPPING_CARRIERS']} />
                  </th>
                  <th>
                    <Translate word={ui['ITEM_TRACKING_NUMBER']} />
                  </th>
                  <th>
                    <Translate word={ui['ITEM_BILL_OF_LADING_NUMBER']} />
                  </th>
                  <th>
                    <Translate word={ui['ITEM_WAREHOUSE_SHIPPING_DATE']} />
                  </th>
                </tr>
              </thead>
              <tbody>
                {order.items.map((item, index) => {
                  return item.delivery.map((del, i) => {
                    let carrier = carrierCodeMappings.find(carrierCode => del.shippingCarrier === carrierCode.CODE);
                    return (
                      <tr key={i}>
                        <td>{del.material}</td>
                        <td>{del.deliveredQty}</td>
                        <td>{carrier ? carrier.NAME : ''}</td>
                        <td>
                          {carrier && carrier.TRACKING_URL !== '#' ? 
                            <a target="_blank"  rel="noopener noreferrer" href={carrier.TRACKING_URL.replace('~TRACKING_NUMBER~', del.trackingNumber)}>
                              {del.trackingNumber}
                            </a> 
                            : 
                            del.trackingNumber
                          }
                        </td>
                        <td>{del.billOfLadingNumber}</td>
                        <td>{del.creationDate ? moment(del.creationDate).format('MM/DD/YYYY') : ''}</td>
                      </tr>
                    );
                  });
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        ''
      )}

      {order.items.forEach(item => {
        invoiceCount = invoiceCount + item.invoice.length;
      })}
      {invoiceCount > 0 ? (
        <div className="table-responsive">
          <hr size="2" />
          <h2 style={{ fontSize: '1.8rem', fontWeight: '450' }}>
            <Translate word={ui['ITEM_INVOICE_INFORMATION']} />
          </h2>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>
                    <Translate word={ui['MODEL']} />
                  </th>
                  <th>
                    <Translate word={ui['ITEM_INVOICE_NUMBER']} />
                  </th>
                  <th>
                    <Translate word={ui['ITEM_INVOICE_DATE']} />
                  </th>
                  <th>
                    <Translate word={ui['ITEM_INVOICE_QUANTITY']} />
                  </th>
                  <th>
                    <Translate word={ui['ITEM_SUB_TOTAL']} />
                  </th>
                  <th>
                    <Translate word={ui['ITEM_TAX']} />
                  </th>
                  <th>
                    <Translate word={ui['ITEM_FREIGHT']} />
                  </th>
                  <th>
                    <Translate word={ui['ITEM_TOTAL']} />
                  </th>
                  <td />
                </tr>
              </thead>
              <tbody>
                {order.items.map((item, index) => {
                  return item.invoice.map((inv, i) => (
                    <tr key={i}>
                      <td>{inv.material}</td>
                      <td>{inv.invoiceNumber}</td>
                      <td>{inv.creationDate ? moment(inv.creationDate).format('MM/DD/YYYY') : ''}</td>
                      <td>{inv.invoicedQuantity}</td>
                      <td>{inv.lineNetValue === 0 ? '$' + inv.lineNetValue.toFixed(2) : '$-' + inv.lineNetValue.toFixed(2)}</td>
                      <td>{inv.lineTax ? '$' + inv.lineTax.toFixed(2) : '$0.00'}</td>
                      <td>{inv.lineFreight ? '$' + inv.lineFreight.toFixed(2) : '$0.00'}</td>
                      <td>
                        {inv.lineNetValue + inv.lineTax === 0
                          ? '$' + (inv.lineNetValue + inv.lineTax).toFixed(2)
                          : '$-' + (inv.lineNetValue + inv.lineTax).toFixed(2)}
                      </td>
                      <td>
                        <Button
                          bsStyle="primary"
                          className="btn-margin"
                          onClick={() => getInvoiceDetails(translate({ word: ui['LANG_ID'], language: language }), order.id, inv.invoiceNumber ,source)}
                          disabled={isFetching}
                        >
                          <Translate word={ui['DOWNLOAD_INVOICE_PDF_BUTTON']} />
                        </Button>
                      </td>
                    </tr>
                  ));
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        ''
      )}
      <div style={{ paddingBottom: '20px', fontSize: '1.3rem' }}>
        <b>
          <Translate word={ui['SHIPPING_COST']} />
        </b>
        :
        <br />
        <Translate word={ui['SHIPPING_COST_MSG']} />
      </div>
      {/* <div className="disclaimer" style={{ paddingBottom: '20px' }}>
        <Translate word={ui['ORDER_ESTIMATED_SHIPPING_MESSAGE']} />
      </div> */}
      <hr size="8" />
      <Link
        to={'/returnhistory'}
        role="button"
        className="btn btn-primary"
        style={{ paddingTop: '8px', paddingBottom: '8px', float: 'left' }}
        onClick={() => setBackButtonFlag(true)}
      >
        <Translate word={ui['BACK_BUTTON']} />
      </Link>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    order: state.order.order,
    isFetching: state.common.isFetching,
    invoice: state.order.invoice,
    error: state.error.error_obj,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchOrder: (language, orderId , source) => dispatch(orderActions.fetchOrder(language, orderId ,source)),
    setBackButtonFlag: resetFlag => dispatch(orderActions.setBackButtonFlag(resetFlag)),
    getInvoiceDetails: (language, orderId, invoiceNumber,source) => dispatch(orderActions.getInvoiceDetails(language, orderId, invoiceNumber,source)),
    clearOrderDetails: () => dispatch(orderActions.clearOrderDetails()),
    resetError: () => dispatch(errorActions.resetError()),
    resetFetching: () => dispatch(commonActions.resetFetching())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReturnView);
