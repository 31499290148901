import React from 'react';
import SonyLogo from '../../static/images/sony-logo-vector.png';
import './Logo.css';
import { Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
const Logo = () => (
  <div className="Logo">
    <Navbar.Brand>
      <Link to="/" style={{ marginLeft: '25px', marginRight: '25px', marginTop: '10px' }}>
        <div className="Logo_typo">
          <img className="Logo__Sony" src={SonyLogo} alt="Sony Logo" />
        </div>
      </Link>
    </Navbar.Brand>
  </div>
);

export default Logo;
