import React from 'react';
import { connect } from 'react-redux';
import Cart from './cart/Cart';
import * as accountActions from '../modules/account';
import * as errorActions from '../modules/error';
import { SET_IMPERSONATION } from '../modules/common_actions';
import Loading from './Loading';
import Translate from './dictionary/Translator';
import errorDictionary from './dictionary/error';

class Home extends React.Component {
  componentWillMount() {
    this.props.resetError();

    if (this.props.isAdmin && !this.props.user) {
      this.props.history.push('/admin/accountSearch');
    } else if (!this.props.user) {
      this.props.loadAccount(this.props.country);
    }
  }

  componentWillUnmount() {
    this.props.resetError();
  }
  componentDidMount() {
    this.props.setImpersonate();
  }

  componentDidUpdate() {
    if (this.props.impersonated) {
      this.props.setImpersonate();
    }
  }
  render() {
    const { user, error } = this.props;

    return (
      <div>
        {error &&
          !user && (
            <div className="text-danger">
              <h4>
                <Translate dictionary={errorDictionary} word={error.errorPath} />
              </h4>
            </div>
          )}
        {!error && !user && <Loading />}
        {user && <Cart />}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    user: state.account.user,
    isAdmin: state.auth.isAdmin,
    impersonated: state.account.impersonated,
    isFetching: state.common.isFetching,
    error: state.error.error_obj,
    country: state.country
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadAccount: country => dispatch(accountActions.loadAccount(undefined, country)),
    resetError: () => dispatch(errorActions.resetError()),
    setImpersonate: () => dispatch({ type: SET_IMPERSONATION, data: { impersonated: undefined } })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
