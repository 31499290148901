import React from 'react';
import CountrySelect from '../countrySelect/CountrySelect';
import LanguageSelect from '../languageSelect/LanguageSelect';
import './SecondaryHeader.css';
import { connect } from 'react-redux';
import ProductSearch from '../productsearch/ProductSearch';
import { Link } from 'react-router-dom';
import ui from './dictionary/ui.json';
import Translate from '../dictionary/Translator';
import { TEST, PRODUCTION } from '../../modules/environment';
import CartHeader from './CartHeader';
const SecondaryHeader = ({ user, cartItems, environment }) => {
  return (
    <div className="SecondaryHeader">
      <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
        <div className="SecondaryHeader__typo on-hover-gray on-hover-cursor">
          <Link to="/" style={{ all: 'unset' }} className="reset-style">
            ExpressNet
          </Link>
        </div>
        {environment.show && (
          <span style={{ fontSize: '2rem', color: 'red', textAlign: 'center', marginLeft: '4px', paddingTop: '3px', fontWeight: '600' }}>
            *** {renderEnvironmentMessage(environment.environment)} ***
          </span>
        )}
      </div>
      {user && <ProductSearch />}
      <div className="SecondaryHeader__right">
        {user && <CartHeader />}
        <div>
          <CountrySelect />
          <LanguageSelect />
        </div>
      </div>
    </div>
  );
};

const renderEnvironmentMessage = environment => {
  switch (environment) {
    case TEST:
      return <Translate dictionary={ui} word="TEST" />;
    case PRODUCTION:
      return <Translate dictionary={ui} word="PRODUCTION" />;
    default:
      return <span />;
  }
};
const mapStateToProps = state => {
  return {
    user: state.account.user,

    environment: state.environment
  };
};
export default connect(mapStateToProps)(SecondaryHeader);
