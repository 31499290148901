export const searchBulletin = ({ bulletins, model, type, effectivePeriod, publishPeriod, number, description }) => {
  let results = [].concat(bulletins);
  if (model && model.length > 1) {
    results = filterBulletinsBasedOnModel(results, model);
  }
  if (type && type.length > 1) {
    results = results.filter(bulletin => bulletin.bulletin_type === type);
  }
  if (effectivePeriod) {
    results = filterBulletinsBasedOnMonth(results, effectivePeriod, "effectivePeriod");
  }
  if (publishPeriod) {
    results = filterBulletinsBasedOnMonth(results, publishPeriod, "publishPeriod");
  }
  if (number && number.length > 1) {
    results = results.filter(bulletin => bulletin.bulletin_no === number);
  }
  if (description && description.length > 1) {
    results = filterBulletinsBasedOnDescription(results, description);
  }
  if (publishPeriod && effectivePeriod) {
      results = results.sort((a, b) => {
          return (
              parseDateToJavaScript(getPublishPeriod(a)) -
              parseDateToJavaScript(getPublishPeriod(b))
          );
      });
      results = results.sort((a, b) => {
          return (
              parseDateToJavaScript(getEffectivePeriod(a)) -
              parseDateToJavaScript(getEffectivePeriod(b))
          );
      });
  } else if (publishPeriod && !effectivePeriod) {
      results = results.sort((a, b) => {
          return (
              parseDateToJavaScript(getEffectivePeriod(a)) -
              parseDateToJavaScript(getEffectivePeriod(b))
          );
      });
      results = results.sort((a, b) => {
          return (
              parseDateToJavaScript(getPublishPeriod(a)) -
              parseDateToJavaScript(getPublishPeriod(b))
          );
      });
  } else if (effectivePeriod && !publishPeriod) {
      results = results.sort((a, b) => {
          return (
              parseDateToJavaScript(getPublishPeriod(a)) -
              parseDateToJavaScript(getPublishPeriod(b))
          );
      });
      results = results.sort((a, b) => {
          return (
              parseDateToJavaScript(getEffectivePeriod(a)) -
              parseDateToJavaScript(getEffectivePeriod(b))
          );
      });
  } else {
      results = results.sort((a, b) => {
          return a.bulletin_no - b.bulletin_no;
      });
  }
  return results;
};
export const filterBulletinsBasedOnDescription = (bulletins, description) => {
  const normalizedDescription = normalizeString(description);
  return bulletins.filter(({ bulletin_desc }) => normalizeString(bulletin_desc).indexOf(normalizedDescription) !== -1);
};
export const filterBulletinsBasedOnModel = (bulletins, model) => {
  const filtered = [];
  const normalizedModel = normalizeString(model);
  bulletins.forEach(bulletin => {
    const normalizedBulletinNumbers = bulletin.model.map(no => normalizeString(no));
    if (normalizedBulletinNumbers.indexOf(normalizedModel) !== -1) {
      filtered.push(bulletin);
    }
  });
  return filtered;
};

const normalizeString = str => {
  return String(str)
    .toLocaleLowerCase()
    .trim()
    .replace(/\s/g, '');
};

export const filterBulletinsBasedOnMonth = (bulletins, month, periodType) => {
  return bulletins.filter(bulletin => {
    const validDate = periodType === "effectivePeriod" ? getEffectivePeriod(bulletin) : getPublishPeriod(bulletin);
    if (
      parseDateToJavaScript(validDate)
        .getMonth()
        .toString() === month
    ) {
      return true;
    }
    return false;
  });
};
function parseDateToJavaScript(str) {
  return new Date(str);
}

export const getEffectivePeriod = bulletin => {
  if (bulletin.bulletin_start_date && bulletin.bulletin_start_date.length > 1 && bulletin.bulletin_start_date !== '01/01/1900') {
    return bulletin.bulletin_start_date;
  }
  return bulletin.bulletin_date;
};

export const getPublishPeriod = bulletin => {
  if (bulletin.bulletin_date && bulletin.bulletin_date.length > 1 && bulletin.bulletin_date !== '01/01/1900') {
    return bulletin.bulletin_date;
  }
  return bulletin.bulletin_date;
};

export const shoudlShowBulletin = ({ contract, user }) => {
  const allowedRoles = ['XVO', 'XBU', 'XPR', 'XPA', 'XMU','XAD','ACTREPMGR','ACTREP'];
  const allowedCustomerGroup = ['Z1', 'Z4']
  if (user && contract && allowedRoles.includes(user.role) && allowedCustomerGroup.includes(contract.customerGroup)) {
    return true;
  }
  return false;
};

export const convertToHumanReadableDate = str => {
  const date = parseDateToJavaScript(str);
  return formatDate(date);
};

const formatDate = date => {
  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();
  return day + ' ' + monthNames[monthIndex] + ' ' + year;
};

export const BULLETIN_TYPES = [
  'IPSA Model Intro',
  'IPSA Model Disco',
  'IPSA Model Update',
  'IPSA Pricing Promotion',
  'IPSA Back-end rebate',
  'IPSA Design In',
  'IPSA RSVP',
  'IPSA RSVP Alumni',
  'IPSA Price Increase',
  'IPSA Price Decrease',
  'IPSA VIR/GIR/TRP',
  'IPSA End User IR',
  'IPSA End User Mail In',
  'IPSA GOV EDU HOW CORP',
  'IPSA SPIFF',
  'IPSA XL REBATE',
  'IPSA REBATE',
  'IPSA Engineer Reward',
  'IPSA Model Intro/Disco'
];

export const getProductBulletins = (bulletins, sku) => {
  return bulletins.filter(({ model }) => {
    return model.includes(sku);
  });
};

export const filterBulletinsBasedOnPriceBook = (bulletins, priceBook) => {
  const allAvailableSkus = priceBook.map(product => product.sku);
  // const filtered = bulletins.filter(bulletin => bulletin.model.some(m => allAvailableSkus.indexOf(m) !== -1));
  const filtered = bulletins.reduce((accu, current) => {
    const currentAvailableModels = [];
    current.model.forEach(m => {
      if (allAvailableSkus.includes(m)) {
        currentAvailableModels.push(m);
      }
    });
    if (currentAvailableModels.length > 0) {
      return accu.concat({
        ...current,
        model: currentAvailableModels
      });
    }
    return accu;
  }, []);
  return filtered;
};
