import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { NavDropdown } from 'react-bootstrap';
import { translate } from '../dictionary/Translator';
import main_ui from '../dictionary/ui';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import './catalog.css';
import SideBySideMenu from './SideBySideCatalogMenu/SideBySideCatalogMenu';
import MobileViewMenu from './MobileViewCatalogMenu/MobileViewCatalogMenu';
class Catalog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      closeDropdown: true,
      showSideBySide: true,
      selectedMain: null,
      selectedId: null,
      selectedSub: null
    };
  }
  componentDidMount() {
    this.chooseCatalogMenuView();
    window.addEventListener('resize', this.chooseCatalogMenuView);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.chooseCatalogMenuView);
  }
  chooseCatalogMenuView = () => {
    const width = document.documentElement.clientWidth;
    if (width < 750) {
      this.setState({ showSideBySide: false });
    } else {
      this.setState({ showSideBySide: true });
    }
  };

  closeDropdown = () => {
    this.setState({ closeDropdown: true, selectedMain: null, selectedSub: null, selectedId: null });
  };
  onSelectMain = ({title, id}) => {
    this.setState({ selectedMain: title, selectedId: id  });
  };
  onSelectSub = ({ id }) => {
    this.setState({ selectedSub: id });
    this.props.history.push('/productlist/' + encodeURIComponent(id));
    this.closeDropdown();
  };
  render() {
    const { language } = this.props;
    const { showSideBySide, closeDropdown, selectedMain, selectedSub, selectedId } = this.state;
    return (
      <NavDropdown
        title={translate({ dictionary: main_ui.HEADER, word: 'CATALOG', language })}
        open={!this.state.closeDropdown}
        onToggle={() => this.setState({ closeDropdown: !this.state.closeDropdown, selectedMain: null, selectedSub: null, selectedId : null})}
        id="catalog-dropdown"
      >
        {!showSideBySide && (
          <MobileViewMenu
            closed={closeDropdown}
            mains={this.getAllCatalogNames()}
            onGetSubCategories={this.getSubCatObjectByCatalogName}
            onSelectMain={this.onSelectMain}
            onSelectSub={this.onSelectSub}
            selectedMain={selectedMain}
            selectedId = {selectedId}
            selectedSub={selectedSub}
          />
        )}
        {showSideBySide && (
          <SideBySideMenu
            closed={closeDropdown}
            mains={this.getAllCatalogNames()}
            onGetSubCategories={this.getSubCatObjectByCatalogName}
            onSelectMain={this.onSelectMain}
            onSelectSub={this.onSelectSub}
            selectedId = {selectedId}
            selectedMain={selectedMain}
            selectedSub={selectedSub}
          />
        )}
      </NavDropdown>
    );
  }

  getAllCatalogNames = () => {
    const retVal = [];
    this.props.catalog.forEach(aCatalog => {
      retVal.push({name : aCatalog.name , id: aCatalog.id});
    });
    return retVal;
  };

  getSubCatObjectByCatalogName = id => {
    const cats = this.props.catalog.filter(aCatalog => {
      if (aCatalog.id === id) {
        return true;
      } else {
        return false;
      }
    });
    if (cats && cats.length > 0) {
      return cats[0].subCats;
    }
    return [];
  };
}

const mapStateToProps = state => {
  const { language } = state;
  var catalog = state.account.catalog && state.account.catalog[language] ? state.account.catalog[language] : [];
  return {
    catalog,
    language: state.language
  };
};

export default withRouter(connect(mapStateToProps)(Catalog));
