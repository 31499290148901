import React from 'react';
import { connect } from 'react-redux';
import { TEST, PRODUCTION, confirmTheDisclaimer } from '../../modules/environment';
import Translate from '../dictionary/Translator';
import ui from './dictionary/ui.json';
import './EnvironmentDisclaimer.css';
import LanguageSelect from '../languageSelect/LanguageSelect';
import MdWarning from 'react-icons/lib/md/warning';
const EnvironmentDisclaimer = ({ show, environment, confirmTheDisclaimer, country }) => {
  return (
    <div className="EnvironmentDisclaimer">
      {show && (
        <div className="EnvironmentDisclaimer__box">
          {country === 'CA' && (
            <div style={{ float: 'right', color: '#000' }}>
              <LanguageSelect />
            </div>
          )}
          <MdWarning style={{ fontSize: '4rem' }} />
          <p>{renderEnvironmentMessage(environment)}</p>
          <button className="EnvironmentDisclaimer__btn-ok" onClick={confirmTheDisclaimer}>
            Ok
          </button>
        </div>
      )}
    </div>
  );
};
const renderEnvironmentMessage = environment => {
  switch (environment) {
    case TEST:
      return <Translate dictionary={ui} word="TEST" />;
    case PRODUCTION:
      return <Translate dictionary={ui} word="PRODUCTION" />;
    default:
      return <span />;
  }
};
const mapStateToProps = ({ environment: { show, environment }, country }) => ({ show, environment, country });
export default connect(
  mapStateToProps,
  { confirmTheDisclaimer }
)(EnvironmentDisclaimer);
