import React from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import 'jquery-menu-aim/jquery.menu-aim.js';
import GoChevronRight from 'react-icons/lib/go/chevron-right';
import Translate from '../../dictionary/Translator';
import ui from '../dictionary/ui.json';
class Main extends React.Component {
  componentDidUpdate(preProps) {
    if (preProps.lang !== this.props.lang) {
      this.initiateMenuAim();
    }
    if (this.props.closed === true) {
      this.initiateMenuAim();
    }
  }
  componentDidMount() {
    this.initiateMenuAim();
  }
  initiateMenuAim = () => {
    this.$main = $(this.main);
    this.$main.menuAim({
      activate: this.activateSubMenu,
      deactivate: this.deactivateSubmenu
    });
  };
  activateSubMenu = row => {
    $(row).css({ fontWeight: 600 });
    this.props.onSelectMain({ title: $(row).text(), id: $(row).attr('id') });
  };
  deactivateSubmenu = row => {
    $(row).css({ fontWeight: 300 });
  };
  componentWillUnmount() {
    this.$main.menuAim('destroy');
  }
  render() {
    const { titles, lang } = this.props;
    return titles.length > 0 ? (
      <ul role="menu" ref={main => (this.main = main)}>
        {titles.map(title => (
          <li role="presentation" key={title.id} id={title.id}>
            <span role="menuitem" className="Side-By-Side-Menu__catalog-title">{title.name}</span>
            <span className="Side-By-Side-Menu__arrow">
              <GoChevronRight aria-hidden="true"/>
            </span>
          </li>
        ))}
      </ul>
    ) : (
      <p style={{ textAlign: 'center', verticalAlign: 'middle' }}>
        <Translate word="NO_CATEGORY" dictionary={ui} language={lang} />
      </p>
    );
  }
}

const mapStateToProps = ({ language }) => {
  return { lang: language };
};

export default connect(mapStateToProps)(Main);
