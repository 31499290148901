import React from 'react';
import EmailProvider from './EmailProvider';
export const FAQData = [
  {
    title: {
      en_US: <span>User Login, Password Reset and Account Change Inquiries</span>,
      en_CA: <span>User Login, Password Reset and Account Change Inquiries</span>,
      fr_CA: <span>Connexion utilisateur, réinitialisation du mot de passe et demande de modification de compte</span>
    },
    questions: [
      {
        question: {
          en_US: (
            <p>
              What do I do if I forgot my password or got locked out of my Sony ExpressNet
              <sup>SM</sup>
              System account?
            </p>
          ),
          en_CA: (
            <p>
              What do I do if I forgot my password or got locked out of my Sony ExpressNet
              <sup>SM</sup>
              System account?
            </p>
          ),
          fr_CA: (
            <p>
              Que faire si j'ai oublié mon mot de passe ou si mon compte d'utilisateur a été verrouillé par le système ExpressNet
              <sup>SM</sup>
               Sony ?
            </p>
          )
        },
        answer: {
          en_US: (
            <p>
              You can reset your password yourself by clicking on the Forgot Password button. Please contact your Order & Operations Analyst if you encounter
              any issues.
            </p>
          ),
          en_CA: (
            <p>
              You can reset your password yourself by clicking on the Forgot Password button. Please contact your Order & Operations Analyst if you encounter
              any issues.
            </p>
          ),
          fr_CA: (
            <p>
              Vous pouvez réinitialiser votre mot de passe vous-même en cliquant sur « Mot de passe oublié ». Pour tout autre problème, communiquez avec
              l'Analyste des Commandes et des Opérations.
            </p>
          )
        }
      },
      {
        question: {
          en_US: (
            <p>
              How do I make changes to my Sony ExpressNet
              <sup>SM</sup>
              System account (add or remove users)?
            </p>
          ),
          en_CA: (
            <p>
              How do I make changes to my Sony ExpressNet
              <sup>SM</sup>
              System account (add or remove users)?
            </p>
          ),
          fr_CA: (
            <p>
              Comment puis-je modifier (ajout/suppression) mon compte d'utilisateur ExpressNet
              <sup>SM</sup> Sony ?
            </p>
          )
        },
        answer: {
          en_US: (
            <p>
              In order to make changes in your Sony ExpressNet
              <sup>SM</sup> System account, the Principal Owner or Purchasing Agent must send an email written request to your Account Manager. For adding new
              users or updating existing user information the Principal Owner or Purchasing Agent may utilize the Sony ExpressNetSM System Registration Form
              listed below.
              <a href="/assets/docs/ExpressNetRegistrationForm.doc" target="_blank" rel="noopener noreferrer">
                *Download the Sony ExpressNetSM System Registration Form.
              </a>
            </p>
          ),
          en_CA: (
            <p>
              In order to make changes in your Sony ExpressNet
              <sup>SM</sup> System account, the Principal Owner or Purchasing Agent must send an email written request to your Account Manager. For adding new
              users or updating existing user information the Principal Owner or Purchasing Agent may utilize the Sony ExpressNetSM System Registration Form
              listed below.
              <a href="/assets/docs/ExpressNetRegistrationForm.doc" target="_blank" rel="noopener noreferrer">
                *Download the Sony ExpressNetSM System Registration Form.
              </a>
            </p>
          ),
          fr_CA: (
            <p>
              Pour modifier votre compte ExpressNet
              <sup>SM</sup> Sony, le Propriétaire principal ou l'Agent responsable des achats doit envoyer une demande par courriel à votre Gestionnaire de
              compte. Pour l'ajout de nouveaux utilisateurs ou pour mettre à jour les renseignements d'un utilisateur existant, le Propriétaire principal ou
              l'Agent responsable des achats doit télécharger et compléter le formulaire disponible ci-dessous.
              <a href="/assets/docs/ExpressNetRegistrationForm_FR.docx" target="_blank" rel="noopener noreferrer">
                *Télécharger le formulaire d'enregistrement au système ExpressNetSM Sony.
                <sup>SM</sup>
              </a>
            </p>
          )
        }
      },
      {
        question: {
          en_US: (
            <p>
              How do I add ship to’s to my Sony ExpressNet
              <sup>SM</sup>
              System?
            </p>
          ),
          en_CA: (
            <p>
              How do I add ship to’s to my Sony ExpressNet
              <sup>SM</sup>
              System?
            </p>
          ),
          fr_CA: (
            <p>
              Comment puis-je ajouter une adresse de livraison à mon compte ExpressNet
              <sup>SM</sup> Sony ?
            </p>
          )
        },
        answer: {
          en_US: <p>Please contact your Account Manager.</p>,
          en_CA: <p>Please contact your Account Manager.</p>,
          fr_CA: <p>Veuillez contacter votre Gestionnaire de compte.</p>
        }
      },
      {
        question: {
          en_US: <p>How do I make changes to an address that is currently set up?</p>,
          en_CA: <p>How do I make changes to an address that is currently set up?</p>,
          fr_CA: <p>Comment puis-je modifier une adresse déjà configurée?</p>
        },
        answer: {
          en_US: <p>Please contact your Account Manager.</p>,
          en_CA: <p>Please contact your Account Manager.</p>,
          fr_CA: <p>Veuillez contacter votre Gestionnaire de compte.</p>
        }
      },
      {
        question: {
          en_US: (
            <p>
              Who do I contact if I’m having a problem with my Sony ExpressNet
              <sup>SM</sup>
              System account?
            </p>
          ),
          en_CA: (
            <p>
              Who do I contact if I’m having a problem with my Sony ExpressNet
              <sup>SM</sup>
              System account?
            </p>
          ),
          fr_CA: (
            <p>
              Qui dois-je contacter si j'ai un problème avec mon compte ExpressNet
              <sup>SM</sup>
               Sony ?
            </p>
          )
        },
        answer: {
          en_US: (
            <p>
              If you need assistance navigating the site, your Order & Operations Analyst (OOA) can walk you through the process. If your problem is system
              related, please contact <a href="mailto:ExpressNet@am.sony.com">ExpressNet@am.sony.com.</a>
            </p>
          ),
          en_CA: (
            <p>
              If you need assistance navigating the site, your Order & Operations Analyst (OOA) can walk you through the process. If your problem is system
              related, please contact <a href="mailto:ExpressNet@am.sony.com">ExpressNet@am.sony.com.</a>
            </p>
          ),
          fr_CA: (
            <p>
              Pour obtenir de l'aide concernant l'utilisation du site, l'Analyste des Commandes et des Opérations (ACO) se fera un plaisir de vous guider tout
              au long du processus. Si votre demande d'aide concerne plutôt le système informatique, envoyez un courriel à
              <a href="mailto:ExpressNet@am.sony.com"> ExpressNet@am.sony.com. </a>
            </p>
          )
        }
      }
    ]
  },
  {
    title: {
      en_US: <span>Price Book Inquiries</span>,
      en_CA: <span>Price Book Inquiries</span>,
      fr_CA: <span>Questions concernant l'affichage des prix</span>
    },
    questions: [
      {
        question: {
          en_US: <p>Is the ‘Your Price’ reflected on the Price Report the Customer Contract Price or the Customer Net Price?</p>,
          en_CA: <p>Is the ‘Your Price’ reflected on the Price Report the Customer Contract Price or the Customer Net Price?</p>,
          fr_CA: <p>Le montant du champ « Votre prix » est-il constitué du prix des clients contractuels ou du prix net du client ?</p>
        },
        answer: {
          en_US: (
            <p>
              ‘Your Price’ indicates standard contract pricing at a model level in the catalog and price book. Please note that additional net pricing may exist
              once you Preview your order, and will be accurately reflected in the ‘Your Price’ field before you process your order.{' '}
            </p>
          ),
          en_CA: (
            <p>
              ‘Your Price’ indicates standard contract pricing at a model level in the catalog and price book. Please note that additional net pricing may exist
              once you Preview your order, and will be accurately reflected in the ‘Your Price’ field before you process your order.{' '}
            </p>
          ),
          fr_CA: (
            <p>
              Le montant du champ « Votre prix » indique le prix standard du contrat au niveau du modèle dans le catalogue et le cahier des prix. Prenez note
              que des frais supplémentaires peuvent être affichés à l'étape de prévisualisation de la commande; ceux-ci seront exister une fois que vous avez
              aperçu votre commande, et seront précisément inclus dans le champ « Votre prix » avant le traitement de votre commande.
            </p>
          )
        }
      },
      {
        question: {
          en_US: <p>Why are no Consumer products being shown in the Price Book report?</p>,
          en_CA: <p>Why are no Consumer products being shown in the Price Book report?</p>,
          fr_CA: <p>Pourquoi les produits de consommation n'apparaissent-ils pas dans le cahier des prix ?</p>
        },
        answer: {
          en_US: (
            <p>
              The Sony ExpressNet
              <sup>SM</sup>
              System does not list Consumer Models in the Price Book. Please contact your Sony Account Manager for the most recent price sheet.
            </p>
          ),
          en_CA: (
            <p>
              The Sony ExpressNet
              <sup>SM</sup>
              System does not list Consumer Models in the Price Book. Please contact your Sony Account Manager for the most recent price sheet.
            </p>
          ),
          fr_CA: (
            <p>
              Le système ExpressNet
              <sup>SM</sup>
               ne répertorie pas les produits destinés au grand public dans le cahier des prix. Veuillez contacter votre Gestionnaire de compte Sony pour
              connaître le prix courant.
            </p>
          )
        }
      }
    ]
  },
  {
    title: {
      en_US: <span>Claims Request Inquiries</span>,
      en_CA: <span>Claims Request Inquiries</span>,
      fr_CA: <span>Questions concernant les demandes de réclamation</span>
    },
    questions: [
      {
        question: {
          en_US: <p>What is a Claims Request?</p>,
          en_CA: <p>What is a Claims Request?</p>,
          fr_CA: <p>Qu'est-ce qu'une demande de réclamation ?</p>
        },
        answer: {
          en_US: <p>Claims request is the link that eligible customers can use to request credits for Trailing and/or Price Protection promotions.</p>,
          en_CA: <p>Claims request is the link that eligible customers can use to request credits for Trailing and/or Price Protection promotions.</p>,
          fr_CA: (
            <p>
              Les demandes de réclamation sont des liens que les clients éligibles peuvent utiliser pour réclamer des crédits relatifs aux promotions de suivi
              et/ou à la protection des prix.
            </p>
          )
        }
      },
      {
        question: {
          en_US: <p>What does “Contact CCA” mean?</p>,
          en_CA: <p>What does “Contact CCA” mean?</p>,
          fr_CA: <p>Que signifie "Contact CCA"?</p>
        },
        answer: {
          en_US: <p>Contact CCA means that your credit request has been received and is under review by your Collections and Claims Analyst.</p>,
          en_CA: <p>Contact CCA means that your credit request has been received and is under review by your Collections and Claims Analyst.</p>,
          fr_CA: (
            <p>
              Par « Contact CCA », nous entendons une demande de crédit qui a été reçue et qui est en cours d’examen par l'Analyste du recouvrement et des
              réclamations.
            </p>
          )
        }
      }
    ]
  },
  {
    title: {
      en_US: <span>Cart Related Inquiries</span>,
      en_CA: <span>Cart Related Inquiries</span>,
      fr_CA: <span>Questions concernant le panier d'achats</span>
    },
    questions: [
      {
        question: {
          en_US: <p>Can I change my order after I submit it?</p>,
          en_CA: <p>Can I change my order after I submit it?</p>,
          fr_CA: <p>Puis-je changer ma commande après l'avoir soumise ?</p>
        },
        answer: {
          en_US: <p>Please contact your Order and Operations Analyst within 24 hours of submitting the order to cancel or make any changes.</p>,
          en_CA: <p>Please contact your Order and Operations Analyst within 24 hours of submitting the order to cancel or make any changes.</p>,
          fr_CA: (
            <p>
              Veuillez contacter votre analyste des commandes et des opérations dans les 24 heures suivant l'envoi de la commande pour annuler ou apporter des
              modifications.
            </p>
          )
        }
      },
      {
        question: {
          en_US: <p>What is ‘Requested Delivery Date’ in the Shopping Cart?</p>,
          en_CA: <p>What is ‘Requested Delivery Date’ in the Shopping Cart?</p>,
          fr_CA: <p>Qu'est-ce que la « Date de livraison souhaitée » du panier d'achats ?</p>
        },
        answer: {
          en_US: (
            <p>
              It is the date you are requesting your order to be delivered and it must be a date in the future. If none is provided, the system will default to
              three days and then your shipment will schedule a date based on the lead time set up in the system. Each order can only have one requested
              delivery date. If expedited shipping is required and is available as an option then this can be changed at the line item, if it is not available
              please contact your Order and Operations Analyst.
            </p>
          ),
          en_CA: (
            <p>
              It is the date you are requesting your order to be delivered and it must be a date in the future. If none is provided, the system will default to
              three days and then your shipment will schedule a date based on the lead time set up in the system. Each order can only have one requested
              delivery date. If expedited shipping is required please contact your Order and Operations Analyst.
            </p>
          ),
          fr_CA: (
            <p>
              C'est la date à laquelle vous demandez que votre commande soit livrée et il doit s'agir d'une date ultérieure. Si aucun n'est fourni, le système
              sera par défaut à trois jours et votre envoi planifiera alors une date en fonction du délai défini dans le système. Chaque commande ne peut avoir
              qu'une seule demande date de livraison. Si un envoi accéléré est requis, veuillez contacter votre analyste des commandes et des opérations.
            </p>
          )
        }
      },
      {
        question: {
          en_US: <p>Can I have multiple ‘Requested Delivery Date’ per line item?</p>,
          en_CA: <p>Can I have multiple ‘Requested Delivery Date’ per line item?</p>,
          fr_CA: <p>Puis-je avoir de multiples « Date de livraison souhaitée » pour chaque ligne de produit ?</p>
        },
        answer: {
          en_US: <p>Each order can only have one requested delivery date. If this is a requirement, multiple orders may be placed.</p>,
          en_CA: <p>Each order can only have one requested delivery date. If this is a requirement, multiple orders may be placed.</p>,
          fr_CA: (
            <p>Chaque commande ne peut avoir qu'une seule date de livraison souhaitée. En cas de nécessité absolue, veuillez passer de multiples commandes.</p>
          )
        }
      },
      {
        question: {
          en_US: <p>Can I ask for expedited shipping?</p>,
          en_CA: <p>Can I ask for expedited shipping?</p>,
          fr_CA: <p>Puis-je demander une livraison accélérée ?</p>
        },
        answer: {
          en_US: (
            <p>
              If you require expedited shipping then ship mode can be changed at a line item level. If this option is not available then contact your Order and
              Operations Analyst. Expedited shipping requests are subject to additional charges, please contact your Account Manager or Order and Operations
              Analyst for details.
            </p>
          ),
          en_CA: <p>Please contact your Order and Operations Analyst to update to expediated shipping.</p>,
          fr_CA: <p>Veuillez contacter votre analyste des commandes et des opérations pour effectuer une mise à jour vers une livraison accélérée.</p>
        }
      },
      {
        question: {
          en_US: <p>When will the product be available?</p>,
          en_CA: <p>When will the product be available?</p>,
          fr_CA: <p>Quand le produit sera-t-il disponible ?</p>
        },
        answer: {
          en_US: <p>You will only see if a product is available once you hit preview order. ETA info is not visible in the shopping cart.</p>,
          en_CA: <p>You will only see if a product is available once you hit preview order. ETA info is not visible in the shopping cart.</p>,
          fr_CA: (
            <p>
              C'est seulement lors de la prévisualisation de votre commande que vous pourrez voir si un produit est disponible. Les renseignements relatifs à
              l'inventaire ne sont pas affichés dans le panier d'achats.
            </p>
          )
        }
      },
      {
        question: {
          en_US: <p>What is “Schedule” area on the Verify Order Page?</p>,
          en_CA: <p>What is “Schedule” area on the Verify Order Page?</p>,
          fr_CA: <p>Qu'est-ce que le champ « Calendrier » à la page de prévisualisation de la commande ?</p>
        },
        answer: {
          en_US: (
            <div>
              There are 4 columns that need to be reviewed:
              <ol>
                <li>
                  Schedule Quantity – This indicates if your order is allocating or not. If there is a schedule line quantity greater than 0 that means your
                  order will be shipping based and the dates in subsequent columns can be reviewed. If the schedule line quantity is TBD and there is no
                  allocation and the following should be contacted: <EmailProvider />
                </li>
                <li> Material Availability Date is the date that the item will drop to our warehouse for processing.</li>
                <li>Estimated Shipping Date is the date the product will leave our warehouse. </li>
                <li>Estimated Delivery Date is the date that the product will arrive to ship to location on the order.</li>
              </ol>
              Please keep in mind that all dates are “estimated” and not firm.
            </div>
          ),
          en_CA: (
            <div>
              There are 4 columns that need to be reviewed:
              <ol>
                <li>
                  Schedule Quantity – This indicates if your order is allocating or not. If there is a schedule line quantity greater than 0 that means your
                  order will be shipping based and the dates in subsequent columns can be reviewed. If the schedule line quantity is TBD and there is no
                  allocation and the following should be contacted: <EmailProvider />
                </li>
                <li> Material Availability Date is the date that the item will drop to our warehouse for processing.</li>
                <li>Estimated Shipping Date is the date the product will leave our warehouse. </li>
                <li>Estimated Delivery Date is the date that the product will arrive to ship to location on the order.</li>
              </ol>
              Please keep in mind that all dates are “estimated” and not firm.
            </div>
          ),
          fr_CA: (
            <div>
              Il y a 4 colonnes à revoir :
              <ol>
                <li>
                  Quantité de calendrier - Indique si votre commande est allouée ou non. Si le chiffre inscrit à cette ligne est supérieur à 0, cela signifie
                  que votre commande sera expédiée et les dates des colonnes suivantes peuvent être consultées. Si TBD est affiché sur cette ligne et qu'il n'y
                  a aucun allocation, contacter l'équipe de distribution à l'adresse: <EmailProvider />
                </li>
                <li>La date de disponibilité du produit est la date à laquelle le produit sera expédié de notre entrepôt pour traitement. </li>
                <li> La date d'expédition estimée est la date à laquelle le produit quittera notre entrepôt. </li>
                <li>La date de livraison estimée est la date à laquelle le produit arrivera à l'adresse de livraison indiquée sur la commande. </li>
              </ol>
              N'oubliez pas que ces dates vous sont fournies à titre indicatif seulement, et ne sont pas garanties.
            </div>
          )
        }
      },
      {
        question: {
          en_US: <p>How do I use my own FedEx or UPS account for the delivery?</p>,
          en_CA: null,
          fr_CA: null
        },
        answer: {
          en_US: <p>Please contact your Order and Operations Analyst to place freight collect orders. </p>,
          en_CA: null,
          fr_CA: null
        }
      },
      {
        question: {
          en_US: <p>How do I add special shipping instructions on my orders, like ship complete or to call before delivery?</p>,
          en_CA: <p>How do I add special shipping instructions on my orders, like ship complete or to call before delivery? </p>,
          fr_CA: (
            <p>Comment puis-je ajouter des instructions d'expédition spéciales sur mes commandes, comme un envoi complet ou un appel avant la livraison?</p>
          )
        },
        answer: {
          en_US: <p>Please contact your Order and Operations Analyst to place these orders.</p>,
          en_CA: <p>Please contact your Order and Operations Analyst to place these orders.</p>,
          fr_CA: <p>Veuillez contacter votre analyste des commandes et des opérations pour passer ces commandes.</p>
        }
      },
      {
        question: {
          en_US: (
            <p>
              What is the <b>Upload PO</b> functionality?
            </p>
          ),
          en_CA: (
            <p>
              What is the <b>Upload PO</b> functionality?
            </p>
          ),
          fr_CA: (
            <p>
              Quelle est la fonctionnalité de <b>Télécharger le bon de commande</b>?
            </p>
          )
        },
        answer: {
          en_US: (
            <p>
              The <b>Upload PO</b> functionality allows users to load a list of models and quantities rather than entering them one at a time.
            </p>
          ),
          en_CA: (
            <p>
              The <b>Upload PO</b> functionality allows users to load a list of models and quantities rather than entering them one at a time.
            </p>
          ),
          fr_CA: (
            <p>
              La fonctionnalité <b>Télécharger le bon de commande</b> permet aux utilisateurs de charger une liste de modèles et de quantités plutôt que de les
              saisir un par un.
            </p>
          )
        }
      },
      {
        question: {
          en_US: (
            <p>
              How do I use the <b>Upload PO</b> functionality?
            </p>
          ),
          en_CA: (
            <p>
              How do I use the <b>Upload PO</b> functionality?
            </p>
          ),
          fr_CA: (
            <p>
              Comment utiliser la fonctionnalité <b>Télécharger le bon de commande</b>?
            </p>
          )
        },
        answer: {
          en_US: (
            <p>
              The purchase order upload functionality allows users to upload “.CSV” (comma delimited) file type which contains only one sheet/tab. There is a
              template for the user to download.
              <a href="/assets/docs/PO_TEMPLATE.csv" target="_blank">
                Click here
              </a>
              . The PO upload template provided must be saved in this specified file format and must be used. ExpressNet will read the first two columns of the
              file which must have row one colunm one as “MODEL” and row one colunm two as “QUANTITY”.  When you click on <b>Upload PO</b> and select the file
              from your directory, a pop up page to inform you will appear showing the numbers of models that will be uploaded successfully and list any models
              that are incorrect.  If all models are correct then click to upload or cancel.  If you need cancel, you can amend the file to correct the models
              that are failing to upload. If any quantities are incorrect, i.e. master carton requirements, after you click on <b>Upload PO</b> you will see
              “The system cannot process your order at this time. However, you may browse the site for information. Please contact your Order and Operations
              Analyst if you require immediate assistance.”  You will need to amend the file to correct the quantities to be in the correct multiple.
            </p>
          ),
          en_CA: (
            <p>
              The purchase order upload functionality allows users to upload “.CSV” (comma delimited) file type which contains only one sheet/tab. There is a
              template for the user to download.
              <a href="/assets/docs/PO_TEMPLATE.csv" target="_blank">
                Click here
              </a>
              .  The PO upload template provided must be saved in this specified file format and must be used. ExpressNet will read the first two columns of the
              file which must have row one colunm one as “MODEL” and row one colunm two as “QUANTITY”.  When you click on <b>Upload PO</b> and select the file
              from your directory, a pop up page to inform you will appear showing the numbers of models that will be uploaded successfully and list any models
              that are incorrect.  If all models are correct then click to upload or cancel.  If you need cancel, you can amend the file to correct the models
              that are failing to upload. If any quantities are incorrect, i.e. master carton requirements, after you click on <b>Upload PO</b> you will see
              “The system cannot process your order at this time. However, you may browse the site for information. Please contact your Order and Operations
              Analyst if you require immediate assistance.”  You will need to amend the file to correct the quantities to be in the correct multiple.
            </p>
          ),
          fr_CA: (
            <p>
              La fonctionnalité de téléchargement du bon de commande permet aux utilisateurs de télécharger leurs bons de commande par le biais de fichiers
              ".CSV" (séparés par une virgule), pour les bons qui ne contiennent qu'une seule page ou un seul onglet. Un modèle de bon de commande est
              téléchargeable.
              <a href="/assets/docs/PO_TEMPLATE.csv" target="_blank">
                Cliquez ici
              </a>
              .Le modèle offert en téléchargement doit être sauvegardé dans le format de fichier spécifié ci-haut. Aucun autre format de fichier ne peut être
              demandé. Le système ExpressNet lira les deux premières colonnes du fichier : le numéro de MODÈLE doit être inscrit à la première colonne, et la
              QUANTITÉ à la deuxième colonne. Lorsque vous cliquez sur <b>Télécharger le bon de commande</b> et sélectionnez le fichier à l'endroit où vous
              l'avez sauvegardé, une fenêtre contextuelle s'affichera pour confirmer le nombre de modèles téléchargés, et ajoutera la mention SUCCÈS si aucune
              erreur n'est survenue. Vous y retrouverez aussi les numéros de modèle refusés ou qui nécessitent une correction. Si tous vos numéros de modèles
              sont acceptés, vous pourrez cliquer sur le bouton de <b>téléchargement</b>, ou annuler votre commande. Pour corriger la quantité commandée d'un
              modèle, incluant les corrections visant à respecter le nombre minimal commandé, le message « Le système ne peut traiter la totalité de votre
              commande en ce moment » s'affichera. Vous pourrez toutefois parcourir le site Web pour obtenir d'autres informations. Veuillez contacter votre
              Analyste des commandes et des opérations pour obtenir de l'aide immédiate. Si ce message s'affiche, vous devrez alors corriger la quantité de
              modèle commandée afin qu'elles respectent le nombre minimal.
            </p>
          )
        }
      },
      {
        question: {
          en_US: (
            <p>
              Why am I seeing the following message when I use the <b>Upload PO</b> functionality?  “The system cannot process your order at this time. However,
              you may browse the site for information. Please contact your Order and Operations Analyst if you require immediate assistance.”
            </p>
          ),
          en_CA: (
            <p>
              Why am I seeing the following message when I use the <b>Upload PO</b> functionality?  “The system cannot process your order at this time. However,
              you may browse the site for information. Please contact your Order and Operations Analyst if you require immediate assistance.”
            </p>
          ),
          fr_CA: (
            <p>
              Pourquoi est-ce que je vois le message suivant lorsque j'utilise la fonctionnalité Télécharger le bon de commande? “Le système ne peut pas traiter
              votre commande en ce moment. Cependant, vous pouvez parcourir le site pour obtenir des informations. Veuillez contacter votre analyste des
              commandes et des opérations si vous avez besoin d'une assistance immédiate.”
            </p>
          )
        },
        answer: {
          en_US: (
            <p>
              If any quantities are incorrect, i.e. master carton requirements after you click on <b>Upload PO</b> you will see this message. You will need to
              amend the file to correct the quantities to be in the correct multiple and then try to use the <b>Upload PO</b> again.  If this fails then please
              contact your Order and Operations Analyst for assistance.
            </p>
          ),
          en_CA: (
            <p>
              If any quantities are incorrect, i.e. master carton requirements after you click on <b>Upload PO</b> you will see this message. You will need to
              amend the file to correct the quantities to be in the correct multiple and then try to use the <b>Upload PO</b> again.  If this fails then please
              contact your Order and Operations Analyst for assistance.
            </p>
          ),
          fr_CA: (
            <p>
              Si des quantités sont incorrectes, c.-à-d. Les exigences relatives au carton principal après avoir cliqué sur Télécharger le bon de commande, vous
              verrez ce message. Vous devrez modifier le fichier pour corriger les quantités dans le bon multiple, puis essayer à nouveau d'utiliser
              le Télécharger le bon de commande. En cas d'échec, contactez votre analyste des commandes et des opérations pour obtenir de l'aide.
            </p>
          )
        }
      },
      {
        question: {
          en_US: <p>How do I see net pricing on my order?</p>,
          en_CA: <p>How do I see net pricing on my order?</p>,
          fr_CA: <p>Comment puis-je voir le prix net sur ma commande ?</p>
        },
        answer: {
          en_US: <p>Once you click on Preview Order this will be displayed.</p>,
          en_CA: <p>Once you click on Preview Order this will be displayed.</p>,
          fr_CA: <p>C'est à la page de prévisualisation de la commande que vous pourrez connaître le prix net.</p>
        }
      },
      {
        question: {
          en_US: <p>Where did the items go that I had listed in my Cart? </p>,
          en_CA: <p>Where did the items go that I had listed in my Cart?</p>,
          fr_CA: <p>Où sont passés les articles que j'avais listés dans mon panier?</p>
        },
        answer: {
          en_US: <p>Items will be saved in the Cart only when Preview Order is clicked. It is important to click Preview Order to avoid losing items.</p>,
          en_CA: <p>Items will be saved in the Cart only when Preview Order is clicked. It is important to click Preview Order to avoid losing items.</p>,
          fr_CA: (
            <p>
              Les articles seront enregistrés dans le panier uniquement lorsque l'utilisateur clique sur Prévisualisation de la commande. Il est important de
              cliquer sur Prévisualisation de la commande pour éviter de perdre des éléments.
            </p>
          )
        }
      },
      {
        question: {
          en_US: <p>Is the ‘Your Price’ reflected in the Cart the Customer Contract Price or the Customer Net Price?</p>,
          en_CA: <p>Is the ‘Your Price’ reflected in the Cart the Customer Contract Price or the Customer Net Price?</p>,
          fr_CA: <p>Le «prix» correspond-il au prix du contrat client ou au prix net client?</p>
        },
        answer: {
          en_US: (
            <p>
              ‘Your Price’ indicates standard contract pricing at a model level in the catalog and price book. Please note that additional net pricing may exist
              once you Preview Order, and will be accurately reflected in the ‘Your Price’ field before you process your order.
            </p>
          ),
          en_CA: (
            <p>
              ‘Your Price’ indicates standard contract pricing at a model level in the catalog and price book. Please note that additional net pricing may exist
              once you Preview Order, and will be accurately reflected in the ‘Your Price’ field before you process your order.
            </p>
          ),
          fr_CA: (
            <p>
              «Votre prix» indique le prix standard d’un contrat au niveau du modèle dans le catalogue et le catalogue de prix. Veuillez noter que des prix nets
              supplémentaires peuvent exister une fois que vous avez cliquez sur Prévisualisation de la commande. Le prix sera fidèlement reflété dans le champ
              «Votre prix à l'unité» avant de traiter votre commande.
            </p>
          )
        }
      },
      {
        question: {
          en_US: <p>Can I order my media pack models in “Packs” instead of “Pieces”?</p>,
          en_CA: <p>Can I order my media pack models in “Packs” instead of “Pieces”?</p>,
          fr_CA: <p>Puis-je commander mes modèles de packs médias en lots au lieu d'unités ?</p>
        },
        answer: {
          en_US: (
            <p>
              No. All order quantities must be in “piece” quantities, so “pack” quantities will need to be converted to pieces. Model-specific messaging on the
              Shopping Cart page and Product Detail page will assist you in this conversion.
            </p>
          ),
          en_CA: (
            <p>
              No. All order quantities must be in “piece” quantities, so “pack” quantities will need to be converted to pieces. Model-specific messaging on the
              Shopping Cart page and Product Detail page will assist you in this conversion.
            </p>
          ),
          fr_CA: (
            <p>
              Non. Toutes les quantités commandées doivent être à l'unité; les quantités en lots doivent donc être converties en unités. Des messages
              spécifiques au produit à la ligne panier vous aidera dans cette conversion.
            </p>
          )
        }
      }
    ]
  },
  {
    title: {
      en_US: <span>Order History Inquiries</span>,
      en_CA: <span>Order History Inquiries</span>,
      fr_CA: <span>Questions concernant l'historique des commandes</span>
    },
    questions: [
      {
        question: {
          en_US: <p>What is ‘Awaiting OOA Review’ in Order History?</p>,
          en_CA: <p>What is ‘Awaiting OOA Review’ in Order History?</p>,
          fr_CA: <p>Qu'est-ce que « En attente de révision OOA » signifie ?</p>
        },
        answer: {
          en_US: (
            <p>
              It means that your order needs your Order and Operations Analyst’s attention. You will be contacted by your Order and Operations Analyst shortly
              if there’s an issue with the order and more information is required.
            </p>
          ),
          en_CA: (
            <p>
              It means that your order needs your Order and Operations Analyst’s attention. You will be contacted by your Order and Operations Analyst shortly
              if there’s an issue with the order and more information is required.
            </p>
          ),
          fr_CA: (
            <p>
              Cela signifie que votre commande doit être révisée par l'Analyste des Commandes et des Opérations..Ce dernier vous contactera dans les meilleurs
              délais pour tout problème avec votre commande ou si des informations supplémentaires sont requises.
            </p>
          )
        }
      },
      {
        question: {
          en_US: <p>What is “On Credit Review” in Order History?</p>,
          en_CA: <p>What is “On Credit Review” in Order History?</p>,
          fr_CA: <p>Que signifie « Examen de crédit » dans l'historique des commandes ?</p>
        },
        answer: {
          en_US: (
            <p>
              It means that your order is being reviewed for any credit or accounts receivable issues, your Sony Account Manager can provide more information.
            </p>
          ),
          en_CA: (
            <p>
              It means that your order is being reviewed for any credit or accounts receivable issues, your Sony Account Manager can provide more information.
            </p>
          ),
          fr_CA: (
            <p>
              Cela signifie que votre commande est en cours de vérification suite à un problème de crédit ou de débit, votre Gestionnaire de compte pourra vous
              donner plus d'informations.
            </p>
          )
        }
      },
      {
        question: {
          en_US: <p>Why are changes that were made to an order not appearing in Order Details?</p>,
          en_CA: <p>Why are changes that were made to an order not appearing in Order Details? </p>,
          fr_CA: <p>Pourquoi les modifications apportées à une commande ne sont pas affichées dans les détails de la commande ?</p>
        },
        answer: {
          en_US: (
            <p>
              This could be due to order details remaining in the web browser cache for 30 minutes. If an order is first viewed in ExpressNet and changes made
              after the fact, the user will have to wait 30 minutes to see the updates in Order Details. If 30 minutes has gone by and the changes still aren’t
              visible, please contact your Order and Operations Analyst for assistance.
            </p>
          ),
          en_CA: (
            <p>
              This could be due to order details remaining in the web browser cache for 30 minutes. If an order is first viewed in ExpressNet and changes made
              after the fact, the user will have to wait 30 minutes to see the updates in Order Details. If 30 minutes has gone by and the changes still aren’t
              visible, please contact your Order and Operations Analyst for assistance.
            </p>
          ),
          fr_CA: (
            <p>
              Il s'agit probablement du délai de mise à jour des commandes, qui est d'environ 30 minutes. Pendant ce temps, les modifications de commandes sont
              conservées dans la mémoire cache de votre navigateur internet. Par exemple, lorsqu'un utilisateur visualise une commande qui a été révisée par la
              suite, il doit attendre environ 30 minutes pour que les mises à jour soient prise en compte. Si les 30 minutes se sont écoulées et que les
              modifications ne sont toujours pas affichées, veuillez contacter votre Analyste des commandes et des opérations pour obtenir de l'aide.
            </p>
          )
        }
      }
    ]
  },
  {
    title: {
      en_US: <span>Any Other Inquiries</span>,
      en_CA: <span>Any Other Inquiries</span>,
      fr_CA: <span>Autres questions</span>
    },
    questions: [
      {
        question: {
          en_US: (
            <p>

            </p>
          ),
          en_CA: (
            <p>

            </p>
          ),
          fr_CA: (
            <p>

            </p>
          )
        },
        answer: {
          en_US: (
            <p>
              For login issue, email GlobalServiceDesk.US@am.sony.com or call 877-652-7669 (USA). Include the website, your name, email address,company name and the issue.
            </p>
          ),
          en_CA: (
            <p>
              For login issue, email GlobalServiceDesk.US@am.sony.com or call 888-574-4444 (Canada). Include the website, your name, email address,company name and the issue.
            </p>
          ),
          fr_CA: (
            <p>
              En cas de problème d’ouverture de session, écrivez à GlobalServiceDesk.US@am.sony.com ou composez le 888-574-4444 (Canada). Dans votre message, inscrivez l’adresse du site Web en question, votre nom, votre courriel, le nom de l’entreprise et une description du problème.
            </p>
          )
        }
      }
    ]
  }
];

export const adobeNotice = {
  en_US: (
    <p>
      * This manual requires Adobe Acrobat to view. Click
      <a href="https://get.adobe.com/reader/" target="_blank" rel="noopener noreferrer">
        {' here '}
      </a>
      to download if you are unable to view the document.
    </p>
  ),
  en_CA: (
    <p>
      This manual requires Adobe Acrobat to view. Click
      <a href="https://get.adobe.com/reader/" target="_blank" rel="noopener noreferrer">
        {' here '}
      </a>
      to download if you are unable to view the document.
    </p>
  ),
  fr_CA: (
    <p>
      La consultation de ce manuel nécessite l'installation du logiciel Adobe Acrobat. Veuillez cliquer
      <a href="https://get.adobe.com/reader/" target="_blank" rel="noopener noreferrer">
        {' ici '}
      </a>
      pour télécharger gratuitement le logiciel Adobe Acrobat.
    </p>
  )
};

export const links = {
  en_US: {
    professional: [
      {
        header: 'To learn more about Sony Professional solutions, products and services:',
        links: [
          {
            title: 'Professional Solutions & Products',
            href: 'http://pro.sony.com'
          }
        ]
      },
      {
        header: 'To access PSA Reseller tools requiring logins:',
        links: [
          {
            title: 'Access to programs, claims, SPIFFS, bulletins, statements and more for the Broadcast and Professional Products',
            href: '//www.sony.com/rebates',
            visibility:'www.sony.com/rebates'
          },
          {
            title: 'Dealer Source',
            href: '//www.sony.com/dealersource',
            visibility:'www.sony.com/dealersource'
          }
        ]
      },
      {
        header: 'To learn more about other Sony product:',
        links: [
          {
            title: 'Pro Media',
            href: 'https://pro.sony/ue_US/products/professional-media'
          },
          {
            title: 'Consumer Products',
            href: 'https://www.sony.com/'
          }
        ]
      }
    ],
    consumer: [
      {
        header: '',
        links: [
          {
            title: 'To learn more about Sony Consumer product',
            href: 'https://www.sony.com/'
          },
          {
            title: 'FREE training and activities to help you improve your selling skills and build your product expertise',
            href: 'https://cyberscholar.com/'
          },
          {
            title: 'For product support, manuals, drivers and repair information',
            href: 'https://esupport.sony.com/'
          },
          {
            title: 'To learn more about other Sony Professional Products',
            href: 'https://pro.sony.com'
          }
        ]
      }
    ]
  },
  en_CA: {
    professional: [
      {
        header: 'To learn more about Sony Professional solutions, products and services:',
        links: [
          {
            title: 'Professional Solutions & Products',
            href: 'http://pro.sony/en_CA '
          }
        ]
      },
      {
        header: 'To access PSA Reseller tools requiring logins:',
        links: [
          {
            title: 'Access to programs, claims, SPIFFS, bulletins, statements and more for the Broadcast and Professional Products',
            href: '//www.sony.com/rebates',
            visibility:'www.sony.com/rebates'
          },
          {
            title: 'Dealer Source',
            href: '//www.sony.com/dealersource',
            visibility:'www.sony.com/dealersource'
          }
        ]
      },
      {
        header: 'To learn more about other Sony product',
        links: [
          {
            title: 'Pro Media',
            href: 'https://pro.sony/en_CA/products/professional-media'
          },
          {
            title: 'Consumer Products',
            href: 'https://www.sony.ca/en'
          }
        ]
      }
    ],
    consumer: [
      {
        header: '',
        links: [
          {
            title: 'To learn more about Sony Consumer product',
            href: 'https://www.sony.ca/en'
          },
          {
            title: 'For product support, manuals, drivers and repair information',
            href: 'https://esupport.sony.com/'
          },
          {
            title: 'Free training and activities to help you improve your selling skills and build your product expertise',
            href: 'https://cyberscholar.com/'
          },
          {
            title: 'To learn more about Sony Professional Products',
            href: 'https://pro.sony/en_CA'
          }
        ]
      }
    ]
  },
  fr_CA: {
    professional: [
      {
        header: 'Pour connaître nos produits, services et solutions aux professionnels:',
        links: [
          {
            title: 'Produits et solutions aux professionnels',
            href: 'http://pro.sony/qf_CA'
          }
        ]
      },
      {
        header: 'Accès aux outils des distributeurs PSA requérant une ouverture de session:',
        links: [
          {
            title:
              'Accès aux programmes, réclamations, SPIFFS, bulletins de nouvelles, états de comptes et plus, visant la télédiffusion professionnelle et ses produits ',
            href: '//www.sony.com/rebates',
            visibility:'www.sony.com/rebates'
          },
          {
            title: 'Approvisionnement des distributeurs',
            href: '//www.sony.com/dealersource',
            visibility:'www.sony.com/dealersource'
          }
        ]
      },
      {
        header: 'Pour connaître les autres produits Sony:',
        links: [
          {
            title: 'Pro Media',
            href: 'https://pro.sony/qf_CA/products/professional-media'
          },
          {
            title: 'Produits grand public',
            href: 'https://www.sony.ca/fr'
          }
        ]
      }
    ],
    consumer: [
      {
        header: '',
        links: [
          {
            title: 'Pour connaître les produits grand public de Sony',
            href: 'https://www.sony.ca/fr'
          },
          {
            title: "Accès au service d'aide des produits, aux manuels d'utilisation, aux pilotes et à la réparation des produits",
            href: 'https://esupport.sony.com/'
          },
          {
            title: 'Formation et activités gratuites pour vous aider à améliorer vos compétences en vente et à développer votre expertise produit',
            href: 'https://cyberscholar.com/'
          },
          {
            title: 'Pour en savoir plus sur les produits professionnels Sony',
            href: 'https://pro.sony/qf_CA'
          }
        ]
      }
    ]
  }
};

export const mediaLinks = {
  en_US: [
    {
      header: 'Distributor, Reseller and System Integrator Tools requiring logins',
      links: [
        {
          title: 'Dealer Network Registration',
          href: 'http://www.sony.com/dn'
        },
        {
          title: 'Dealer Network',
          href: 'http://b2b.sony.com/DealerNetwork'
        },
        {
          title: 'EMMA',
          href: 'http://www.sony.com/emma'
        }
      ]
    },
    {
      header: 'Reseller/ End user websites',
      links: [
        {
          title: 'Pro Media Products and services',
          href: 'http://www.sony.com/promedia'
        },
        {
          title: 'Rewarding Recording',
          href: 'http://www.sony.com/RewardingRecording'
        },
        {
          title: 'Digital Master ',
          href: 'http://www.sony.com/DigitalMaster'
        },
        {
          title: 'Cinealta ',
          href: 'http://www.sony.com/cinealta'
        },
        {
          title: 'Data Media',
          href: 'http://www.sony.com/storagemedia'
        },
        {
          title: 'Storage Rewards',
          href: 'http://www.sony.com/storagerewards'
        },
        {
          title: 'Consumer Media',
          href: 'http://www.mediabysony.com/'
        },
        {
          title: 'Consumer Dealer Portal',
          href: 'http://www.sony.com/dealersource'
        }
      ]
    },
    {
      header: 'Consumer Products Links',
      links: [
        {
          title: 'Sony Dealer Network',
          href: 'http://b2b.sony.com/DealerNetwork'
        },
        {
          title: 'Sony - Cyberscholar',
          href: 'http://www.cyberscholar.com/sony'
        },
        {
          title: 'Sony Mobile',
          href: 'http://www.xplodsony.com/'
        },
        {
          title: 'Sony Service Company',
          href: 'http://eservice.sony.com/'
        },
        {
          title: 'Sony eSupport',
          href: 'http://esupport.sony.com/'
        }
      ]
    }
  ],
  en_CA: [
    {
      header: 'Reseller/ End user websites',
      links: [
        {
          title: 'Pro Media Products and services',
          href: 'http://www.sonybiz.ca/'
        },
        {
          title: 'Rewarding Recording',
          href: 'http://www.sony.com/RewardingRecording'
        },
        {
          title: 'Data Media',
          href: 'http://www.sony.com/storagemedia'
        }
      ]
    },
    {
      header: 'Consumer Products Links',
      links: [
        {
          title: 'Sony eSupport',
          href: 'http://esupport.sony.com/'
        }
      ]
    }
  ],
  fr_CA: [
    {
      header: 'Sites Web pour revendeurs/utilisateurs finaux',
      links: [
        {
          title: 'Produits et services Pro Media',
          href: 'http://www.sonybiz.ca/'
        },
        {
          title: 'Programme de récompenses',
          href: 'http://www.sony.com/RewardingRecording'
        },
        {
          title: 'Données média',
          href: 'http://www.sony.com/storagemedia'
        }
      ]
    },
    {
      header: 'Liens pour produits grand public',
      links: [
        {
          title: 'Service d’assistance en ligne de Sony',
          href: 'http://esupport.sony.com/'
        }
      ]
    }
  ]
};

export const manualPDFS = {
  en_US: [
    <a
      href="https://expressnetstg.sony.com/wcsstore/SonySelB2BDirectStorefrontAssetStore/pdf/Sony%20ExpressNet%20System%20User%20Manual.pdf"
      rel="noopener noreferrer"
      target="_blank"
    >
      Click here to view Sony ExpressNet
      <sup>SM</sup>
      System Manual
    </a>,
    <a
      href="https://expressnetstg.sony.com/webapp/wcs/stores/servlet/TrainingModuleView?storeId=10151&langId=-1&catalogId=10013&moduleFileName=ExpressNet%20Introduction_skin.swf&moduleNameKey=Help_Online_Training_OverView_Simulation_Title"
      rel="noopener noreferrer"
      target="_blank"
    >
      Click here to view Sony ExpressNet
      <sup>SM</sup>
      System Simulatio
    </a>
  ],
  en_CA: [
    <a
      href="https://expressnetstg.sony.ca/wcsstore/SonySelB2BDirectStorefrontAssetStore/pdf/Sony%20ExpressNet%20System%20User%20Manual.pdf"
      rel="noopener noreferrer"
      target="_blank"
    >
      Click here to view Sony ExpressNet
      <sup>SM</sup>
      System Manual
    </a>,
    <a
      href="https://expressnetstg.sony.ca/webapp/wcs/stores/servlet/TrainingModuleView?storeId=10152&langId=10000&catalogId=10014&moduleFileName=ExpressNet%20Introduction_skin.swf&moduleNameKey=Help_Online_Training_OverView_Simulation_Title"
      rel="noopener noreferrer"
      target="_blank"
    >
      Click here to view Sony ExpressNet
      <sup>SM</sup>
      System Simulatio
    </a>
  ],
  fr_CA: [
    <a
      href="https://expressnetstg.sony.ca/wcsstore/SonySelB2BDirectStorefrontAssetStore/pdf/Sony%20ExpressNet%20System%20User%20Manual%20FR.pdf"
      rel="noopener noreferrer"
      target="_blank"
    >
      Cliquez ici pour voir le manuel du système ExpressNet
      <sup>MS</sup> de Sony
    </a>
  ]
};

export const simulationsLiks = {
  en_US: [
    <a href="/#" rel="noopener noreferrer" target="_blank">
      Logon and Password
    </a>,
    <a href="/#" rel="noopener noreferrer" target="_blank">
      Product Catalog
    </a>,
    <a href="/#" rel="noopener noreferrer" target="_blank">
      Price Book
    </a>,
    <a href="/#" rel="noopener noreferrer" target="_blank">
      My Account and FAQ's
    </a>,
    <a href="/#" rel="noopener noreferrer" target="_blank">
      Favorites
    </a>,
    <a href="/#" rel="noopener noreferrer" target="_blank">
      Pricing & Inventory Availability
    </a>,
    <a href="/#" rel="noopener noreferrer" target="_blank">
      Order Placement
    </a>,
    <a href="/#" rel="noopener noreferrer" target="_blank">
      Order History & Check Order/Delivery Status
    </a>,
    <a href="/#" rel="noopener noreferrer" target="_blank">
      Price Protection and Trailing Credits
    </a>,
    <a href="/#" rel="noopener noreferrer" target="_blank">
      Credit History and Check Status
    </a>,
    <a href="/#" rel="noopener noreferrer" target="_blank">
      PO Upload
    </a>,
    <a href="/#" rel="noopener noreferrer" target="_blank">
      Return History
    </a>
  ],
  en_CA: [
    <a href="/#" rel="noopener noreferrer" target="_blank">
      Logon and Password
    </a>,
    <a href="/#" rel="noopener noreferrer" target="_blank">
      Product Catalog
    </a>,
    <a href="/#" rel="noopener noreferrer" target="_blank">
      Price Book
    </a>,
    <a href="/#" rel="noopener noreferrer" target="_blank">
      My Account and FAQ's
    </a>,
    <a href="/#" rel="noopener noreferrer" target="_blank">
      Favorites
    </a>,
    <a href="/#" rel="noopener noreferrer" target="_blank">
      Pricing & Inventory Availability
    </a>,
    <a href="/#" rel="noopener noreferrer" target="_blank">
      Order Placement
    </a>,
    <a href="/#" rel="noopener noreferrer" target="_blank">
      Order History & Check Order/Delivery Status
    </a>,
    <a href="/#" rel="noopener noreferrer" target="_blank">
      Price Protection and Trailing Credits
    </a>,
    <a href="/#" rel="noopener noreferrer" target="_blank">
      Credit History and Check Status
    </a>,
    <a href="/#" rel="noopener noreferrer" target="_blank">
      PO Upload
    </a>,
    <a href="/#" rel="noopener noreferrer" target="_blank">
      Return History
    </a>
  ],
  fr_CA: []
};
