import { getDivisionAndDistribution, getHttp, sortOrderList } from './utils';
import { CLEAR_ORDER_HISTORY, CHANGE_FETCHING, ADMIN_LOGOUT, USER_LOGOUT } from './common_actions';
import * as commonActions from './common';
import { respond } from './utils';
import config from '../config';
import moment from 'moment';

const orderApi = config.enAPI + 'order/';
const v1_order_api = config.enAPI + 'order/v1/';
const v2_order_api = config.enAPI + 'order/v2/';

const FETCH_ORDER_SUCCESS = 'FETCH_ORDER_SUCCESS';
const SIMULATE_ORDER_SUCCESS = 'SIMULATE_ORDER_SUCCESS';
const PLACE_ORDER_SUCCESS = 'PLACE_ORDER_SUCCESS';
const SET_BACK_BUTTON_FLAG = 'SET_BACK_BUTTON_FLAG';
const GET_INVOICE_DETAILS = 'GET_INVOICE_DETAILS';
const CLEAR_ORDER_DETAILS = 'CLEAR_ORDER_DETAILS';
const LIST_ORDER_SUCCESS = 'LIST_ORDER_SUCCESS';
const GET_PROMOTION_LIST = 'GET_PROMOTION_LIST';
const GET_PROMOTION_DETAILS = 'GET_PROMOTION_DETAILS';
const CLEAR_PROMO_MODELS = 'CLEAR_PROMO_MODELS';
const CREATE_CREDIT_SUCCESS = 'CREATE_CREDIT_SUCCESS';
const CLEAR_CREDIT = 'CLEAR_CREDIT';

export default (state = {}, action) => {
  switch (action.type) {
    case CLEAR_ORDER_HISTORY:
      return {};
    case FETCH_ORDER_SUCCESS:
    case SIMULATE_ORDER_SUCCESS:
    case PLACE_ORDER_SUCCESS:
    case LIST_ORDER_SUCCESS:
    case SET_BACK_BUTTON_FLAG:
    case GET_INVOICE_DETAILS:
    case GET_PROMOTION_LIST:
    case GET_PROMOTION_DETAILS:
    case CREATE_CREDIT_SUCCESS:
      return { ...state, ...action.data };
    case CLEAR_ORDER_DETAILS:
      return { ...state, order: undefined, invoice: undefined };
    case CLEAR_PROMO_MODELS:
      return { ...state, promotion: undefined };
    case CLEAR_CREDIT:
      return { ...state, credit: undefined };
    case ADMIN_LOGOUT:
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const fetchOrder = (language, orderId, source) => {
  return (dispatch, state) => {
    //adding 2 different sources ,GS will be added for GSAP non-vistex , GV for GSAP vistex
    //adding 2 different versions v1 and v2 to distinguish GPW and GSAP
    let URL = v2_order_api + 'detail/' + language + '/' + orderId;
    if (source === 'GV') {
      URL = v2_order_api + 'creditdetail/' + language + '/' + orderId;
    } else if (source === 'S') {
      URL = v1_order_api + 'detail/' + language + '/' + orderId;
    } else if (source === 'V') {
      URL = v1_order_api + 'creditdetail/' + language + '/' + orderId;
    }
    const http = getHttp(dispatch, state);
    http
      .get(URL)
      .then(response => {
        dispatch({ type: FETCH_ORDER_SUCCESS, data: { order: response.data } });
      })
      .catch(error_obj => {
        dispatch({ type: CHANGE_FETCHING, data: { isFetching: undefined } });
        respond('fetchOrder', error_obj, dispatch);
      });
  };
};

export const simulateOrder = (cart, buyer, payerId, billToId, contract, user, language) => {
  var items = [];
  let input = {
    salesOrganization: contract ? contract.salesOrg : '',
    distributionChannel: contract ? contract.channel : '',
    division: contract ? contract.division : '',
    items: cart.items
      ? cart.items.map(item => {
        return {
          sku: item.sku,
          qty: item.qty,
          grade: item.grade,
          shipMode: item.shipMode,
          meansOfTransport: item.meansOfTransport ? item.meansOfTransport : '',
          RDD:
            item.shipMode === "N000" || item.shipMode === "N001"
              ? moment().add(1, 'days').format('YYYY-MM-DD')
              : item.shipMode === "N002"
                ? moment().add(2, 'days').format('YYYY-MM-DD')
                : item.shipMode === "N003"
                  ? moment().add(3, 'days').format('YYYY-MM-DD')
                  : moment(cart.rdd).format('YYYY-MM-DD')
        };
      })
      : items,
    orderType: 'ZOR',
    partnerInfo: {
      defaultShipToId: typeof (cart.shippingAddress) === "object" ? cart.shippingAddress.value : cart.shippingAddress,
      billToId: billToId,
      payerId: payerId,
      soldToId: buyer.id,
      userPlacingOrder: user.id
    },
    language: language
  };
  return (dispatch, state) => {
    dispatch({ type: CHANGE_FETCHING, data: { isFetching: true } });
    dispatch({
      type: SIMULATE_ORDER_SUCCESS,
      data: { preOrder: undefined, order: undefined }
    });
    const http = getHttp(dispatch, state);
    http
      .post(v2_order_api + 'simulate', input)
      .then(response => {
        dispatch({
          type: SIMULATE_ORDER_SUCCESS,
          data: { preOrder: response.data }
        });
        dispatch({ type: CHANGE_FETCHING, data: { isFetching: undefined } });
      })
      .catch(error_obj => {
        dispatch({ type: CHANGE_FETCHING, data: { isFetching: undefined } });
        respond('simulateOrder', error_obj, dispatch);
      });
  };
};

export const placeOrder = (cart, filters, buyer, payerId, billToId, contract, user, language) => {
  var items = [];
  let input = {
    salesOrganization: contract ? contract.salesOrg : '',
    distributionChannel: contract ? contract.channel : '',
    division: contract ? contract.division : '',
    items: cart.items
      ? cart.items.map(item => {
        return {
          sku: item.sku,
          qty: item.qty,
          grade: item.grade,
          shipMode: item.shipMode,
          meansOfTransport: item.meansOfTransport ? item.meansOfTransport : '',
          //shipToId: cart.shippingAddress.value,
          RDD:
            item.shipMode === "N000" || item.shipMode === "N001"
              ? moment().add(1, 'days').format('YYYY-MM-DD')
              : item.shipMode === "N002"
                ? moment().add(2, 'days').format('YYYY-MM-DD')
                : item.shipMode === "N003"
                  ? moment().add(3, 'days').format('YYYY-MM-DD')
                  : moment(cart.rdd).format('YYYY-MM-DD')
        };
      })
      : items,
    orderType: 'ZOR',
    PO: filters.PO ? filters.PO.trim() : filters.PO,
    partnerInfo: {
      defaultShipToId: cart.shippingAddress.value,
      billToId: billToId,
      payerId: payerId,
      soldToId: buyer.id,
      userPlacingOrder: user.id
    },
    language: language,
    shipComments: filters.shipComments ? filters.shipComments.trim() : filters.shipComments
  };
  return (dispatch, state) => {
    dispatch({ type: CHANGE_FETCHING, data: { isFetching: true } });
    dispatch({
      type: PLACE_ORDER_SUCCESS,
      data: { preOrder: undefined, order: undefined }
    });

    const http = getHttp(dispatch, state);
    http
      .post(v2_order_api + 'place', input)
      .then(response => {
        dispatch({ type: PLACE_ORDER_SUCCESS, data: { order: response.data } });
        dispatch({ type: CHANGE_FETCHING, data: { isFetching: undefined } });
      })
      .catch(error_obj => {
        dispatch({ type: CHANGE_FETCHING, data: { isFetching: undefined } });
        respond('placeOrder', error_obj, dispatch);
      });
  };
};

export const listOrders = (filter, buyer, contract, orderType, gsapOrderType, reasonType, gsapReasonType, contactIdMap) => {
  var tenDigitOrderNumber = filter.orderNumber ? filter.orderNumber.trim() : filter.orderNumber;
  if (filter.orderNumber && tenDigitOrderNumber && tenDigitOrderNumber.length !== 10) {
    tenDigitOrderNumber = '' + tenDigitOrderNumber;
    while (tenDigitOrderNumber.length < 10) {
      tenDigitOrderNumber = '0' + tenDigitOrderNumber;
    }
  }

  let input = {
    sellerOrgId: buyer.id,
    orderNumber: tenDigitOrderNumber ? tenDigitOrderNumber.toUpperCase(): tenDigitOrderNumber,
    orderDateFrom: filter.orderDateFrom ? filter.orderDateFrom.format("YYYY-MM-DD"): undefined,
    orderDateTo: filter.orderDateTo ? filter.orderDateTo.format("YYYY-MM-DD") : undefined,
    invoiceNumber: filter.invoiceNumber ? filter.invoiceNumber.trim().toUpperCase() : filter.invoiceNumber,
    invoiceDateFrom: filter.invoiceDateFrom ? filter.invoiceDateFrom.format("YYYY-MM-DD") : undefined,
    invoiceDateTo: filter.invoiceDateTo ? filter.invoiceDateTo.format("YYYY-MM-DD") : undefined,
    PO: filter.PO ? filter.PO.trim() : filter.PO,
    sku: filter.sku ? (filter.sku.trim().endsWith('.B') ?
      filter.sku.trim().substring(0, filter.sku.length - 2).toUpperCase() : filter.sku.trim().toUpperCase()) : filter.sku,
    headerStatus: filter.headerStatus,
    lineItemStatus: filter.lineItemStatus,
    orderStatus: filter.orderStatus === false ? '' : filter.orderStatus,
    enteredBy: filter.enteredBy ,
    maxRecords: filter.maxRecords,
    creditHold: filter.creditHold === false ? '' : filter.creditHold,
    sortBy: filter.sortBy ? filter.sortBy : '02',
    sortDirection: filter.sortDirection ? filter.sortDirection : '2',
    orderType: gsapOrderType,
    reasonType: gsapReasonType,
    salesOrganization: contract ? contract.salesOrg : '',
    distributionChannel: contract ? contract.channel : '',
    division: contract ? contract.division : ''
  };

  //input to GPW for A stock
  let sapInputForAStock = {
    ...input,
    orderType: orderType,
    reasonType: reasonType,
    distributionChannel:getDivisionAndDistribution(contract),
    division: getDivisionAndDistribution(contract),
    enteredBy: contactIdMap[filter.enteredBy]
  }
  //input to GSAP for A stock
  let gsapInputForAStock = {
    ...input,
    ...(input.sku) && { grade: 'A' }
  }
  // input to GPW for B stock 
  let sapInputForBStock = {
    ...input,
    orderType: orderType,
    reasonType: reasonType,
    sku: input.sku ? input.sku + '.B': input.sku,
    distributionChannel:getDivisionAndDistribution(contract),
    division: getDivisionAndDistribution(contract),
    enteredBy: contactIdMap[filter.enteredBy]

  }
  // input to GSAP for B stock
  let gsapInputForBStock = {
    ...input,
    ...(input.sku) && { grade: 'B' }
  }

  if (orderType.find(type => type === 'ZCR')) {
    return (dispatch, state) => {
      dispatch({ type: CHANGE_FETCHING, data: { isFetching: true } });
      const http = getHttp(dispatch, state);
      let requestArray = [];
      let inputSap = filter.sku && filter.AStock ? sapInputForAStock : sapInputForBStock;
      let inputGsap = filter.sku && filter.AStock ? gsapInputForAStock : gsapInputForBStock;

      //Call to GPW when either A stock or B stock is checked
      requestArray.push(http.post(v1_order_api + 'list', inputSap))
      requestArray.push(http.post(v1_order_api + 'credits', inputSap))
      //Call to GSAP when either A stock or B stock is checked.
      requestArray.push(http.post(v2_order_api + 'list', inputGsap))
      requestArray.push(http.post(v2_order_api + 'credits', inputGsap))
      if(filter.sku && filter.AStock && filter.BStock){
        //Call to GPW when both A stock and B stock are checked
        requestArray.push(http.post(v1_order_api + 'list', sapInputForBStock))
        requestArray.push(http.post(v1_order_api + 'credits', sapInputForBStock))
        //Call to GSAP when both A stock and B stock are checked
        requestArray.push(http.post(v2_order_api + 'list', gsapInputForBStock))
        requestArray.push(http.post(v2_order_api + 'credits', gsapInputForBStock))
      }

      http
        .all(requestArray)
        .then(
          http.spread(function (...response) {
            let listData = [];
            response.forEach(element => {
              listData = [...listData, ...element.data]
            });

      //Logic to filter out suppressed non-vistex credits and duplicate order based on source and orderNumber
      //bcz for A stock and B stock will come in one line now and order Number will be same when we place the order
      // for that particular material so we are filtering out orderNumbers.
            let filteredList = listData.filter(
              (data, index) =>
                data.suppress_indicator !== 'X' &&
                index ===
                  listData.findIndex(
                    (item) => item.orderNumber === data.orderNumber && item.source === data.source
                  )
            );
            let sortedOrderList = sortOrderList(filteredList)
            sortedOrderList = sortedOrderList.slice(0, input.maxRecords);
            dispatch({
              type: LIST_ORDER_SUCCESS,
              data: { list: sortedOrderList, maxRecords: input.maxRecords }
            });
            dispatch({
              type: CHANGE_FETCHING,
              data: { isFetching: undefined }
            });
          })
        )
        .catch(error_obj => {
          dispatch({ type: CHANGE_FETCHING, data: { isFetching: undefined } });
          respond('listOrders', error_obj, dispatch);
        });
    };
  } else {
    return (dispatch, state) => {
      dispatch({ type: CHANGE_FETCHING, data: { isFetching: true } });
      const http = getHttp(dispatch, state);
      let requestArray = [];
      let inputSap = filter.sku && filter.AStock ? sapInputForAStock : sapInputForBStock;
      let inputGsap = filter.sku && filter.AStock ? gsapInputForAStock : gsapInputForBStock;
      //Call to GPW when either A stock or B stock is checked
      requestArray.push(http.post(v1_order_api + 'list', inputSap));
      //Call to GSAP when either A stock or B stock is checked
      requestArray.push(http.post(v2_order_api + 'list', inputGsap));
      if(filter.sku && filter.AStock && filter.BStock){
      //Call to GPW when both A stock and B stock are checked
      requestArray.push(http.post(v1_order_api + 'list', sapInputForBStock));
      //Call to GSAP when both A stock and B stock are checked
      requestArray.push(http.post(v2_order_api + 'list', gsapInputForBStock));
      }

      http
        .all(requestArray)
        .then(http.spread(function (...response) {
          let completeList = [];
          response.forEach(element => {
            completeList = [...completeList, ...element.data]
          });
          //Logic to filter out duplicate order based on source and orderNumber
          let filteredList = completeList.filter(
            (data, index) =>
              index ===
              completeList.findIndex(
                  (item) => item.orderNumber === data.orderNumber && item.source === data.source
                )
          );
          let sortedOrderList = sortOrderList(filteredList)
          sortedOrderList = sortedOrderList.slice(0, input.maxRecords);
          dispatch({ type: LIST_ORDER_SUCCESS, data: { list: sortedOrderList } });
          dispatch({ type: CHANGE_FETCHING, data: { isFetching: undefined } });
        })
        )
        .catch(error_obj => {
          dispatch({ type: CHANGE_FETCHING, data: { isFetching: undefined } });
          respond('listOrders', error_obj, dispatch);
        });
    };
  }
};

export const setBackButtonFlag = flag => {
  return dispatch => {
    dispatch({ type: SET_BACK_BUTTON_FLAG, data: { backButtonFlag: flag } });
    dispatch({ type: CLEAR_ORDER_DETAILS });
  };
};

export const clearOrderHistory = clearFormFlag => {
  return dispatch => {
    dispatch({ type: CLEAR_ORDER_HISTORY });
    if (clearFormFlag) {
      dispatch(commonActions.resetForm('orderSearchFilter'));
    }
  };
};

export const clearOrderDetails = () => {
  return dispatch => {
    dispatch({ type: CLEAR_ORDER_DETAILS });
  };
};

export const getInvoiceDetails = (orderLang, orderNumber, invoiceNumber, source) => {
  let URL = source === 'S'? 
  v1_order_api + 'invoicepdf/' + orderLang + '/' + source + '/' + orderNumber + '/' + invoiceNumber :
  v2_order_api + 'invoicepdf/' + orderLang + '/' + source + '/' + orderNumber + '/' + invoiceNumber
  return (dispatch, state) => {
    dispatch({ type: GET_INVOICE_DETAILS, data: { invoice: undefined } });

    const http = getHttp(dispatch, state);
    http
      .get(URL)
      .then(response => {
        dispatch({
          type: GET_INVOICE_DETAILS,
          data: { invoice: { file: response.data, number: invoiceNumber } }
        });
      })
      .catch(error_obj => {
        dispatch({ type: CHANGE_FETCHING, data: { isFetching: undefined } });
        respond('getInvoiceDetails', error_obj, dispatch);
      });
  };
};

export const getPromotionList = (salesOrg, channel, division, language) => {
  return (dispatch, state) => {
    const http = getHttp(dispatch, state);
    dispatch({ type: CHANGE_FETCHING, data: { isFetching: true } });
    dispatch({
      type: GET_PROMOTION_LIST,
      data: {
        ZAMList: undefined,
        ZPMList: undefined,
        promotion: undefined,
        credit: undefined
      }
    });
    http
      .all([
        http.get(orderApi + 'promolist/' + salesOrg + '/' + channel + '/' + division + '/ZAM/' + language),
        http.get(orderApi + 'promolist/' + salesOrg + '/' + channel + '/' + division + '/ZPM/' + language)
      ])
      .then(
        http.spread(function (ZAMList, ZPMList) {
          ZAMList = ZAMList.data && ZAMList.data.length > 0 ? ZAMList.data : undefined;
          ZPMList = ZPMList.data && ZPMList.data.length > 0 ? ZPMList.data : undefined;

          dispatch({
            type: GET_PROMOTION_LIST,
            data: { ZAMList: ZAMList, ZPMList: ZPMList }
          });
          dispatch({ type: CHANGE_FETCHING, data: { isFetching: undefined } });
        })
      )
      .catch(error_obj => {
        dispatch({ type: CHANGE_FETCHING, data: { isFetching: undefined } });
        respond('getPromotionList', error_obj, dispatch);
      });
  };
};

export const getPromotion = (promoNumber, language) => {
  return (dispatch, state) => {
    dispatch({ type: CHANGE_FETCHING, data: { isFetching: true } });
    dispatch({
      type: GET_PROMOTION_DETAILS,
      data: { promotion: undefined, credit: undefined }
    });

    const http = getHttp(dispatch, state);
    http
      .get(orderApi + 'promodetails/' + promoNumber + '/' + language)
      .then(response => {
        dispatch({
          type: GET_PROMOTION_DETAILS,
          data: { promotion: response.data }
        });
        dispatch({ type: CHANGE_FETCHING, data: { isFetching: undefined } });
      })
      .catch(error_obj => {
        dispatch({ type: CHANGE_FETCHING, data: { isFetching: undefined } });
        respond('getPromotion', error_obj, dispatch);
      });
  };
};

export const submitCredit = (filter, buyer, contract, promotion, user, language) => {
  var items = [];
  let input = {
    sellerOrgId: buyer ? buyer.id : '',
    buyerId: buyer ? buyer.id : '',
    salesOrganization: contract ? contract.salesOrg : '',
    distributionChannel: contract ? contract.channel : '',
    division: contract ? contract.division : '',
    orderType: promotion[0].reasonCode === 'ZPM' ? 'ZPAC' : 'ZTAC',
    promotionCode: promotion[0].promoNumber,
    promotionReasonCode: promotion[0].reasonCode,
    debitReferenceNumber: filter.debitReferenceNumber ? filter.debitReferenceNumber.trim() : filter.debitReferenceNumber,
    userPlacingOrder: user.id,
    comments: filter.comments,
    promotionStartDate: promotion[0].promoStartDate,
    promotionEndDate: promotion[0].promoEndDate,
    lastSubmissionDate: promotion[0].promoLastSubmitDate,
    items: filter.items
      ? filter.items.map(item => {
        return {
          sku: item.model,
          qty: item.qty,
          grade: item.grade,
          purchaseDate: item.purchaseDate,
          invoiceNumber: item.invoiceNumber ? item.invoiceNumber.trim() : item.invoiceNumber,
          serialNumber: item.serialNumber ? item.serialNumber.trim() : item.serialNumber,
          creditAmount: item.creditAmount ? item.creditAmount : item.originalPrice - item.newDealerPrice
        };
      })
      : items
  };

  return (dispatch, state) => {
    dispatch({ type: CHANGE_FETCHING, data: { isFetching: true } });
    dispatch({ type: CREATE_CREDIT_SUCCESS, data: { credit: undefined } });
    const http = getHttp(dispatch, state);
    http
      .post(v2_order_api + 'creditcreate/', input)
      .then(response => {
        dispatch({
          type: CREATE_CREDIT_SUCCESS,
          data: { credit: response.data }
        });
        dispatch({ type: CHANGE_FETCHING, data: { isFetching: undefined } });
      })
      .catch(error_obj => {
        dispatch({ type: CHANGE_FETCHING, data: { isFetching: undefined } });
        dispatch({
          type: GET_PROMOTION_DETAILS,
          data: { promotion: undefined, credit: undefined }
        });
        respond('submitCredit', error_obj, dispatch);
      });
  };
};

export const clearPromoModels = () => {
  return dispatch => {
    dispatch({ type: GET_PROMOTION_DETAILS, data: { promotion: undefined } });
  };
};

export const clearCredit = () => {
  return dispatch => {
    dispatch({ type: CLEAR_CREDIT, data: { credit: undefined } });
  };
};
