import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Loading from '../Loading';
import moment from 'moment';
import * as utilsActions from '../../modules/utils';
import { Field, reduxForm } from 'redux-form';
import { renderInput } from '../utils/renderUtils';
import * as orderActions from '../../modules/order';
import * as errorActions from '../../modules/error';
import * as commonActions from '../../modules/common';
import * as cartActions from '../../modules/cart';
import Translate from '../dictionary/Translator';
import errorDictionary from '../dictionary/error';
import  ship_modes_mapping from './dictionary/gsap_ship_modes.json'
import staticMapping from './dictionary/static';
import { translate } from '../dictionary/Translator';
import ui from './dictionary/ui';
import pDetailUi from '../product/dictionary/ui';
import DocumentTitle from 'react-document-title';
import EmailProvider from '../Help/EmailProvider';
const PO_VALID_LENGTH = staticMapping['PO_LENGTH'];

class OrderPreview extends React.Component {
  static getDerivedStateFromProps(props, state) {
    if (props.language !== state.language)
      return {
        language: props.language,
        langId: translate({ word: ui['LANG_ID'], language: props.language })
      };

    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      language: this.props.language,
      langId: translate({ word: ui['LANG_ID'], language: this.props.language })
    };
  }

  submit = filters => {
     //Update each line item with sku, qty and grade to send to GSAP
    const updatedCart = utilsActions.updateCartItemsWithGrade(this.props.cart.values);
    this.props.placeOrder(
      updatedCart,
      filters,
      this.props.buyer,
      this.props.payerId,
      this.props.billToId,
      this.props.contract,
      this.props.user,
      this.state.langId
    );
  };

  componentDidUpdate() {
    if (this.props.order && this.props.order.orderId) {
      this.props.resetForm('cart');
      this.props.saveCart([], this.props.user, this.props.contract, this.props.language);
    }
  }

  render() {
    const { preOrder, order, error } = this.props;  
    return (
      <DocumentTitle title={`${translate({ word: ui['PREVIEW_ORDER'], language: this.props.language })} | Sony ExpressNet`}>
        <div style={{ paddingRight: '3%', paddingLeft: '3%' }}>
          {!error && !preOrder && !order && <Loading />}
          {error && (
            <div className="text-danger">
              <h1 style={{ fontSize: '1.83rem', fontWeight: '450' }}>
                <Translate word={errorDictionary[error.errorPath]} displayMessage={error.code && JSON.parse(error.code).message} />
              </h1>
            </div>
          )}
          {!error && preOrder && (
            <div>
              <PreviewForm onSubmit={this.submit} {...this.props} />
            </div>
          )}
          {!error && order && order.orderId && (
            <div>
              <ShowOrderConfirmation {...this.props} />
            </div>
          )}
        </div>
      </DocumentTitle>
    );
  }
}

let ShowOrderConfirmation = props => {
  const { order } = props;
  return (
    <div style={{ paddingBottom: '15px' }}>
      <h1 style={{ fontSize: '2.2rem', fontWeight: '600' }}>
        <Translate word={ui['ORDER_CONFIRMATION']} />
      </h1>
      <div>
        <Translate word={ui['ORDER_THANK_YOU_MESSAGE_1']} />
      </div>
      <div>
        <Translate word={ui['ORDER_THANK_YOU_MESSAGE_2']} />
        <Link to={{ pathname: '/orderview/GS/' + order.orderId }}>{order.orderId}</Link>
      </div>
      <div>
        <Translate word={ui['ORDER_THANK_YOU_MESSAGE_3']} />
      </div>
    </div>
  );
};

let PreviewForm = props => {
  const { preOrder, user, handleSubmit, isFetching, cart, language, priceBook } = props;

  let confirmationRequired = false;
  let substutionRequired = false;
  let cartItem = cart && cart.values && utilsActions.updateCartItemsWithGrade(cart.values); 

  return (
    <div>
      <div style={{ paddingBottom: '15px' }}>
        <h1 style={{ fontSize: '2.2rem', fontWeight: '600' }}>
          <Translate word={ui['ORDER_VERIFY']} />
        </h1>
      </div>
      <form
        id="previewForm"
        onSubmit={handleSubmit}
        onKeyPress={e => {
          if (e.key === 'Enter') e.preventDefault();
        }}
      >
        <div className="row" style={{ paddingBottom: '10px' }}>
          <div className="col-sm-2">
            <label htmlFor="PO">
              <strong>
                <Translate word={ui['PO_NUMBER']} />
                <span className="text-danger">*</span>
              </strong>
            </label>
          </div>
          <div className="col-sm-2">
            <Field id="PO" name="PO" isClearable={true} style={{ width: '180px', height: '35px' }} component={renderInput} maxLength={20} />
          </div>
        </div>
        <h2 style={{ fontSize: '1.8rem', fontWeight: '450' }}>
          <Translate word={ui['ORDER_SUMMARY']} />
        </h2>
        <hr size="6" />
        <div className="row">
          <div className="col-sm-4">
            <div className="table-responsive">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <strong>
                        <Translate word={ui['ORDER_SUB_TOTAL']} />
                      </strong>
                    </td>
                    <td className="col-sm-2 text-right">{'$' + preOrder.netValue.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        <Translate word={ui['ORDER_ESTIMATED_SHIPPING']} />
                      </strong>
                    </td>
                    <td className="col-sm-2 text-right">{'$' + preOrder.netFreight.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        <Translate word={ui['ORDER_ESTIMATED_TAX']} />
                      </strong>
                    </td>
                    <td className="col-sm-2 text-right">{'$' + preOrder.netTax.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td>
                      <hr size="4" />
                    </td>
                    <td>
                      <hr size="4" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        <Translate word={ui['ORDER_ESTIMATED_TOTAL']} />
                      </strong>
                    </td>
                    <td className="col-sm-2 text-right">{'$' + (preOrder.netValue + preOrder.netTax + preOrder.netFreight).toFixed(2)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="table-responsive">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <strong>
                        <Translate word={ui['ORDER_PAYMENT_TERM']} />
                      </strong>
                    </td>
                    <td className="col-sm-2">{preOrder.descriptions ? preOrder.descriptions.paymentTermsDesc : ''}</td>
                  </tr>
                  <tr>
                    <td style={{ verticalAlign: 'top' }}>
                      <strong>
                        <Translate word={ui['ORDER_SHIPPING_ADDRESS']} />
                      </strong>
                    </td>
                    <td className="col-sm-8">
                      {preOrder.shipToAddress && preOrder.shipToAddress[0] ? preOrder.shipToAddress[0].name : ''}
                      <br />
                      {preOrder.shipToAddress[0] ? preOrder.shipToAddress[0].line1 : ''}
                      <br />
                      {preOrder.shipToAddress[0] ? preOrder.shipToAddress[0].city + ' ' : ''}
                      {preOrder.shipToAddress[0] ? preOrder.shipToAddress[0].state + ' ' : ''}
                      {preOrder.shipToAddress[0] ? preOrder.shipToAddress[0].postalCode : ''}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <hr size="2" />
        {preOrder.items &&
          preOrder.items.map((item, index) => {
            // let isBStock = item.outSKU.includes('.B', item.outSKU.length - 2);
            let isBStock = item.grade === 'B' ? true : false;

            let shortQtyIssue = item.isShortQtyIssue;

            if (isBStock && shortQtyIssue) {
              confirmationRequired = true;
            }
            if (item.outSKU !== item.requestedSKU) {
              substutionRequired = true;
            }
            return confirmationRequired || substutionRequired;
          })}

        {(confirmationRequired || substutionRequired) && <MandatoryConfirmation items={preOrder.items} />}
        <h2 style={{ fontSize: '1.8rem', fontWeight: '450' }}>
          <Translate word={ui['ORDER_LINE_ITEM_SUMMARY']} />
        </h2>
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>
                  <Translate word={ui['MODEL']} />
                </th>
                <th>
                  <Translate word={ui['GRADE_INDICATOR']} />
                </th>
                <th>
                  <Translate word={ui['MODEL_DESCRIPTION']} />
                </th>
                <th>
                  <Translate word={ui['ITEM_COST']} />
                </th>
                <th>
                  <Translate word={ui['ITEM_SHIPPING']} />
                </th>
                <th>
                  <Translate word={ui['ITEM_SCHEDULE']} />
                </th>
              </tr>
            </thead>
            <tbody>
              {preOrder.items.map((item, index) => {
                let isBStock = item.grade === 'B' ? true : false;
                // let item_categories = ['ZTS', 'ZTS5', 'ZTS4'],
                // item_plants = ['0444', '0809', '0777', 'DS01'],
                // new item_categories and item_plants.
                let item_categories = ['ZSDD','ZCTO'],
                item_plants = ['US04', 'US10'],
                plant_category_check = item_categories.includes(item.item_category) || item_plants.includes(item.item_plant),
                scheduleLineMapping = item.nonZeroConfQtySchLines && item.nonZeroConfQtySchLines.length > 0 ? item.nonZeroConfQtySchLines : item.schedule;
                return (
                  <tr key={index}>
                    <td>
                      {item.outSKU}{' '}
                      <p style={{ color: 'red', fontSize: '1.3rem' }}>
                        {priceBook.find(x => x.sku === item.requestedSKU && (x.discontinuedInd === 'Z3' || x.discontinuedInd === 'Z4'))
                          ? translate({ word: pDetailUi['PRODUCT_DISCONT_MSG'], language })
                          : null}
                      </p>
                    </td>
                    <td>{item.grade}</td>
                    <td>{item.desc}</td>
                    <td>
                      <table className="table table-bordered table-condensed table-transparent">
                        <tbody>
                          <tr>
                            <td>
                              <Translate word={ui['ITEM_UNIT_PRICE']} />
                            </td>
                            <td>{'$' + item.price[0].unitPrice.toFixed(2)}</td>
                          </tr>
                          {
                            !isBStock ?  (
                              <tr>
                            <td>
                              <Translate word={ui['ITEM_A_STOCK_QUANTITY']} />
                            </td>
                            <td>{item.requestedQuantity}</td>
                          </tr>
                            ) : (
                              <tr>
                            <td>
                              <Translate word={ui['ITEM_B_STOCK_QUANTITY']} />
                            </td>
                            <td>{item.requestedQuantity}</td>
                          </tr>
                            )
                          }
                          <tr>
                            <td>
                              <Translate word={ui['ITEM_SUB_TOTAL']} />
                            </td>
                            <td>{'$' + item.price[0].netValue.toFixed(2)}</td>
                          </tr>
                          <tr>
                            <td>
                              <Translate word={ui['ITEM_TAX']} />
                            </td>
                            <td>{'$' + item.price[0].tax.toFixed(2)}</td>
                          </tr>
                          <tr>
                            <td>
                              <Translate word={ui['ITEM_FREIGHT']} />
                            </td>
                            <td>{'$' + item.price[0].freight.toFixed(2)}</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td>
                      <table className="table table-bordered table-condensed table-transparent">
                        <tbody>
                          <tr>
                            <td>
                              <Translate word={ui['ITEM_RDD']} />
                            </td>
                            <td>{item.schedule[0] ? (item.schedule[0].RDD ? moment(item.schedule[0].RDD).format('MM/DD/YYYY') : '') : ''}</td>
                          </tr>
                          <tr>
                            <td>
                              <Translate word={ui['ITEM_SHIPPING_MODE']} />
                            </td>
                            <td>
                              {cartItem 
                                ?  (cartItem.items[index] && cartItem.items[index].shipMode)
                                  ? translate({
                                      word: ship_modes_mapping[cartItem.items[index].shipMode],
                                      language
                                    })
                                  : translate({
                                      word: ship_modes_mapping[''],
                                      language
                                    })
                                : ''}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td>
                      {scheduleLineMapping.length > 0 ? (
                        <table className="table table-bordered table-condensed table-transparent">
                          <tbody>
                            <tr>
                              <td>
                                <Translate word={ui['ITEM_SCHDULED_QUANTITY']} />
                              </td>
                              <td>
                                <Translate word={ui['ITEM_MAD']} />
                              </td>
                              <td>
                                <Translate word={ui['ITEM_ESD']} />
                              </td>
                              <td>
                                <Translate word={ui['ITEM_EDD']} />
                              </td>
                            </tr>
                            {scheduleLineMapping.map((schItem, i) => (
                              <tr key={i}>
                                <td>{schItem.confirmedQuantity}</td>
                                <td>
                                  {plant_category_check || schItem.confirmedQuantity === 0
                                    ? 'TBD'
                                    : schItem.materialAvailableDate
                                    ? moment(schItem.materialAvailableDate).format('MM/DD/YYYY')
                                    : ''}
                                </td>
                                <td>
                                  {plant_category_check || schItem.confirmedQuantity === 0
                                    ? 'TBD'
                                    : schItem.shippingDate
                                    ? moment(schItem.shippingDate).format('MM/DD/YYYY')
                                    : ''}
                                </td>
                                <td>
                                  {plant_category_check || schItem.confirmedQuantity === 0
                                    ? 'TBD'
                                    : schItem.estimatedDeliveryDate
                                    ? moment(schItem.estimatedDeliveryDate).format('MM/DD/YYYY')
                                    : ''}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        ''
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <hr size="8" />
        <div className="row" style={{ paddingBottom: '10px', textAlign: 'center' }}>
          <small>
            <strong>
              <Translate word={ui['ORDER_DISCLAIMER']} />
            </strong>
            <Translate word={ui['ORDER_PRICE_CHANGE_MESSAGE']} />
          </small>
        </div>
        <div>
          {user &&
            !(
              user.role === 'XBU' ||
              user.role === 'XVO' ||
              user.role === 'XAO' ||
              user.role === 'ACTREP' ||
              user.role === 'ACTREPMGR' ||
              user.role === 'SLSANL'
            ) && (
              <div className="row" style={{ paddingBottom: '20px', float: 'right' }}>
                <Button bsStyle="primary" className="btn-margin" type="submit" disabled={isFetching}>
                  <Translate word={ui['ORDER_PROCESS_BUTTON']} />
                </Button>
              </div>
            )}
          {user &&
            (user.role === 'XBU' ||
              user.role === 'XVO' ||
              user.role === 'XAO' ||
              user.role === 'ACTREP' ||
              user.role === 'ACTREPMGR' ||
              user.role === 'SLSANL') && (
              <div className="row" style={{ paddingBottom: '10px', textAlign: 'center' }}>
                <small>
                  <Translate word={ui['ORDER_SECURITY_DISCLAIMER_MESSAGE']} />
                  <a href="mailto:digwiprocustomer@am.sony.com">digwiprocustomer@am.sony.com</a>
                </small>
              </div>
            )}
        </div>
      </form>
      <div className="row" style={{ paddingBottom: '20px' }} />
      <div style={{ paddingBottom: '20px', fontSize: '1.3rem' }}>
        <b>
          <Translate word={ui['SONY_PRODUCT_AVAILABILITY']} />
        </b>
        :
        <br />
        <Translate word={ui['SONY_PRODUCT_AVAILABILITY_MSG_1']} />
        {/* <a href='mailto:ETARequests@am.sony.com'>ETARequests@am.sony.com</a> */}
        <EmailProvider />
        <Translate word={ui['SONY_PRODUCT_AVAILABILITY_MSG_2']} />
      </div>
      <div style={{ paddingBottom: '20px', fontSize: '1.3rem' }}>
        <b>
          <Translate word={ui['NON_SONY_PRODUCT_AVAILABILITY']} />
        </b>
        :
        <br />
        <Translate word={ui['NON_SONY_PRODUCT_AVAILABILITY_MSG']} />
      </div>
      <div style={{ paddingBottom: '20px', fontSize: '1.3rem' }}>
        <b>
          <Translate word={ui['SHIPPING_COST']} />
        </b>
        :
        <br />
        <Translate word={ui['SHIPPING_COST_MSG']} />
      </div>
    </div>
  );
};

let MandatoryConfirmation = props => {
  const { items } = props;
  return (
    <div>
      <h4>
        <span className="text-danger">
          <Translate word={ui['ORDER_MANDATORY_TITLE']} />
        </span>
      </h4>
      <div className="table-responsive">
        <table className="table table-bordered table-condensed table-transparent">
          <thead>
            <tr>
              <th>
                <Translate word={ui['ORDER_REQUESTED']} />
              </th>
              <th />
              <th />      
              <th />
              <th>
                <Translate word={ui['ORDER_AVAILABLE']} />
              </th>
              <th />
              <th />
            </tr>
            <tr>
              <th>
                <Translate word={ui['MODEL']} /> <br />
                <Translate word={ui['MODEL_DESCRIPTION']} />
              </th>
              <th><Translate word={ui['GRADE_INDICATOR']} /></th>
              <th>
                <Translate word={ui['ITEM_PRICE']} />
              </th>
              <th>
                <Translate word={ui['ITEM_STOCK_QUANTITY']} />
              </th>
              <th>
                <Translate word={ui['MODEL']} />
                <br />
                <Translate word={ui['MODEL_DESCRIPTION']} />
              </th>
              <th>
                <Translate word={ui['ITEM_PRICE']} />
              </th>
              <th>
                <Translate word={ui['ITEM_SCHEDULE']} />
              </th>
              <th>
                <Translate word={ui['COMMENT']} />
              </th>
            </tr>
          </thead>
          <tbody>
            {items &&
              items.map((item, index) => {
                // let item_categories = ['ZTS', 'ZTS5', 'ZTS4'],
                // item_plants = ['0444', '0809', '0777', 'DS01']
                
                //New Values on Plant and categories for GSAP
                let item_categories = ['ZSDD','ZCTO'],
                item_plants = ['US04', 'US10'],
                  plant_category_check = item_categories.includes(item.item_category) || item_plants.includes(item.item_plant);

                  let confirmationRequired = false;
                  let substutionRequired = false;
                  let isBStock = item.grade === 'B' ? true : false;

                if (isBStock && item.isShortQtyIssue) {
                  confirmationRequired = true;
                }
                if (item.outSKU !== item.requestedSKU) {
                  substutionRequired = true;
                }
                let confirmation1 = substutionRequired;
                let confirmation2 = confirmationRequired;
                let confirmation3 = confirmationRequired && substutionRequired;
                let scheduleLineMapping = item.nonZeroConfQtySchLines && item.nonZeroConfQtySchLines.length > 0 ? item.nonZeroConfQtySchLines : item.schedule;
                return (
                  (confirmation1 || confirmation2) && (
                    <tr key={index}>
                      <td>
                        {item.requestedSKU} <br /> {item.desc}
                      </td>
                      <td>{item.grade}</td>
                      <td>{'$' + item.price[0].unitPrice.toFixed(2)}</td>
                      <td>{item.requestedQuantity}</td>
                      {confirmation1 ? (
                        <td>
                          <span className="text-danger">
                            {item.outSKU} <br /> {item.desc}
                          </span>
                        </td>
                      ) : (
                        <td>
                          {item.outSKU} <br /> {item.desc}
                        </td>
                      )}
                      <td>{'$' + item.price[0].unitPrice.toFixed(2)}</td>
                      <td>
                        {scheduleLineMapping.length > 0 ? (
                          <table className="table table-bordered table-condensed table-transparent">
                            <tbody>
                              <tr>
                                <td>
                                  <Translate word={ui['ITEM_SCHDULED_QUANTITY']} />
                                </td>
                                <td>
                                  <Translate word={ui['ITEM_MAD']} />
                                </td>
                                <td>
                                  <Translate word={ui['ITEM_ESD']} />
                                </td>
                                <td>
                                  <Translate word={ui['ITEM_EDD']} />
                                </td>
                              </tr>
                              {scheduleLineMapping.map((schItem, i) => (
                                <tr key={i}>
                                  <td>{schItem.confirmedQuantity}</td>
                                  <td>
                                    {plant_category_check
                                      ? 'TBD'
                                      : schItem.materialAvailableDate
                                      ? moment(schItem.materialAvailableDate).format('MM/DD/YYYY')
                                      : ''}
                                  </td>
                                  <td>{plant_category_check ? 'TBD' : schItem.shippingDate ? moment(schItem.shippingDate).format('MM/DD/YYYY') : ''}</td>
                                  <td>
                                    {plant_category_check
                                      ? 'TBD'
                                      : schItem.estimatedDeliveryDate
                                      ? moment(schItem.estimatedDeliveryDate).format('MM/DD/YYYY')
                                      : ''}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ) : (
                          ''
                        )}
                      </td>
                      <td>
                        {(confirmation1 || confirmation3) && (
                          <span className="text-danger">
                            <Translate word={ui['ORDER_CONFIRMATION_MESSAGE_1']} />
                          </span>
                        )}
                        {(confirmation2 || confirmation3) && (
                          <span className="text-danger">
                            <Translate word={ui['ORDER_CONFIRMATION_MESSAGE_2']} />
                          </span>
                        )}
                      </td>
                    </tr>
                  )
                );
              })}
          </tbody>
        </table>
      </div>
      <div className="__inline-group--buttons">
        <Field name="confirmation" component={renderInput} type="checkbox" style={{ marginLeft: '5px', marginRight: '5px' }} />
        <strong>
          <small>
            <Translate word={ui['ORDER_ACCEPTANCE_MESSAGE']} />
          </small>
        </strong>
      </div>
      <div>
        <strong>
          <small>
            <Translate word={ui['ORDER_ALERT_MESSAGE_1']} />
            <Link to={{ pathname: '/' }}>
              <Translate word={ui['SHOPPING_CART']} />
            </Link>
            <Translate word={ui['ORDER_ALERT_MESSAGE_2']} />
          </small>
        </strong>
      </div>
      <hr size="8" />
    </div>
  );
};

const validate = (values, props) => {
  const errors = {};
  if (values && (!values.PO || (values.PO && values.PO.trim().length === 0))) {
    errors.PO = <Translate word={ui['PO_BLANK_MESSAGE']} />;
  }
  if (values && values.PO && values.PO.length > PO_VALID_LENGTH) {
    errors.PO =
      translate({ word: ui['PO_LENGTH_MESSAGE_1'], language: props.language }) +
      PO_VALID_LENGTH +
      translate({ word: ui['PO_LENGTH_MESSAGE_2'], language: props.language });
  }
  if (!(values && values.confirmation && values.confirmation === true)) {
    errors.confirmation = <Translate word={ui['ACCEPT_CHANGES_MESSAGE']} />;
  }
  return errors;
};

PreviewForm = reduxForm({
  form: 'previewForm',
  destroyOnUnmount: true,
  validate
})(PreviewForm);

const mapStateToProps = (state, props) => {
  var payerId = utilsActions.getDefaultAddress(state.account.addresses, state.account.buyer, utilsActions.payerAddressType);
  var billToId = utilsActions.getDefaultAddress(state.account.addresses, state.account.buyer, utilsActions.billToAddressType);

  return {
    priceBook: state.account.priceBook,
    preOrder: state.order.preOrder,
    order: state.order.order,
    buyer: state.account.buyer,
    payerId,
    billToId,
    contract: state.account.contract,
    user: state.account.user,
    isFetching: state.common.isFetching,
    error: state.error.error_obj,
    cart: state.form.cart,
    language: state.language,
    country: state.country
  };
};

const mapDispatchToProps = dispatch => {
  return {
    placeOrder: (cart, filters, buyer, payerId, billToId, contract, user, langId) =>
      dispatch(orderActions.placeOrder(cart, filters, buyer, payerId, billToId, contract, user, langId)),
    resetFetching: () => dispatch(commonActions.resetFetching()),
    resetError: () => dispatch(errorActions.resetError()),
    resetForm: formName => dispatch(commonActions.resetForm(formName)),
    saveCart: (items, user, contract, language) => dispatch(cartActions.saveCart(items, user, contract, language))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderPreview);
