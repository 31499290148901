import React from 'react';
import Footer from '../Footer/Footer';
import DocumentTitle from 'react-document-title';
import Header from '../Header';
import Translate from '../dictionary/Translator';
import GoTools from 'react-icons/lib/go/tools';
import ui from './dictionary/ui.json';
import { connect } from 'react-redux';
import SecondaryHeader from '../header/SecondaryHeader';
const UnavailablePlaceHolder = ({ country, contract_type }) => {
  return (
    <DocumentTitle title="Sony ExpressNet">
      <div className="body-wrapper">
        <Header />
        <SecondaryHeader />
        <div className="container-full-height">
          <div style={{ margin: ' 3% auto', maxWidth: '700px' }} className="alert alert-warning">
            <GoTools style={{ fontSize: '4rem' }} />
            <div style={{ color: '#212121', fontSize: '1.7rem', textAlign: 'justify', fontWeight: '600' }}>
              <Translate dictionary={ui} word="MAIN" />
              {renderSupportEmail({ country })}
              <Translate dictionary={ui} word="SECOND" />
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </DocumentTitle>
  );
};

const renderSupportEmail = ({ country }) => {
  const EMAILS = {
    CA: (
      <div>
        <div>
          <label style={{ width: '100px' }}>PSA</label> <a href="mailto:Order_Management@sony.ca">Order_Management@sony.ca</a>
        </div>
        <div>
          <label style={{ width: '100px' }}>Consumer</label> <a href="mailto:CanadaConsumerOM@am.sony.com">CanadaConsumerOM@am.sony.com</a>
        </div>
      </div>
    ),
    US: (
      <div>
        <div>
          <label style={{ width: '100px' }}>PSA</label> <a href="mailto:CSGeneralInquiriesEast@am.sony.com">CSGeneralInquiriesEast@am.sony.com</a>
        </div>
        <div>
          <label style={{ width: '100px' }}>Consumer</label> <a href="mailto:SonyOrderManagement@am.sony.com">SonyOrderManagement@am.sony.com</a>
        </div>
      </div>
    )
  };
  return EMAILS[country];
};
const mapStateToProps = ({ country }) => ({ country });
export default connect(mapStateToProps)(UnavailablePlaceHolder);
