import React from 'react';

class AppErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
    // TODO :: You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div className="container">
          <h4>{this.state.error.toString()}</h4>
        </div>
      );
    } else {
      // Normally, just render children
      return this.props.children;
    }
  }
}

export default AppErrorBoundary;
