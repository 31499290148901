import React, { Component } from 'react';
import { connect } from 'react-redux';
import Translate, { translate } from '../dictionary/Translator';
import ui from './dictionary/ui.json';
import { updateCart } from '../../modules/cart';
import { formValueSelector } from 'redux-form';
import validator from 'validator';
import cartUI from '../cart/dictionary/ui.json';
import { successToast } from '../utils/renderUtils';
import { validateCart } from '../../modules/utils';
const selector = formValueSelector('cart');
class AccessoryThumbCart extends Component {
  state = {
    normalQuantity: 0,
    bStockQuantity: 0,
    normalQuantityError: null,
    bStockQuantityError: null
  };

  handleInputChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    if (!value || value.trim().length === 0 || validator.isInt(value, { min: 0 })) {
      this.setState({ [name]: value, [`${name}Error`]: false });
    } else {
      this.setState({ [`${name}Error`]: true, [name]: value });
    }
  };

  handleAddToCartBStock = e => {
    e.preventDefault();
    const { originalSku, cartItems = [], language } = this.props;

    const { bStockQuantity } = this.state;
    if (!validator.isInt(bStockQuantity, { min: 1 })) {
      return false;
    }
    this.props.updateCart(cartItems.concat({ sku: originalSku, BStockQty: (bStockQuantity).toString(), grade: 'B'}), language);
    successToast(translate({ word: cartUI['CART_UPDATE_NOTIFICATION_SUCCESS'], language }));
    this.setState({ bStockQuantity: 0 });
  };

  handleAddToCartNormal = e => {
    e.preventDefault();
    const { sku, cartItems = [], language } = this.props;
    const { normalQuantity } = this.state;
    if (!validator.isInt(normalQuantity, { min: 1 })) {
      return false;
    }
    this.props.updateCart(cartItems.concat({ sku, AStockQty: (normalQuantity).toString(), grade: 'A' }), language);
    successToast(translate({ word: cartUI['CART_UPDATE_NOTIFICATION_SUCCESS'], language }));
    this.setState({ normalQuantity: 0 });
  };

  handleBlur = e => {
    const name = e.target.name;
    const value = e.target.value;
    if (!value || value.trim().length === 0) {
      this.setState({ [name]: '0', [`${name}Error`]: false });
    }
  };

  render() {
    const { prices, originalSku, language, cartItems } = this.props;
    const { normalQuantity, bStockQuantity, normalQuantityError, bStockQuantityError } = this.state;
    var isCartFull = validateCart(cartItems);
    return (
      <div className="AccessoryThumb__cart">
        <div className="AccessoryThumb__cart__main">
          <span className="price">
            <Translate dictionary={ui} word="YOUR_PRICE" />:{!prices.A_base_price || prices.A_base_price === '0.00' ? '' : ' $' + prices.A_base_price}
          </span>
          <div>
            <div className="AccessoryThumb__cart__add-to-cart">
              <label htmlFor={`qty-${originalSku}`} className="label-sm">
                <Translate dictionary={ui} word="QTY" />
              </label>
              <input
                className={`quantity-input ${normalQuantityError ? 'input-has-error' : undefined}`}
                id={`qty-${originalSku}`}
                name="normalQuantity"
                maxLength={9}
                onChange={this.handleInputChange}
                value={normalQuantity}
                onBlur={this.handleBlur}
              />
              <button
                className="btn-with-icon-span btn btn-success btn-sm"
                onClick={this.handleAddToCartNormal}
                disabled={isCartFull || normalQuantityError}
                title={normalQuantityError ? translate({ dictionary: ui, language, word: 'INVALID_INPUT' }) : null}
              >
                <span className="glyphicon glyphicon-shopping-cart" />
                <span>
                  <Translate dictionary={ui} word="BTN_ADD_CART" />
                </span>
              </button>
            </div>
            {prices.has_Bstock && (
              <div style={{ paddingTop: '5%' }}>
                <span className="price">
                  <Translate dictionary={ui} word="BSTOCK" />:{!prices.B_base_price || prices.B_base_price === ' 0.00' ? '' : ' $' + prices.B_base_price}
                </span>
                <div className="AccessoryThumb__cart__add-to-cart">
                  <label htmlFor={`bstock-qty-${originalSku}`} className="label-sm">
                    Qty
                  </label>
                  <input
                    className={`quantity-input ${bStockQuantityError ? 'input-has-error' : undefined}`}
                    id={`bstock-qty-${originalSku}`}
                    name="bStockQuantity"
                    value={bStockQuantity}
                    onBlur={this.handleBlur}
                    maxLength={9}
                    onChange={this.handleInputChange}
                  />
                  <button
                    className="btn-with-icon-span btn btn-success btn-sm"
                    onClick={this.handleAddToCartBStock}
                    disabled={isCartFull || bStockQuantityError}
                    title={bStockQuantityError ? translate({ dictionary: ui, language, word: 'INVALID_INPUT' }) : null}
                  >
                    <span className="glyphicon glyphicon-shopping-cart" />
                    <span>
                      <Translate dictionary={ui} word="BTN_ADD_CART_BSTOCK" />
                    </span>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        {(normalQuantityError || bStockQuantityError) && (
          <span style={{ color: 'red' }}>
            <Translate dictionary={ui} word="INVALID_INPUT" />
          </span>
        )}
      </div>
    );
  }
}
const mapStateToProps = state => ({ cartItems: selector(state, 'items'), language: state.language });
export default connect(mapStateToProps, { updateCart })(AccessoryThumbCart);
