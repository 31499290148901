import React, { Component } from 'react';
import './Gallery.css';
import Translate, { translate } from '../dictionary/Translator';
import ui from './dictionary/ui.json';
import { connect } from 'react-redux';
class Gallery extends Component {
  state = {
    selected: this.props.images[0] || ''
  };
  onThumbClick = e => {
    e.preventDefault();
    this.setState({ selected: e.target.name });
  };
  render() {
    const { images, language } = this.props;
    const { selected } = this.state;
    return images.length > 0 ? (
      <div className="Gallery">
        <div className="Gallery__thumbs">
          {images.map((image, key) => {
            return (
              <div key={key} className={`Gallery__thumb ${image === selected ? 'Gallery__thumb--selected' : null}`}>
                <img src={image} onClick={this.onThumbClick} alt={`${key + 1}`} name={image} className="img-responsive" />
              </div>
            );
          })}
        </div>
        <div className="Gallery__image">
          <img src={selected} alt={translate({ dictionary: ui, word: 'CURRENT_IMG', language })} className="Gallery__current-image" />
        </div>
      </div>
    ) : (
      <div className="Gallery">
        <div className="Gallery__thumbs" />
        <div className="Gallery__image">
          <p style={{ fontSize: '1.7rem', fontWeight: '700' }}>
            <Translate dictionary={ui} word="IMG_NOT_AVAILABLE" />
          </p>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ language }) => ({ language });
export default connect(mapStateToProps)(Gallery);
