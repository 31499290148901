import React from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Field, reduxForm, change, formValueSelector } from 'redux-form';
import ReactTable from 'react-table';
import moment from 'moment';
import * as orderActions from '../../modules/order';
import { renderInput, renderDatePicker, renderFilterSelect, isIE } from '../utils/renderUtils';
import Loading from '../Loading';
import * as errorActions from '../../modules/error';
import * as commonActions from '../../modules/common';
import 'react-table/react-table.css';
import MdSearch from 'react-icons/lib/md/search';
import IoAndroidRefresh from 'react-icons/lib/io/android-refresh';
import Translate from '../dictionary/Translator';
import errorDictionary from '../dictionary/error';
import creditHistoryStatusMappings from './dictionary/credit_history_status';
import orderTypeMappings from './dictionary/order_type';
import gsapOrderTypeMappings from './dictionary/gsap_order_type.json'
import vistexCreditStatusMappings from './dictionary/vistex_credit_status';
import { translate } from '../dictionary/Translator';
import ui from './dictionary/ui';
import staticMappings from './dictionary/static';
import DocumentTitle from 'react-document-title';

const selector = formValueSelector('orderSearchFilter');
var users = [];
var language;
var headerStatusOptions;
const orderType = orderTypeMappings['ORDER_TYPES_CREDIT_SEARCH'];
const gsapOrderType = gsapOrderTypeMappings['ORDER_TYPES_CREDIT_SEARCH_GSAP'];
const reasonType = orderTypeMappings['REASON_CODES_CREDIT_SEARCH'];
const gsapReasonType = gsapOrderTypeMappings['REASON_CODES_CREDIT_SEARCH_GSAP']
const maxRecordsOptions = staticMappings['MAX_RECORD_OPTIONS'];

class CreditHistory extends React.Component {

  userlistOptions = userList => {
    users = [];
    users.push({ value: '', label: <Translate word={ui['USER_TYPE_ANY']} /> });
    userList.map((item, index) => users.push({ value: item.id, label: item.firstName + ' ' + item.lastName }));
  };

  showResults = filters => {
    this.props.resetError();
    this.props.clearCreditHistory();
    this.props.creditOrders(filters, this.props.buyer, this.props.contract, orderType, gsapOrderType, reasonType, gsapReasonType, this.props.contactIdMapping);
  };

  componentWillMount() {
    this.props.resetError();
    this.props.resetFetching();
    if (this.props.backButtonFlag) {
      this.props.setBackButtonFlag();
    } else {
      this.props.clearCreditHistory(true);
    }

    if (this.props.userList) {
      this.userlistOptions(this.props.userList);
    }
  }

  componentWillUnmount() {
    this.props.resetError();
  }

  render() {
    const { orderList, error, isFetching } = this.props;
    language = this.props.language;
    headerStatusOptions = translate({ word: ui['CREDIT_HEADER_STATUS_OPTIONS'], language });
    return (
      <DocumentTitle title={`${translate({ word: ui['CREDIT_HISTORY'], language: this.props.language })} | Sony ExpressNet`}>
        <div className="container-less-fluid">
          <h1 style={{ fontSize: '2.2rem', fontWeight: '600' }}>
            <Translate word={ui['CREDIT_HISTORY']} />
          </h1>
          <div>
            <Translate word={ui['CREDIT_HISTORY_HEADER_MESSAGE']} />
          </div>
          <div />
          <hr />
          <SearchFilterForm onSubmit={this.showResults} {...this.props} />
          <hr size="4" />
          {error && (
            <div className="text-danger">
              <h1 style={{ fontSize: '1.33rem', fontWeight: '450' }}>
                <Translate word={errorDictionary[error.errorPath]} />
              </h1>
            </div>
          )}
          {!isFetching && !error && orderList && orderList.length === 0 && (
            <div className="text-danger">
              <strong>
                <Translate word={errorDictionary['NO_RESULTS']} />
              </strong>
            </div>
          )}
          {!isFetching && !error && orderList && orderList.length > 0 && (
            <div>
              <h2 style={{ fontSize: '1.8rem', fontWeight: '450' }}>
                <Translate word={ui['CREDIT_ORDER_LIST']} />
              </h2>
              <OrderList {...this.props} />
            </div>
          )}
        </div>
      </DocumentTitle>
    );
  }
}

const columns = userList => {
  return [
    {
      Header: () => (
        <div>
          <strong>
            <Translate word={ui['CREDIT_REQUEST_NO.']} />
          </strong>
        </div>
      ),
      accessor: 'orderNumber',
      minWidth: 165,
      style: { whiteSpace: 'pre-wrap' },
      headerStyle: { whiteSpace: 'pre-wrap', padding: '1%' },
      className: 'react-table__td',
      headerClassName: 'react-table__th',
      Cell: row => (
        <strong>
          <Link to={{ pathname: '/creditview/' + row.original.source + '/' + row.value }} className="bold-link">
            {row.value}
          </Link>
        </strong>
      )
    },
    {
      Header: () => (
        <div>
          <strong>
            <Translate word={ui['CREDIT_TYPE']} />
          </strong>
        </div>
      ),
      accessor: 'orderType',
      minWidth: 165,
      style: { whiteSpace: 'pre-wrap' },
      headerStyle: { whiteSpace: 'pre-wrap', padding: '1%' },
      className: 'react-table__td',
      headerClassName: 'react-table__th',
      Cell: row => {
        return row.value ? (row.original.source === 'S' ? translate({ word: orderTypeMappings[row.value], language }) : translate({ word: gsapOrderTypeMappings[row.value], language })) : '';
      }
    },
    {
      Header: () => (
        <div>
          <strong>
            <Translate word={ui['CREDIT_REQUEST_DATE']} />
          </strong>
        </div>
      ),
      accessor: 'orderDate',
      minWidth: 165,
      style: { whiteSpace: 'pre-wrap' },
      headerStyle: { whiteSpace: 'pre-wrap', padding: '1%' },
      className: 'react-table__td',
      headerClassName: 'react-table__th',
      Cell: row => {
        return row.value ? moment(row.value).format('MM/DD/YYYY') : '';
      }
    },
    {
      Header: () => (
        <div>
          <strong>
            <Translate word={ui['ORDER_STATUS']} />
          </strong>
        </div>
      ),
      minWidth: 165,
      style: { whiteSpace: 'pre-wrap' },
      headerStyle: { whiteSpace: 'pre-wrap', padding: '1%' },
      className: 'react-table__td',
      headerClassName: 'react-table__th',
      accessor: 'status'
    },
    {
      Header: () => (
        <div>
          <strong>
            <Translate word={ui['DEBIT_REFERENCE_NO']} />
          </strong>
        </div>
      ),
      minWidth: 165,
      style: { whiteSpace: 'pre-wrap' },
      headerStyle: { whiteSpace: 'pre-wrap', padding: '1%' },
      className: 'react-table__td',
      headerClassName: 'react-table__th',
      accessor: 'PO'
    },
    {
      Header: () => (
        <div>
          <strong>
            <Translate word={ui['CREDIT_ORDER_TOTAL']} />
          </strong>
        </div>
      ),
      accessor: 'orderTotal',
      minWidth: 125,
      style: { whiteSpace: 'pre-wrap' },
      headerStyle: { whiteSpace: 'pre-wrap', padding: '1%' },
      className: 'react-table__td',
      headerClassName: 'react-table__th',
      Cell: row => {
        return '$-' + row.value;
      },
      sortMethod: (a, b) => {
        return a - b;
      }
    },
    {
      Header: () => (
        <div>
          <strong>
            <Translate word={ui['ENTERED_BY']} />
          </strong>
        </div>
      ),
      accessor: 'contactId',
      minWidth: 165,
      style: { whiteSpace: 'pre-wrap' },
      headerStyle: { whiteSpace: 'pre-wrap', padding: '1%' },
      className: 'react-table__td',
      headerClassName: 'react-table__th',
      Cell: row => {
        let x = userList.find(user => user.id === row.value);
        return x ? x.firstName + ' ' + x.lastName : <a href="/contact-us#claims" style={{textDecoration:'none', color:'#2E6CA3'}}><Translate word={ui['CCA']} /></a>;
      },
      sortMethod: (a, b) => {
        let x = userList.find(user => user.id === a);
        let newA = x ? x.firstName + ' ' + x.lastName : <Translate word={ui['CCA']} />;
        let y = userList.find(user => user.id === b);
        let newB = y ? y.firstName + ' ' + y.lastName : <Translate word={ui['CCA']} />;

        if (newA > newB) {
          return 1;
        }
        if (newA < newB) {
          return -1;
        }
        return 0;
      }
    }
  ];
};

let OrderList = props => {
  const { orderList, userList, language } = props;
  orderList.map(item => {
    item.source === 'S' || item.source === 'GS'
      ? (item.status = item.overallBlockedStatus
          ? translate({ word: ui['STATUS_CREDIT_UNDER_REVIEW'], language })
          : item.orderHeaderStatus !== null
          ? translate({ word: creditHistoryStatusMappings[item.orderHeaderStatus], language })
          : '')
      : (item.status = item.orderHeaderStatus !== null ? translate({ word: vistexCreditStatusMappings[item.orderHeaderStatus], language }) : '');
    return item;
  });

  return (
    <div className="row">
      <div className="overflow-wrapper">
        <ReactTable
          minRows={1}
          showPagination={false}
          defaultPageSize={100}
          data={orderList}
          columns={columns(userList)}
          className={`-striped -highlight ${isIE() ? 'react-table-ie--customized' : ''}`}
          style={
            isIE()
              ? { marginBottom: '20px' }
              : {
                  maxHeight: '400px',
                  overflowY: 'auto',
                  marginBottom: '20px'
                }
          }
        />
      </div>
    </div>
  );
};

let SearchFilterForm = props => {
  const { handleSubmit, isFetching, clearCreditHistory, sku, AStockCheckbox, BStockCheckbox, resetStockCheckbox} = props;

  return (
    <form
      id="searchForm"
      onSubmit={handleSubmit}
      onKeyPress={e => {
        if (e.key === 'Enter') e.preventDefault();
      }}
    >
      <div className="form-group__row">
        <fieldset className="form-group--vertical">
          <div className="form-group--vertical__controls">
            <div className="form-group-fixed-width">
              <label htmlFor="orderNumber">
                <Translate word={ui['CREDIT_REQUEST_NUMBER']} />
              </label>
              <Field id="orderNumber" name="orderNumber" isClearable={true} component={renderInput} maxLength={10} />
            </div>
            <div className="form-group-fixed-width">
              <label htmlFor="orderDateFrom">
                <Translate word={ui['REQUEST_DATE_FROM']} />
              </label>
              <Field id="orderDateFrom" name="orderDateFrom" isClearable={true} component={renderDatePicker} />
            </div>
            <div className="form-group-fixed-width">
              <label htmlFor="orderDateTo">
                <Translate word={ui['REQUEST_DATE_TO']} />
              </label>
              <Field id="orderDateTo" name="orderDateTo" isClearable={true} component={renderDatePicker} />
            </div>
          </div>
        </fieldset>
        <fieldset className="form-group--vertical">
          <div className="form-group--vertical__controls">
            <div className="form-group-fixed-width">
              <label htmlFor="PO">
                <Translate word={ui['DEBIT_REFERENCE_NUMBER']} />
              </label>
              <Field id="PO" name="PO" isClearable={true} component={renderInput} maxLength={20} />
            </div>
            <div className="form-group-fixed-width">
              <label htmlFor="sku">
                <Translate word={ui['MODEL']} />
              </label>
              <Field id="sku" name="sku" isClearable={true} component={renderInput} />
            </div>
            <div style={{ marginLeft: '10px' }} className="stock-input-checkbox">
              <div className="stock-checkbox">
                <Field id="AStock" name="AStock" component="input" type="checkbox" disabled={sku && sku.length >0 ?false:true}  />
                <label htmlFor="AStock">
                  <Translate word={ui['A_STOCK']} />
                </label>
              </div>
              <div className="stock-checkbox">
                <Field id="BStock" name="BStock" component="input" type="checkbox" disabled={sku && sku.length >0 ?false:true} />
                <label htmlFor="BStock">
                  <Translate word={ui['B_STOCK']} />
                </label>
              </div>
            </div>
             {/* Error message if both checkboxes are unchecked */}
             <div className="stockError">
              <Field id="StockError" name="StockError" component={renderInput} type="checkbox" hidden
              style={{display: sku && !AStockCheckbox && !BStockCheckbox?'block':'none', position: 'absolute', left: '-9999px'}}/>
            </div>
            <div className="form-group-fixed-width">
              <label htmlFor="headerStatus">
                <Translate word={ui['ORDER_STATUS']} />
              </label>
              <Field id="headerStatus" name="headerStatus" component={renderFilterSelect} options={headerStatusOptions} />
            </div>
          </div>
        </fieldset>
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '1%' }} className="search-tools">
        <fieldset className="form-group--vertical no-shadow" style={{ border: 'none' }}>
          <div className="form-group-fixed-width">
            <label htmlFor="maxRecords">
              <Translate word={ui['LIMIT_SEARCH_TO_LAST']} />
            </label>
            <Field id="maxRecords" name="maxRecords" component={renderFilterSelect} options={maxRecordsOptions} props={{ clearable: false }} />
          </div>
        </fieldset>
        <div className="form-group--vertical no-shadow" style={{ border: 'none' }}>
          <div className="__inline-group--buttons">
            <Button bsStyle="primary" className="btn-margin button" type="submit" disabled={isFetching} title="Search Credit Orders">
              <MdSearch className="button__icon" role="img" aria-label="Search Credit Orders" />
              <span>
                <Translate word={ui['SEARCH_BUTTON']} />
              </span>
            </Button>
            <Button
              bsStyle="primary"
              className="btn-margin button"
              id="resetForm"
              title="Reset Form"
              onClick={() => {
                clearCreditHistory(true);
                resetStockCheckbox()
              }}
              disabled={isFetching}
              style={{ backgroundColor: '#D63A0A' }}
            >
              <IoAndroidRefresh className="button__icon" role="img" aria-label="Reset Form" />
              <span>
                <Translate word={ui['RESET_BUTTON']} />
              </span>
            </Button>
          </div>
        </div>
      </div>
      {isFetching && <Loading />}
    </form>
  );
};

const validate = filters => {
  const errors = {};
  if (filters.orderDateFrom && !moment(filters.orderDateFrom, 'MM/DD/YYYY', true).isValid()) {
    errors.orderDateFrom = <Translate word={ui['ERROR_DATE_FORMAT']} />;
  }
  if (filters.orderDateTo && !moment(filters.orderDateTo, 'MM/DD/YYYY', true).isValid()) {
    errors.orderDateTo = <Translate word={ui['ERROR_DATE_FORMAT']} />;
  }
  if (filters.orderDateFrom && !filters.orderDateTo) {
    errors.orderDateTo = <Translate word={ui['ERROR_DATE_TO']} />;
  }
  if (moment(filters.orderDateTo, 'MM/DD/YYYY') < moment(filters.orderDateFrom, 'MM/DD/YYYY')) {
    errors.orderDateTo = <Translate word={ui['ERROR_DATE_FROM']} />;
  }
  if(filters.sku && !filters.sku.toUpperCase().endsWith('.B') && !filters.AStock && !filters.BStock) {
    errors.StockError= <Translate word={ui['MATERIAL_MANDATORY']} />;
  }
  return errors;
};

SearchFilterForm = reduxForm({
  form: 'orderSearchFilter',
  destroyOnUnmount: false,
  initialValues: { maxRecords: 10, AStock:true, BStock: true },
  validate
})(SearchFilterForm);

const mapStateToProps = (state, props) => {
  return {
    buyer: state.account.buyer,
    contract: state.account.contract,
    userList: state.account.userList,
    contactIdMapping: state.account.contactIdMapping,
    orderList: state.order.list,
    maxRecords: state.order.maxRecords,
    error: state.error.error_obj,
    isFetching: state.common.isFetching,
    backButtonFlag: state.order.backButtonFlag,
    language: state.language,
    sku: selector(state, 'sku'),
    AStockCheckbox: selector(state, 'AStock'),
    BStockCheckbox: selector(state, 'BStock')
  };
};

const mapDispatchToProps = dispatch => {
  return {
    creditOrders: (filter, buyer, contract, orderType, gsapOrderType, reasonType, gsapReasonType, customerIdMap) => {
      if(filter.sku && filter.sku.endsWith('.B') && filter.BStock === false){
        dispatch(change('orderSearchFilter', 'BStock', true))
      filter.BStock = true;
      filter ={...filter,...filter.BStock}
    }
    setTimeout(()=>{
      dispatch(orderActions.listOrders(filter, buyer, contract, orderType, gsapOrderType, reasonType, gsapReasonType, customerIdMap)) 
    },.1);
    },
    clearCreditHistory: clearFormFlag => dispatch(orderActions.clearOrderHistory(clearFormFlag)),
    resetError: () => dispatch(errorActions.resetError()),
    setBackButtonFlag: resetFlag => dispatch(orderActions.setBackButtonFlag(resetFlag)),
    resetFetching: () => dispatch(commonActions.resetFetching()),
    // reset checkboxes to checked and disabled on click of reset button
    resetStockCheckbox: () => {dispatch(change('orderSearchFilter', 'AStock', true))
      dispatch(change('orderSearchFilter', 'BStock', true))
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditHistory);
