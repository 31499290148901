import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changeCountry } from '../../modules/country';
import USFlag from '../../static/images/us-flag.png';
import CanadaFlag from '../../static/images/canada-flag.png';
import './CountrySelect.css';
class CountrySelect extends Component {
  render() {
    const { country } = this.props;
    return country === 'CA' ? <img src={CanadaFlag} alt="" className="flag" /> : <img src={USFlag} alt="" className="flag" />;
  }
}

const mapStateToProps = ({ country }) => {
  return { country };
};
export default connect(
  mapStateToProps,
  { changeCountry }
)(CountrySelect);
