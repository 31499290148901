import React from 'react';
import { connect } from 'react-redux';
import { translate } from '../dictionary/Translator';
import cartDictionary from './dictionary/ui.json';

import {v1 as uuid} from 'uuid';
const CartError = ({ errors, submitErrors }) =>
  (errors && errors.length > 0) || (submitErrors && submitErrors.length > 0) ? (
    <div className="error-container" style={{ paddingLeft: '4%' }}>
      <ul style={{ padding: '0' }}>
        {errors.map((error, index) => (
          <li key={uuid() + index} className="error-message">
            {error}
          </li>
        ))}
        {submitErrors.map((error, i) => (
          <li key={uuid() + i}>{error}</li>
        ))}
      </ul>
    </div>
  ) : (
    <div />
  );

const mapStateToProps = ({ form: { cart }, language }, ownProps) => {
  const errors = [];
  if (!cart.syncErrors) {
    return { errors: null };
  }
  if (cart.syncErrors.shippingAddress && ownProps.submitFailed) {
    const { dictionary, word } = cart.syncErrors.shippingAddress.props;
    errors.push(translate({ dictionary, word, language }));
  }
  if (
    cart.syncErrors.items &&
    cart.fields &&
    cart.fields.items &&
    cart.fields.items.length > 0 &&
    cart.fields.items.some(
      (item, index) =>
        item &&
        item.AStockQty &&
        item.AStockQty.touched === true &&
        item.BStockQty &&
        item.BStockQty.touched === true &&
        cart.syncErrors.items[index] &&
        (cart.values.items[index].sku || (!cart.values.items[index].sku &&
           (!cart.values.items[index].AStockQty || !cart.values.items[index].BStockQty)))
    )
  ) {
    errors.push(translate({ dictionary: cartDictionary, word: 'CART_ITEMS_GENERAL_ERROR', language }));
  }
  return { errors };
};
export default connect(mapStateToProps)(CartError);
