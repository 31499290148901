import React, { Component } from 'react';
import { connect } from 'react-redux';
import ProductDetail from './ProductDetail'
import DocumentTitle from 'react-document-title';
import { translate } from '../dictionary/Translator';
import ui from './dictionary/ui.json';
import { validateCart } from '../../modules/utils';
import { formValueSelector } from 'redux-form';

const selector = formValueSelector('cart');

class ProductDetailWrapper extends Component {
  render() {
    let sku = decodeURIComponent(this.props.match.params.sku);
    const prices = this.getPriceBook(sku);
    const { language, cartItems } = this.props;
    const isCartFull = validateCart(cartItems);
    if (!prices) {
      return (
        <div className="container">
          <h3>You are not authorized to view this product.</h3>
        </div>
      );
    } else {
        return (
          <DocumentTitle title={`${translate({ dictionary: ui, word: 'TITLE', language })} | ${sku} | Sony ExpressNet`}>
            <ProductDetail prices={prices} isCartFull={isCartFull} />
          </DocumentTitle>
        );
    }
  }

  getPriceBook(sku) {
    return this.props.priceBook.find(aProduct => aProduct.originalSku === sku);
  }
}

const mapStateToProps = state => {
  return {
    priceBook: state.account.priceBook,
    country: state.country,
    language: state.language,
    cartItems: selector(state, 'items')
  };
};

export default connect(mapStateToProps)(ProductDetailWrapper);
