export function checkNested(obj /*[, level1, level2, ... levelN]*/) {
  var args = Array.prototype.slice.call(arguments, 1);

  for (var i = 0; i < args.length; i++) {
    if (!obj || !obj.hasOwnProperty(args[i])) {
      return false;
    }
    obj = obj[args[i]];
  }
  return true;
}

// https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
export function escapeRegexCharacters(str) {
  return str.replace(/[*+?^${}()|[\]\\]/g, '\\$&');
}

export function getAlphaNumOnly(str) {
  return str.replace(/[^a-z0-9./]/gi, '');
}

export function getAlphaNumSkuToCompare(str) {
  return getAlphaNumOnly(escapeRegexCharacters(str.trim().toLowerCase()));
}

export const scrollToASpecificID = (e, id) => {
  e.preventDefault();
  const element = document.getElementById(id);
  setTimeout(() => {
    element.scrollIntoView(true);
  }, 100);
};
