import React, { useState } from 'react';
import './Footer.css';
import Translate, { translate } from '../dictionary/Translator';

import ui from './dictionary/ui';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PRIVACY_POLICY_LINKS, APPS_LINKS } from './FooterLinks';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    boxSizing: ' border-box'
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.5)'
  }
};

const HelpModal = props => {
  return (
    <div>
      <Modal ariaHideApp={false} isOpen={props.isOpen} onRequestClose={props.toggleModal} style={customStyles}>
        <button
          style={{
            float: 'Right',
            cursor: 'pointer',
            marginTop: '0%',
            position: 'absolute',
            top: '0',
            right: '2px',
            fontSize: '1.4rem',
            fontWeight: '600',
            transition: 'all .3s ease',
            padding: '1%',
            background: 'transparent',
            border: 'none'
          }}
          onClick={props.toggleModal}
        >
          X
        </button>
        <h5 style={{ textAlign: 'center' }}>
          <Translate dictionary={ui} word={'LOGIN_ISSUE_CONTACT_INFO'} />
        </h5>
      </Modal>
    </div>
  );
};

const Footer = ({ language, country, user, message }) => {
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };
  return (
      <div>
          <div className="browser__message">{message}</div>

          <div className="Footer">
              <HelpModal
                  isOpen={modal}
                  toggleModal={() => {
                      toggle();
                  }}
              />
              <ul className="Footer__links">
                  {APPS_LINKS[country][language].map((linkObj, index) => (
                      <li
                          className="Footer__link"
                          key={index}
                          style={{ paddingRight: '1%' }}
                      >
                          <a
                              href={linkObj['url']}
                              target="_blank"
                              rel="noopener noreferrer"
                              aria-label={translate({
                                  word: ui[linkObj['label']],
                                  language,
                              })}
                          >
                              <Translate
                                  dictionary={ui}
                                  word={linkObj['key']}
                              />
                          </a>
                      </li>
                  ))}
                  {user ? (
                      <li className="Footer__link">
                          <Link to="/faq">
                              <Translate dictionary={ui} word="HELP" />
                          </Link>
                      </li>
                  ) : (
                      <li className="Footer__link">
                          <a onClick={toggle} href="/#">
                              <Translate dictionary={ui} word="HELP" />
                          </a>
                      </li>
                  )}
              </ul>
              <ul className="Footer__links">
                  {PRIVACY_POLICY_LINKS[country][language].map(
                      (linkObj, index) => (
                          <li
                              className="Footer__link"
                              key={index}
                              style={{ paddingRight: '1%' }}
                          >
                              <a
                                  href={linkObj['url']}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  aria-label={translate({
                                      word: ui[linkObj['label']],
                                      language,
                                  })}
                              >
                                  <Translate
                                      dictionary={ui}
                                      word={linkObj['key']}
                                  />
                              </a>
                          </li>
                      )
                  )}
                  <li>
                  <p className="Footer__copy-right">
                      <Translate dictionary={ui} word="FOOTER_COPY_RIGHT" />
                  </p></li>
              </ul>
          </div>
      </div>
  );
};

const mapStateToProps = ({ language, country, account: { user } }) => ({ language, country, user });
export default connect(mapStateToProps)(Footer);
