import React from 'react';
import './AccessoryThumb.css';
import { Link } from 'react-router-dom';
import { replaceHTMLTagsWithUnicodes } from '../utils/renderUtils';
import AccessoryThumbCart from './AccessoryThumbCart';
import { CropString } from '../utils/renderUtils';
import SmallImageSlider from '../SmallImageSlider/SmallImageSlider';
import ReactHtmlParser from 'react-html-parser';

const AccessoryThumbPresent = ({ sku, description, originalSku, images, name, prices }) => (
  <div className="AccessoryThumb">
    <SmallImageSlider images={images} alt={sku} />
    <div className="AccessoryThumb__info">
      <h4 className="AccessoryThumb__info__sku">
        <Link to={`/productdetail/${encodeURIComponent(originalSku)}`}>
          <span style={{ display: 'block', fontSize: '1.5rem', color: '#01579b' }}>{ReactHtmlParser(replaceHTMLTagsWithUnicodes(name))}</span>
          <span style={{ fontSize: '1.2rem', color: '#0288d', marginTop: '3px' }}>{sku}</span>
        </Link>
      </h4>
      <div className="AccessoryThumb__info__description">
        <CropString content={ReactHtmlParser(replaceHTMLTagsWithUnicodes(description))} length="400" readMoreLink={`/productdetail/${encodeURIComponent(originalSku)}`} />
      </div>
    </div>
    <div className="AccessoryThumb__action">
      <AccessoryThumbCart prices={prices} sku={sku} originalSku={originalSku} />
    </div>
  </div>
);

export default AccessoryThumbPresent;
