import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';
import { render } from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import App from './components/App';
import configureStore from './store/configureStore';
import 'bootstrap/dist/css/bootstrap.css';
import 'babel-polyfill';
import './index.css';
import { initializeAppLanguage } from './modules/language';
import { initializeCountry } from './modules/country';
import { initiateTheEnvironmentDisclaimer } from './modules/environment';
const { persistor, store } = configureStore();
store.dispatch(initializeCountry());
store.dispatch(initializeAppLanguage());
store.dispatch(initiateTheEnvironmentDisclaimer());
render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <BrowserRouter>
        <Route path="/" component={App} />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
