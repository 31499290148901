import React, { Component } from 'react';
import './ImageWithLoader.css';
class ImageWithLoader extends Component {
  state = { loaded: false };
  componentDidMount() {
    if (this.main && this.main.complete) {
      this.setState({ loaded: true });
    }
    this.fn = null;
  }
  completed = () => {
    this.fn = setTimeout(() => {
      this.setState({ loaded: true });
    }, 1000);
  };
  componentWillUnmount() {
    clearTimeout(this.fn);
    this.fn = null;
  }
  render() {
    const { src, alt = '' } = this.props;
    const { loaded } = this.state;
    return (
      <div>
        <img src={src} onLoad={this.completed} ref={main => (this.main = main)} className={loaded ? undefined : 'hidden'} alt={alt} />
        <div className={`img-loader ${loaded ? 'hidden' : undefined} `} />
      </div>
    );
  }
}

export default ImageWithLoader;
