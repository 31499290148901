import { CHANGE_FETCHING } from './common_actions';
import { destroy } from 'redux-form';

export default (state = {}, action) => {
  switch (action.type) {
    case CHANGE_FETCHING:
      return { ...state, ...action.data };
    default:
      return state;
  }
};

export const resetForm = formName => {
  return dispatch => {
    return dispatch(destroy(formName));
  };
};

export const resetFetching = () => {
  return dispatch => {
    dispatch({ type: CHANGE_FETCHING, data: { isFetching: undefined } });
  };
};
