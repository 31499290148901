import Axios from 'axios';
import axiosRetry from 'axios-retry';
import { SET_ERROR, UNAVAILABLE_ERROR } from './common_actions';
import { login } from './auth.js';
import Papa from 'papaparse';
require('es6-promise').polyfill();

axiosRetry(Axios, { retries: 10, retryDelay: axiosRetry.exponentialDelay });

export const getHttp = (dispatch, state) => {
  // Refresh Cognito session
  dispatch(login());
  Axios.defaults.headers.common['Authorization'] = state().auth.token;
  Axios.defaults.headers.common['x-en-user-token'] = state().account.enUserToken;
  Axios.defaults.headers.common['Pragma'] = 'no-cache';
  var http = {};
  http.get = function(uri, input) {
    return Axios.get(uri, input);
  };

  http.post = function(uri, input) {
    return new Promise(function(resolve, reject) {
      try {
        Axios.post(uri, input)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            if (error.response && error.response.status === 503) {
              if (!input.headers) {
                input.headers = {};
              }
              input.headers['x-cache-key'] = error.response.data.key;
              http
                .get(uri, input)
                .then(response => {
                  resolve(response);
                })
                .catch(error => {
                  reject(error);
                });
            } else {
              reject(error);
            }
          });
      } catch (error) {
        reject(error);
      }
    });
  };

  http.all = function(iterable) {
    return Axios.all(iterable);
  };

  http.spread = function(callback) {
    return Axios.spread(callback);
  };

  return http;
};

export const parseCSV = (file, cb) => {
  var parsedData;

  Papa.parse(file, {
    header: true,
    skipEmptyLines: true,
    complete: function(results) {
      cb(results);
    },
    error: function(error) {
      cb();
    }
  });

  return parsedData;
};

export const respond = (actionName, error, dispatch) => {
  let error_response = {};

  if (error.response && error.response.status && error.response.data) {
    if (JSON.parse(error.response.data).key === 'SERVICE_UNAVAILABLE') {
      dispatch({ type: UNAVAILABLE_ERROR, status: true });
      return;
    }
    error_response = {
      status: error.response.status,
      code: error.response.data,
      actionName: actionName,
      errorPath: error.response.status + '_' + JSON.parse(error.response.data).key
    };
    console.error(error_response);
  } else {
    error_response = {
      message: error.message,
      errorPath: 'GENERIC_ERROR'
    };
  }
  dispatch({
    type: SET_ERROR,
    data: { error_obj: error_response }
  });
};

export const validateCartMaxLimit = any => {
  if (any > 50) {
    return true;
  } else {
    return false;
  }
};

export const getContract = contract => {
  const customerGroup = contract.customerGroup
  if(customer_group.pro_customer_group.includes(customerGroup)){
      return "professional" ;
  }else if(customer_group.consumer_customer_group.includes(customerGroup)){
    return "consumer";
  }else {
    return 'default';
  }
};

export const validateCart = cartItems => cartItems && cartItems.length >= 50;

export const getDefaultAddress = (addresses, buyer, addressType) => {
  var defaultAddress = addresses && addresses.filter(adr => adr.addressType === addressType && adr.isDefault === 'TRUE');
  return defaultAddress && defaultAddress.length > 0 ? defaultAddress[0].addressId : buyer.id;
};

export const payerAddressType = 'RG';

export const billToAddressType = 'RE';

export const updateCartItemsWithGrade = cart => {
  let updatedCart = {...cart}
  updatedCart.items =[]
  cart.items.forEach((item) => {
    if(item.AStockQty) {
      updatedCart.items.push({sku:item.sku, qty: item.AStockQty, grade: 'A' , shipMode: item.shipMode ? item.shipMode :''})
    }
    if(item.BStockQty) {
      updatedCart.items.push({sku:item.sku, qty:item.BStockQty, grade: 'B' , shipMode:item.shipMode ? item.shipMode : ''})
    }
  })
  return updatedCart;
}

export const sortOrderList = listData  => {
  listData.sort((a, b) => {
    if (a.orderDate > b.orderDate) {
      return -1;
    }
    if (a.orderDate < b.orderDate) {
      return 1;
    }
    return 0;
  });
  return listData;
}

export const sortPriceByName = priceData => {
  let sortedData =  [...priceData].sort((a, b) => { 
    let fa = a.originalSku.toLowerCase(), fb = b.originalSku.toLowerCase();
     if (fa < fb) { 
      return -1;
     } if (fa > fb) { 
      return 1;
     } 
     return 0; 
    });
  return sortedData;
}

export const getDivisionAndDistribution = (contract) => {
  if (customer_group["pro_customer_group"].includes(contract.customerGroup)) {
    return '10';
  } else if (customer_group["consumer_customer_group"].includes(contract.customerGroup)) {
    return '20';
  } else {
    return '';
  }
};

export const customer_group = {
  "pro_customer_group": ["Z1", "Z4"],
  "consumer_customer_group": ["Y1", "Y2"]
}

export const getMultiplesOf = (sku_item, buyer) => {
	const orgRoundingIndicator = buyer ? buyer.roundingIndicator : '';
	let validateQtyObj = {type: 'MUL'}
	//customer level validation
	if(orgRoundingIndicator) {
		if (orgRoundingIndicator === 'O') return;
		else if (orgRoundingIndicator === 'M' && sku_item && sku_item.MC !== null) {
			validateQtyObj.val = sku_item.MC
			return validateQtyObj;
		}
		else if (orgRoundingIndicator === 'S' && sku_item && sku_item.SC !== null) {
			validateQtyObj.val = sku_item.SC
			return validateQtyObj;
		}
		else if(orgRoundingIndicator === 'H' && sku_item) {
			validateQtyObj.val = sku_item.SC ? sku_item.SC:sku_item.MC
			return validateQtyObj;
		}
	}
	//material level validation
	else {
		if (sku_item && sku_item.minOrderCode === 'N00' && sku_item.MC !== null) {
			validateQtyObj.val = sku_item.MC
			return validateQtyObj;
		}
		else if (sku_item && sku_item.minOrderCode === 'N01' && sku_item.SC !== null) {
			validateQtyObj.val = sku_item.SC
			return validateQtyObj;
		}
		else if (sku_item && sku_item.minOrderCode === 'N02') {
			validateQtyObj.val = sku_item.SC ? sku_item.SC:sku_item.MC
			return validateQtyObj;
		}
		else if (sku_item && sku_item.minOrderCode === null && sku_item.minOrderQty) {
			validateQtyObj.type = 'MOQ'
			validateQtyObj.val = parseInt(sku_item.minOrderQty,10)
			return validateQtyObj
		}
	}
};