import React, { Component } from 'react';
import { shoudlShowBulletin } from '../bulletin/BulletinHelper';
import IoAndroidLock from 'react-icons/lib/io/android-lock';
function withProtectRoutes(WrappedComponent, passedProps) {
  return class extends Component {
    state = {
      allowed: false,
      decided: false
    };
    componentDidMount() {
      if (isAuthorized(this.props.location.pathname, passedProps)) {
        this.setState({ allowed: true, decided: true });
      } else {
        this.setState({ allowed: false, decided: true });
      }
    }

    render() {
      const { allowed, decided } = this.state;
      return (
        <React.Fragment>
          {allowed && decided && <WrappedComponent />}
          {!allowed && !decided && null}
          {!allowed &&
            decided && (
              <div className="container">
                <p className="alert alert-danger">
                  <IoAndroidLock style={{ fontSize: '3rem', verticalAlign: '-5px' }} />You don't have authorization to access this page
                </p>
              </div>
            )}
        </React.Fragment>
      );
    }
  };
}
export default withProtectRoutes;
const isAuthorized = (path, data) => {
  switch (path) {
    case '/bulletinsearch':
      return shoudlShowBulletin(data);
    default:
      return true;
  }
};
