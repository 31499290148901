import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import { formValueSelector } from 'redux-form';
import * as orderActions from '../../modules/order';
import { removeAttribute, renderSelect } from '../utils/renderUtils';
import * as errorActions from '../../modules/error';
import CreateCredit from './CreateCredit';
import Loading from '../Loading';
import * as commonActions from '../../modules/common';
import Translate from '../dictionary/Translator';
import errorDictionary from '../dictionary/error';
import './credit.css';
import './Promotion.css';
import { translate } from '../dictionary/Translator';
import ui from './dictionary/ui';
import DocumentTitle from 'react-document-title';

const selector = formValueSelector('promotionForm');

var programOptions;
var langId;
var promotions = [];

class Promotion extends React.Component {
  formPromotionOptions = program => {
    if (this.props.credit) {
      this.props.clearCredit();
    }
    promotions = [];
    if (program === 'ZAM') {
      this.props.ZAMList
        ? this.props.ZAMList.map((item, index) =>
            promotions.push({
              value: item.promoNumber,
              label: (
                <span>
                  {item.promoNumber} {item.promoDesc} <br />
                  <Translate word={ui['START_DATE']} />
                  {item.promoStartDate} <br />
                  <Translate word={ui['END_DATE']} />
                  {item.promoEndDate} <br />
                  <Translate word={ui['LAST_SUBMISSION_DATE']} />
                  {item.promoLastSubmitDate}
                </span>
              ),
              promo: item
            })
          )
        : (promotions = []);
    } else if (program === 'ZPM') {
      this.props.ZPMList
        ? this.props.ZPMList.map((item, index) =>
            promotions.push({
              value: item.promoNumber,
              label: (
                <span>
                  {item.promoNumber} {item.promoDesc} <br />
                  <Translate word={ui['START_DATE']} />
                  {item.promoStartDate} <br />
                  <Translate word={ui['END_DATE']} />
                  {item.promoEndDate} <br />
                  <Translate word={ui['LAST_SUBMISSION_DATE']} />
                  {item.promoLastSubmitDate}
                </span>
              ),
              promo: item
            })
          )
        : (promotions = []);
    }
  };

  componentWillMount() {
    this.props.resetError();
    var language = this.props.language;
    langId = translate({ word: ui['LANG_ID'], language });
    this.props.getPromotionList(this.props.contract.salesOrg, this.props.contract.channel, this.props.contract.division, langId);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.credit && nextProps.credit.creditId) {
      this.props.resetForm('promotionForm');
      this.props.clearPromoModels();
    }
    if (nextProps.isFetching !== this.props.isFetching) {
      if (this.status) {
        this.status.focus();
      }
    }
  }
  componentWillUnmount() {
    this.props.resetError();
    this.props.clearPromoModels();
  }

  render() {
    const { error, isFetching, language } = this.props;
    langId = translate({ word: ui['LANG_ID'], language });
    programOptions = translate({ word: ui['PROGRAM_OPTIONS'], language });

    return (
      <DocumentTitle title={`${translate({ word: ui['CLAIM_REQUEST'], language: this.props.language })} | Sony ExpressNet`}>
        <div className="container">
          <div className="row">
            {error && (
              <div className="text-danger">
                <h1 style={{ fontSize: '1.33rem', fontWeight: '450' }}>
                  <Translate word={errorDictionary[error.errorPath]} />
                </h1>
              </div>
            )}
            {!error && isFetching && !this.props.ZAMList && !this.props.ZPMList && <Loading />}
            {!error &&
              isFetching &&
              !this.props.ZAMList &&
              !this.props.ZPMList && (
                <p className="offscreen" ref={elm => (this.status = elm)}>
                  Loading the page
                </p>
              )}
            {!error &&
              !isFetching &&
              !this.props.ZAMList &&
              !this.props.ZPMList && (
                <div>
                  <h1 style={{ fontSize: '2.2rem', fontWeight: '600' }}>
                    <Translate word={ui['PROMOTIONAL_CREDIT_REQUEST']} />
                  </h1>
                  <hr />
                  <h2 style={{ fontSize: '1.5rem', fontWeight: '450' }}>
                    <Translate word={ui['NO_PROMOTION_MESSAGE']} />
                  </h2>
                </div>
              )}
            {!error &&
              (this.props.ZAMList || this.props.ZPMList) && (
                <div>
                  {!error &&
                    isFetching &&
                    !this.props.ZAMList &&
                    !this.props.ZPMList && (
                      <p className="offscreen" ref={elm => (this.status = elm)}>
                        The results are loaded. Page has changed.
                      </p>
                    )}
                  <h1 style={{ fontSize: '2.2rem', fontWeight: '600' }}>
                    <Translate word={ui['PROMOTIONAL_CREDIT_REQUEST']} />
                  </h1>
                  <div />
                  {/* <PromoListPanel ZAMList={this.props.ZAMList} ZPMList={this.props.ZPMLIst} promoClass={this.state.promoClass} setGlyphClass={this.setGlyphClass} /> */}
                  <PromotionSelectionForm
                    onSubmit={this.submit}
                    {...this.props}
                    formPromotionOptions={this.formPromotionOptions}
                    language={this.props.language}
                  />
                  {this.props.promotion && (
                    <div>
                      <hr size="8" />
                      <CreateCredit />
                    </div>
                  )}
                  {this.props.credit && (
                    <div>
                      <hr size="8" />
                      <ShowCreditOrder credit={this.props.credit} />
                    </div>
                  )}
                </div>
              )}
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

class CustomPromotionValue extends React.Component {
  render() {
    return (
      <div className="Select-value" title={this.props.value.promo.promoNumber}>
        <span className="Select-value-label">
          {this.props.value.promo.promoNumber} {this.props.value.promo.promoDesc} <br />
          <Translate word={ui['START_DATE']} />
          {this.props.value.promo.promoStartDate} <br />
          <Translate word={ui['END_DATE']} />
          {this.props.value.promo.promoEndDate} <br />
          <Translate word={ui['LAST_SUBMISSION_DATE']} />
          {this.props.value.promo.promoLastSubmitDate}
        </span>
      </div>
    );
  }
}

class PromotionSelectionForm extends React.Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.isFetching !== this.props.isFetching) {
      if (this.status) {
        this.status.focus();
      }
    }
  }
  render() {
    const { change, handleSubmit, isFetching, formPromotionOptions, getPromotion, language } = this.props;
    removeAttribute();
    return (
      <div id="promotionForm" onSubmit={handleSubmit} style={{ width: '100%' }}>
        <div className="Promotion__group">
          <label htmlFor="program">
            <strong>
              <Translate dictionary={ui} word="PROGRAM_SELECT" language={language} />
            </strong>
          </label>
          <div className="__Field Promotion__program-selection">
            <Field
              id="program"
              aria-label="program"
              label="program"
              name="program"
              language={language}
              component={renderSelect}
              options={programOptions}
              searchable={true}
              placeholder={<Translate word={ui['PROGRAM_SELECTION_MESSAGE']} />}
              onChange={(event, program) => {
                change('promotion', '');
                removeAttribute();
                if (program) {
                  formPromotionOptions(program.value);
                }
              }}
              className="__Field"
            />
          </div>
        </div>
        <div className="Promotion__group">
          <label htmlFor="promotion">
            <strong>
              <Translate dictionary={ui} word="PROMOTION_SELECT" language={language} />
            </strong>
          </label>
          <div className="__Field Promotion__promotion-selection">
            <Field
              id="promotion"
              aria-label="promotion"
              label="promotion"
              name="promotion"
              language={language}
              component={renderSelect}
              options={promotions}
              searchable={true}
              placeholder={<Translate word={ui['PROMOTION_SELECTION_MESSAGE']} />}
              props={{ disabled: isFetching }}
              onChange={(event, promotion) => {
              removeAttribute();
                if (promotion) {
                  getPromotion(promotion.value, langId);
                }
              }}
              valueComponent={CustomPromotionValue}
              filterOption={(options, filter, currentValues) => {
                var addrText =
                  options.promo.promoNumber +
                  ' ' +
                  options.promo.promoDesc +
                  ' ' +
                  options.promo.promoStartDate +
                  ' ' +
                  options.promo.promoEndDate +
                  ' ' +
                  options.promo.promoLastSubmitDate;
                return filter ? addrText && filter && addrText.toLowerCase().includes(filter.toLowerCase()) : true;
              }}
            />
          </div>
        </div>
        {isFetching && <Loading />}
        {isFetching && (
          <p className="offscreen" ref={elm => (this.status = elm)}>
            Loading the page
          </p>
        )}
      </div>
    );
  }
}

let ShowCreditOrder = props => {
  const { credit } = props;
  return (
    <div style={{ paddingBottom: '15px' }}>
      <div>
        <Translate word={ui['CREDIT_CONFIRMATION_MESSAGE_1']} />
      </div>
      <div>
        <Translate word={ui['CREDIT_CONFIRMATION_MESSAGE_2']} />
        {/* source is GV for new Vistex system */}
        <Link to={{ pathname: '/creditview/GV/' + credit.creditId }}>{credit.creditId}</Link>
      </div>
      <div>
        <Translate word={ui['CREDIT_CONFIRMATION_MESSAGE_3']} />
      </div>
    </div>
  );
};

const validate = values => {
  const errors = {};

  if (!values.program) {
    errors.program = <Translate word={ui['PROGRAM_SELECTION_MESSAGE']} />;
  }
  if (!values.promotion) {
    errors.promotion = <Translate word={ui['PROMOTION_SELECTION_MESSAGE']} />;
  }
  return errors;
};

PromotionSelectionForm = reduxForm({
  form: 'promotionForm',
  destroyOnUnmount: true,
  validate
})(PromotionSelectionForm);

const mapStateToProps = (state, props) => {
  return {
    ZAMList: state.order.ZAMList,
    ZPMList: state.order.ZPMList,
    programSelected: selector(state, 'program'),
    promotion: state.order.promotion,
    credit: state.order.credit,
    contract: state.account.contract,
    error: state.error.error_obj,
    isFetching: state.common.isFetching,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getPromotion: (promoNumber, language) => dispatch(orderActions.getPromotion(promoNumber, language)),
    getPromotionList: (salesOrg, channel, division, language) => dispatch(orderActions.getPromotionList(salesOrg, channel, division, language)),
    clearPromoModels: () => dispatch(orderActions.clearPromoModels()),
    clearCredit: () => dispatch(orderActions.clearCredit()),
    resetError: () => dispatch(errorActions.resetError()),
    resetForm: formName => dispatch(commonActions.resetForm(formName))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Promotion);
