import { createStore, compose, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { persistStore, persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import reducers from '../modules';

export default function configureStore() {
  const middewares = [thunkMiddleware];

  const reducer = persistCombineReducers({ key: 'expressnet', storage }, reducers);

  const store = createStore(reducer, compose(applyMiddleware(...middewares)));

  const persistor = persistStore(store);

  return { persistor, store };
}
