import React from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Field, FieldArray, reduxForm, formValueSelector, change } from 'redux-form';
import * as orderActions from '../../modules/order';
import { renderInput, renderFilterSelect, renderDatePicker, renderTextArea, removeAttribute } from '../utils/renderUtils';
import Loading from '../Loading';
import * as errorActions from '../../modules/error';
import * as commonActions from '../../modules/common';
import moment from 'moment';
import './CreateCredit.css';
import Translate from '../dictionary/Translator';
import { translate } from '../dictionary/Translator';
import ui from './dictionary/ui';

const selector = formValueSelector('createCreditForm');

var langId;

class CreateCredit extends React.Component {
  constructor(props) {
    super(props);
    this.state = { models: [] };
  }

  submit = filters => {
    // Add grade A to each line item to be passed as input
    filters.items.forEach(item => item.grade = 'A')
    this.props.resetError();
    this.props.submitCredit(filters, this.props.buyer, this.props.contract, this.props.promotion, this.props.user, langId);
  };

  componentWillMount() {
    this.props.resetError();
    this.props.resetFetching();
    var models = [];
    if (this.props.promotion) {
      this.props.promotion.map((item, index) => models.push({ value: item.model, label: item.model }));
      this.setState({ models: models });
    }
  }

  componentWillUnmount() {
    this.props.resetError();
  }

  componentWillReceiveProps(nextProps) {
    var items = nextProps.cdtItems;
    var creditTotal = 0;
    if (items && items.length > 0) {
      for (let item of items) {
        if (item.model && item.qty) {
          creditTotal += item.creditAmount ? item.qty * item.creditAmount : item.qty * (item.originalPrice - item.newDealerPrice);
        }
      }
    }
    creditTotal = creditTotal ? creditTotal.toLocaleString(undefined, { minimumFractionDigits: 2 }) : '';
    nextProps.updateCreditTotal(creditTotal);
  }

  render() {
    langId = translate({ word: ui['LANG_ID'], language: this.props.language });
    return (
      <div className="container">
        <div className="row">
          <CreateCreditForm onSubmit={this.submit} {...this.props} models={this.state.models} />
        </div>
      </div>
    );
  }
}

let CreateCreditForm = props => {
  const { change, promotion, buyer, handleSubmit, models, isFetching } = props;
  const reasonCode = promotion ? promotion[0].reasonCode : '';
  return (
    <form
      id="createCreditForm"
      onSubmit={handleSubmit}
      onKeyPress={e => {
        if (e.key === 'Enter') e.preventDefault();
      }}
    >
      <div className="form-group __inline-group">
        <span>
          <strong>
            <Translate word={ui['PROMOTION']} />
          </strong>
        </span>
        <span aria-label="Promotion Number">{promotion ? promotion[0].promoNumber : ''}</span>
      </div>
      <div className="form-group __inline-group">
        <span>
          <strong>
            <Translate word={ui['PROMOTION_DATES']} />
          </strong>
        </span>
        <span aria-label="Promotion Dates">
          {promotion ? moment(promotion[0].promoStartDate).format('MM/DD/YYYY') + ' - ' + moment(promotion[0].promoEndDate).format('MM/DD/YYYY') : ''}
        </span>
      </div>
      <div className="form-group __inline-group">
        <span>
          <strong>
            <Translate word={ui['SUBMIT_CREDIT_REQUEST_BY']} />
          </strong>
        </span>
        <span aria-label="Submit Credit Request By">{promotion ? moment(promotion[0].promoLastSubmitDate).format('MM/DD/YYYY') : ''}</span>
      </div>
      <div className="form-group __inline-group">
        <span>
          <strong>
            <Translate word={ui['REQUEST_DATE']} />
          </strong>
        </span>
        <span aria-label="Request Date">{moment().format('MM/DD/YYYY')}</span>
      </div>
      <div className="form-group __inline-group">
        <span>
          <strong>
            <Translate word={ui['DEALER']} />
          </strong>
        </span>
        <span aria-label="Dealer">{buyer ? buyer.name : ''}</span>
      </div>
      <div className="form-group __inline-group" style={{ maxWidth: '455px' }}>
        <label htmlFor="comments" style={{ flexBasis: 'unset' }}>
          <strong>
            <Translate word={ui['COMMENTS']} />
          </strong>
        </label>
        <Field id="comments" name="comments" isClearable={true} style={{ width: '350px', height: '105px' }} component={renderTextArea} />
      </div>
      <div className="form-group __inline-group">
        <label htmlFor="debitReferenceNumber" style={{ flexBasis: 'unset' }}>
          <span className="text-danger">*</span>
          <strong>
            <Translate word={ui['DEBIT_REFERENCE_NUMBER']} />
          </strong>
        </label>
        <Field id="debitReferenceNumber" name="debitReferenceNumber" isClearable={true} component={renderInput} />
      </div>
      <FieldArray id="items" name="items" component={creditItems} models={models} change={change} reasonCode={reasonCode} />
      <div className="row" style={{ paddingBottom: '10px' }}>
        <div style={{ paddingRight: '50px' }}>
          <Button bsStyle="primary" className="btn-margin" type="submit" style={{ float: 'right' }} disabled={isFetching}>
            <Translate word={ui['SUBMIT_BUTTON']} />
          </Button>
        </div>
        {isFetching && <Loading />}
      </div>
    </form>
  );
};

let creditItems = ({ models, fields, isFetching, change, reasonCode, meta: { touched, error, submitFailed } }) => {
  return (
    <div className="container">
      <div className="row">
        <h2 style={{ fontSize: '1.8rem', fontWeight: '450' }}>
          <Translate word={ui['PROMOTION_LINE_ITEMS']} />
        </h2>
        <Button bsStyle="primary" className="btn-margin" id="items" onClick={() => fields.push({})}>
          <Translate word={ui['ADD_ITEM']} />
        </Button>
        <div>{(touched || submitFailed) && error && <span className="text-danger">{error}</span>}</div>
        <div className="text-danger" style={{ paddingTop: '10px' }}>
          <small>
            <Translate word={ui['REQUIRED_FIELDS_MESSAGE']} />
          </small>
        </div>
        <div className="row">
          <div className="overflow-wrapper">
            {reasonCode === 'ZPM' ? (
              <ZPMCreditItems fields={fields} models={models} change={change} reasonCode={reasonCode} />
            ) : (
              <ZAMCreditItems fields={fields} models={models} change={change} reasonCode={reasonCode} />
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group __inline-group" style={{ float: 'right', paddingLeft: '15px' }}>
            <label htmlFor="creditTotal">
              <Translate word={ui['CREDIT_TOTAL']} />
            </label>
            <Field id="creditTotal" name="creditTotal" isClearable={true} disabled={true} component={renderInput} />
          </div>
        </div>
        <div className="disclaimer" style={{ paddingBottom: '10px' }}>
          <Translate word={ui['CREATE_CREDIT_MESSAGE_1']} />
        </div>
        <div className="disclaimer" style={{ paddingBottom: '10px' }}>
          <Translate word={ui['CREATE_CREDIT_MESSAGE_2']} />
        </div>
        <div className="disclaimer" style={{ paddingBottom: '10px' }}>
          <Translate word={ui['CREATE_CREDIT_MESSAGE_3']} />
        </div>
      </div>
    </div>
  );
};

let ZPMCreditItems = props => {
  const { change, fields, models, promotion, isFetching } = props;
  removeAttribute();
  return (
    <table className="table">
      <thead>
        <tr>
          <th style={{ width: '150px' }}>
            <span className="text-danger">*</span>
            <Translate word={ui['MODEL_NUMBER']} />
          </th>
          <th style={{ width: '80px' }}>
            <span className="text-danger">*</span>
            <Translate word={ui['ITEM_QTY']} />
          </th>
          <th style={{ width: '130px' }}>
            <Translate word={ui['NEW_BASE_PRICE']} />
          </th>
          <th style={{ width: '150px' }}>
            <Translate word={ui['ORIGINAL_BASE_PRICE']} />
          </th>
          <th style={{ width: '140px' }}>
            <Translate word={ui['DATE_OF_PURCHASE']} />
          </th>
          <th style={{ width: '120px' }}>
            <Translate word={ui['INVOICE_NUMBER']} />
          </th>
          <th style={{ width: '100px' }}>
            <Translate word={ui['SERIAL_NUMBER']} />
          </th>
          <td style={{ width: '70px' }} />
        </tr>
      </thead>
      <tbody>
        {fields.map((item, index) => {
          return (
            <tr id={'item_' + index} key={index}>
              <td style={{ paddingBottom: '10px', width: '180px' }}>
                <div className="fix-drop-down-overflow">
                  <div className="CreateCredit__SameHeight">
                    <Field
                      id={`${item}.model`}
                      name={`${item}.model`}
                      label="Model Number"
                      component={renderFilterSelect}
                      options={models}
                      style={{ width: '180px' }}
                      placeholder={<Translate word={ui['SELECT_A_MODEL_MESSAGE']} />}
                      props={{ disabled: isFetching }}
                      onChange={(event, modelId) => {
                        removeAttribute();
                        change(`${item}.originalPrice`, promotion ? promotion.find(promo => promo.model === modelId).originalPrice : '');
                        change(`${item}.newDealerPrice`, promotion ? promotion.find(promo => promo.model === modelId).newDealerPrice : '');
                      }}
                    />
                  </div>
                </div>
              </td>
              <td>
                <div className="CreateCredit__SameHeight">
                  <Field id={`${item}.qty`} name={`${item}.qty`} isClearable={true} style={{ width: '80px' }} component={renderInput} label="quantity" />
                </div>
              </td>
              <td>
                <div className="CreateCredit__SameHeight">
                  <Field
                    id={`${item}.newDealerPrice`}
                    name={`${item}.newDealerPrice`}
                    isClearable={true}
                    disabled={true}
                    style={{ width: '130px' }}
                    component={renderInput}
                    label="New Dealer Price"
                  />
                </div>
              </td>
              <td>
                <div className="CreateCredit__SameHeight">
                  <Field
                    id={`${item}.originalPrice`}
                    name={`${item}.originalPrice`}
                    isClearable={true}
                    disabled={true}
                    style={{ width: '140px' }}
                    component={renderInput}
                    className="CreateCredit__SameHeight"
                    label="Original Price"
                  />
                </div>
              </td>
              <td className="purchaseDate">
                <div className="CreateCredit__SameHeight">
                <label for="items[0].purchaseDate" class="purchasedate_label_hide_wcag">Purchase Date</label>
                  <Field
                    id={`${item}.purchaseDate`}
                    name={`${item}.purchaseDate`}
                    label="Purchase Date"
                    isClearable={true}
                    style={{ width: '140px', fixedHeight: 'false' }}
                    component={renderDatePicker}
                    className="CreateCredit__SameHeight"
                                     />
                </div>
              </td>
              <td>
                <div className="CreateCredit__SameHeight">
                  <Field
                    id={`${item}.invoiceNumber`}
                    name={`${item}.invoiceNumber`}
                    isClearable={true}
                    style={{ width: '120px' }}
                    component={renderInput}
                    className="CreateCredit__SameHeight"
                    label="Invoice Number"
                  />
                </div>
              </td>
              <td>
                <div className="CreateCredit__SameHeight">
                  <Field
                    id={`${item}.serialNumber`}
                    name={`${item}.serialNumber`}
                    isClearable={true}
                    style={{ width: '100px' }}
                    component={renderInput}
                    className="CreateCredit__SameHeight"
                    label="Serial Number"
                  />
                </div>
              </td>
              <td>
                <button
                  id="RemoveItem"
                  type="button"
                  className="btn btn-danger glyphicon glyphicon-trash"
                  title="Remove Item"
                  aria-label="Remove"
                  onClick={() => fields.remove(index)}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

let ZAMCreditItems = props => {
  const { change, fields, models, promotion, isFetching } = props;
  removeAttribute();
  return (
    <table className="table">
      <thead>
        <tr>
          <th style={{ width: '150px' }}>
            <span className="text-danger">*</span>
            <Translate word={ui['MODEL_NUMBER']} />
          </th>
          <th style={{ width: '100px', paddingLeft: '5%' }}>
            <span className="text-danger">*</span>
            <Translate word={ui['ITEM_QTY']} />
          </th>
          <th style={{ width: '140px' }}>
            <Translate word={ui['DATE_OF_PURCHASE']} />
          </th>
          <th style={{ width: '100px' }}>
            <Translate word={ui['CREDIT_AMOUNT']} />
          </th>
          <td style={{ width: '70px' }} />
        </tr>
      </thead>
      <tbody>
        {fields.map((item, index) => {
          return (
            <tr id={'item_' + index} key={index}>
              <td style={{ paddingBottom: '10px', width: '100px' }}>
                <div className="fix-drop-down-overflow">
                  <div className="CreateCredit__SameHeight">
                    <Field
                      id={`${item}.model`}
                      label="Model Number"
                      name={`${item}.model`}
                      component={renderFilterSelect}
                      options={models}
                      style={{ width: '180px' }}
                      placeholder={<Translate word={ui['SELECT_A_MODEL_MESSAGE']} />}
                      props={{ disabled: isFetching }}
                      onChange={(event, modelId) => {
                        removeAttribute();
                        change(`${item}.creditAmount`, promotion ? promotion.find(promo => promo.model === modelId).creditAmount : '');
                      }}
                    />
                  </div>
                </div>
              </td>
              <td style={{ paddingLeft: '5%' }}>
                <div className="CreateCredit__SameHeight">
                  <Field id={`${item}.qty`} name={`${item}.qty`} isClearable={true} style={{ width: '70px' }} component={renderInput} label="quantity" />
                </div>
              </td>
              <td className="purchaseDate">
                <div className="CreateCredit__SameHeight">
                <label for="items[0].purchaseDate" class="purchasedate_label_hide_wcag">Purchase Date</label>
                  <Field
                    id={`${item}.purchaseDate`}
                    label="Purchase Date"
                    name={`${item}.purchaseDate`}
                    isClearable={true}
                    style={{ width: '140px' }}
                    component={renderDatePicker}
                  />
                </div>
              </td>
              <td>
                <div className="CreateCredit__SameHeight">
                  <Field
                    id={`${item}.creditAmount`}
                    name={`${item}.creditAmount`}
                    isClearable={true}
                    disabled={true}
                    style={{ width: '100px' }}
                    component={renderInput}
                    label="Credit Amount"
                  />
                </div>
              </td>
              <td>
                <button
                  id="RemoveItem"
                  type="button"
                  className="btn btn-danger glyphicon glyphicon-trash"
                  title="Remove Item"
                  onClick={() => fields.remove(index)}
                  aria-label="Remove"
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const validate = values => {
  const errors = {};
  const itemsErrors = [];
  if (!values.debitReferenceNumber) {
    errors.debitReferenceNumber = <Translate word={ui['ERROR_DEBIT_REF_NUMBER']} />;
  }
  if (!values.items || !(values.items.length > 0)) {
    errors.items = { _error: <Translate word={ui['ITEM_SELECTION_MESSAGE']} /> };
  } else {
    values.items.forEach((item, itemIndex) => {
      const itemErrors = {};
      if (!item.model) {
        itemErrors.model = <Translate word={ui['SELECT_A_MODEL_MESSAGE']} />;
        itemsErrors[itemIndex] = itemErrors;
      }
      if (isNaN(item.qty) || !item.qty || item.qty <= 0) {
        itemErrors.qty = <Translate word={ui['ENTER_QUANTITY_MESSAGE']} />;
        itemsErrors[itemIndex] = itemErrors;
      }
      if (item.purchaseDate && !moment(item.purchaseDate, 'MM/DD/YYYY', true).isValid()) {
        itemErrors.purchaseDate = <Translate word={ui['ERROR_DATE_FORMAT']} />;
        itemsErrors[itemIndex] = itemErrors;
      }
    });
  }
  if (itemsErrors.length) {
    errors.items = itemsErrors;
  }
  return errors;
};

ZPMCreditItems = connect((state, props) => ({
  promotion: state.order.promotion
}))(ZPMCreditItems);

ZAMCreditItems = connect((state, props) => ({
  promotion: state.order.promotion
}))(ZAMCreditItems);

CreateCreditForm = reduxForm({
  form: 'createCreditForm',
  destroyOnUnmount: true,
  initialValues: { items: [{}] },
  validate
})(CreateCreditForm);

const mapStateToProps = (state, props) => {
  return {
    promotion: state.order.promotion,
    cdtItems: selector(state, 'items'),
    buyer: state.account.buyer,
    user: state.account.user,
    contract: state.account.contract,
    isFetching: state.common.isFetching
  };
};

const mapDispatchToProps = dispatch => {
  return {
    submitCredit: (filters, buyer, contract, promotion, user, language) =>
      dispatch(orderActions.submitCredit(filters, buyer, contract, promotion, user, language)),
    resetError: () => dispatch(errorActions.resetError()),
    resetFetching: () => dispatch(commonActions.resetFetching()),
    updateCreditTotal: total => dispatch(change('createCreditForm', 'creditTotal', total))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateCredit);
