import React from 'react';
import MdKeyboardArrowUp from 'react-icons/lib/md/keyboard-arrow-up';
import './GoUpButton.css';
class GoUpButton extends React.Component {
  state = {
    location: null
  };
  scrollUp = e => {
    try {
      document.documentElement.scrollIntoView();
    } catch (e) {
      console.log(e);
    }
  };
  componentDidMount() {
    window.onscroll = e => {
      this.setState({ location: window.scrollY });
    };
  }
  render() {
    return (
      <div ref={btnTop => (this.btnTop = btnTop)} onFocus={null}>
        {document.documentElement.scrollTop > Math.max(document.documentElement.clientHeight, window.innerHeight || 0) && (
          <MdKeyboardArrowUp className="GoUpButton" onClick={this.scrollUp} style={{ ...this.state }} role="img" aria-label="Go UP Button" />
        )}
      </div>
    );
  }
}

export default GoUpButton;
