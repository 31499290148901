import { UNAVAILABLE_ERROR } from './common_actions';

export default function(state = { isAppUnavailable: false }, action) {
  switch (action.type) {
    case UNAVAILABLE_ERROR:
      return { isAppUnavailable: action.status };
    default:
      return state;
  }
}

export const resetAppStatus = () => {
  return {
    type: UNAVAILABLE_ERROR,
    status: false
  };
};
