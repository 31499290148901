import React from 'react';
import { withRouter } from 'react-router-dom';
import './MobileViewCatalogMenu.css';
import Translate from '../../dictionary/Translator';
import ui from '../dictionary/ui.json';
import { connect } from 'react-redux';
const MobileViewCatalogMenu = ({ mains, onGetSubCategories, selectedMain, selectedId, selectedSub, onSelectSub, onSelectMain, lang }) => {
  return mains.length > 0 ? (
    <div className="Full-View-Catalog-Menu">
      {mains.map(category => (
        <Category
          title={category.name}
          subs={onGetSubCategories(category.id)}
          onSelectSub={onSelectSub}
          onSelectMain={onSelectMain}
          selectedMain={selectedMain}
          selectedId ={selectedId}
          selectedSub={selectedSub}
          key={category.id}
          id ={category.id}
        />
      ))}
    </div>
  ) : (
    <p style={{ textAlign: 'center', verticalAlign: 'middle', color: '#fff' }}>
      <Translate word="NO_CATEGORY" dictionary={ui} language={lang} />
    </p>
  );
};

const Category = ({ title, subs, onSelectSub, onSelectMain, selectedMain, selectedSub, id }) => {
  return (
    <div className="Full-View-Catalog-Menu__category">
      <span
        className={
          selectedMain === title
            ? 'Full-View-Catalog-Menu__category__title Full-View-Catalog-Menu__category__title--open'
            : 'Full-View-Catalog-Menu__category__title'
        }
        onClick={() => onSelectMain({ title, id })}
      >
        {title}
      </span>
      <ul className={selectedMain === title ? 'Full-view-Catalog-Menu__subcategories open' : 'Full-view-Catalog-Menu__subcategories'}>
        {subs.map(({ name, id }, index) => (
          <li className={selectedSub === id ? 'selected-sub' : ''} key={`${id}-${index}`} onClick={() => onSelectSub({ id })}>
            {name}
          </li>
        ))}
      </ul>
    </div>
  );
};
const mapStateToProps = ({ language }) => {
  return { lang: language };
};
export default connect(mapStateToProps)(withRouter(MobileViewCatalogMenu));
