const PRIVACY_POLICY_LINKS = {
  US: {
    en_US: [
      {
        key: 'SEL_T_C',
        url: 'https://www.sony.com/electronics/terms-conditions',
        label: 'SEL_T_C_LABEL'
      },
      {
        key: 'SEL_PRIVACY_POLICY',
        url: 'https://electronics.sony.com/privacy-policy',
        label: 'SEL_PRIVACY_POLICY_LABEL'
      },
      {
        key: 'CA_PRIVACY_NOTICE',
        url: 'https://electronics.sony.com/privacy-policy#DataPractices',
        label: 'CA_PRIVACY_NOTICE_LABEL'
      },
      {
        key: 'PERSONAL_INFO',
        url: 'https://products.sel.sony.com/SEL/legal/DNSRequest.html',
        label: 'PERSONAL_INFO_LABEL'
      }
    ]
  },
  CA: {
    en_CA: [
      {
        key: 'FOOTER_PRIVACY',
        url: 'http://corporate.sony.ca/view/privacy.htm',
        label: 'FOOTER_PRIVACY'
      }
    ],
    fr_CA: [
      {
        key: 'FOOTER_PRIVACY',
        url: 'http://fr.corporate.sony.ca/view/politiquedeconfidentialite.htm',
        label: 'FOOTER_PRIVACY'
      }
    ]
  }
};
const APPS_LINKS = {
  US: {
    en_US: [
      {
        key: 'FOOTER_DEALER_SOURCE',
        url: 'https://www.sony.com/dealersource',
        label: 'DS_LABEL'
      },
      {
        key: 'FOOTER_C_WEBSITE',
        url: 'https://www.electronics.sony.com',
        label: 'CONS_E_WEBSITE_LABEL'
      },
      {
        key: 'FOOTER_P_WEBSITE',
        url: 'http://pro.sony.com',
        label: 'PRO_WEBSITE_LABEL'
      },
      {
        key: 'FOOTER_REBATES',
        url: 'https://www.sony.com/rebates',
        label: 'REBATES_LABEL'
      }
    ]
  },
  CA: {
    en_CA: [
      {
        key: 'FOOTER_DEALER_SOURCE',
        url: 'https://www.sony.com/dealersource',
        label: 'DS_LABEL'
      },
      {
        key: 'FOOTER_C_WEBSITE',
        url: 'https://www.sony.ca/en',
        label: 'CONS_E_WEBSITE_LABEL'
      },
      {
        key: 'FOOTER_P_WEBSITE',
        url: 'http://pro.sony/en_CA',
        label: 'PRO_WEBSITE_LABEL'
      },
      {
        key: 'FOOTER_REBATES',
        url: 'https://www.sony.com/rebates',
        label: 'REBATES_LABEL'
      }
    ],
    fr_CA:  [
      {
        key: 'FOOTER_DEALER_SOURCE',
        url: 'https://www.sony.com/dealersource',
        label: 'DS_LABEL'
      },
      {
        key: 'FOOTER_C_WEBSITE',
        url: 'https://www.sony.ca/fr',
        label: 'CONS_E_WEBSITE_LABEL'
      },
      {
        key: 'FOOTER_P_WEBSITE',
        url: 'http://pro.sony/qf_CA',
        label: 'PRO_WEBSITE_LABEL'
      },
      {
        key: 'FOOTER_REBATES',
        url: 'https://www.sony.com/rebates',
        label: 'REBATES_LABEL'
      }
    ]
  }
};
module.exports = { PRIVACY_POLICY_LINKS, APPS_LINKS };
