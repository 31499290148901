import React from 'react';
import { connect } from 'react-redux';
import * as authActions from '../../modules/auth';

class Callback extends React.Component {
  componentWillMount() {
    this.props.auth(window.location.href);
    this.props.history.push(this.props.isAdmin ? '/admin/accountSearch' : '/');
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state, props) => {
  return {
    isAdmin: state.auth.isAdmin
  };
};

const mapDispatchToProps = dispatch => {
  return {
    auth: location => dispatch(authActions.authReceived(location))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Callback);
