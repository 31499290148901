import React from 'react';
import { Grid, Table, Column } from 'react-virtualized';
import { connect } from 'react-redux';
import { RenderPanel } from '../utils/renderUtils';
import roles from '../dictionary/roles';
import ui from './dictionary/ui';
import Translate from '../dictionary/Translator';
import { translate } from '../dictionary/Translator';
import DocumentTitle from 'react-document-title';
import address_types from '../configurations/address_types.json'
class MyAccount extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      addresses: this.props.addresses,
      spAddresses: this.getAddresses(this.props.addresses, address_types['soldTo']),
      pyAddresses: this.getAddresses(this.props.addresses, address_types['payer']),
      bpAddresses: this.getAddresses(this.props.addresses, address_types['billTo']),
      shipAddresses: this.getAddresses(this.props.addresses, address_types['shipTo']),
      addressClass: false,
      userClass: false,
      managerClass: false
    };

    this.spCellRenderer = this.spCellRenderer.bind(this);
    this.bpCellRenderer = this.bpCellRenderer.bind(this);
    this.pyCellRenderer = this.pyCellRenderer.bind(this);
    this.shipCellRenderer = this.shipCellRenderer.bind(this);
  }

  getAddresses = (addresses, type) => {
    if (!addresses) return undefined;

    let addr = [];
    let defAddr = addresses.find(addr => addr.addressType === type && addr.isDefault === 'TRUE');
    if (defAddr) {
      addr.push(defAddr);
    }
    addr = addr.concat(addresses.filter(addr => addr.addressType === type && addr.isDefault === 'FALSE'));

    return addr.length === 0 ? undefined : addr;
  };

  getRowCount = addresses => {
    let count = 0;
    if (addresses) count = addresses.length % 4 === 0 ? addresses.length / 4 : ((addresses.length / 4) >> 0) + 1;
    return count;
  };

  spCellRenderer({ columnIndex, key, rowIndex, style }) {
    return <AddressCell key={key} style={style} address={this.state.spAddresses[rowIndex * 4 + columnIndex]} />;
  }

  pyCellRenderer({ columnIndex, key, rowIndex, style, ...rest }) {
    return <AddressCell key={key} style={style} address={this.state.pyAddresses[rowIndex * 4 + columnIndex]} />;
  }

  bpCellRenderer({ columnIndex, key, rowIndex, style }) {
    return <AddressCell key={key} style={style} address={this.state.bpAddresses[rowIndex * 4 + columnIndex]} />;
  }

  shipCellRenderer({ columnIndex, key, rowIndex, style }) {
    return <AddressCell key={key} style={style} address={this.state.shipAddresses[rowIndex * 4 + columnIndex]} />;
  }

  setGlyphClass = panelType => {
    switch (panelType) {
      case 'addr':
        this.setState(prevState => ({ addressClass: !prevState.addressClass }));
        break;
      case 'manager':
        this.setState(prevState => ({ managerClass: !prevState.managerClass }));
        break;
      case 'user':
        this.setState(prevState => ({ userClass: !prevState.userClass }));
        break;
      default:
    }
  };

  render() {
    const { user, buyer, userList, addresses, language } = this.props;
    const { spAddresses, pyAddresses, shipAddresses, bpAddresses, addressClass, userClass } = this.state;

    return (
      <DocumentTitle title={`${translate({ dictionary: ui, language, word: 'TITLE' })} | Sony ExpressNet`}>
        <div style={{ paddingRight: '3%', paddingLeft: '3%' }}>
          <PersonalInformation user={user} buyer={buyer} />

          <br />
          {addresses &&
            addresses.length > 0 && (
              <AddressPanel
                setGlyphClass={this.setGlyphClass}
                spAddresses={spAddresses}
                spCellRenderer={this.spCellRenderer}
                pyAddresses={pyAddresses}
                pyCellRenderer={this.pyCellRenderer}
                shipAddresses={shipAddresses}
                shipCellRenderer={this.shipCellRenderer}
                bpAddresses={bpAddresses}
                bpCellRenderer={this.bpCellRenderer}
                getRowCount={this.getRowCount}
                addressClass={addressClass}
              />
            )}
          {(user.role === 'XPR' || user.role === 'XPA' || user.role === 'XAD') &&
            userList &&
            userList.length > 0 && <UserList language={language} userList={userList} userClass={userClass} setGlyphClass={this.setGlyphClass} />}
        </div>
      </DocumentTitle>
    );
  }
}

let AddressCell = props => {
  const { address, style } = props;
  return (
    <div style={style}>
      {address && (
        <div
          style={{
            border: '1px solid',
            borderColor: '#D3D3D3',
            height: '130px',
            margin: '0 5px',
            padding: '5px',
            background: address.isDefault === 'TRUE' ? 'lightgrey' : ''
          }}
        >
          {address.isDefault === 'TRUE' && (
            <div>
              <strong>
                <Translate dictionary={ui} word="MYACCOUNT_ADDRESS_PANEL_DEFAULT_ADDRESS" />
              </strong>
            </div>
          )}
          {address.name}
          <br />
          {address.street}
          <br />
          {address.city},{address.state} {address.postalCode}
          <br />
          {address.country}
        </div>
      )}
    </div>
  );
};

let AddressPanel = props => {
  const {
    spAddresses,
    spCellRenderer,
    pyAddresses,
    pyCellRenderer,
    shipAddresses,
    shipCellRenderer,
    bpAddresses,
    bpCellRenderer,
    getRowCount,
    setGlyphClass,
    addressClass
  } = props;

  return (
    <RenderPanel
      setGlyphClass={setGlyphClass}
      header={<Translate dictionary={ui} word="MYACCOUNT_ADDRESS_PANEL_HEADER" />}
      classType={addressClass}
      panelType="addr"
    >
      <div className="table-responsive">
        <div className="row" style={{ margin: '0', paddingBottom: '20px', fontSize: '12px' }}>
          <Translate dictionary={ui} word="MYACCOUNT_ADDRESS_PANEL_MSG" />
        </div>
        {spAddresses && (
          <div className="row" style={{ margin: 0 }}>
            <div className="col-sm-2">
              <h5 style={{ fontWeight: 'bold' }}>
                <Translate dictionary={ui} word="MYACCOUNT_ADDRESS_PANEL_SOLDTOADR" />
              </h5>
            </div>
            <div className="col-sm-10">
              <Grid
                cellRenderer={spCellRenderer}
                columnCount={4}
                columnWidth={220}
                height={getRowCount(spAddresses) * 140}
                rowCount={getRowCount(spAddresses)}
                rowHeight={140}
                width={900}
              />
            </div>
          </div>
        )}
        {pyAddresses && (
          <div className="row" style={{ margin: 0 }}>
            <div className="col-sm-2">
              <h5 style={{ fontWeight: 'bold' }}>
                <Translate dictionary={ui} word="MYACCOUNT_ADDRESS_PANEL_PAYERADR" />
              </h5>
            </div>
            <div className="col-sm-10">
              <Grid
                cellRenderer={pyCellRenderer}
                columnCount={4}
                columnWidth={220}
                height={getRowCount(pyAddresses) * 140}
                rowCount={getRowCount(pyAddresses)}
                rowHeight={140}
                width={900}
              />
            </div>
          </div>
        )}
        {shipAddresses && (
          <div className="row" style={{ margin: 0 }}>
            <div className="col-sm-2">
              <h5 style={{ fontWeight: 'bold' }}>
                <Translate dictionary={ui} word="MYACCOUNT_ADDRESS_PANEL_SHPTOADR" />
              </h5>
            </div>
            <div className="col-sm-10">
              <Grid
                cellRenderer={shipCellRenderer}
                columnCount={4}
                columnWidth={220}
                height={getRowCount(shipAddresses) * 140}
                rowCount={getRowCount(shipAddresses)}
                rowHeight={140}
                width={900}
              />
            </div>
          </div>
        )}
        {bpAddresses && (
          <div className="row" style={{ margin: 0 }}>
            <div className="col-sm-2">
              <h5 style={{ fontWeight: 'bold' }}>
                <Translate dictionary={ui} word="MYACCOUNT_ADDRESS_PANEL_BILLTOADR" />
              </h5>
            </div>
            <div className="col-sm-10">
              <Grid
                cellRenderer={bpCellRenderer}
                columnCount={4}
                columnWidth={220}
                height={getRowCount(bpAddresses) * 140}
                rowCount={getRowCount(bpAddresses)}
                rowHeight={140}
                width={900}
              />
            </div>
          </div>
        )}
      </div>
    </RenderPanel>
  );
};

let UserList = props => {
  const { userList, setGlyphClass, userClass, language } = props;

  return (
    <RenderPanel setGlyphClass={setGlyphClass} header="User List" classType={userClass} panelType="user">
      <div className="table-responsive">
        <div className="row" style={{ margin: '0', paddingBottom: '20px', fontSize: '12px' }}>
          <Translate dictionary={ui} word="MYACCOUNT_USERLIST_MSG1" />
          <a href={'mailto:ExpressNetRegistration@sony.com'}>ExpressNetRegistration@sony.com</a>. <Translate dictionary={ui} word="MYACCOUNT_USERLIST_MSG2" />
          <br />
          <a href={language === 'fr_CA' ? '/assets/docs/ExpressNetRegistrationForm_FR.docx' : '/assets/docs/ExpressNetRegistrationForm.doc'}>
            <Translate dictionary={ui} word="MYACCOUNT_USERLIST_DWNLD_FORM" />
          </a>
        </div>
        <Table
          width={1100}
          height={userList.length < 15 ? (userList.length + 1) * 30 : 480}
          headerHeight={20}
          rowHeight={30}
          rowCount={userList.length}
          rowGetter={({ index }) => userList[index]}
        >
          <Column
            label={<Translate dictionary={ui} word="MYACCOUNT_USERLIST_ROLE" />}
            dataKey="role"
            width={200}
            cellRenderer={({ cellData }) => translate({ word: roles[cellData], language: language })}
          />
          <Column width={200} label={<Translate dictionary={ui} word="MYACCOUNT_USERLIST_USERLIST" />} dataKey="id" />
          <Column
            width={300}
            label="Name"
            dataKey="id"
            cellRenderer={({ cellData }) => {
              let x = userList.find(user => user.id === cellData);
              return (
                <div>
                  {x.firstName} {x.lastName}
                </div>
              );
            }}
          />
          <Column
            width={300}
            label={<Translate dictionary={ui} word="MYACCOUNT_USERLIST_EMAIL" />}
            dataKey="email"
            cellRenderer={({ cellData }) => <a href={'mailto:' + cellData}>{cellData}</a>}
          />
          <Column width={300} label={<Translate dictionary={ui} word="MYACCOUNT_USERLIST_PHONE" />} dataKey="phone" />
        </Table>
      </div>
    </RenderPanel>
  );
};

let PersonalInformation = props => {
  const { user, buyer } = props;
  return (
    <div>
      <h1>
        <Translate dictionary={ui} word="MYACCOUNT_HEADER" />
      </h1>
      <br />
      <h2 style={{ fontSize: '2.3rem', fontWeight: '600' }}>
        <Translate dictionary={ui} word="MYACCOUNT_PI_HEADER" />
      </h2>
      <hr />
      <div className="row">
        <div className="col-sm-2">
          <span style={{ fontWeight: '600' }}>
            <Translate dictionary={ui} word="MYACCOUNT_PI_USERID" />
          </span>
        </div>
        <div className="col-sm-3">{user.id}</div>
      </div>
      <div className="row">
        <div className="col-sm-2">
          <span style={{ fontWeight: '600' }}>
            <Translate dictionary={ui} word="MYACCOUNT_PI_ORG" />
          </span>
        </div>
        <div className="col-sm-3">{buyer.name}</div>
      </div>
      <div className="row">
        <div className="col-sm-2">
          <span style={{ fontWeight: '600' }}>
            <Translate dictionary={ui} word="MYACCOUNT_PI_NAME" />
          </span>
        </div>
        <div className="col-sm-2">
          {user.firstName} {user.lastName}
        </div>
      </div>
      <div className="row">
        <div className="col-sm-2">
          <span style={{ fontWeight: '600' }}>
            <Translate dictionary={ui} word="MYACCOUNT_PI_EMAIL" />
          </span>
        </div>
        <div className="col-sm-3">{user.email}</div>
      </div>
      <div className="row">
        <div className="col-sm-2">
          <span style={{ fontWeight: '600' }}>
            <Translate dictionary={ui} word="MYACCOUNT_PI_ROLE" />
          </span>
        </div>
        <div className="col-sm-3">
          <Translate word={roles[user.role]} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  var userList = state.account.userList;
  userList = userList ? userList.filter(user => user.role !== 'OASC' && user.role !== 'CLAIMS') : [];

  return {
    user: state.account.user,
    buyer: state.account.buyer,
    addresses: state.account.addresses,
    userList,
    language: state.language
  };
};

export default connect(mapStateToProps)(MyAccount);
