import React, { Component } from 'react';
import BulletinSearchResults from './BulletinSearchResults';
import { connect } from 'react-redux';
import Loading from '../Loading';
import BulletinSearchFrom from './BulletinSearchForm';
import { searchBulletin } from './BulletinHelper';
import Translate, { translate } from '../dictionary/Translator';
import ui from './dictionary/ui';
import DocumentTitle from 'react-document-title';
import { downloadFilesAndCompress } from '../../modules/bulletin';
import IoAndroidDownload from 'react-icons/lib/io/android-download';
export class BulletinSearch extends Component {
  state = {
    model: '',
    number: '',
    description: '',
    type: '',
    effectivePeriod: '',
    publishPeriod: '',
    allSelected: false,
    selectedBulletins: {},
    results: undefined,
    bulletinNumbers: undefined,
    searching: false,
    downloadError: false
  };
  onInputChange = ({ key, value }) => {
    this.setState({ [key]: value, downloadError: false });
  };
  onSelectABulletin = e => {
    const name = e.target.name;
    const checked = e.target.checked;
    this.setState(pre => ({
      downloadError: false,
      selectedBulletins: {
        ...pre.selectedBulletins,
        [name]: checked
      }
    }));
  };
  onSelectAllChange = e => {
    let selectedBulletins = {};
    Object.keys(this.state.selectedBulletins).forEach(key => {
      selectedBulletins = {
        ...selectedBulletins,
        [key]: e.target.checked
      };
    });
    this.setState({ allSelected: e.target.checked, selectedBulletins, downloadError: false });
  };
  onClearClick = () => {
    this.setState({
      model: '',
      number: '',
      description: '',
      type: '',
      effectivePeriod: '',
      publishPeriod: '',
      results: undefined,
      allSelected: false,
      downloadError: false,
      searching: false
    });
    this.initiateSelectedBulletins();
  };
  onSearchBulletin = () => {
    this.setState({ searching: true ,allSelected:false});
    const { model, type, effectivePeriod, publishPeriod, number, description } = this.state;
    const { bulletins } = this.props;
    const results = searchBulletin({ bulletins, model, type, effectivePeriod, publishPeriod, number, description });
    this.setState({
      selectedBulletins: results.reduce((accu, { bulletin_no }) => {
        return {
          ...accu,
          [bulletin_no]: false
        };
      }, {})
      
    });
    this.setState({ results, downloadError: false });
    setTimeout(() => {
      this.setState({ searching: false });
    }, 500);
  };
  componentDidMount() {
    this.initiateSelectedBulletins();
  }
  onDownloadPDFs = () => {
    const { downloadFilesAndCompress } = this.props;
    const downloadList = this.props.bulletins.filter(bulletin => this.state.selectedBulletins[bulletin.bulletin_no]);
    if (downloadList && downloadList.length > 0) {
      downloadFilesAndCompress({ bulletins: downloadList })
        .then(() => {
          this.setState({ downloadError: false,allSelected:false });
        })
        .catch(() => {
          this.setState({ downloadError: true });
        })
        .finally(() => {
          this.initiateSelectedBulletins();
        });
    }
  };
  initiateSelectedBulletins = () => {
    this.setState({
      selectedBulletins: this.props.bulletins.reduce((accu, { bulletin_no }) => {
        return {
          ...accu,
          [bulletin_no]: false
        };
      }, {})
    });
  };
  render() {
    const { fetching, error, bulletins, downloading,bulletin_types } = this.props;
    const { effectivePeriod, publishPeriod, type, model, description, number, searching, results, allSelected, selectedBulletins, downloadError } = this.state;
    return (
      <DocumentTitle title={`${translate({ dictionary: ui, word: 'title', language: this.props.language })} | Sony ExpressNet`}>
        <div className="container">
          <h1 style={{ fontSize: '2.2rem', fontWeight: '600' }}>
            <Translate dictionary={ui} word="title" />
          </h1>
          {error && <BulletinError message={error} />}
          {fetching && <Loading />}
          {downloading && <Loading />}
          {searching && <Loading />}
          <div className={`${(fetching || error || downloading) && 'disabled-element'}`}>
            <BulletinSearchFrom
              onInputChange={this.onInputChange}
              model={model}
              type={type}
              number={number}
              description={description}
              effectivePeriod={effectivePeriod}
              publishPeriod={publishPeriod}
              onClear={this.onClearClick}
              onSearch={this.onSearchBulletin}
              typeOptions={bulletin_types}
              models={populateModels(bulletins)}
              numbers={populateNumbers(bulletins)}
            />
            <hr className="hr-customized" style={{ margin: '1% 0' }} />
            {results &&
              !searching && (
                <div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
                    <h2 style={{ fontSize: '2.2rem', fontWeight: '600' }}>
                      <Translate dictionary={ui} word="SEARCH_RESULTS" />
                    </h2>
                    {results &&
                      results.length > 0 && (
                        <button
                          className="btn btn-primary"
                          onClick={this.onDownloadPDFs}
                          disabled={Object.keys(selectedBulletins).filter(key => selectedBulletins[key]).length <= 0}
                          title="Select bulletins to download PDFs"
                        >
                          <IoAndroidDownload role="img" aria-label="Download PDF Files" style={{ fontSize: '2.5rem', marginRight: '1%', marginBottom: '3%' }} />
                          <Translate word="DOWNLOAD_PDF" dictionary={ui} />
                        </button>
                      )}
                  </div>
                  {downloadError && <BulletinError message={'Something went wrong when tried to download pdf files'} />}
                  <BulletinSearchResults
                    results={results}
                    allSelected={allSelected}
                    onSelectAll={this.onSelectAllChange}
                    selectedBulletins={selectedBulletins}
                    onSelectABulletin={this.onSelectABulletin}
                  />
                </div>
              )}
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

export const BulletinError = ({ message }) => {
  return <p className="alert alert-danger">{message}</p>;
};

const populateModels = bulletins => {
  return bulletins.reduce((accu, { model }) => {
    const temp = [];
    model.forEach(m => {
      if (!accu.includes(m)) {
        temp.push(m);
      }
    });
    return accu.concat(temp);
  }, []);
};

const populateNumbers = bulletins => {
  return bulletins.reduce((accu, { bulletin_no }) => {
    if (!accu.includes(bulletin_no)) {
      return accu.concat(bulletin_no);
    }
    return accu;
  }, []);
};
function mapStateToProps({ account, bulletin, language }) {
  return {
    cpg: account.contract.pricingGroup,
    fetching: bulletin.fetching,
    downloading: bulletin.downloading,
    error: bulletin.error ? bulletin.error.message : null,
    bulletins: bulletin.bulletins,
    bulletin_types:bulletin.bulletin_types,
    language
  };
}

export default connect(
  mapStateToProps,
  { downloadFilesAndCompress }
)(BulletinSearch);
