import React from 'react';
import { connect } from 'react-redux';
import Translate, { translate } from '../dictionary/Translator';
import ui from './dictionary/ui.json';
import EmailProvider from './EmailProvider';
const ContactUs = ({ managerList, language, ZHUsersList, ZKUserList, country, managerClass, contractType }) => {
  var isZHPresent = ZHUsersList && ZHUsersList.length > 0 ? true : false;
  var isZKPresent = ZKUserList && ZKUserList.length > 0;
  var isManagerListPresent = managerList && managerList.length > 0 ? true : false;
  var isCAProf = country === 'CA' && contractType === 'professional' ? true : false;

  return (
    <div className="container">
      <h1>
        <Translate dictionary={ui} word="CONTACT_US" />
      </h1>
      <p className="offscreen" id="mail-desc">
        redirects to mail
      </p>
      <div>
        {isManagerListPresent && <SalesTable isZHPresent={isZHPresent} language={language} contacts={managerList} />}
        {isCAProf && <CAProfessionalOrdersTable language={language} isZKPresent={isZKPresent} />}
        {!isCAProf && isZHPresent && <OrdersTable language={language} ZHUsersList={ZHUsersList} ZKUserList={ZKUserList} />}
        {isZKPresent && <CreditAndClaimTable language={language} ZKUserList={ZKUserList} />}
        <ETASTable />
        <StaticContactMessage />
      </div>
    </div>
  );
};

const SalesTable = ({ contacts, language, isZHPresent }) => {
  return (
    <div className="Contact-US__section">
      <h2>
        <Translate dictionary={ui} word="SALES" />
      </h2>
      <p>
        <Translate dictionary={ui} word="SALES_DESC_WITH_ZH" />
        {!isZHPresent ? <Translate dictionary={ui} word="SALES_DESC_WITHOUT_ZH" /> : '.'}
      </p>
      <ContactsTable contacts={contacts} />
    </div>
  );
};

const OrdersTable = ({ ZHUsersList, ZKUserList, language }) => {
  const description =
    ZHUsersList.length > 0 && ZKUserList.length > 0
      ? translate({ dictionary: ui, word: 'SALES_DESCRIPTION_ZH_ZK', language })
      : translate({ dictionary: ui, word: 'SALES_DESCRIPTION_NO_ZK', language });
  return (
    <div className="Contact-US__section" id="order">
      <h2>
        <Translate dictionary={ui} word="ORDERS" />
      </h2>
      <p>{description}</p>
      <ContactsTable contacts={ZHUsersList} />
    </div>
  );
};

const CAProfessionalOrdersTable = ({ language, isZKPresent }) => {
  const description = isZKPresent
    ? translate({ dictionary: ui, word: 'CA_PROF_CONTACT_US_MSG', language })
    : translate({ dictionary: ui, word: 'CA_PROF_CONTACT_US_NO_ZK_MSG', language });
  return (
    <div className="Contact-US__section">
      <h2>
        <Translate dictionary={ui} word="ORDERS" />
      </h2>
      <p>
        {description}
        <EmailProvider />
      </p>
    </div>
  );
};

const ETASTable = () => {
  return (
    <div className="Contact-US__section">
      <h2>
        <Translate dictionary={ui} word="ETAs" />
      </h2>
      <p>
        <Translate dictionary={ui} word="ETAS_DESCRIPTION" />
        <EmailProvider />
      </p>
    </div>
  );
};
const CreditAndClaimTable = ({ ZKUserList, language }) => {
  return (
    <div className="Contact-US__section" id="claims">
      <h2>
        <Translate dictionary={ui} word="CREDIT_AND_CLAIMS" />
      </h2>
      <p>
        <Translate dictionary={ui} word="CREDIT_AND_CLAIMS_DESCRIPTION" />
      </p>
      <ContactsTable contacts={ZKUserList} />
    </div>
  );
};

const StaticContactMessage = () => {
  return (
    <div className="Contact-US__section">
      <h2>
        <Translate dictionary={ui} word="OTHER_INQUIRIES" />
      </h2>
      <p>
        <Translate dictionary={ui} word={'LOGIN_ISSUE_CONTACT_INFO'} />
      </p>
    </div>
  );
};
const ContactsTable = ({ contacts }) => {
  return (
    <div className="overflow-wrapper">
      <table className="table table-striped ">
        <thead>
          <tr>
            <th>
              <Translate dictionary={ui} word="USERLIST_NAME" />
            </th>
            <th>
              <Translate dictionary={ui} word="USERLIST_EMAIL" />
            </th>
          </tr>
        </thead>
        <tbody>
          {contacts.map(({ firstName, lastName, email }, index) => (
            <tr key={email + index}>
              <td style={{ fontWeight: '600' }}>
                {firstName} {lastName}
              </td>
              <td>
                {email && email.length > 0 && (
                  <a aria-describedby="mail-desc" href={`mailto:${email}`} style={{ color: '#3072AB'}}>
                  {email}
                  </a>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
const mapStateToProps = (state, props) => {
  var userList = state.account.userList ? state.account.userList : [];
  const ZHUsersList = userList.filter(user => user.role === 'OASC');
  const ZKUserList = userList.filter(user => user.role === 'CLAIMS');
  userList = userList ? userList.filter(user => user.role !== 'OASC' && user.role !== 'CLAIMS') : [];

  var managerList = state.account.managerList ? state.account.managerList : [];
  var contractType = state.account.contract_type;
  return {
    user: state.account.user,
    buyer: state.account.buyer,
    addresses: state.account.addresses,
    userList,
    managerList,
    language: state.language,
    ZHUsersList,
    ZKUserList,
    country: state.country,
    contractType
  };
};
export default connect(mapStateToProps)(ContactUs);
