import React, { Component } from 'react';
import IoChevronRight from 'react-icons/lib/io/chevron-right';
import IoChevronLeft from 'react-icons/lib/io/chevron-left';
import './SmallImageSlider.css';
import ImageWithLoader from '../ImageWithLoader/ImageWithLoader';
import Translate from '../dictionary/Translator';
import ui from './dictionary/ui.json';
class SmallImageSlider extends Component {
  state = { currentIndex: 0, show: true };

  next = () => {
    const { images } = this.props;
    this.setState({ show: false });
    setTimeout(() => {
      this.setState(pre => ({
        currentIndex: (pre.currentIndex + 1) % images.length,
        show: true
      }));
    }, 100);
  };
  previous = () => {
    const { images } = this.props;
    this.setState({ show: false });
    setTimeout(() => {
      this.setState(pre => ({
        currentIndex: Math.abs(pre.currentIndex - 1) % images.length,
        show: true
      }));
    });
  };
  render() {
    const { images } = this.props;
    const { currentIndex, show } = this.state;
    return images.length > 0 ? (
      <div className="SmallImageSlider">
        {images.length > 1 && (
          <div className="SmallImageSlider__arrow SmallImageSlider__arrow--pre" onClick={this.previous}>
            <IoChevronLeft />
          </div>
        )}
        {show && <ImageWithLoader src={images[currentIndex]} />}
        {images.length > 1 && (
          <div className="SmallImageSlider__arrow SmallImageSlider__arrow--next" onClick={this.next}>
            <IoChevronRight />
          </div>
        )}
      </div>
    ) : (
      <div className="SmallImageSlider">
        <span>
          <Translate dictionary={ui} word="IMAGE_NOT_AVAILABLE" />
        </span>
      </div>
    );
  }
}

export default SmallImageSlider;
