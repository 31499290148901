import cart from './cart';
import auth from './auth';
import account from './account';
import order from './order';
import error from './error';
import product from './product';
import common from './common';
import { reducer as reduxFormReducer } from 'redux-form';
import language from './language';
import country from './country';
import bulletin from './bulletin';
import environment from './environment';
import appStatus from './appStatus';
export default {
  form: reduxFormReducer,
  cart,
  auth,
  account,
  order,
  product,
  error,
  common,
  language,
  country,
  bulletin,
  environment,
  appStatus
};
