const SET_ENVIRONMENT = 'SET_ENVIRONMENT';
const CONFIRM_DISCLAIMER = 'CONFIRM_DISCLAIMER';
const UNCONFIRM_DISCLAIMER = 'UNCONFIRM_DISCLAIMER';
const HIDE_DISCLAIMER = 'HIDE_DIISCLAIMER';
const TEST_PREFIXES = ['test'];
const PRODUCTION_PREFIXES = ['new'];
export const TEST = 'TEST';
export const PRODUCTION = 'PRODUCTION';
export default (state = { show: true, environment: '', confirmed: false }, action) => {
  switch (action.type) {
    case SET_ENVIRONMENT:
      return { ...state, environment: action.environment };
    case HIDE_DISCLAIMER:
      return { ...state, show: false };
    case CONFIRM_DISCLAIMER:
      return { ...state, confirmed: true };
    case UNCONFIRM_DISCLAIMER:
      return { ...state, confirmed: false };
    default:
      return state;
  }
};

export const confirmTheDisclaimer = () => {
  return {
    type: CONFIRM_DISCLAIMER
  };
};

export const unConfirmTheDisclaimer = () => {
  return {
    type: UNCONFIRM_DISCLAIMER
  };
};
export const initiateTheEnvironmentDisclaimer = () => {
  return (dispatch, state) => {
    const { environment } = getEnvironment();
    dispatch({ type: SET_ENVIRONMENT, environment });
    if (!environment) {
      dispatch({ type: HIDE_DISCLAIMER });
    }
  };
};
const getEnvironment = () => {
  const hostname = window.location.hostname;
  const hostnameEnv = hostname.split('.')[0];
  
  if (TEST_PREFIXES.indexOf(hostnameEnv) !== -1) {
    return { environment: TEST };
  } else if (PRODUCTION_PREFIXES.indexOf(hostnameEnv) !== -1) {
    return { environment: PRODUCTION };
  } else {
    return { environment: null };
  }
};
