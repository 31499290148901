import React from 'react';
import { connect } from 'react-redux';
import { Table, Column, AutoSizer } from 'react-virtualized';
import { NavLink } from 'react-router-dom';
import './priceBook.css';
import 'react-virtualized/styles.css';
import ui from './dictionary/ui';
import Translate from '../dictionary/Translator';
import { translate } from '../dictionary/Translator';
import DocumentTitle from 'react-document-title';
import * as XLSX from "xlsx";
var language;

class PriceBook extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchSku: '',
      searchDescription: '',
      searchCategory: '',
      selectedData: []
    };

    this.handleSkuChange = this.handleSkuChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);

    language = this.props.language;
  }

  onSelectChange = e => {
    const data = this.filteredData(this.state.selectedData);
    if (e.target.name === 'allSelected') {
      this.setState({
        selectedData: this.state.selectedData.map((pricebook) => {
          return { ...pricebook, isChecked: data.includes(pricebook) ? e.target.checked : pricebook.isChecked };
        })
      })
    }
    else {
      this.setState({
        selectedData: this.state.selectedData.map((pricebook) =>
          pricebook.originalSku === e.target.name ? { ...pricebook, isChecked: e.target.checked } : pricebook
        )
      })
    }
  };

  componentDidMount() {
    this.setState({ selectedData: this.props.priceBook });
  }

  handleDescriptionChange(event) {
    this.setState({ searchDescription: event.target.value });
  }

  handleCategoryChange(event) {
    this.setState({ searchCategory: event.target.value });
  }

  handleSkuChange(event) {
    this.setState({ searchSku: event.target.value });
  }

  filteredData = data => {
    if (data !== null) {
      if (this.state.searchSku !== '') {
        data = data.filter(aData => {
          if (aData.sku)
            return (
              aData.sku.toLowerCase().includes(this.state.searchSku.toLowerCase()) ||
              aData.originalSku.toLowerCase().includes(this.state.searchSku.toLowerCase())
            );
          else return false;
        });
      }
      if (this.state.searchDescription !== '') {
        data = data.filter(aData => {
          if (aData.short_desc && aData.short_desc[this.props.language])
            return aData.short_desc[this.props.language].toLowerCase().includes(this.state.searchDescription.toLowerCase());
          else return false;
        });
      }
      if (this.state.searchCategory !== '') {
        data = data.filter(aData => {
          if (aData.sCats) return aData.sCats.join().toLowerCase().includes(this.state.searchCategory.toLowerCase());
          else return false;
        });
      }
    }
    return data;
  };

  renderPriceBook(data) {
    let { selectedData } = this.state;
    return (
      <div style={{ minHeight: '60vh', minWidth: '1000px' }} role="table">
        <AutoSizer>
          {({ height, width }) => {
            return (
              <Table
                className={'borderlessTable'}
                rowClassName={({ index }) => (index % 2 === 0 ? 'PriceBook__evenRow' : 'PriceBook__oddRow')}
                height={height}
                headerHeight={70}
                rowHeight={row => {
                  var item = data[row.index];
                  return 30 * (item.A_tiers.length || item.B_tiers.length) ;
                }}
                rowCount={data.length}
                rowGetter={({ index }) => data[index]}
                width={width}
              >
                <Column
                  width={50}
                  dataKey="skucheck"
                  cellRenderer={tableData => {           
                    var row = tableData.rowData;
                    return (
                      <input
                        type="checkbox"
                        id="CheckpriceBook"
                        style={{ marginRight: '1%', verticalAlign: '-1px' }}
                        name={row.originalSku}
                        aria-label="Selec the bulletin"
                        checked={row.isChecked || ''}
                        onChange={(e) => this.onSelectChange(e)}
                      />
                    );
                  }}
                  headerRenderer={() =>
                    <input type="checkbox" style={{ marginRight: '1%', verticalAlign: '-1px' }} name={'allSelected'} checked={!this.filteredData(selectedData).some(dat => dat.isChecked !== true)} onChange={(e) => this.onSelectChange(e)} aria-label="check all" />
                  }
                />
                <Column
                  width={275}
                  dataKey="sku"
                  cellRenderer={tableData => {
                    var row = tableData.rowData;
                    var URISku =
                      row && row.originalSku && row.originalSku.endsWith('.B') ? row.originalSku.substring(0, row.originalSku.length - 2) : row.originalSku;
                    return (
                      <NavLink to={'/productdetail/' + encodeURIComponent(URISku)} className="bold-link">
                        {row.sku}
                      </NavLink>
                    );
                  }}
                  headerRenderer={() => (
                    <div>
                      <label style={{ fontWeight: 'bold' }} htmlFor="skuId">
                        <Translate word={ui['PRICEBOOK_SKU']} />
                      </label>
                      <span id="skuDesc" style={{ display: 'none' }}>
                        <Translate word={ui['SEARCH_SKU_DESCRIPTION']} />
                      </span>
                      <input
                        id="skuId"
                        autoFocus
                        type="text"
                        title={translate({ word: ui['PRICEBOOK_SKU'], language: language })}
                        className="form-control"
                        value={this.state.searchSku}
                        placeholder={translate({ word: ui['PRICEBOOK_SKU_TITLE'], language: language })}
                        aria-label={translate({ word: ui['PRICEBOOK_SKU_TITLE'], language: language })}
                        onChange={this.handleSkuChange}
                        aria-describedby="skuDesc"
                        style={{ fontSize: '1.3rem' }}
                      />
                    </div>
                  )}
                />
                <Column
                  width={600}
                  dataKey="short_desc"
                  cellRenderer={tableData => {
                    return tableData.cellData ? tableData.cellData[this.props.language] : '';
                  }}
                  headerRenderer={() => (
                    <div>
                      <label style={{ fontWeight: 'bold' }} htmlFor="descId">
                        <Translate word={ui['PRICEBOOK_DESCRIPTION']} />
                      </label>
                      <span id="descriptionDesc" style={{ display: 'none' }}>
                        <Translate word={ui['SEARCH_DESC_DESCRIPTION']} />
                      </span>
                      <input
                        id="descId"
                        type="text"
                        title={translate({ word: ui['PRICEBOOK_DESCRIPTION_TITLE'], language: language })}
                        className="form-control"
                        value={this.state.searchDescription}
                        placeholder={translate({ word: ui['PRICEBOOK_DESCRIPTION_TITLE'], language: language })}
                        aria-label={translate({ word: ui['PRICEBOOK_DESCRIPTION_TITLE'], language: language })}
                        aria-describedby="descriptionDesc"
                        onChange={this.handleDescriptionChange}
                      />
                    </div>
                  )}
                />
                <Column
                  width={500}
                  dataKey="sCats"
                  headerRenderer={() => (
                    <div>
                      <label style={{ fontWeight: 'bold' }} htmlFor="catId">
                        <Translate word={ui['PRICEBOOK_CATEGORY']} />
                      </label>
                      <span id="categoryDesc" style={{ display: 'none' }}>
                        <Translate word={ui['SEARCH_CATEGORY_DESCRIPTION']} />
                      </span>
                      <input
                        id="catId"
                        type="text"
                        title={translate({ word: ui['PRICEBOOK_CATEGORY'], language: language })}
                        className="form-control"
                        value={this.state.searchCategory}
                        placeholder={translate({ word: ui['PRICEBOOK_CATEGORY_TITLE'], language: language })}
                        aria-label={translate({ word: ui['PRICEBOOK_CATEGORY_TITLE'], language: language })}
                        aria-describedby="categoryDesc"
                        onChange={this.handleCategoryChange}
                      />
                    </div>
                  )}
                  cellRenderer={tableData => (tableData.cellData ? tableData.cellData.join() : '')}
                />
                <Column
                  width={192}
                  dataKey="list_price"
                  headerRenderer={() => (
                    <span style={{ display: 'block', height: '60px' }}>
                      <Translate word={ui['PRICEBOOK_LIST_PRICE']} />
                    </span>
                  )}
                  cellRenderer={tableData => <span>{!tableData.cellData || tableData.cellData === '0.00' ? '' : '$' + tableData.cellData}</span>}
                />
                <Column
                  width={200}
                  dataKey="map_price"
                  headerRenderer={() => (
                    <span style={{ display: 'block', height: '60px' }}>
                      <Translate word={ui['PRICEBOOK_MAP_PRICE']} />
                    </span>
                  )}
                  cellRenderer={tableData => <span>{!tableData.cellData || tableData.cellData === '0.00' ? '' : '$' + tableData.cellData}</span>}
                />
                <Column
                  width={200}
                  dataKey="sppg_price"
                  headerRenderer={() => (
                    <span style={{ display: 'block', height: '60px' }}>
                      <Translate word={ui['PRICEBOOK_SPPG_PRICE']} />
                    </span>
                  )}
                  cellRenderer={tableData => <span>{!tableData.cellData || tableData.cellData === '0.00' ? '' : '$' + tableData.cellData}</span>}
                />
                <Column
                  width={200}
                  dataKey="sure_price"
                  headerRenderer={() => (
                    <span style={{ display: 'block', height: '60px' }}>
                      <Translate word={ui['PRICEBOOK_SURE_PRICE']} />
                    </span>
                  )}
                  cellRenderer={tableData => <span>{!tableData.cellData || tableData.cellData === '0.00' ? '' : '$' + tableData.cellData}</span>}
                />
                <Column
                  width={150}
                  dataKey="A_base_qty"
                  headerRenderer={() => (
                    <span  style={{ display: 'block', height: '60px' }}>
                      <Translate word={ui['A_QTY']} />
                    </span>
                  )}
                  cellRenderer={tableData => (
                    <ul className="list-unstyled">
                      {tableData.rowData.A_tiers.map((item, index) => (
                        <li key={index}>{item.start_qty}</li>
                      ))}
                    </ul>
                  )}
                /> 
                 <Column
                  width={200}
                  dataKey="A_base_price"
                  headerRenderer={() => (
                    <span  style={{ display: 'block', height: '60px' }}>
                      <Translate word={ui['PRICEBOOK_A_PRICE']} />
                    </span>
                  )}
                  cellRenderer={tableData => (
                    <ul className="list-unstyled">
                      {tableData.rowData.A_tiers.map((item, index) => (
                        <li key={index}>{!item.price || item.price === '0.00' ? '' : '$' + item.price}</li>
                      ))}
                    </ul>
                  )}
                /> 
                <Column
                  width={150}
                  dataKey="B_base_qty"
                  headerRenderer={() => (
                    <span style={{ display: 'block', height: '60px' }}>
                      <Translate word={ui['B_QTY']} />
                    </span>
                  )}
                  cellRenderer={tableData => (
                    <ul className="list-unstyled">
                      {tableData.rowData.has_Bstock && tableData.rowData.B_tiers.map((item, index) => (
                        <li key={index}>{item.start_qty}</li>
                      ))}
                    </ul>
                  )}
                />            
                <Column
                  width={200}
                  dataKey="B_base_price"
                  headerRenderer={() => (
                    <span style={{ display: 'block', height: '60px' }} id="B_base_price">
                      <Translate word={ui['PRICEBOOK_B_PRICE']} />
                    </span>
                  )}
                  cellRenderer={tableData => {
                      return ( <ul className="list-unstyled">
                        { tableData.rowData.has_Bstock && tableData.rowData.B_tiers.map((item, index) => (
                        <li key={index}>{!item.price || item.price === '0.00' ? '' : '$' +
                          item.price}</li>
                      )) }
                    </ul>)
                  }}
                />
              </Table>
            )
          }}
        </AutoSizer>
      </div>
    );
  }

  render() {
    const workbookdata = this.state.selectedData && this.state.selectedData.filter(data => data.isChecked === true);
    const { language } = this.props;
    if (!this.props.priceBook || this.props.priceBook.length === 0) {
      return (
        <DocumentTitle title={`${translate({ dictionary: ui, word: 'TITLE', language })} | Sony ExpressNet`}>
          <div className="container" style={{ alignItems: 'center' }}>
            <h1>
              <Translate word="TITLE" dictionary={ui} />
            </h1>
            <span className="text-danger">
              <Translate word={ui['PRICEBOOK_ERROR']} />
            </span>
          </div>
        </DocumentTitle>
      );
    } else {
      return (
        <DocumentTitle title={`${translate({ dictionary: ui, word: 'TITLE', language })} | Sony ExpressNet`}>
          <div style={{ paddingLeft: '1%', paddingRight: '1%', paddingTop: '1%' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
              <h1 style={{ margin: '0', alignSelf: 'flex-start' }}>
                <Translate word="TITLE" dictionary={ui} />
              </h1>
              <div style={{ marginRight: '1%' }}>
                <div style={{ display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap' }}>
                  {renderWorkBook(workbookdata.length > 0 ? workbookdata : this.props.priceBook, this.props.contract_type)}
                </div>
              </div>
            </div>
            <div style={{ overflowY: 'hidden', padding: '1%' }}>
              {this.renderPriceBook(this.filteredData(this.state.selectedData))}
            </div>
          </div>
        </DocumentTitle>
      );
    }
  }
}

const getTierQty = (rowData, index, tier) =>{ 
  if(tier === "B_tiers") {
     return rowData && rowData.has_Bstock && rowData[tier][index] ? rowData[tier][index].start_qty : ''
  }else{
  return rowData && rowData[tier][index] ? rowData[tier][index].start_qty : ''
  }
};

const getTierPrice = (rowData, index, tier) => {
  if(tier === "B_tiers") {
    return rowData && rowData.has_Bstock&& rowData[tier][index] ? rowData[tier][index].price.replace(/,\s?/g, '') * 1 : '';
  } else {
    return rowData && rowData[tier][index] ? rowData[tier][index].price.replace(/,\s?/g, '') * 1 : '';
  }
  
};

const WorkBook = (data) => {
  let array = [];
  for(let i = 0; i < 17; i++)
    array.push(data[i]);
  let columnLabels = [
      translate({ word: ui['PRICEBOOK_SKU'], language: language }),
      translate({ word: ui['PRICEBOOK_DESCRIPTION'], language: language }),
      translate({ word: ui['PRICEBOOK_CATEGORY'], language: language }),
      translate({ word: ui['PRICEBOOK_LIST_PRICE'], language: language }),
      translate({ word: ui['PRICEBOOK_MAP_PRICE'], language: language }),
      translate({ word: ui['PRICEBOOK_SPPG_PRICE'], language: language }),
      translate({ word: ui['PRICEBOOK_SURE_PRICE'], language: language }),
      translate({ word: ui['PRICEBOOK_TIER_A_QTY_1'], language: language }),
      translate({ word: ui['PRICEBOOK_TIER_A_PRICE_1'], language: language }),
      translate({ word: ui['PRICEBOOK_TIER_B_QTY_1'], language: language }),
      translate({ word: ui['PRICEBOOK_TIER_B_PRICE_1'], language: language }),
      translate({ word: ui['PRICEBOOK_TIER_A_QTY_2'], language: language }),
      translate({ word: ui['PRICEBOOK_TIER_A_PRICE_2'], language: language }),
      translate({ word: ui['PRICEBOOK_TIER_B_QTY_2'], language: language }),
      translate({ word: ui['PRICEBOOK_TIER_B_PRICE_2'], language: language }),
      translate({ word: ui['PRICEBOOK_TIER_A_QTY_3'], language: language }),
      translate({ word: ui['PRICEBOOK_TIER_A_PRICE_3'], language: language }),
      translate({ word: ui['PRICEBOOK_TIER_B_QTY_3'], language: language }),
      translate({ word: ui['PRICEBOOK_TIER_B_PRICE_3'], language: language }),
      translate({ word: ui['PRICEBOOK_TIER_A_QTY_4'], language: language }),
      translate({ word: ui['PRICEBOOK_TIER_A_PRICE_4'], language: language }),
      translate({ word: ui['PRICEBOOK_TIER_B_QTY_4'], language: language }),
      translate({ word: ui['PRICEBOOK_TIER_B_PRICE_4'], language: language }),
      translate({ word: ui['PRICEBOOK_TIER_A_QTY_5'], language: language }),
      translate({ word: ui['PRICEBOOK_TIER_A_PRICE_5'], language: language }),
      translate({ word: ui['PRICEBOOK_TIER_B_QTY_5'], language: language }),
      translate({ word: ui['PRICEBOOK_TIER_B_PRICE_5'], language: language }),
  ];

  const modifiedData = data.map((item) => {
      const modifiedItem = {};
      let objectKeys = Object.keys(item);
      for (let key of objectKeys) {
          if (key === 'originalSku') {
              modifiedItem[columnLabels[0]] = item[key];
          }
          if (key === 'short_desc') {
              modifiedItem[columnLabels[1]] = item[key]
                  ? item[key][language]
                  : '';
          }
          if (key === 'sCats') {
              modifiedItem[columnLabels[2]] = item[key] ? item[key].join() : '';
          }
          if (key === 'list_price') {
              modifiedItem[columnLabels[3]] = getPrice(item[key]);
          }
          if (key === 'map_price') {
              modifiedItem[columnLabels[4]] = getPrice(item[key]);
          }
          if (key === 'sppg_price') {
              modifiedItem[columnLabels[5]] = getPrice(item[key]);
          }
          if (key === 'sure_price') {
              modifiedItem[columnLabels[6]] = getPrice(item[key]);
          }
          if (key === 'A_tiers') {
              modifiedItem[columnLabels[7]] = getTierQty(item, 0, 'A_tiers');
              modifiedItem[columnLabels[8]] = getTierPrice(item, 0, 'A_tiers');
              modifiedItem[columnLabels[9]] = getTierQty(item, 0, 'B_tiers');
              modifiedItem[columnLabels[10]] = getTierPrice(item, 0, 'B_tiers');

              modifiedItem[columnLabels[11]] = getTierQty(item, 1, 'A_tiers');
              modifiedItem[columnLabels[12]] = getTierPrice(item, 1, 'A_tiers');
              modifiedItem[columnLabels[13]] = getTierQty(item, 1, 'B_tiers');
              modifiedItem[columnLabels[14]] = getTierPrice(item, 1, 'B_tiers');

              modifiedItem[columnLabels[15]] = getTierQty(item, 2, 'A_tiers');
              modifiedItem[columnLabels[16]] = getTierPrice(item, 2, 'A_tiers');
              modifiedItem[columnLabels[17]] = getTierQty(item, 2, 'B_tiers');
              modifiedItem[columnLabels[18]] = getTierPrice(item, 2, 'B_tiers');

              modifiedItem[columnLabels[19]] = getTierQty(item, 3, 'A_tiers');
              modifiedItem[columnLabels[20]] = getTierPrice(item, 3, 'A_tiers');
              modifiedItem[columnLabels[21]] = getTierQty(item, 3, 'B_tiers');
              modifiedItem[columnLabels[22]] = getTierPrice(item, 3, 'B_tiers');

              modifiedItem[columnLabels[23]] = getTierQty(item, 4, 'A_tiers');
              modifiedItem[columnLabels[24]] = getTierPrice(item, 4, 'A_tiers');
              modifiedItem[columnLabels[25]] = getTierQty(item, 4, 'B_tiers');
              modifiedItem[columnLabels[26]] = getTierPrice(item, 4, 'B_tiers');
          }
      }
      return modifiedItem;
  });

  // Create worksheet with custom column labels
  const worksheet = XLSX.utils.json_to_sheet(modifiedData, {
    header: columnLabels
  });

  // // Create workbook and add the worksheet
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, worksheet, "Sheet 1");

  // // Trigger download
  XLSX.writeFile(wb, "Pricebook.xlsx");
}


const getPrice = price => (!price || price === '0.00' ? '' : price.replace(/,\s?/g, '') * 1);

const renderWorkBook = (data, contract_type) => {
  return (
      <div onClick={() => WorkBook(data)}>
          <span id="initInstr" style={{ display: 'none' }}>
              <Translate word={ui['DOWNLOAD_PRICEBOOK_DESCRIPTION']} />
          </span>
          <button
              id="downloadpriceBook"
              className="btn btn-primary"
              aria-describedby="initInstr"
          >
              <Translate word={ui['PRICEBOOK_DOWNLOAD']} />
          </button>
      </div>
  );
};

const mapStateToProps = state => {
  return {
    priceBook: state.account.priceBook,
    contract_type: state.account.contract_type,
    language: state.language
  };
};

export default connect(mapStateToProps)(PriceBook);
