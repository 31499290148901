import React, { Component } from 'react';
import { connect } from 'react-redux';
import Autosuggest from 'react-autosuggest';
import { withRouter } from 'react-router-dom';
import { debounce } from 'lodash';
import { escapeRegexCharacters, getAlphaNumOnly } from '../utils/jsUtils';
import GoSearch from 'react-icons/lib/go/search';
import MdHighlightRemove from 'react-icons/lib/md/highlight-remove';
import ui from './dictionary/ui.json';
import Translate from '../dictionary/Translator';
import { translate } from '../dictionary/Translator';
import './productsearch.css';
//import conflictModels from '../../mappings/conflict_models';

class ProductSearch extends Component {
  state = {
    value: '',
    suggestions: []
  };

  handleClearOnClick(e) {
    this.setState({
      topSuggestion: undefined,
      suggestions: [],
      value: '',
      clearStyle: { display: 'none' }
    });
  }
  onSuggestionsFetchRequested = debounce(({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  }, 500);

  onSuggestionsClearRequested() {
    this.setState(pre => ({
      topSuggestion: pre.suggestions[0],
      suggestions: []
    }));
  }

  renderInputComponent = inputProps => {
    const { value } = this.state;
    return (
      <div className="Header__search">
        <div className="Header__search__input">
          <input {...inputProps} value={value} />
        </div>
        <div className="Header__search__icon-container">
          {value && (
            <button className="btn-transparent" aria-label="Remove">
              <span style={{ display: 'none' }}>Remove</span>
              <MdHighlightRemove className="Header__icon Header__icon--remove" style={{ fontSize: '2.3rem' }} role="img" aria-label="Remove" onClick={e => this.handleClearOnClick(e)} />
            </button>
          )}
          <button className="btn-transparent" aria-label="Enter a minimum of two characters to search for a model">
            <span style={{ display: 'none' }}>Search</span>
            <GoSearch className="Header__icon Header__icon--search on-hover-gray" style={{ fontSize: '2.3rem' }} role="img" aria-label="Search"/>
          </button>
        </div>
      </div>
    );
  };

  renderSuggestion = suggestion => {
    if (suggestion.error) {
      return (
        <div align="center">
          <Translate dictionary={ui} word="NO_RESULTS" />
        </div>
      );
    } else if (suggestion.type === 'cat') {
      return (
        <div>
          <span className="link">{suggestion.name}</span>
          <br />
          <span style={{ fontSize: '13px' }}>
            <Translate dictionary={ui} word="MAIN_CATEGORY" />: {suggestion.cat}
          </span>
        </div>
      );
    } else if (suggestion.type === 'subCat') {
      return (
        <div>
          <span className="link">{suggestion.name}</span>
          <br />
          <span style={{ fontSize: '13px' }}>
            <Translate dictionary={ui} word="SUB_CATEGORY" />: {suggestion.subCat}
          </span>
        </div>
      );
    } else {
      return (
        <div style={{ margin: '0', padding: '0', border: '0' }}>
          <span className="link">{suggestion.name}</span>
          <br />
          <span style={{ fontSize: '13px' }}>{suggestion.desc}</span>
        </div>
      );
    }
  };
  onSuggestionSelected(event, { suggestion, suggestionValue }) {
    if (!suggestion.error) {
      this.setState({
        value: '',
        clearStyle: { display: 'none' }
      });
      if (suggestion.type === 'cat') {
        this.props.history.push(`/productlist/${encodeURIComponent(suggestion.id)}`);
      } else {
        this.props.history.push(`/productdetail/${encodeURIComponent(suggestion.originalSku)}`);
      }
    }
  }
  getSuggestions = () => {
    const { value } = this.state;
    const { results } = this.props;
    const escapedValue = escapeRegexCharacters(value.trim()).toLowerCase();
    if (escapedValue === '') {
      return [];
    }
    const nonalphanumericValue = getAlphaNumOnly(escapedValue);

    let retVal = [];
    retVal = results
      .map(section => {
        if (section.title === 'Catalogs') {
          return {
            title: section.title,
            results: section.results.filter(result => {
              let Ncat;
              if(result.cat !== null) {
                Ncat = result.cat.toLowerCase().includes(escapedValue)
              }
              return Ncat;
            })
          };
        } else if (section.title === 'Sub Catalogs') {
          return {
            title: section.title,
            results: section.results.filter(result => {
              let NsubCat;
              if(result.subCat !== null){
              NsubCat = !result.name.toLowerCase().endsWith('.b') && result.subCat.toLowerCase().includes(escapedValue)
              }
              return NsubCat;
            })
          }
        } else {
          return {
            title: section.title,
            results: section.results.filter(
              result =>
               !result.name.toLowerCase().endsWith('.b') &&
                (result.name.toLowerCase().includes(nonalphanumericValue) ||
                  result.originalSku.toLowerCase().includes(nonalphanumericValue) ||
                  (result.desc && result.desc.toLowerCase().includes(escapedValue)))
            )
          };
        }
      })
      .filter(section => section.results.length > 0);
    if (retVal.length === 0) {
      retVal = [
        {
          title: '',
          results: [
            {
              error: 'not found',
              name: value
            }
          ]
        }
      ];
    }
    return retVal;
  };

  render() {
    const { value, suggestions } = this.state;
    const placeholder = translate({ word: ui['INPUT_PALCEHOLDER'], language: this.props.lang });
    const label = translate({ word: ui['SEARCH_BOX_LABEL'], language: this.props.lang });
    const inputProps = {
      placeholder,
      value,
      id: 'productsearch',
      'aria-label': label,
      onChange: e => this.setState({ value: e.target.value })
    };

    return (
      <Autosuggest
        multiSection={true}
        suggestions={suggestions}
        shouldRenderSuggestions={value => value.trim().length > 1}
        renderInputComponent={inputProps => this.renderInputComponent(inputProps)}
        onSuggestionsFetchRequested={props => this.onSuggestionsFetchRequested(props)}
        onSuggestionsClearRequested={() => this.onSuggestionsClearRequested()}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={suggestion => this.renderSuggestion(suggestion)}
        renderSectionTitle={renderSectionTitle}
        getSectionSuggestions={getSectionSuggestions}
        focusInputOnSuggestionClick={false}
        onSuggestionSelected={(event, { suggestion, suggestionValue }) => this.onSuggestionSelected(event, { suggestion, suggestionValue })}
        inputProps={inputProps}
      />
    );
  }
}

function getSuggestionValue(suggestion) {
  return suggestion.name;
}

function renderSectionTitle(section) {
  if (section.title) {
    return (
      <strong style={{ fontSize: '16px' }}>
        <Translate dictionary={ui} word={section.title} />
      </strong>
    );
  }
}

function getSectionSuggestions(section) {
  return section.results;
}

function generateResults({ priceBook, catalog, country, language }) {
  const skuResults = [];
  priceBook.forEach(aItem => {
    skuResults.push({
      type: 'sku',
      name: aItem.sku,
      desc: aItem.short_desc ? aItem.short_desc[language] : '',
      originalSku: aItem.originalSku
    });
  });
  const catResults = [];
  const subCatResults = [];
  catalog.forEach(aCat => {
    aCat.subCats.forEach(aSubCat => {
      catResults.push({
        type: 'cat',
        name: aSubCat.name,
        cat: aCat.name,
        id: aSubCat.id
      });
      aSubCat.skus.forEach(aSku => {
        subCatResults.push({
          type: 'subCat',
          //name: conflictModels[country][aSku] ? conflictModels[country][aSku] : aSku,
          name:  aSku,
          subCat: aSubCat.name,
          originalSku: aSku
        });
      });
    });
  });
  const newResults = [
    {
      title: 'SKUs',
      results: skuResults
    },
    {
      title: 'Catalogs',
      results: catResults
    },
    {
      title: 'Sub Catalogs',
      results: subCatResults
    }
  ];
  return newResults;
}
const mapStateToProps = state => {
  const { language, country } = state;
  var catalog = state.account.catalog && state.account.catalog[language] ? state.account.catalog[language] : [];
  return {
    catalog,
    priceBook: state.account.priceBook,
    lang: state.language,
    country: state.country,
    results: generateResults({ priceBook: state.account.priceBook, catalog, country, language })
  };
};

export default withRouter(connect(mapStateToProps)(ProductSearch));
