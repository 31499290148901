import React from 'react';
import VirtualizedSelect from 'react-virtualized-select';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-select/dist/react-select.css';
import 'react-virtualized/styles.css';
import 'react-virtualized-select/styles.css';
import moment from 'moment';
import MdCloudUpload from 'react-icons/lib/md/cloud-upload';
import { Panel, FormGroup, FormControl } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './utils.css';
import { toast } from 'react-toastify';
export const renderInput = props => {
  const {
    label = '',
    id = '',
    wrap = true,
    style,
    input,
    type,
    disabled,
    min,
    max,
    maxLength,
    meta: { touched, error, dirty }
  } = props;
  input['id'] = id;
  return (
    <div className="__Field">
      <input
        {...input}
        type={type}
        disabled={disabled}
        min={min}
        max={max}
        maxLength={maxLength}
        style={style}
        aria-label={label.length > 0 ? label : undefined}
        id={id}
        className={(touched && error) || (dirty && error) ? 'input-has-error' : ''}
      />
      {((touched && error) || (dirty && error)) && (
        <div style={{ whiteSpace: `${wrap ? 'pre-wrap' : 'nowrap'}` }} className="text-danger input-error">
          {error}
        </div>
      )}
    </div>
  );
};

export const renderTextArea = props => {
  const {
    rows = 4,
    cols = 35,
    id = '',
    style,
    input,
    type,
    meta: { touched, error }
  } = props;
  input['id'] = id;
  return (
    <div className="__Field">
      <textarea {...input} type={type} rows={rows} cols={cols} style={style} />
      {touched && error && <div className="text-danger">{error}</div>}
    </div>
  );
};
export const renderSelect = props => {
  const noResult = {
    en_US: 'No results found',
    en_CA: 'No results found',
    fr_CA: 'Aucun résultat'
  };
  const {
    options,
    id = '',
    label = '',
    input,
    children,
    language = 'en_US',
    meta: { touched, error },
    ...rest
  } = props;

  return (
    <div>
      <Select
        value={input.value}
        onChange={(value) => {
          input.onChange(value);
          removeAttribute();
          }
        }
        options={options}
        {...rest}
        aria-label={label.length > 0 ? label : undefined}
        id={id}
        noResultsText={noResult[language]}
      />
      {touched && error && <div className="text-danger">{error}</div>}
    </div>
  );
};


export const removeAttribute = (id) => {
    var timesRun = 0;
    var interval = setInterval(function () {
        timesRun += 1;
        if (timesRun === 5) {
            clearInterval(interval);
        }
        if(id){
          document.getElementById(id) && document.getElementById(id).removeAttribute("aria-owns");
        }
        else{
          document
              .querySelectorAll('[aria-owns]')
              .forEach((input) => input.removeAttribute('aria-owns'));
        }
    }, 500);
};

export const renderFilterSelect = props => {
  const {
    input,
    label = '',
    options,
    meta: { touched, error },
    ...rest
  } = props;
  return (
    <div className="__Select">
      <VirtualizedSelect
        value={input.value}
        onChange={(value) => {
          input.onChange(value);
          // document.getElementById(props.id) && document.getElementById(props.id).removeAttribute("aria-owns");
          removeAttribute(props.id); 
          }
        }
        options={options}
        simpleValue
        {...rest}
        aria-label={label.length > 0 ? label : undefined}
      />
      {touched && error && <div className="text-danger">{error}</div>}
    </div>
  );
};
export const renderDatePicker = ({ input, label = '', isClearable, editable = true, meta: { touched, error }, ...rest }) => {
  var selected = input.value || null;
  if (typeof selected === 'string') {
    selected = moment(selected);
  }
  return (
    <div>
      {editable ? (
        <DatePicker
          {...rest}
          preventOpenOnFocus={true}
          dateFormat="MM/DD/YYYY"
          selected={selected}
          onChange={input.onChange}
          onChangeRaw={input.onChange}
          isClearable={isClearable}
          autoComplete="off"
        />
      ) : (
        <DatePicker
          {...rest}
          preventOpenOnFocus={true}
          dateFormat="MM/DD/YYYY"
          selected={selected}
          onChange={input.onChange}
          onChangeRaw={input.onChange}
          isClearable={isClearable}
          autoComplete="off"
          customInput={<DatePickerNotEditableInput />}
        />
      )}
      {touched && error && <div className="text-danger">{error}</div>}
    </div>
  );
};

const DatePickerNotEditableInput = props => {
  return (
    <span
      role="button"
      aria-label={props.label}
      style={{ backgroundColor: '#fff', width: '220px', padding: '1% 3%', border: '1px solid #ccc', display: 'block' }}
      onClick={props.onClick}
      {...props}
    >
      {props.value}
    </span>
  );
};
export const RenderFileUpload = ({ handleFiles, fileTypes, label }) => {
  return (
    <label className="btn btn-primary button btn-small-screen" role="button" style={{ width: '100%' }}>
      <input type="file" onChange={handleFiles} accept={fileTypes} className="helper__hover--pointer helper__display--none" aria-label="file upload" />
      <MdCloudUpload className="button__icon" role="img" aria-label="click here to upload file" />
      {label}
    </label>
  );
};

export class Modal extends React.Component {
  render() {
    // Render nothing if the "show" prop is false
    if (!this.props.show) {
      return null;
    }

    return (
      <div className="Modal__backdropStyle">
        <div className="Modal__modalStyle">{this.props.children}</div>
        <span className="Modal__closeModal" onClick={this.props.onClose} />
      </div>
    );
  }
}

export const RenderPanel = props => {
  return (
    <Panel>
      <Panel.Heading>
        <Panel.Toggle componentClass="div" style={{ cursor: 'pointer', padding: 0 }}>
          <div>
            <h3 style={{ fontSize:'18px', margin: 0 }} onClick={() => props.setGlyphClass(props.panelType)}>
              {props.header}
              <button className="accordian_button" aria-label="expand">
              <span className={props.classType ? 'glyphicon glyphicon-minus' : 'glyphicon glyphicon-plus'} />
            </button>
            </h3>
          </div>
        </Panel.Toggle>
      </Panel.Heading>
      <Panel.Collapse>
        <Panel.Body>{props.children}</Panel.Body>
      </Panel.Collapse>
    </Panel>
  );
};

export const CropString = ({ content, length = '400', readMoreLink = '#', btnCaption = ' Read More' }) => {
  return length >= content.length ? (
    <p> {content} </p>
  ) : (
    <React.Fragment>
      <p>
        {String(content).substring(0, length)}
        {'...'}
        <Link style={{ display: 'inline-block', fontSize: '1.3rem' }} to={readMoreLink}>
          {btnCaption}
        </Link>
      </p>
    </React.Fragment>
  );
};
export const MonthDropdown = props => {
  return (
    <FormGroup controlId="formControlsSelect">
      <FormControl componentClass="select" value={props.value} onChange={props.onMonthChange}>
        <option value="">None</option>
        <option value="0">January</option>
        <option value="1">February</option>
        <option value="2">March</option>
        <option value="3">April</option>
        <option value="4">May</option>
        <option value="5">June</option>
        <option value="6">July</option>
        <option value="7">August</option>
        <option value="8">September</option>
        <option value="9">October</option>
        <option value="10">November</option>
        <option value="11">December</option>
      </FormControl>
    </FormGroup>
  );
};

export const replaceHTMLTagsWithUnicodes = str => {
  if (!str || str.length === 0) {
    return str;
  }
  var htmlElement = document.createElement("div");
    htmlElement.innerHTML = str;
  if (htmlElement.textContent.toLowerCase().indexOf('<sup>') !== -1) {
    htmlElement.textContent = convertSupHTMLToUnicode(htmlElement.textContent);
  }
  if (htmlElement.textContent.toLowerCase().indexOf('<br>') !== -1 || htmlElement.textContent.toLowerCase().indexOf('<br/>') !== -1 || htmlElement.textContent.toLowerCase().indexOf('</br>') !== -1) {
    htmlElement.textContent = convertBreakLineToUnicode(htmlElement.textContent);
  }
  return htmlElement.textContent
};

export const convertSupHTMLToUnicode = str => {
  const temp = /<sup>(.*?)<\/sup>/g;
  do {
    const extracted = temp.exec(str);
    if (extracted && extracted[1]) {
      const withInTag = extracted[1];
      const supperScripted = superscript(withInTag);

      const replaced = str.replace(extracted[0], supperScripted);
      str = replaced;
    }
  } while (str && str.match(temp));
  return str;
};

const convertBreakLineToUnicode = str => {
  str = str.replace(new RegExp('<br>', 'ig'), '\n');
  str = str.replace(new RegExp('<br/>', 'ig'), '\n');
  str = str.replace(new RegExp('</br>', 'ig'), '\n');
  return str;
};

const superscript = text => {
  var map = {
    '0': '⁰',
    '1': '¹',
    '2': '²',
    '3': '³',
    '4': '⁴',
    '5': '⁵',
    '6': '⁶',
    '7': '⁷',
    '8': '⁸',
    '9': '⁹',
    a: 'ᵃ',
    b: 'ᵇ',
    c: 'ᶜ',
    d: 'ᵈ',
    e: 'ᵉ',
    f: 'ᶠ',
    g: 'ᵍ',
    h: 'ʰ',
    i: 'ᶦ',
    j: 'ʲ',
    k: 'ᵏ',
    l: 'ˡ',
    m: 'ᵐ',
    n: 'ⁿ',
    o: 'ᵒ',
    p: 'ᵖ',
    q: 'ᑫ',
    r: 'ʳ',
    s: 'ˢ',
    t: 'ᵗ',
    u: 'ᵘ',
    v: 'ᵛ',
    w: 'ʷ',
    x: 'ˣ',
    y: 'ʸ',
    z: 'ᶻ',
    A: 'ᴬ',
    B: 'ᴮ',
    C: 'ᶜ',
    D: 'ᴰ',
    E: 'ᴱ',
    F: 'ᶠ',
    G: 'ᴳ',
    H: 'ᴴ',
    I: 'ᴵ',
    J: 'ᴶ',
    K: 'ᴷ',
    L: 'ᴸ',
    M: 'ᴹ',
    N: 'ᴺ',
    O: 'ᴼ',
    P: 'ᴾ',
    Q: 'Q',
    R: 'ᴿ',
    S: 'ˢ',
    T: 'ᵀ',
    U: 'ᵁ',
    V: 'ⱽ',
    W: 'ᵂ',
    X: 'ˣ',
    Y: 'ʸ',
    Z: 'ᶻ',
    '+': '⁺',
    '-': '⁻',
    '=': '⁼',
    '(': '⁽',
    ')': '⁾'
  };
  const charArray = text.split('');
  for (var i = 0; i < charArray.length; i++) {
    if (map[charArray[i].toLowerCase()]) {
      charArray[i] = map[charArray[i]];
    }
  }
  text = charArray.join('');
  return text;
};

export const isIE = () => {
  const isIE = false || !!document.documentMode;
  const isEdge = !isIE && !!window.StyleMedia;
  return isIE || isEdge;
};

export const successToast = msg => {
  return toast(<CustomToast msg={msg} />);
};
const CustomToast = ({ msg }) => {
  return (
    <div className="Toastify--custom-success" role="alert">
      {msg}
    </div>
  );
};
export const errorToast = msg => {
  toast.error(msg, { className: 'toastify-error' });
};

export const VerticalWrapper = ({ height = 500, children }) => (
  <div style={{ overflowY: 'auto', whiteSpace: 'no-wrap', maxHeight: `${height}px` }}>{children}</div>
);

export const groupSpecificationByAttribute = function (techSpecs) {
  return techSpecs.reduce(function (groups, tech) {
    const key = tech['Attribute Group Name'];
    if (!groups[key]) {
      groups[key] = {
        groupName: tech['Attribute Group Name'],
        id: tech['Attribute Group Id'],
        technicalFeatures: [],
      };
    } 
    groups[key].technicalFeatures.push({
      id: tech['Attribute Id'],
      name: tech['Attribute Name'],
      value: tech['Value'],
      rank: tech['Key Specs Rank'],
    });
    return groups;
  }, {});
};

export const checkQtyIsValid = (qty) => {
	if (!isNaN(qty) && parseInt(qty, 10) > 0 && parseInt(qty, 10) <= 999999999) {
		return true;
	  }
		return false;
}

