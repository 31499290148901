import React from 'react';
import ui from './dictionary/ui.json';
import Translate from '../dictionary/Translator';
import { getEffectivePeriod, getPublishPeriod, convertToHumanReadableDate } from './BulletinHelper';
import ReactTable from 'react-table';
import { isIE } from '../utils/renderUtils';
const BulletinSearchResults = ({ results, allSelected, onSelectAll, selectedBulletins, onSelectABulletin, presentational = false }) => {
  return results && results.length > 0 ? (
    <div className="row">
      <div className="overflow-wrapper">
        <React.Fragment>
          <span className="offscreen" id="link-desc">
            Downloads the pdf file
          </span>
          {presentational ? (
            <PureResultTable results={results} />
          ) : (
            <ResultTableWithAction
              results={results}
              allSelected={allSelected}
              onSelectAll={onSelectAll}
              selectedBulletins={selectedBulletins}
              onSelectABulletin={onSelectABulletin}
            />
          )}
        </React.Fragment>
      </div>
    </div>
  ) : (
    <div className="alert alert-info">
      <Translate dictionary={ui} word="NO_RESULT" />
    </div>
  );
};

export const ResultTableWithAction = ({ results, allSelected, onSelectAll, onSelectABulletin, selectedBulletins }) => {
  return (
    <ReactTable
      minRows={0}
      className={`-striped ${isIE() ? 'react-table-ie--customized' : ''}`}
      data={results}
      style={
        isIE()
          ? {}
          : {
              maxHeight: '400px',
              overflowY: 'auto'
            }
      }
      columns={[
        {
          Header: (
            <input type="checkbox" style={{ marginRight: '1%', verticalAlign: '-1px' }} checked={allSelected} onChange={onSelectAll} aria-label="check all" />
          ),
          className: 'react-table__td',
          headerClassName: 'react-table__th',
          width: 50,
          Cell: ({
            row: {
              _original: { bulletin_no }
            }
          }) => (
            <input
              type="checkbox"
              style={{ marginRight: '1%', verticalAlign: '-1px' }}
              name={bulletin_no}
              aria-label="Selec the bulletin"
              checked={selectedBulletins[bulletin_no]}
              onChange={onSelectABulletin}
            />
          )
        },
        {
          Header: <Translate dictionary={ui} word="BULLETIN" />,
          accessor: 'bulletin_no',
          width: 200,
          className: 'react-table__td',
          headerClassName: 'react-table__th',
          Cell: ({
            row: {
              _original: { bulletin_url, bulletin_no }
            }
          }) => (
            <a href={`${bulletin_url}`} target="_blank" rel="noopener noreferrer" aria-describedby="link-desc" className="bold-link">
              {bulletin_no}
            </a>
          )
        },
        {
          Header: <Translate dictionary={ui} word="DESCRIPTION" />,
          width: 700,
          className: 'react-table__td--text',
          headerClassName: 'react-table__th',
          accessor: 'bulletin_desc'
        },
        {
          Header: <Translate dictionary={ui} word="EFFECTIVE_DATE" />,
          className: 'react-table__td',
          headerClassName: 'react-table__th',
          width: 150,
          Cell: ({ row: { _original } }) => <span>{convertToHumanReadableDate(getEffectivePeriod(_original))}</span>,
          accessor: "bulletin_start_date"
        },
        {
          Header: <Translate dictionary={ui} word="PUBLISH_DATE" />,
          className: 'react-table__td',
          headerClassName: 'react-table__th',
          width: 150,
          Cell: ({ row: { _original } }) => <span>{convertToHumanReadableDate(getPublishPeriod(_original))}</span>,
          accessor: "bulletin_date"
        }
      ]}
      showPagination={false}
      filterable={false}
      pageSize={results.length}
    />
  );
};

export const PureResultTable = ({ results }) => (
  <ReactTable
    minRows={0}
    className={`-striped ${isIE() ? 'react-table-ie--customized' : ''}`}
    data={results}
    style={
      isIE()
        ? {}
        : {
            maxHeight: '400px',
            overflowY: 'auto'
          }
    }
    columns={[
      {
        Header: <Translate dictionary={ui} word="BULLETIN" />,
        accessor: 'bulletin_no',
        width: 200,
        className: 'react-table__td',
        headerClassName: 'react-table__th',
        Cell: ({
          row: {
            _original: { bulletin_url, bulletin_no }
          }
        }) => (
          <a href={`${bulletin_url}`} target="_blank" rel="noopener noreferrer" aria-describedby="link-desc" className="bold-link">
            {bulletin_no}
          </a>
        )
      },
      {
        Header: <Translate dictionary={ui} word="DESCRIPTION" />,
        width: 700,
        className: 'react-table__td--text',
        headerClassName: 'react-table__th',
        accessor: 'bulletin_desc'
      },
      {
        Header: <Translate dictionary={ui} word="EFFECTIVE_DATE" />,
        className: 'react-table__td',
        headerClassName: 'react-table__th',
        width: 150,
        Cell: ({ row: { _original } }) => <span>{convertToHumanReadableDate(getEffectivePeriod(_original))}</span>
      },
      {
        Header: <Translate dictionary={ui} word="PUBLISH_DATE" />,
        className: 'react-table__td',
        headerClassName: 'react-table__th',
        width: 150,
        Cell: ({ row: { _original } }) => <span>{convertToHumanReadableDate(getPublishPeriod(_original))}</span>
      }
    ]}
    showPagination={false}
    filterable={false}
    pageSize={results.length}
  />
);
export default BulletinSearchResults;
