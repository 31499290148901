import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import * as orderActions from '../../modules/order';
import * as errorActions from '../../modules/error';
import * as commonActions from '../../modules/common';
import Loading from '../Loading';
import moment from 'moment';
import fileSaver from 'file-saver';
import Translate from '../dictionary/Translator';
import errorDictionary from '../dictionary/error';
import creditStatusMappings from './dictionary/credit_status';
import creditItemStatusMappings from './dictionary/credit_item_status';
import vistexCreditStatusMappings from './dictionary/vistex_credit_status';
import vistexCreditItemStatusMappings from './dictionary/vistex_credit_lineitem_status';
import { translate } from '../dictionary/Translator';
import ui from './dictionary/ui';
import DocumentTitle from 'react-document-title';

class CreditView extends React.Component {

  componentWillMount() {
    this.props.resetFetching();
    this.props.resetError();
    this.props.clearOrderDetails();
    this.props.fetchOrder(translate({ word: ui['LANG_ID'], language: this.props.language }), this.props.match.params.id, this.props.match.params.source);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.invoice && nextProps.invoice !== this.props.invoice) {
      downloadPdf(nextProps);
    }
  }

  componentWillUnmount() {
    this.props.resetError();
  }
  render() {
    const { order, error } = this.props;
    return (
      <DocumentTitle title={`${translate({ word: ui['CREDIT_MEMO_DETAILS'], language: this.props.language })} | Sony ExpressNet`}>
        <div style={{ paddingLeft: '3%', paddingRight: '3%' }}>
          {!error && !order && <Loading />}
          {error && (
            <div className="text-danger">
              <h1 style={{ fontSize: '1.33rem', fontWeight: '450' }}>
                <Translate word={errorDictionary[error.errorPath]} />
              </h1>
            </div>
          )}
          {!error && order && renderOrder({ ...this.props })}
        </div>
      </DocumentTitle>
    );
  }
}

const downloadPdf = props => {
  const { invoice } = props;
  fileSaver.saveAs(b64toBlob(invoice.file, 'base64', 512), invoice.number + '.pdf');
};

function b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

const renderOrder = (props) => {
  const { order, setBackButtonFlag, isFetching, getInvoiceDetails, language } = props;
  const { match: { params: { source } } } = props;
  let invoiceCount = 0;
  let mainStatus = '';
  //To Find the Order status
  // S --GPW , GS -- GSAP -- non - vistex credits
  // V - GPW . GV - GSAP - vistex credits
  if (source === 'S' || source === 'GS') {
    mainStatus =
      order.orderStatus[0].rejectionStatus === 'C'
        ? translate({
          word: ui['STATUS_CANCELLED'],
          language: language
        })
        : translate({
          word:
            creditStatusMappings[
            (order.orderStatus[0].overAllStatus ? order.orderStatus[0].overAllStatus : ' ') +
            (order.orderStatus[0].rejectionStatus ? order.orderStatus[0].rejectionStatus : ' ')
            ],
          language
        });
    mainStatus =
      order.billingBlockCode || order.deliveryBlockCode || order.orderStatus[0].creditStatus === 'B'
        ? translate({
          word: ui['STATUS_CREDIT_UNDER_REVIEW'],
          language: language
        })
        : mainStatus;
  } else {
    mainStatus = translate({
      word: vistexCreditStatusMappings[order.orderStatus],
      language
    });
    mainStatus =
      mainStatus === ''
        ? translate({
          word: ui['STATUS_CREDIT_UNDER_REVIEW'],
          language: language
        })
        : mainStatus;
  }

  return (
    <div>
      <div style={{ paddingBottom: '25px' }}>
        <Link
          to={'/credithistory'}
          role="button"
          className="btn btn-primary"
          style={{ paddingTop: '8px', paddingBottom: '8px', float: 'left' }}
          onClick={() => setBackButtonFlag(true)}
        >
          <Translate word={ui['BACK_BUTTON']} />
        </Link>
      </div>
      <div style={{ paddingBottom: '20px' }}>
        <h1 style={{ fontSize: '2.2rem', fontWeight: '600' }}>
          <Translate word={ui['CREDIT_MEMO_DETAILS']} />
        </h1>
        <h2 style={{ fontSize: '1.8rem', fontWeight: '450' }}>
          <Translate word={ui['CREDIT_MEMO_SUMMARY']} />
        </h2>
      </div>
      <hr size="6" />
      <div className="row">
        <div className="col-sm-4">
          <div className="table-responsive">
            <table>
              <tbody>
                <tr>
                  <td>
                    <strong>
                      <Translate word={ui['CREDIT_TOTAL']} />:
                    </strong>
                  </td>
                  <td className="col-sm-2 text-right">{'$-' + (order.netValue + order.netTax).toFixed(2)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="table-responsive">
            <table>
              <tbody>
                <tr>
                  <td>
                    <strong>
                      <Translate word={ui['CREDIT_REQUEST_NUMBER']} />:
                    </strong>
                  </td>
                  <td className="col-sm-2">{order.id}</td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      <Translate word={ui['REQUEST_DATE']} />:
                    </strong>
                  </td>
                  <td className="col-sm-2">{order.orderDate ? moment(order.orderDate).format('MM/DD/YYYY') : ''}</td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      <Translate word={ui['ORDER_STATUS']} />:
                    </strong>
                  </td>
                  <td className="col-sm-2">{mainStatus}</td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      <Translate word={ui['DEBIT_REFERENCE_NUMBER']} />:
                    </strong>
                  </td>
                  <td className="col-sm-2">{order.po}</td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      <Translate word={ui['COMMENTS']} />:
                    </strong>
                  </td>
                  <td className="col-sm-8">{order.comments}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <hr size="2" />
      <h2 style={{ fontSize: '1.8rem', fontWeight: '450' }}>
        <Translate word={ui['ORDER_LINE_ITEM_SUMMARY']} />
      </h2>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>
                <Translate word={ui['MODEL']} />
              </th>
              <th id="col-header-grade">
                <Translate word={ui['GRADE_INDICATOR']} />
              </th>
              <th>
                <Translate word={ui['MODEL_DESCRIPTION']} />
              </th>
              <th>
                <Translate word={ui['ORDER_STATUS']} />
              </th>
              <th>
                <Translate word={ui['ITEM_COST']} />
              </th>
            </tr>
          </thead>
          <tbody>
            {order.items.map((item, index) => {
              // will check wehter code has .B products or not and then add grade into an Item
              //if source has GPWBstock then will remove .B from model name and add the respective productCode
              let isGPWBStock;
              if (source === 'S') {
                //If Product ends with .B then we remove the .B from model
                isGPWBStock = item.outProductCode.endsWith(".B") ? true : false;
                //if GPWstock is true for GPW vistex and non-vistex credits then add grade
                isGPWBStock === true ? (item.grade = "B") : (item.grade = "A")
              }
              if(source === 'V') {
                // vistex credits will not have B stock Products
                item.grade = "A"
              }

              //Irrespective of source will check wheter it has grade "A" / "B"  after adding grade field in GPW to display the quanity respectively
              let isBStock = item.grade === 'B' ? true : false;
              let itemStatus = '';
              //TO display Line Item Order Status
              if (source === 'S' || source === 'GS') {
                itemStatus =
                  item.status[0].rejectionStatus === 'C'
                    ? translate({
                      word: ui['STATUS_CANCELLED'],
                      language: language
                    })
                    : translate({
                      word:
                        creditItemStatusMappings[
                        (item.status[0].processingStatus ? item.status[0].processingStatus : ' ') +
                        (item.status[0].rejectionStatus ? item.status[0].rejectionStatus : ' ')
                        ],
                      language
                    });
              } else {
                itemStatus = translate({ word: vistexCreditItemStatusMappings[item.lineItemStatus], language });
              }
              return (
                <tr key={index}>
                  <td id={`productCode_${index}`}>{source === 'S' ? (isGPWBStock ? item.outProductCode.slice(0, item.outProductCode.length - 2) : item.outProductCode) : item.productCode}</td>
                  <td id={`gradeValue_${index}`}>{item.grade}</td>
                  <td>{item.shortText}</td>
                  <td>{itemStatus}</td>
                  <td>
                    <table className="table table-bordered table-condensed table-transparent">
                      <tbody>
                        <tr>
                          <td>
                            <Translate word={ui['ITEM_UNIT_PRICE']} />
                          </td>
                          <td>
                            {source === 'S' || source === 'GS'
                              ? (item.price
                                ? item.price.unitPrice === 0
                                  ? '$' + item.price.unitPrice.toFixed(2)
                                  : '$-' + item.price.unitPrice.toFixed(2)
                                : '')
                              : (item.unitPrice
                              ? item.unitPrice === 0 ? '$' + item.unitPrice.toFixed(2) 
                              : '$-' + item.unitPrice.toFixed(2)
                              : '')}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {!isBStock ? <Translate word={ui['ITEM_A_STOCK_QUANTITY']} /> : <Translate word={ui['ITEM_B_STOCK_QUANTITY']} />}
                          </td>
                          <td>{(source === 'S' || source === 'GS') ? item.targetQuantity : item.requestedQuantity}</td>
                        </tr>
                        <tr>
                          <td>
                            <Translate word={ui['ITEM_SUB_TOTAL']} />
                          </td>
                          <td>
                            {(source === 'S' || source === 'GS')
                              ? item.price
                                ? item.price.netValue === 0
                                  ? '$' + item.price.netValue.toFixed(2)
                                  : '$-' + item.price.netValue.toFixed(2)
                                : ''
                              : item.lineSubtotal !== null
                                ? item.lineSubtotal === 0
                                  ? '$' + item.lineSubtotal.toFixed(2)
                                  : '$-' + item.lineSubtotal.toFixed(2)
                                : ''}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Translate word={ui['ITEM_TAX']} />
                          </td>
                          <td>{(source === 'S' || source === 'GS') ? '$' + item.price.tax.toFixed(2) : '$' + item.tax.toFixed(2)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {order.items.forEach(item => {
        invoiceCount = invoiceCount + item.invoice.length;
      })}
      {invoiceCount > 0 ? (
        <div className="table-responsive">
          <hr size="2" />
          <h2 style={{ fontSize: '1.8rem', fontWeight: '450' }}>
            <Translate word={ui['ITEM_INVOICE_INFORMATION']} />
          </h2>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>
                    <Translate word={ui['MODEL']} />
                  </th>
                  <th>
                  <Translate word={ui['GRADE_INDICATOR']} />
                  </th>
                  <th>
                    <Translate word={ui['CREDIT_MEMO_NUMBER']} />
                  </th>
                  <th>
                    <Translate word={ui['REQUEST_DATE']} />
                  </th>
                  <th>
                    <Translate word={ui['ITEM_INVOICE_QUANTITY']} />
                  </th>
                  <th>
                    <Translate word={ui['ITEM_SUB_TOTAL']} />
                  </th>
                  <th>
                    <Translate word={ui['ITEM_TAX']} />
                  </th>
                  <th>
                    <Translate word={ui['ITEM_TOTAL']} />
                  </th>
                  {
                    source === 'GV' ?
                      <th>
                        <Button
                              bsStyle="primary"
                              className="btn-margin"
                              onClick={() => getInvoiceDetails(translate({ word: ui['LANG_ID'], language: language }), order.id, order.items[0].invoice[0].invoiceNumber,source)}
                              disabled={isFetching}
                            >
                              <Translate word={ui['DOWNLOAD_INVOICE_PDF_BUTTON']} />
                            </Button>
                      </th> : <td />
                  }
                </tr>
              </thead>
              <tbody>
                {order.items.map((item, index) => {
                  return item.invoice.map((inv, i) => {
                    let isGPWBStock = inv.material.endsWith(".B") ? true : false;
                    return (
                      <tr key={i}>
                        <td>{!isGPWBStock ? inv.material : inv.material.slice(0, inv.material.length - 2)}</td>
                        <td>{item.grade}</td>
                        <td>{inv.invoiceNumber}</td>
                        <td>{inv.creationDate ? moment(inv.creationDate).format('MM/DD/YYYY') : ''}</td>
                        <td>{inv.invoicedQuantity}</td>
                        <td>{inv.lineNetValue === 0 ? '$' + inv.lineNetValue.toFixed(2) : '$-' + inv.lineNetValue.toFixed(2)}</td>
                        <td>{'$' + inv.lineTax.toFixed(2)}</td>
                        <td>
                          {inv.lineNetValue + inv.lineTax === 0
                            ? '$' + (inv.lineNetValue + inv.lineTax).toFixed(2)
                            : '$-' + (inv.lineNetValue + inv.lineTax).toFixed(2)}
                        </td>
                        <td>
                          {source !== 'V' && source !== 'GV' ? (
                            <Button
                              bsStyle="primary"
                              className="btn-margin"
                              onClick={() => getInvoiceDetails(translate({ word: ui['LANG_ID'], language: language }), order.id, inv.invoiceNumber,source)}
                              disabled={isFetching}
                            >
                              <Translate word={ui['DOWNLOAD_INVOICE_PDF_BUTTON']} />
                            </Button>
                          ) : ''}
                        </td>
                      </tr>
                    )
                  }
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        ''
      )}
      <hr size="8" />
      <Link
        to={'/credithistory'}
        role="button"
        className="btn btn-primary"
        style={{ paddingTop: '8px', paddingBottom: '8px', float: 'left' }}
        onClick={() => setBackButtonFlag(true)}
      >
        <Translate word={ui['BACK_BUTTON']} />
      </Link>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    order: state.order.order,
    isFetching: state.common.isFetching,
    invoice: state.order.invoice,
    error: state.error.error_obj,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchOrder: (language, orderId, source) => dispatch(orderActions.fetchOrder(language, orderId, source)),
    setBackButtonFlag: resetFlag => dispatch(orderActions.setBackButtonFlag(resetFlag)),
    getInvoiceDetails: (language, orderId, invoiceNumber,source) => dispatch(orderActions.getInvoiceDetails(language, orderId, invoiceNumber,source)),
    clearOrderDetails: () => dispatch(orderActions.clearOrderDetails()),
    resetError: () => dispatch(errorActions.resetError()),
    resetFetching: () => dispatch(commonActions.resetFetching())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreditView);
