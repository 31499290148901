import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import Home from './Home';
import OrderView from './order/OrderView';
import ReturnView from './order/ReturnView';
import CreditView from './order/CreditView';
import OrderSimulate from './order/OrderSimulate';
import OrderHistory from './order/OrderHistory';
import CreditHistory from './order/CreditHistory';
import ReturnHistory from './order/ReturnHistory';
import Promotion from './order/Promotion';
import PriceBook from './pricebook/PriceBook';
import ProductList from './product/ProductList';
import Callback from './auth/Callback';
import ProductDetailWrapper from './product/ProductDetailWrapper';
import MyAccount from './myaccount/myAccount';
import AccountSearch from './admin/AccountSearch';
import BulletinSearch from './bulletin/BulletinSearch';
import Header from './Header';
import Login from './Login';
import AppErrorBoundary from './errors/AppErrorBoundary';
import Footer from './Footer/Footer';
import FAQ from './Help/FAQ';
import ContactUs from './Help/ContactUs';
import SonyLinks from './Help/SonyLinks';
import GoUpButton from './GoUpButton/GoUpButton';
import withProtectRoutes from './HOCProtectRoutes/withProtectRoutes';
import DocumentTitle from 'react-document-title';
import Helmet from 'react-helmet';
import { ToastContainer, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SecondaryHeader from './header/SecondaryHeader';
import EnvironmentDisclaimer from './EnvironmentDisclaimer/EnvironmentDisclaimer';
import { unConfirmTheDisclaimer } from '../modules/environment';
import UnavilableHandler from './UnavailableHandler/UnavailableHandler';
import * as accountActions from '../modules/account';
import { resetFetching } from '../modules/common';
import { translate } from './dictionary/Translator';
import ui from './dictionary/ui.json';
import { removeAttribute } from './utils/renderUtils';

class App extends Component {
  componentDidMount() {
    console.log('Entered component did mount:::::::::');
    this.props.resetFetching();
    if (!this.props.token) {
      this.props.unConfirmTheDisclaimer();
    }
    if (this.props.userTemp && !this.props.priceBook && this.props.isAdmin) {
      this.props.loadAccount({ user: this.props.userTemp.id, buyer: this.props.userTemp.buyerId }, this.props.country);
    }
  }
  render() {
    const user = this.props.user;
    const language = this.props.language;

    const ProtectedRoute = ({ component: Component, ...rest }) => {
      return (
        <Route
          {...rest}
          render={props =>
            user ? (
              <AppErrorBoundary>
                <Component {...props} />
              </AppErrorBoundary>
            ) : (
              <Redirect to="/" />
            )
          }
        />
      );
    };

    const ProtectedAdminRoute = ({ component: Component, ...rest }) => {
      return (
        <Route
          {...rest}
          render={props =>
            this.props.isAdmin && this.props.token ? (
              <AppErrorBoundary>
                <Component {...props} />
              </AppErrorBoundary>
            ) : (
              <Redirect to="/" />
            )
          }
        />
      );
    };
    // force update
    // setTimeout(() => {
      removeAttribute();
    // }, 0);
    return (
      <DocumentTitle title="Sony ExpressNet">
        <div className="body-wrapper">
          <UnavilableHandler>
            <ToastContainer position="top-right" autoClose={2500} hideProgressBar={true} newestOnTop={true} closeOnClick={true} transition={Flip} />
            <Helmet htmlAttributes={{ lang: this.props.metaLang }} />
            <Header />
            <SecondaryHeader />
            {this.props.showDisclaimer && !this.props.disclaimerConfirmed && <EnvironmentDisclaimer />}
            <div className="container-full-height">
              <Switch>
                <Route
                  exact
                  path="/"
                  render={props =>
                    this.props.token ? (
                      <AppErrorBoundary>
                        <Home {...props} />
                      </AppErrorBoundary>
                    ) : (
                      <Login {...props} />
                    )
                  }
                />
                <Route path="/callback" component={Callback} />
                <ProtectedRoute path="/orderview/:source/:id" component={OrderView} />
                <ProtectedRoute path="/returnview/:source/:id" component={ReturnView} />
                <ProtectedRoute path="/creditview/:source/:id" component={CreditView} />
                <ProtectedRoute path="/ordersimulate" component={OrderSimulate} />
                <ProtectedRoute path="/orderhistory" component={OrderHistory} />
                <ProtectedRoute path="/credithistory" component={CreditHistory} />
                <ProtectedRoute path="/returnhistory" component={ReturnHistory} />
                <ProtectedRoute path="/promocredit" component={Promotion} />
                <ProtectedRoute path="/pricebook" component={PriceBook} />
                <ProtectedRoute path="/myaccount" component={MyAccount} />
                <ProtectedRoute path="/productdetail/:sku" component={ProductDetailWrapper} />
                <ProtectedRoute path="/productlist/:subcatid" component={ProductList} />
                <ProtectedRoute path="/bulletinsearch" component={withProtectRoutes(BulletinSearch, this.props)} />
                <ProtectedRoute path="/faq" component={FAQ} />
                <ProtectedRoute path="/contact-us" component={ContactUs} />
                <ProtectedRoute path="/sony-links" component={SonyLinks} />
                <ProtectedAdminRoute path="/admin/accountSearch" component={AccountSearch} />
                <Route path="*" render={() => <Redirect to="/" />} />
              </Switch>
              <GoUpButton />
              <Footer className="Footer" 
                message={!this.props.token ? 
                  translate({ language, dictionary: ui.FOOTER, word: 'BROWSER_MESSAGE' }) : null } 
              />
            </div>
          </UnavilableHandler>
        </div>
      </DocumentTitle>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    unConfirmTheDisclaimer: () => dispatch(unConfirmTheDisclaimer()),
    loadAccount: (params, country) => dispatch(accountActions.loadAccount(params, country)),
    resetFetching: () => dispatch(resetFetching())
  };
};

const mapStateToProps = (state, props) => {
  return {
    user: state.account.user,
    contract: state.account.contract,
    isAdmin: state.auth.isAdmin,
    token: state.auth.token,
    metaLang: state.language === 'en_CA' || state.language === 'en_US' ? 'en' : 'fr',
    showDisclaimer: state.environment.show,
    disclaimerConfirmed: state.environment.confirmed,
    priceBook: state.account.priceBook,
    country: state.country,
    userTemp: state.account.userTemp,
    language: state.language
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
