import React, { Component } from 'react';

class StickyContainer extends Component {
  state = {
    show: false
  };
  componentDidMount() {
    window.addEventListener('scroll', this.trackScroll);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.trackScroll);
  }

  trackScroll = () => {
    if (document.documentElement.scrollTop > Math.max(document.documentElement.clientHeight, window.innerHeight || 0)) {
      this.setState({ show: true });
    } else {
      this.setState({ show: false });
    }
  };
  render() {
    const { show } = this.state;
    const { top, bottom, left, right } = this.props;
    return show ? <div style={{ position: 'fixed', left, bottom, top, right }}>{this.props.children}</div> : null;
  }
}

export default StickyContainer;
