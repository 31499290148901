import { change } from 'redux-form';
import { getHttp } from './utils';
import config from '../config';
//import ui from '../components/cart/dictionary/ui';
//import { translate } from '../components/dictionary/Translator';
//import { successToast, errorToast } from './utils';

// Reducer
export default (state = { items: [] }, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const saveCart = (cart, user, contract, language) => {
  return (dispatch, state) => {
    const http = getHttp(dispatch, state);

    var cartApi = config.enAPI + 'cart/' + contract.salesOrg + '/' + contract.division + '/' + contract.channel + '/' + user.buyerId + '/' + user.id;
    http
      .post(cartApi, cart)
      .then(response => {
        //successToast(translate({ word: ui['CART_SAVE_NOTIFICATION_SUCCESS'], language }));
      })
      .catch(error => {
        //errorToast(translate({ word: ui['CART_SAVE_NOTIFICATION_FAIL'], language }));
      });
  };
};

export const getCart = (user, contract, pricebook, language) => {
  return (dispatch, state) => {
    const http = getHttp(dispatch, state);

    var cartApi = config.enAPI + 'cart/' + contract.salesOrg + '/' + contract.division + '/' + contract.channel + '/' + user.buyerId + '/' + user.id;
    http
      .get(cartApi)
      .then(response => {
        let itemArray = [];
        let items = response.data;
        for (let i = 0; i < items.length; i++) {
          var element = pricebook.find(x => x.sku === items[i].sku);
          if (element) {
            itemArray.push(items[i]);
          }
        }
        dispatch(updateCart(itemArray));
      })
      .catch(error => {
        console.log(error);
      });
  };
};

export const updateCart = items => {
  return dispatch => {
    dispatch(change('cart', 'items', items));
  };
};
