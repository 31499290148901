import React from 'react';
import { Navbar, Nav, NavItem, NavDropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as authActions from '../modules/auth';
import Catalog from './catalog/Catalog';
import Logo from './header/Logo';
import Translate from './dictionary/Translator';
import ui from './dictionary/ui';
import './headerfooter.css';
import './Header.css';
import { shoudlShowBulletin } from './bulletin/BulletinHelper';

class Header extends React.Component {
  login() {
    this.props.login();
  }

  logout() {
    this.props.logout();
  }

  adminLogout() {
    if (this.props.isAdmin) {
      this.props.logout(true);
      this.props.history.push('/admin/accountSearch');
    } else {
      this.logout();
    }
  }
  render() {
    return (
      <div className="Header">
        <Navbar inverse>
          <div className={`Header__container ${this.props.user ? 'Header__container--less-bottom' : null}`}>
            <div className="Header__left">
              <Logo />
            </div>
            <div className="Header__middle">
              <ActionMenus {...this.props} onLogout={this.logout.bind(this)} />
            </div>
            <div className="Header__right">
              {!this.props.user &&
                this.props.isAdmin &&
                this.props.token && (
                  <Navbar.Collapse>
                    <Nav pullRight>
                      <AdminDropDown {...this.props} onLogout={this.logout.bind(this)} />
                    </Nav>
                  </Navbar.Collapse>
                )}
              {this.props.user && <UserMenu {...this.props} onAdminLogout={this.adminLogout.bind(this)} />}
              {!this.props.user &&
                this.props.token &&
                !this.props.isAdmin && (
                  <Navbar.Collapse>
                    <Nav pullRight>
                      <NavItem onClick={this.adminLogout.bind(this)}>
                        <Translate dictionary={ui.BTN} word="LOG_OUT" />
                      </NavItem>
                    </Nav>
                  </Navbar.Collapse>
                )}
            </div>
          </div>
          <Navbar.Toggle />
        </Navbar>
      </div>
    );
  }
}
const UserMenu = props => (
  <React.Fragment>
    <Nav className="Header__user">
      <NavDropdown
        id="basic-nav-dropdown"
        title={
          <React.Fragment>
            <span className="Header__user__self">
              <Translate dictionary={ui.HEADER} word="WELCOME" />
              {`, `}
              {props.user.firstName} {props.user.lastName}
            </span>
            <br />
            <span className="Header__user__company">
              {props.buyer.id} - {props.buyer.name}
            </span>
          </React.Fragment>
        }
      >
        <LinkContainer to="/myaccount">
          <li role="presentation"> 
            <a href="/myaccount" role="menuitem"><Translate dictionary={ui.HEADER} word="MY_ACCOUNT" /></a>
          </li>
        </LinkContainer>
        <li role="presentation" onClick={props.onAdminLogout} tabindex="0">
          <div class="logout_wcag" role="menuitem">
            <Translate dictionary={ui.BTN} word="LOG_OUT" />
          </div>
        </li> 
      </NavDropdown>
    </Nav>
  </React.Fragment>
);


const ActionMenus = props => {
  return (
    <div>
      <div className="Header__menues">
        <Navbar.Collapse>
          <Nav>
            {props.user && <Catalog />}
            {/* Bulletin user cannot see History menu */}
            {props.user && props.user.role !== 'XBU' && (
                <NavDropdown title={<Translate dictionary={ui.HEADER} word="HISTORY" />} id="basic-nav-dropdown">
                  <LinkContainer to="/orderhistory">
                   <li role="presentation"><a href="/orderhistory" role="menuitem"> {<Translate dictionary={ui.HEADER} word="HISTORY_ORDER" />} </a></li>
                  </LinkContainer>
                  <LinkContainer to="/credithistory">
                   <li role="presentation"><a href="/credithistory" role="menuitem"> {<Translate dictionary={ui.HEADER} word="HISTORY_CREDIT" />} </a></li>
                  </LinkContainer>
                  <LinkContainer to="/returnhistory">
                   <li role="presentation"><a href="/returnhistory" role="menuitem"> {<Translate dictionary={ui.HEADER} word="HISTORY_RETURN" />} </a></li>
                  </LinkContainer>
                </NavDropdown>
            )
              }
            {props.user &&
              !(props.user.role === 'XBU' || props.user.role === 'XVO') && (
                <LinkContainer to="/promocredit">
                  <li><a href="/promocredit" >{<Translate dictionary={ui.HEADER} word="CLAIM_REQUEST" />}</a></li>
                </LinkContainer>   
              )}
            {props.user && (
              <LinkContainer to="/pricebook">
                <li><a href="/pricebook" >{<Translate dictionary={ui.HEADER} word="PRICE_BOOK" />}</a></li>
              </LinkContainer>
            )}
            {/* Only View Only, Bulletin, Principal, Purchasing agent, Manager can view bulletins */}
            {shoudlShowBulletin(props) && (
              <LinkContainer to="/bulletinsearch">
                <li><a href="/bulletinsearch" >{<Translate dictionary={ui.HEADER} word="BULLETINS" />}</a></li>
              </LinkContainer>
            )}
            {props.user && <HelpDropDown />}
            {props.user && props.isAdmin && <AdminDropDown {...props} onClick={props.onLogout} />}
          </Nav>
        </Navbar.Collapse>
      </div>
    </div>
  );
};

const AdminDropDown = props => (
  <NavDropdown className="Header__item" title="Admin" id="basic-nav-dropdown">
    <LinkContainer to="/admin/accountSearch">
      <li role="presentation">
         <a href="/admin/accountSearch" role="menuitem"> {<Translate dictionary={ui.HEADER} word="ADMIN_ACCOUNT_SEARCH" />} </a>
      </li>
    </LinkContainer>
    <li role="presentation" onClick={props.onLogout} tabindex="0">
      <div class="logout_wcag" role="menuitem"> { <Translate dictionary={ui.BTN} word="LOG_OUT" />}</div>
    </li>
  </NavDropdown>
);

const HelpDropDown = () => (
    <LinkContainer to="/contact-us">
      <li><a href="/contact" >
        <Translate dictionary={ui.HEADER} word="CONTACT_US" />
       </a></li>
    </LinkContainer>
);
const mapStateToProps = (state, props) => {
  return {
    user: state.account.user,
    contract: state.account.contract,
    isAdmin: state.auth.isAdmin,
    token: state.auth.token,
    country: state.country,
    buyer: state.account.buyer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    login: () => dispatch(authActions.login()),
    logout: isAdmin => dispatch(authActions.logout(isAdmin))
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Header)
);
